import { fetchEventSource } from '@microsoft/fetch-event-source';
import { Message } from 'element-ui'
import store from '../store';
import axios from 'axios'
//  import store from '../store';
let controller = new AbortController()
let signal = controller.signal
// let content = ''
// let roomUid=''
const mixinToolkitFench = {
  data () {
    return {
      toolkitFetchData: {
        imageUrl: ''
      },
      toolkitCode: '',
      docFetchData: '',
      pptFetchData: '',
      compressFetchData: '',
      PDFFetchData: '',
    }
  },
  methods: {
    getToolkitData (url, data) {
      axios.post(
        url,
        data,
        {
          timeout: 60000 * 10,
          headers: {
            "content-type": "application/json",
            accept: "text/event-stream"
          },
          onDownloadProgress: progressEvent => {
            const response = progressEvent.event.target.response;
            const lines = response.split('data:').filter(line => !!line)
            const lastLine = lines[lines.length - 1]
            console.log('lastLine', JSON.parse(lastLine))
            let data = JSON.parse(lastLine)
            this.toolkitFetchData = JSON.parse(lastLine)
            this.toolkitCode = data?.code || null
            if (this.toolkitCode != null && this.toolkitCode != 200) {
              this.$message.error({ message: data?.message, duration: 4000 })
              // this.$bus.emit('toolkitDrawError', data?.message)
            }
          }
        }
      ).then(response => {
        this.$store.dispatch("CheckLogin")
        this.getChargerule()
        this.toolkitCode = response.data.code || null
        if (this.toolkitCode != null && this.toolkitCode != 200) {
          this.$message.error({ message: response?.data?.message, duration: 4000 })
          // this.$bus.emit('toolkitDrawError', response.data.message)
        }
      }).catch(err => {
        this.toolkitCode = err.response?.status || null
        if (this.toolkitCode != null && this.toolkitCode != 200) {
          this.$message.error({ message: err?.response?.data?.message, duration: 4000 })
        }
        // this.$bus.emit('toolkitDrawError', err.response?.data.message)
      })
    },
    getDocData (url, data) {
      axios.post(
        url,
        data,
        {
          timeout: 60000 * 10,
          headers: {
            "content-type": "application/json",
            accept: "text/event-stream"
          },
          onDownloadProgress: progressEvent => {
            const response = progressEvent.event.target.response;
            const lines = response.split('data:').filter(line => !!line)
            const lastLine = lines[lines.length - 1]
            console.log('lastLine', JSON.parse(lastLine))
            let data = JSON.parse(lastLine)
            this.docFetchData = JSON.parse(lastLine)
            this.toolkitCode = data?.code || null
            if (this.toolkitCode != null && this.toolkitCode != 200) {
              this.$message.error({ message: data?.message, duration: 4000 })
              // this.$bus.emit('toolkitDrawError', data?.message)
            }
          }
        }
      ).then(response => {
        this.$store.dispatch("CheckLogin")
        this.getChargerule()
        this.toolkitCode = response?.data?.code || null
        if (this.toolkitCode != null && this.toolkitCode != 200) {
          this.$message.error({ message: response?.data?.message, duration: 4000 })
          // this.$bus.emit('toolkitDrawError', response?.data.message)
        }
      }).catch(err => {
        this.toolkitCode = err.response?.status || null
        if (this.toolkitCode != null && this.toolkitCode != 200) {
          this.$message.error({ message: err?.response?.data?.message, duration: 4000 })
        }
        // this.$bus.emit('toolkitDrawError', err.response?.data.message)
      })
    },
    getPPTData (url, data) {
      axios.post(
        url,
        data,
        {
          timeout: 60000 * 10,
          headers: {
            "content-type": "application/json",
            accept: "text/event-stream"
          },
          onDownloadProgress: progressEvent => {
            const response = progressEvent.event.target.response;
            const lines = response.split('data:').filter(line => !!line)
            const lastLine = lines[lines.length - 1]
            console.log('lastLine', JSON.parse(lastLine))
            let data = JSON.parse(lastLine)
            this.pptFetchData = JSON.parse(lastLine)
            this.toolkitCode = data?.code || null
            if (this.toolkitCode != null && this.toolkitCode != 200) {
              this.$message.error({ message: data?.message, duration: 4000 })
              // this.$bus.emit('toolkitDrawError', data?.message)
            }
          }
        }
      ).then(response => {
        this.$store.dispatch("CheckLogin")
        this.getChargerule()
        this.toolkitCode = response?.data?.code || null
        if (this.toolkitCode != null && this.toolkitCode != 200) {
          this.$message.error({ message: response?.data?.message, duration: 4000 })
          // this.$bus.emit('toolkitDrawError', response?.data?.message)
        }
      }).catch(err => {
        this.toolkitCode = err.response?.status || null
        if (this.toolkitCode != null && this.toolkitCode != 200) {
          this.$message.error({ message: err?.response?.data?.message, duration: 4000 })
        }
        // this.$bus.emit('toolkitDrawError', err.response?.data.message)
      })
    },
    getCompressData (url, data) {
      axios.post(
        url,
        data,
        {
          timeout: 60000 * 10,
          headers: {
            "content-type": "application/json",
            accept: "text/event-stream"
          },
          onDownloadProgress: progressEvent => {
            const response = progressEvent.event.target.response;
            const lines = response.split('data:').filter(line => !!line)
            const lastLine = lines[lines.length - 1]
            console.log('lastLine', JSON.parse(lastLine))
            let data = JSON.parse(lastLine)
            this.compressFetchData = JSON.parse(lastLine)
            this.toolkitCode = data?.code || null
            if (this.toolkitCode != null && this.toolkitCode != 200) {
              this.$message.error({ message: data?.message, duration: 4000 })
              // this.$bus.emit('toolkitDrawError', data?.message)
            }
          }
        }
      ).then(response => {
        this.$store.dispatch("CheckLogin")
        this.getChargerule()
        this.toolkitCode = response?.data?.code || null
        if (this.toolkitCode != null && this.toolkitCode != 200) {
          this.$message.error({ message: response?.data?.message, duration: 4000 })
          // this.$bus.emit('toolkitDrawError', response.data.message)
        }
      }).catch(err => {
        this.toolkitCode = err.response?.status || null
        if (this.toolkitCode != null && this.toolkitCode != 200) {
          this.$message.error({ message: err?.response?.data?.message, duration: 4000 })
        }
        // this.$bus.emit('toolkitDrawError', err.response?.data.message)
      })
    },
    getPDFData (url, data) {
      axios.post(
        url,
        data,
        {
          timeout: 60000 * 10,
          headers: {
            "content-type": "application/json",
            accept: "text/event-stream"
          },
          onDownloadProgress: progressEvent => {
            const response = progressEvent.event.target.response;
            const lines = response.split('data:').filter(line => !!line)
            const lastLine = lines[lines.length - 1]
            console.log('lastLine', JSON.parse(lastLine))
            let data = JSON.parse(lastLine)
            this.PDFFetchData = JSON.parse(lastLine)
            this.toolkitCode = data?.code || null
            if (this.toolkitCode != null && this.toolkitCode != 200) {
              this.$message.error({ message: data?.message, duration: 4000 })
              // this.$bus.emit('toolkitDrawError', data?.message)
            }
          }
        }
      ).then(response => {
        this.$store.dispatch("CheckLogin")
        this.getChargerule()
        this.toolkitCode = response?.data?.code || null
        if (this.toolkitCode != null && this.toolkitCode != 200) {
          this.$message.error({ message: response.data.message, duration: 4000 })
          // this.$bus.emit('toolkitDrawError', response.data.message)
        }
      }).catch(err => {
        this.toolkitCode = err.response?.status || null
        if (this.toolkitCode != null && this.toolkitCode != 200) {
          this.$message.error({ message: err?.response?.data?.message, duration: 4000 })
        }
        // this.$bus.emit('toolkitDrawError', err.response?.data.message)
      })
    }
  },
  //   created() {
  //     console.log(this.msg3)
  //   }
}
export default mixinToolkitFench
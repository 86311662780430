<template>
  <div
    class="aidraw"
    @click="handleClose"
  >
    <div class="aidraw_container">
      <div class="main_left">
        <div class="title">
          <span class="name">AI绘画</span>
        <!-- <span>查看案例</span> -->
        </div>
        <div class="tabs">
          <div
            class="item"
            :class="activeTab == '1' ? 'active' : ''"
            @click="handleChangeTab('1')"
          >
            <div :class="activeTab == '1' ? 'active_bg' : ''">
              快速绘画
            </div>
          </div>
          <div
            class="item"
            :class="activeTab == '2' ? 'active active2' : ''"
            @click="handleChangeTab('2')"
          >
            <div :class="activeTab == '2' ? 'active_bg2' : ''">
              热门功能
            </div>
          </div>
        </div>
        <div
          v-if="activeTab == '1'"
          class="tabs_pane"
        >
          <div class="main">
            <div class="speciality">
              <div class="label">
                <span>专业模式</span>
                <el-tooltip
                  :visible-arrow="false"
                  placement="top"
                  content="专业模式可以更直观地完成多项绘画参数设置，可以通过阅读“绘图咒语”掌握更多技巧"
                >
                  <span>
                    <img
                      slot="reference"
                      class="img"
                      src="@/static/index/tip.png"
                    >
                  </span>
                </el-tooltip>
              </div>
              <el-switch
                v-model="isSpeciality"
                class="switch"
                active-color="#1467FF"
              />
            </div>
            <div class="desc">
              <div class="label">
                <span>绘画描述</span>
                <el-tooltip
                  :visible-arrow="false"
                  placement="top"
                  content="必填，建议参考的描述结构：内容主体+场景描述+细节补充"
                >
                  <span>
                    <img
                      slot="reference"
                      class="img"
                      src="@/static/index/tip.png"
                    >
                  </span>
                </el-tooltip>
              </div>
              <el-input
                v-model="prompt"
                type="textarea"
                :class="errorNotice ? 'error_input' : ''"
                resize="none"
                placeholder="示例：在火车站，一只戴着墨镜的加菲猫，正在吃汉堡"
              />
              <div
                v-show="errorNotice"
                class="error_notice"
              >
                *该项为必填，请输入内容
              </div>
            </div>
            <div
              v-scrollActive
              class="main_bottom"
            >
              <div
                v-show="isSpeciality"
                class="reference"
              >
                <div class="label">
                  <span>上传参考图</span>
                  <el-tooltip
                    :visible-arrow="false"
                    placement="top"
                    content="上传参考图后，可根据参考图生成类似风格的绘画作品，最大支持上传3张参考图"
                  >
                    <span>
                      <img
                        slot="reference"
                        class="img"
                        src="@/static/index/tip.png"
                      >
                    </span>
                  </el-tooltip>
                  <span style="color: #ACB1BF;">选填</span>
                </div>
                <div class="upload">
                  <el-upload
                    :action="'/attachments/img/upload'"
                    list-type="picture-card"
                    accept=".jpg,.png"
                    :show-file-list="false"
                    :auto-upload="true"
                    :on-success="handleSuccess"
                    :multiple="true"
                    :limit="3"
                    :file-list="fileList"
                    :on-exceed="handleOnExceed"
                    :before-upload="beforeUpload"
                    :on-error="handleError"
                  >
                    <div class="upload_img">
                      <img
                        v-if="!showFileListLoading"
                        src="../../static/ai/upload.png"
                        alt=""
                      >
                      <img
                        v-else
                        class="upload_loading"
                        src="../../static/ai/loading2.png"
                        alt=""
                      >
                    </div>
                  </el-upload>
                  <div class="file_list">
                    <div
                      v-for="item, idx in fileList"
                      v-show="fileList.length > 0"
                      :key="item.uid + idx"
                      class="item"
                    >
                      <img
                        :src="item.url"
                        alt=""
                      >
                      <div
                        class="delete"
                        @click="handleDeleteUpload(item)"
                      >
                        <span><i class="el-icon-delete" /></span>
                      </div>
                    </div>
                    <div v-show="fileList.length == 0">
                      <p>上传参考图</p>
                      <p>最多3张，每张3M内，支持JPG/PNG格式</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-show="!isSpeciality"
                class="style"
              >
                <div class="label">
                  <span>艺术风格</span>
                  <el-tooltip
                    :visible-arrow="false"
                    placement="top"
                    content="选填，可根据绘画内容选择风格，或输入您想要的风格名称。选择“随机”，将根据描述语智能匹配风格"
                  >
                    <span>
                      <img
                        slot="reference"
                        class="img"
                        src="@/static/index/tip.png"
                      >
                    </span>
                  </el-tooltip>
                  <span style="color: #ACB1BF;">选填</span>
                </div>
                <div class="image">
                  <div
                    v-for="item in styleList"
                    :key="item.id"
                    :class="item.checked ? 'item_checked' : ''"
                    class="item"
                    @click="handleChangeStyle(item)"
                  >
                    <img
                      v-if="item.text === '随机'"
                      :src="require('@/static/ai/'+ item.iconLink)"
                      alt=""
                    >
                    <img
                      v-else
                      :src="item.iconLink"
                      alt=""
                    >
                    <div class="style_label">
                      <span>{{ item.text }}</span>
                    </div>
                    <div class="mask" />
                    <div
                      v-show="item.checked"
                      class="check"
                    >
                      <img
                        src="../../static/ai/checked.png"
                        alt=""
                      >
                    </div>
                  </div>
                  <el-input
                    v-model="styleCustom"
                    placeholder="点击输入自定义艺术风格"
                    @input="handleChangeStyleCustom"
                  />
                </div>
              </div>
              <div
                v-show="isSpeciality"
                class="inputs"
              >
                <div class="label">
                  <span>绘画风格</span>
                  <el-tooltip
                    :visible-arrow="false"
                    placement="top"
                    content="设置绘画风格后，作品会具有相应风格的特点，可叠加"
                  >
                    <span>
                      <img
                        slot="reference"
                        class="img"
                        src="@/static/index/tip.png"
                      >
                    </span>
                  </el-tooltip>
                  <span style="color: #ACB1BF;">选填</span>
                </div>
                <!-- <div class="input"> -->
                <inputTags
                  ref="artisticStyles"
                  :label="'绘画风格'"
                />
              <!-- </div> -->
              </div>
              <div
                v-show="isSpeciality"
                class="inputs"
              >
                <div class="label">
                  <span>艺术家</span>
                  <el-tooltip
                    :visible-arrow="false"
                    placement="top"
                    content="设置艺术家后，会按照该艺术家的绘画特点进行创作。可叠加"
                  >
                    <span>
                      <img
                        slot="reference"
                        class="img"
                        src="@/static/index/tip.png"
                      >
                    </span>
                  </el-tooltip>
                  <span style="color: #ACB1BF;">选填</span>
                </div>
                <!-- <div class="input">
                  <el-input v-model="styleCustom"></el-input>
                </div> -->
                <inputTags
                  ref="artists"
                  :label="'艺术家名字'"
                />
              </div>
              <div
                v-show="isSpeciality"
                class="inputs"
              >
                <div class="label">
                  <span>构图</span>
                  <el-tooltip
                    :visible-arrow="false"
                    placement="top"
                    content="设置构图后，会按照该效果对画面中的内容进行相应的排布。可叠加"
                  >
                    <span>
                      <img
                        slot="reference"
                        class="img"
                        src="@/static/index/tip.png"
                      >
                    </span>
                  </el-tooltip>
                  <span style="color: #ACB1BF;">选填</span>
                </div>
                <inputTags
                  ref="compositions"
                  :label="'构图方式'"
                />
              </div>
              <div
                v-show="isSpeciality"
                class="inputs"
              >
                <div class="label">
                  <span>光线</span>
                  <el-tooltip
                    :visible-arrow="false"
                    placement="top"
                    content="设置光线效果后，作品将呈现出相应光线的特点，让画面更有生命力。可叠加"
                  >
                    <span>
                      <img
                        slot="reference"
                        class="img"
                        src="@/static/index/tip.png"
                      >
                    </span>
                  </el-tooltip>
                  <span style="color: #ACB1BF;">选填</span>
                </div>
                <inputTags
                  ref="lightings"
                  :label="'光线效果'"
                />
              </div>
              <div
                v-show="isSpeciality"
                class="inputs"
              >
                <div class="label">
                  <span>渲染器</span>
                  <el-tooltip
                    :visible-arrow="false"
                    placement="top"
                    content="渲染器能够在一定程度上为作品赋予风格特征，对画面产生“润色”的效果。可叠加"
                  >
                    <span>
                      <img
                        slot="reference"
                        class="img"
                        src="@/static/index/tip.png"
                      >
                    </span>
                  </el-tooltip>
                  <span style="color: #ACB1BF;">选填</span>
                </div>
                <inputTags
                  ref="renderers"
                  :label="'渲染风格'"
                />
              </div>
              <div class="scale">
                <div class="label">
                  <span>画面比例（宽：高）</span>
                  <el-tooltip
                    :visible-arrow="false"
                    placement="top"
                    content="选填，可以选择预设比例，或输入自定义比例。未选择的情况下将默认输出 1:1 的图片"
                  >
                    <span>
                      <img
                        slot="reference"
                        class="img"
                        src="@/static/index/tip.png"
                      >
                    </span>
                  </el-tooltip>
                  <span style="color: #ACB1BF;">选填</span>
                </div>
                <div class="choose">
                  <div
                    v-for="item in scaleList"
                    :key="item.label"
                    class="item"
                    :class="item.checked ? 'item_checked' : ''"
                    @click="handleChangeScale(item)"
                  >
                    <div v-if="item.label !== '自定义'">
                      <div class="top">
                        <img
                          :src="require('@/static/ai/'+ item.imgSrc)"
                          alt=""
                        >
                      </div>
                      <span>{{ item.label }}</span>
                      <div class="bottom">
                        <span>{{ item.scale }}</span>
                      </div>
                      <div
                        v-show="item.checked"
                        class="check"
                      >
                        <img
                          src="../../static/ai/checked.png"
                          alt=""
                        >
                      </div>
                    </div>
                    <div v-else>
                      <div class="custom">
                        <span>{{ item.label }}</span>
                        <div
                          class="inputs"
                          @click.stop="handleClick(item)"
                        >
                          <el-input
                            v-model="width"
                            class="input"
                            @input="width=width.replace(/[^\d]/g,'')"
                            @change="handleChangeWidth"
                          />
                          <span> : </span>
                          <el-input
                            v-model="height"
                            class="input"
                            @input="height=height.replace(/[^\d]/g,'')"
                            @change="handleChangeHeight"
                          />
                        </div>
                      </div>
                      <div
                        v-show="item.checked"
                        class="check"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-show="errorScale"
                  class="error_notice"
                >
                  *比例不支持，请输入小于2:1的比例数值
                </div>
              </div>
              <div
                v-show="isSpeciality"
                class="inputs"
              >
                <div class="label">
                  <span>内容排除 (--no)</span>
                  <el-tooltip
                    :visible-arrow="false"
                    placement="top"
                    content="设置后可以尽可能避免画面出现所输入的内容。可叠加"
                  >
                    <span>
                      <img
                        slot="reference"
                        class="img"
                        src="@/static/index/tip.png"
                      >
                    </span>
                  </el-tooltip>
                  <span style="color: #ACB1BF;">选填</span>
                </div>
                <div class="input">
                  <el-input
                    v-model="contentExclusions"
                    placeholder="输入不希望出现的内容，按回车键完成输入"
                  />
                </div>
              </div>
              <div
                v-show="isSpeciality"
                class="draw_comic"
              >
                <div class="label">
                  <span>漫画模式 (--niji)</span>
                  <el-tooltip
                    :visible-arrow="false"
                    placement="top"
                    content="开启后，生成的作品均为漫画风格，更适合专业的漫画创作"
                  >
                    <span>
                      <img
                        slot="reference"
                        class="img"
                        src="@/static/index/tip.png"
                      >
                    </span>
                  </el-tooltip>
                  <span style="color: #ACB1BF;">选填</span>
                </div>
                <el-switch
                  v-model="comicModes"
                  class="switch"
                  active-color="#1467FF"
                />
              </div>
            </div>
          </div>
          <div class="leftBotton">
            <div class="leftBottonContent">
              <el-tooltip
                :visible-arrow="false"
                placement="top"
              >
                <div slot="content">
                  极速模式：约25秒左右出图<br>快速模式：约60秒左右出图<br>休闲模式：需根据实际排队情况出图
                </div>
                <div>
                  <img
                    slot="reference"
                    class="img"
                    src="@/static/index/tip.png"
                  >
                </div>
              </el-tooltip>
              <div class="gptSelect">
                <el-select
                  v-model="mode"
                  placeholder="请选择"
                  @change="handleChangeMode"
                >
                  <el-option
                    v-for="item in modeList"
                    :key="item.code"
                    :label="item.desc"
                    :value="item.code"
                  />
                </el-select>
              </div>
              <div
                v-if="chargerule.userType == 'VIP0'"
                class="cash"
              >
                <span>消耗：{{ chargerule.chargeNum }}</span>
                <!-- {{ $store.state.chargeNum }} -->
                <img src="@/static/index/money.png">
              </div>
              <div
                v-else
                class="cash"
              >
                <span>剩余：<span style="color: #3D87FF;">{{ chargerule?.surplusNum }}</span> 次</span>
              <!-- {{ $store.state.chargeNum }} -->
              </div>
              <el-button
                type="primary"
                round
                style="width: 148px; margin: 0 12px"
                :disabled="disabled"
                class="btn"
                @click="handleSumbit"
              >
                立即生成
              </el-button>
              <div class="btn_mask" />
              <div>
                <el-popconfirm
                  title="确定重置所有内容吗？"
                  class="popconfirm"
                  @confirm="confirm"
                >
                  <span
                    slot="reference"
                    class="reset"
                  >重置</span>
                <!-- <el-button slot="reference">删除</el-button> -->
                </el-popconfirm>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="activeTab == '2'"
          class="tabs_pane tabs_right"
        >
          <p class="right_title">
            通过热门工具实现更多创意
          </p>
          <router-link
            to="/aidraw/word"
            active-class="active"
          >
            <div
              class="right_item"
            >
              <div class="image">
                <img
                  src="@/static/ai/word.png"
                  alt=""
                >
              </div>
              <div class="fun_name">
                <p>图生文（Describe）</p>
                <p>上传图片，得到描述词</p>
              </div>
            </div>
          </router-link>
          <!-- <router-link
            to="/aidraw/fusion"
            active-class="active"
          >
            <div
              class="right_item"
            >
              <div class="image">
                <img
                  src="@/static/ai/fusion.png"
                  alt=""
                >
              </div>
              <div class="fun_name">
                <p>图像融合（Blend）</p>
                <p>上传图片，得到融合多张图片特征的作品</p>
              </div>
            </div>
          </router-link> -->
          <router-link
            to="/aidraw/face"
            active-class="active"
          >
            <div
              class="right_item"
            >
              <div class="image">
                <img
                  src="@/static/ai/face.png"
                  alt=""
                >
              </div>
              <div class="fun_name">
                <p>AI 换脸</p>
                <p>将目标人脸替换到其他图片</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="main_right">
        <keep-alive>
          <router-view
            ref="drawingBoard"
            :draw-data="drawData"
            :code="code"
            kee
          />
        </keep-alive>
      </div>
    </div>
    <company class="company" />
    <noTimes
      ref="noTimes"
      :no-times-visible.sync="noTimesVisible"
    />
  </div>
</template>

<script>
import { listByDrawGroup, drawVersions, drawChargerule } from '@/api/index'
import mixinFetchDraw from "@/mixin/fenchDraw";
import inputTags from '@/components/inputTags'
import company from  "@/components/company.vue"
import noTimes from "@/components/noTimes";

export default {
  name: 'AIDraw',
  components: {
    company,
    inputTags,
    noTimes,
  },
  mixins: [mixinFetchDraw],
  data () {
    return {
      noTimesVisible: false,
      activeTab: '1',
      isSpeciality: false,
      styleList: [
        { label: '随机', imgSrc: 'style1.png', checked: false},
        { label: '迪士尼', imgSrc: 'style2.png', checked: false},
        { label: '油画', imgSrc: 'style3.png', checked: false},
        { label: '水墨画', imgSrc: 'style4.png', checked: false},
        { label: '素描', imgSrc: 'style5.png', checked: false},
        { label: '波普艺术', imgSrc: 'style6.png', checked: false},
        { label: '二次元', imgSrc: 'style7.png', checked: false},
        { label: '摄影', imgSrc: 'style8.png', checked: false},
      ],
      styleCustom: '',
      artStyleType: '',
      scaleList: [
        { label: '头像', imgSrc: 'scale1.png', checked: false, scale: '1:1'},
        { label: '文案配图', imgSrc: 'scale2.png', checked: false, scale: '4:3'},
        { label: '封面图', imgSrc: 'scale3.png', checked: false, scale: '16:9'},
        { label: '自定义',  checked: false, scale: ''},
      ],
      width: '',
      height: '',
      mode: '',
      modeList: [],
      disabled: false,
      errorNotice: false,
      prompt: '',
      errorScale: false,
      golden: 6,
      fileList: [],
      uploadList: [],
      showFileListLoading: false,
      contentExclusions: '',
      comicModes: false,
      aigoFileRequests: [],
      chargerule: '',
      hotActive: '1',
      apiAction: 'generate',
    }
  }, 
  watch: {
    prompt(val) {
      if(val){
        this.errorNotice = false
      }
    },
    $route(to, from) {
      console.log(to, from);
      if (to.name == "QuickDraw") {
        this.activeTab = "1";
        this.getListByDrawGroup()
      } else {
        this.activeTab = "2";
      }
    },
  },
  mounted() {
    if (this.$route.name == "QuickDraw") {
      this.activeTab = "1";
      this.getListByDrawGroup()
    } else {
      this.activeTab = "2";
    }
    this.prompt = this.$route.query?.prompt
    this.$bus.on('drawing', (data, val, taskUid) => {
      this.drawData = {}
      if(data.benefitSubType == 'AI_DRAW_PRO'){
        const params = {
          ...data,
          drawUid: val.drawUid,
          artisticStyles: null,
          artists: null,
          compositions: null,
          lightings: null,
          renderers: null,
          contentExclusions: null,
          comicModes: null,
          aigoFileRequests: null,
        }
        this.getDrawBaseData("/ai/draw/send/stream/pro", params)
      } else {
        const params = {
          ...data,
          artStyleType: null,
        }
        this.getDrawBaseData("/ai/draw/send/stream/base", params)
      }
      this.$store.dispatch("CheckLogin")
    })
    this.$bus.on('disabled', () => {
      this.disabled = true
    })
    this.$bus.on('changeDrawData', data => {
      this.drawData = data
      this.$refs.drawingBoard.isAdd = false
    })
    this.$bus.on('closeInputTags', () => {
      this.handleClose()
    })
  },
  methods: {
    handleClickHot(val) {
      this.hotActive = val
    },
    handleClose() {
      if(this.activeTab == '1'){
        this.$refs.artisticStyles.showTagsList = false
        this.$refs.artists.showTagsList = false
        this.$refs.compositions.showTagsList = false
        this.$refs.lightings.showTagsList = false
        this.$refs.renderers.showTagsList = false
      }
    },
    handleError() {
      this.showFileListLoading = false
    },
    handleDeleteUpload(val) {
      let idx = this.fileList.indexOf(val)
      this.fileList.splice(idx, 1)
      this.aigoFileRequests = []
      this.fileList.forEach((item, index) => {
        if(item.response?.data?.encryOssNo){
          this.aigoFileRequests.push({encryOssNo: item.response?.data?.encryOssNo})
        } else {
          this.fileList.splice(index, 1)
        }
      })
      console.log('aigoFileRequests', this.aigoFileRequests)
    },
    beforeUpload(file) {
      this.showFileListLoading = true
      const isPNG = file.type == 'image/png'
      const isJPG = file.type == 'image/jpg'
      const isJPEG = file.type == 'image/jpeg'
      if (file.size > 1024 * 1024 * 3) {
        this.$message.error({message: '图片大小超过3M，请重试', duration: 2000})
        this.showFileListLoading = false
        return false
      }
      if (!isJPG && !isPNG && !isJPEG) {
        this.$message.error({message: '不支持JPG/PNG以外的格式，请重试', duration: 2000})
        this.showFileListLoading = false
        return false
      }
    },
    handleOnExceed() {
      this.$message.error({message:'最多只能上传3张图片', duration: 2000})
      this.showFileListLoading = false
    },
    //
    handleSuccess(res, file, fileList) {
      this.aigoFileRequests = []
      this.fileList = fileList
      this.fileList.forEach((item, index) => {
        if(item.response?.data?.encryOssNo){
        this.aigoFileRequests.push({encryOssNo: item.response?.data?.encryOssNo})
        } else {
          this.fileList.splice(index, 1)
        }
      })
      this.showFileListLoading = false
    },
    handleClick() {
      this.scaleList[3].checked = true
      this.scaleList.forEach(item => {
        if(item.label == '自定义') {
          item.checked = true
        } else {
          item.checked = false
        }
      })
    },
    handleChangeTab(val) {
      this.activeTab = val
      if(val == '1') {
        this.$router.push("/aidraw/quickdraw");
      } else {
        // this.$router.push("/out");
        this.$router.push("/aidraw/word");
      }
    },
    // 切换模式
    handleChangeMode() {
      const params = {
        accessType: 'AI_DRAW',
        apiKey: this.mode
      }
      drawChargerule(params).then(res => {
        this.golden = res.data.chargeNum
        this.chargerule = res.data
      })
    },
    //
    handleChangeStyle(val) {
      if(!val.checked) this.styleCustom = ''
      val.checked = !val.checked
      this.styleList.forEach(item => {
        if(item.id !== val.id) {
          item.checked = false
        }
      })
      if(val.checked == true) {
        this.artStyleType = val.value
      } else {
        this.artStyleType = ''
      }
    },
    handleChangeStyleCustom() {
      if(this.styleCustom !== '') {
        this.styleList.forEach(item => item.checked = false)
      }
    },
    handleChangeScale(val) {
      val.checked = !val.checked
      this.scaleList.forEach(item => {
        if(item.label !== val.label) {
          item.checked = false
        }
      })
      if(val.checked) {
        this.aspectRatioType = val.scale
      } else {
        this.aspectRatioType = ''
      }
      if(val.label == '自定义') {
        if(this.height != '' && this.width != '') {
          this.aspectRatioType = this.width + ':' + this.height
        }
      }
      this.errorScale = false 
    },
    handleChangeWidth() {
      if(this.height != '' && (this.width/this.height > 2)) {
        this.height = ''
        this.width = ''
        this.errorScale = true 
        return
      }
      if(this.height != '' && this.width != ''){
        this.aspectRatioType = this.width + ':' + this.height
      }
      this.errorScale = false 
    },
    handleChangeHeight() {
      if(this.width != '' && (this.width/this.height > 2)) {
        this.height = ''
        this.width = ''
        this.errorScale = true 
        return
      }
      if(this.height != '' && this.width != ''){
        this.aspectRatioType = this.width + ':' + this.height
      }
      this.errorScale = false 
    },
    // 立即生成
    async handleSumbit() {
      if(!this.$store.state.isLogin){
        this.$store.commit('SET_LOGINDISLOG', true)
        return
      }
      if(!this.prompt) {
        this.errorNotice = true
        return
      } else {
        this.errorNotice = false
      }
      if(this.chargerule.userType == 'VIP0' && this.chargerule.recharge){
        this.$refs.noTimes.type = 'gold'
        this.noTimesVisible = true
        return
      }
      if(this.chargerule.userType != 'VIP0' && this.chargerule.recharge && this.chargerule.defaultUseBenefitRule && !this.chargerule.baseBenefitRuleVO.recharge){
        this.$refs.noTimes.type = 'times'
        this.$refs.noTimes.num = this.chargerule.baseBenefitRuleVO.chargeNum
        this.noTimesVisible = true
        return
      }
      if(this.chargerule.userType != 'VIP0' && this.chargerule.recharge && this.chargerule.baseBenefitRuleVO.recharge){
        this.$refs.noTimes.type = 'nothing'
        this.noTimesVisible = true
        return
      }
      this.apiAction = 'generate'
      this.send()
    },
    async send() {
      let params = ''
      this.$refs.drawingBoard.loading = true
      this.$refs.drawingBoard.generating = false
      this.$refs.drawingBoard.picList.unshift({})
      this.$refs.drawingBoard.isAdd = true
      this.$refs.drawingBoard.showBG = true
      this.$refs.drawingBoard.showMask = false
      this.$refs.drawingBoard.showBtns = false
      this.$refs.drawingBoard.handleShowSentence()
      if(this.isSpeciality){
        params = {
         prompt: this.prompt,
         apiKeyModel: this.mode,
         drawUid: '',
         taskUid: '',
         artStyleType: this.artStyleType,
         customArtStyleType: this.styleCustom,
         aspectRatioType: this.aspectRatioType,
         apiAction: this.apiAction,
         benefitSubType: 'AI_DRAW_PRO',
         artisticStyles: this.$refs.artisticStyles.valueList,
         artists: this.$refs.artists.valueList,
         compositions: this.$refs.compositions.valueList,
         lightings: this.$refs.lightings.valueList,
         renderers: this.$refs.renderers.valueList,
         contentExclusions: this.contentExclusions,
         comicModes: this.comicModes,
         aigoFileRequests: this.aigoFileRequests,
         taskPublishBl: true,
        }
        await this.getDrawBaseData("/ai/draw/send/stream/pro", params)
      } else {
        params = {
         prompt: this.prompt,
         apiKeyModel: this.mode,
         drawUid: '',
         taskUid: '',
         artStyleType: this.artStyleType,
         customArtStyleType: this.styleCustom,
         aspectRatioType: this.aspectRatioType,
         apiAction: this.apiAction,
         benefitSubType: 'AI_DRAW_BASE',
         taskPublishBl: true,
       }
       await this.getDrawBaseData("/ai/draw/send/stream/base", params)
      }
    },
    // 重置确认
    confirm() {
      this.prompt = ''
      this.artStyleType = ''
      this.aspectRatioType = ''
      this.styleCustom = ''
      this.width = ''
      this.height = ''
      this.errorNotice = false
      this.errorScale = false
      this.$refs.artisticStyles.valueList = []
      this.$refs.artisticStyles.TagsAll = []
      this.$refs.artists.valueList = []
      this.$refs.artists.TagsAll = []
      this.$refs.compositions.valueList = []
      this.$refs.compositions.TagsAll = []
      this.$refs.lightings.valueList = []
      this.$refs.lightings.TagsAll = []
      this.$refs.renderers.valueList = []
      this.$refs.renderers.TagsAll = [] 
      this.contentExclusions = ''
      this.comicModes = false
      this.aigoFileRequests = []
      this.fileList = []
      this.scaleList.forEach(item => item.checked = false)
      this.styleList.forEach(item => item.checked = false)
    },
    getListByDrawGroup() {
      listByDrawGroup('AI_DRAW_BASE_ARTSTYLETYPE').then(res => {
        var data = res.data
        data.forEach(item => {
          this.$set(item, 'checked', false)
        })
        data.unshift({checked: false, iconLink: 'style1.png', text: '随机', value: '', id: '随机'})
        this.styleList = data
      })
      drawVersions().then(res => {
        var data = res.data
        this.modeList = data
        this.mode = this.modeList[0].code
        this.handleChangeMode()
      })
      // listByGroup({groupNo: 'AI_DRAW_BASE_ASPECTRATIOTYPE'}).then(res => {

      // })
    },
  }
}
</script>

<style lang="less">

.aidraw{
  width: 100%;
  box-sizing: border-box;
  height: calc(100% - 56px);
  overflow: hidden;
  background: url('../../static/ai/draw_bg.png') center no-repeat;
  padding: 24px 0 0 24px;
  background-size: 100% 100%;
  margin-top: 56px;
  .aidraw_container{
    height: calc(100% - 20px);
    display: flex;
    .main_left{
      width: 404px;
      height: 100%;
      margin-right: 24px;
      .title{
        width: 404px;
        font-family: Source Han Sans CN;
        font-size: 28px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0em;
        color: #3D3D3D;
        height: 36px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        span:nth-of-type(2){
          font-family: Source Han Sans CN;
          font-size: 14px;
          font-weight: normal;
          line-height: 22px;
          letter-spacing: 0em;
          color: #1467FF;
          cursor: pointer;
        }
        .name::before{
          content: '';
          width: 8px;
          height: 20px;
          border-radius: 2px;
          background: #1467FF;
          display: inline-block;
          // top: 3px;
          position: relative;
          margin-right: 8px;
        }
      }
      .tabs{
        height: 72px;
        display: flex;
        background: #E6E9F0;
        border-radius: 24px 24px 0 0 ;
        margin-top: 24px;
        font-size: 14px;
        color: #646873;
        justify-content: space-between;
        .item{
          line-height: 46px;
          width: 202px;
          text-align: center;
          color: #646873;
          cursor: pointer;
        }
        .active{
          width: 202px;
          height: 100px;
          color: #1467FF;
          font-weight: bold;
          background-color: #fff;
          border-radius: 24px 100px 0 0 ;
          position: relative;
        }
        .active2{
          border-radius: 100px 24px 0 0 ;
        }
        .active_bg{
          width:  219px;
          height: 46px;
          background: url('../../static/ai/tabbg.png');
          background-size: 100% 100%;
          &.active_bg::before{
            content: '';
            width: 16px;
            height: 2px;
            background: #1467FF;
            border-radius: 1px;
            transform: translateX(25%);
            display: block;
            position: absolute;
            top: 33px;
            left: 99px;
          }
        }
        .active_bg2{
          width:  219px;
          height: 48px;
          background: url('../../static/ai/tabbg2.png');
          background-size: 100% 100%;
          position: relative;
          right: 17px;
          &.active_bg2::before{
            content: '';
            width: 16px;
            height: 2px;
            background: #1467FF;
            border-radius: 1px;
            transform: translateX(25%);
            display: block;
            position: absolute;
            top: 33px;
            left: 99px;
          }
        }
      }
      .tabs_pane{
        height: calc(100% - 128px);
        background-color: #fff;
        box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.03);
        border-radius: 24px;
        position: relative;
        bottom: 50px;
        .main{
          height: calc(100% - 86px);
          .label{
            font-family: Source Han Sans CN;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0em;
            color: #1F2126;
            margin: 12px 0;
            img{
              width: 12px;
              margin: 0 4px 0 5px;
              position: relative;
              top: 1px;
            }
          }
          .speciality{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 24px 24px 0 24px
          }
          .desc{
            margin: 0 24px 24px;
            position: relative;
            .el-textarea__inner{
              width: 356px;
              height: 124px;
              padding: 12px;
              border-radius: 6px;
              background: #F2F4F7;
              box-sizing: border-box;
              border: 1px solid #F2F4F7;
            }
            .error_notice{
              color: #FF4D4F;
              font-size: 12px;
              position: absolute;
            }
            .error_input{
              .el-textarea__inner{
                border: 1px solid #FF4D4F;
                border-radius: 6px;
                box-sizing: border-box;
              }
            }
          }
          .main_bottom{
            overflow-y: scroll;
            margin: 0 8px 24px 0px;
            padding: 0 8px 0 24px;
            height: calc(100% - 242px);
            .style{
              margin-bottom: 24px;
              .image{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .item{
                  border-radius: 8px;
                  position: relative;
                  margin-bottom: 12px;
                  width: 80px;
                  height: 72px;
                  // border: 2px solid transparent;
                  img{
                    width: 80px;
                    height: 72px;
                  border-radius: 8px;
                  }
                  .style_label{
                    width: 80px;
                    height: 24px;
                    border-radius: 0px 0px 8px 8px;
                    background: rgba(0, 0, 0, 0.4);
                    backdrop-filter: blur(10px);
                    font-family: Source Han Sans CN;
                    font-size: 12px;
                    line-height: 24px;
                    text-align: center;
                    letter-spacing: 0em;
                    color: #FFFFFF;
                    position: absolute;
                    bottom: 0px;
                  }
                  .mask{
                    width: 80px;
                    height: 72px;
                    background: rgba(31, 33, 38, .3);
                    border-radius: 8px;
                    position: absolute;
                    top: 0;
                    z-index: -1;
                  }
                  .el-input__inner{
                    border-radius: 6px;
                  }
                  .check{
                    position: absolute;
                    width: 80px;
                    height: 72px;
                    top: 0;
                    right: 0px;
                    z-index: 2;
                    border: 2px solid #1467FF;
                    border-radius: 8px;
                    box-sizing: border-box;
                    img{
                      position: absolute;
                      top: 4px;
                      right: 4px;
                      width: 16px;
                      height: 16px;
                    }
                  }
                }
                .item_checked{
                  // border: 2px solid #1467FF;
                  .style_label{
                    background: #1467FF !important;
                    z-index: 2;
                  }
                }
                .item:hover{
                  .mask{
                    z-index: 1;
                  }
                }
              }
            }
            .scale{
              position: relative;
              .choose{
                display: flex;
                justify-content: space-between;
                .item{
                  width: 80px;
                  height: 80px;
                  background: #F2F4F7;
                  border-radius: 8px;
                  text-align: center;
                  position: relative;
                    box-sizing: border-box;
                  .top{
                    padding-top: 8px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                  span{
                    font-family: Source Han Sans CN;
                    font-size: 12px;
                    text-align: center;
                    color: #646873;
                    margin-bottom: 32px;
                    position: relative;
                    bottom: 4px;
                  }
                  .bottom{
                    height: 26px;
                    line-height: 30px;
                    border-radius: 0px 0px 8px 8px;
                    background: linear-gradient(140deg, #4A7CEF 3%, #CBADFF 121%);
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    span{
                      color: #fff;
                      font-size: 14px;
                    }
                  }
                  
                  .check{
                    position: absolute;
                    width: 80px;
                    height: 80px;
                    box-sizing: border-box;
                    top: 0;
                    right: 0px;
                    z-index: 2;
                    border: 2px solid #1467FF;
                    border-radius: 8px;
                    img{
                      position: absolute;
                      top: 4px;
                      right: 4px;
                      width: 16px;
                      height: 16px;
                    }
                  }
                  .custom{
                    padding-top: 13px;
                    width: 80px;
                    height: 80px;
                    box-sizing: border-box;
                    span{
                      color: #1F2126;
                    }
                    .inputs{
                      margin-top: -4px;
                      position: relative;
                      z-index: 4;
                      .input{
                        width: 29px;
                        height: 20px;
                        .el-input__inner{
                          width: 29px;
                          height: 20px;
                          padding: 0;
                          background-color: transparent;
                          border: 0;
                          border-bottom: 2px solid #CACDD9;
                          text-align: center;
                          font-size: 12px;
                          border-radius: 0;
                          color: #3d3d3d;
                        }
                      }
                    }
                  }
                }
              }
              .error_notice{
                color: #FF4D4F;
                font-size: 12px;
                position: absolute;
                margin-top: 5px;
              }
            }
            .reference{
              margin-bottom: 24px;
              .upload{
                width: 356px;
                height: 92px;
                border-radius: 12px;
                padding: 8px 8px 8px 14px;
                box-sizing: border-box;
                background: #F8F9FC;
                display: flex;
                align-items: center;
                .el-upload--picture-card{
                  background: transparent;
                  border: none;
                  width: 64px;
                  height: 64px;
                  line-height: 64px;
                  margin-right: 16px;
                }
                .upload_img{
                  width: 64px;
                  height: 64px;
                  border-radius: 12px;
                  border: 1px dashed #DADDE5;
                  background: #E6E9F0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  img{
                    width: 24px;
                    height: 24px;
                  }
                  .upload_loading{
                    animation: rotate 1.5s ease-in-out infinite;
                  }
                  @keyframes rotate {
                    to{
                        transform: rotate(360deg);
                    }
                  }
                }
                .file_list{
                  display: flex;
                  align-items: center;
                  .item{
                    position: relative;
                    width: 76px;
                    height: 76px;
                    margin-left: 8px;
                    border-radius: 8px;
                    img{
                      border-radius: 8px;
                      width: 76px;
                      height: 76px;
                    }
                    .delete{
                      position: absolute;
                      width: 76px;
                      height: 76px;
                      border-radius: 8px;
                      top: 0;
                      color: #FF4D4F;
                      font-size: 16px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      opacity: 0;
                      background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
                      span{
                        cursor: pointer;
                      }
                    }
                  }
                  .item:hover{
                    .delete{
                      opacity: 1;
                    }
                  }
                  p:nth-of-type(1){
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 24px;
                    color: #1F2126;
                  }
                  p:nth-of-type(2){
                    font-size: 12px;
                    font-weight: normal;
                    line-height: 24px;
                    color: #7A7F8C;
                  }
                }
              }
            }
            .inputs{
              margin-bottom: 16px;
              .input{
                .el-input__inner{
                  width: 356px;
                  height: 46px;
                  border-radius: 12px;
                  padding: 12px 16px;
                  border: 1px solid #DADDE5;
                }
                .el-input__inner:hover{
                  border: 1px solid #3D87FF;
                }
              }
            }
            .draw_comic{
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 42px;
            }
          }
        }
        .leftBotton {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 404px;
          height: 86px;
          border-radius: 0px 0px 24px 24px;
          box-shadow: 0px -12px 20px 0px rgba(0, 0, 0, 0.05);
          display: flex;
          // justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          padding: 24px 24px 24px 16px;
          background: #fff;
          z-index: 5;
          .img{
            width: 12px;
            height: 12px;
            position: relative;
            bottom: 2px;
            margin-right: 4px;
          }
          .leftBottonContent {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .gptSelect {
              width: 75px;
              .el-input__inner {
                border-color: #fff;
                padding-left: 0px;
                padding-right: 24px;
                font-size: 12px;
              }
            }
            .cash {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 74px;
              span {
                font-family: Source Han Sans CN;
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;
                white-space: nowrap;
                color: #9498a6;
              }
              img {
                width: 16px;
                height: 16px;
                margin-left: 6px;
              }
            }
            .btn{
              width: 143px;
              height: 38px;
              padding: 8px 16px;
              background: linear-gradient(165deg, #2E61FC -38%, #6185FC 190%);
              box-shadow: 0px 1.5px 0px 0px #E6EAF0;
              border: none;
            }
            .btn:hover{
              background: #84a2fe;
            }
            .btn:active{
              background: #3a56b1;
            }
            .is-disabled{
              background: #DADDE5;
            }
            .is-disabled:hover{
              background: #DADDE5;
            }
            .reset {
              font-family: Source Han Sans CN;
              font-size: 14px;
              font-weight: normal;
              line-height: 22px;
              letter-spacing: 0em;
              white-space: nowrap;
              cursor: pointer;
              color: #1467ff;
            }
          }
        }
      }
      a{
        text-decoration: none;
      }
      .tabs_right{
        bottom: 24px;
        padding: 16px;
        box-sizing: border-box;
        .right_title{
          font-size: 12px;
          line-height: 20px;
          color: #9498A6;
          font-family: Source Han Sans CN;
          margin-bottom: 24px;
        }
        .right_item{
          display: flex;
          height: 74px;
          padding: 13px 12px;
          box-sizing: border-box;
          margin-bottom: 16px;
          position: relative;
          .image{
            width: 48px;
            height: 48px;
            border-radius: 8px;
            background: #F2F4F7;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              width: 32px;
              height: 32px;
            }
          }
          .fun_name{
            margin-left: 8px;
            p:nth-of-type(1){
              font-size: 14px;
              font-weight: bold;
              line-height: 22px;
              color: #1F2126;
            }
            p:nth-of-type(2){
              font-size: 12px;
              line-height: 20px;
              color: #7A7F8C;
              margin-top: 8px;
            }
          }
        }
        a{
          position: relative;
          &.active{
            .right_item{
              width: 372px;
              height: 74px;
              border-radius: 8px;
              background: #F2F4F7;
              .image{
                width: 48px;
                height: 48px;
                border-radius: 8px;
                background: #FFFFFF;
              }
            }
            &.active::after{
              content: "";
              position: absolute;
              right: 12px;
              top: 50%;
              margin-top: -16px;
              width: 4px;
              height: 32px;
              border-radius: 8px;
              background: #1467ff;
            }
          }
        }
      }
    }
    .main_right{
      width: 100%;
    }
  }
  .company{
    position: relative;
    bottom: 20px;
  }
}

</style>
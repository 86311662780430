<template>
  <div
    v-resize="resize"
    class="aiMarkdown"
  >
    <div
      v-for="(item,index) in data"
      :key="index"
      class="aiMarkdownItem"
    >
      <div
        v-show="item.messageReplyContent"
        class="userContent"
      >
        <div class="title">
          <p>{{ item.createDateTimeStr }} <img src="@/static/index/default.png"></p>
          <loading v-show="item.loading" />
          <div
            v-show="item.messageReplyContent"
            class="proText"
          >
            {{ item.question }}
          </div>
        </div>
      </div>
      <div
        v-show="item.messageReplyContent"
        class="gptContent"
      >
        <div class="title">
          <img src="@/static/index/gpt.png"> {{ item.createDateTimeStr }}
        </div>
        <div class="replyCon">
          <div class="reply">
            <markdown-it-vue-light
              class="md-body"
              :content="item.messageReplyContent"
            />
          </div>
          <error
            v-show="item.fetchMessage"
            :text="item.fetchMessage"
          />
          <div class="aiButtom">
            <img
              v-copy="item.messageReplyContent"
              src="@/static/index/vetcor2.png"
            >
            <img
              src="@/static/index/vector1.png"
              @click="reset(item)"
            >
          </div>
        </div>
        <div />
      </div>
    </div>
  </div>
</template>

<script>
import error from "@/components/error";
import loading from "@/components/loading.vue";
import MarkdownItVueLight from "markdown-it-vue/dist/markdown-it-vue-light.umd.min.js";
import "markdown-it-vue/dist/markdown-it-vue-light.css";

export default {
  name: "AiMarkdown",
  components: {
    error,
    loading,
    MarkdownItVueLight,
  },
  props: {
    data: { type: Array, default: () => {} },
  },
  methods: {
      resize(width,height){
       this.$parent.$refs.aiBox.scrollTo({
  'top':height.slice(0, -2),
  'behavior': 'smooth'
  })
      // this.$refs.aiContent2.scrollTop=height
    },
    reset(item) {
      let data = {
        messageReplyUid: item.messageReplyUid,
        messageUid: item.messageUid,
        prompt: item.question,

        roomUid: item.roomUid,
      };
      this.$parent.resetSend(data);
    },
  },
};
</script>

<style lang='less'>
.aiMarkdown{
      display: flex;
    flex-direction: column;
    .aiMarkdownItem{
        display: flex;
    flex-direction: column;
   .gptContent {
    width: 100%;
            .title {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-left: 0px;
               margin-bottom: 16px;
              &::before {
                width: 0;
              }
              img {
                width: 36px;
                height: 36px;
                margin-right: 16px;
              }
              font-family: Source Han Sans CN;
              font-size: 12px;
              font-weight: normal;

              text-align: right;
              letter-spacing: 0em;

              /* Grey/4 */
              color: #acb1bf;
            }
            .replyCon {
              display: flex;
            
              .reply {
                padding: 16px;
 width: calc(100% - 64px);
                display: inline-block;
                border-radius: 8px;
                /* Grey/0 */
                padding: 16px;
                flex-grow: 1;

                /* Grey/1 */
                background: #f2f4f7;

                /* 对话条目/GPT */
                // box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.03);
                font-family: Source Han Sans CN;
                font-size: 14px;
                font-weight: normal;
                letter-spacing: 0em;

                /* Grey/10 */
                color: #1f2126;
              }
              .aiButtom {
                img {
                  cursor: pointer;
                  width: 12px;
                  height: 12px;
                  margin-left: 8px;
                  margin-bottom: 9px;
                }
              }
            }
          }
          .userContent {
            display: flex;
            justify-content: flex-end;
            .title {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: flex-end;
              padding-left: 0;
              font-family: Source Han Sans CN;

              &::before {
                width: 0;
              }
              p {
                margin-bottom: 16px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                font-size: 12px;
                font-weight: normal;
                font-family: Source Han Sans CN;
                text-align: right;
                letter-spacing: 0em;

                /* Grey/4 */
                color: #acb1bf;
                img {
                  width: 36px;
                  height: 36px;
                  margin-left: 16px;
                  border-radius: 50% 50%;
                }
              }
              .proText {
                display: flex;
                flex-direction: column;
                padding: 8px 16px;
                border-radius: 999px;
                /* Main/06 */
                background: #1467ff;
                font-family: Source Han Sans CN;
                font-size: 14px;
                font-weight: normal;
                letter-spacing: 0em;
               
                /* Grey/0 */
                color: #ffffff;
              }
            }
          }
    }

}
   

        

</style>
<template>
  <div class="error">
<i class="el-icon-close"></i>  {{text}}
  </div>
</template>

<script>
export default {
  props:{
    text:{
      default:'',
      type:String
    }
  }

}
</script>

<style lang="less">
.error{
    border-radius: 8px;
opacity: 1;

/* 自动布局 */
display: flex;
flex-direction: row;
align-items: center;
padding: 12px;
gap: 8px;

/* Grey/0 */
background: #FFFFFF;

box-sizing: border-box;
/* Red/3 */
border: 1px solid #FFA39E;

/* 对话条目/GPT */
box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.03);
/* Body/regular */
font-family: Source Han Sans CN;
font-size: 14px;
font-weight: normal;
 
letter-spacing: 0em;

/* Red/6 */
color: #F5222D;
width: 318px;

}
</style> 
<template>
  <div class="c-progress">
    <div class="c-progress-outer" :style="setProgressBgStyle" ref="progress">
      <div class="c-progress-inner" :style="setProgressStyle"></div>
      <!-- <div v-if="showSlider" class="c-progress-slider" ref="slider" :style="setSliderStyle"></div> -->
      <div
        v-if="showSlider"
        class="c-progress-slider"
        ref="slider"
        :style="{
          width: `${sliderWidth}px`,
          height: `${sliderHeight}px`,
          left: `${sliderLeft}px`,
        }"
      ></div>
      <div class="progress">
        <span class="item0" @click="change(0)"></span>
        <span class="item1" @click="change(1)"></span>
        <span class="item2" @click="change(2)"></span>
        <span class="item3" @click="change(3)"></span>
        
      </div>
    </div>
    <div class="progressText">
      <div class="item">
        <span>自定义</span>
        <el-input
          v-model="value"
          @input="value=value.replace(/[^\d]/g,'')"
          @blur="changeValue"
          ref="numInput"
          placeholder=""
        ></el-input>
        <p class="labelError" v-show="labelError">
          <img src="@/static/index/close.png" />
          该输入框仅支持输入数字，请重新输入
        </p>
      </div>
      <div class="item"><span>短</span> <span class="text">100字内</span></div>
      <div class="item"><span>中</span><span class="text">100-500字</span></div>
      <div class="item">
        <span>长</span><span class="text">大于 500字</span>
      </div>
    </div>

    <!-- <span v-if="showPerText">{{tempPercent}}%</span> -->
  </div>
</template>

<script>
// 使用了element的颜色
const colorTable = {
  success: "#13ce66",
  fail: "#ff4949",
  warning: "#e6a23c",
  default: "#F2F4F7",
};

// import {dictSave} from "@/api/index.js"
export default {
  name: "CProgress",
  props: {
    wordRange: {
      type: [Number,String],
      default: 0,
    },
    showSlider: {
      type: Boolean,
      default: true,
    },
    showPerText: {
      type: Boolean,
      default: true,
    },
    // 进度条的宽度
    width: {
      type: Number,
      default: 300,
    },
    bgColor: {
      type: String,
      default: "#dadde5",
    },
    progressColor: {
      type: String,
      default: "#1467FF",
    },
    // 滑块的宽度
    sliderWidth: {
      type: Number,
      default: 14,
    },
    sliderHeight: {
      type: Number,
      default: 14,
    },
    // 最大值
    max: {
      type: Number,
      default: 100,
    },
    // 颜色的类型
    type: {
      type: String,
      default: colorTable.default,
    },
  },
  data() {
    return {
      sliderLeft: 0, // 滑块相对父元素发x坐标
      progressWidth: 0, // 进度条当前的的宽度
      tempPercent: 0,
      value: "",
      isClickSlider: false,
      labelError: false,
    };
  },

  computed: {
    // 设置进度条的背景样式
    setProgressBgStyle() {
      return {
        background: this.bgColor,
        // 加上滑块的宽度
        width: `${this.width}px`,
      };
    },
    // 设置进度条的样式
    setProgressStyle() {
      const color = colorTable[this.type] || this.progressColor;
      return {
        width: `${this.progressWidth}px`,
        background: color,
      };
    },
    //滑块边框颜色
    borderColor() {
      return colorTable[this.type] || this.progressColor;
    },
  },
  mounted() {
    this.sliderLeft = (this.width / this.max) * this.percent;
    this.progressWidth = this.sliderLeft + this.sliderWidth; // 滑块的x坐标加上滑块的宽度
    this.tempPercent = this.percent;
    // this.addListener();
  },
  watch:{
    value(value){
      if(value!=''){
        this.sliderLeft=0
        this.progressWidth=0
      }
        const pattern = /^[1-9]\d*$/;
      if (!pattern.test(this.value)) {
        if(this.value){
           this.labelError = true;
        }else{
           this.labelError = false;
           
        }
      
       
      } else {
        
        this.labelError = false;
      }
 
    },
    wordRange(val){
      if(val==0){
        console.log(val)
           this.sliderLeft=0
        this.progressWidth=0
        this.value=''
      }
    }
  },
  methods: {
   changeValue(){
     this.$emit('update:wordRange',this.value)
   },
    change(index){
      console.log(index)
      if(index==1){
        this.sliderLeft=99
        this.progressWidth=102
         this.$emit('update:wordRange',100)
      }
       if(index==2){
        this.sliderLeft=200
        this.progressWidth=204
         this.$emit('update:wordRange',500)
      }
        if(index==3){
        this.sliderLeft=298
        this.progressWidth=300
         this.$emit('update:wordRange',500)
      }
       if(index==0){
        this.sliderLeft=0
        this.progressWidth=0
         this.$emit('update:wordRange',this.value)
      }
    },
    addListener() {
      //注释掉滑块拖动
      const slider = this.$refs.slider;
      const progress = this.$refs.progress;
      const body = document.body;
      this.isClickSlider = false;
      let distance = 0; // 滑块与点击坐标的绝对距离
      progress.onclick = (e) => {
        // 阻止事件冒泡
        if (e.target == slider) {
          return;
        }
        let curX = progress.offsetLeft;
        this.sliderLeft = e.offsetX - curX;
        if (this.sliderLeft <= 0) {
          this.sliderLeft = 0;
        }
        if (this.sliderLeft >= this.width) {
          this.sliderLeft = this.width;
        }
        this._countCurPercent();
      };
      slider.onmousedown = (e) => {
        this.isClickSlider = true;
        let curX = slider.offsetLeft;
        distance = e.pageX - curX; // 得出绝对距离
      };
      progress.onmousemove = (e) => {
        if (this.isClickSlider) {
          // 判断是否已经超出进度条的长度
          if (e.pageX - distance >= 0 && e.pageX - distance <= this.width - 0) {
            this.sliderLeft = e.pageX - distance;
            this._countCurPercent();
          }
        }
      };

      progress.onmouseup = () => {
        this.isClickSlider = false;
      };
      body.onmouseup = () => {
        this.isClickSlider = false;
      };
      progress.mouseleave = () => {
        this.isClickSlider = false;
        console.log(3333);
      };
      progress.mouseout = () => {
        this.isClickSlider = false;
        console.log(444);
      };

      // 解决有些时候,在鼠标松开的时候,元素仍然可以拖动;
      progress.ondragstart = function (ev) {
        ev.preventDefault();
        console.log(1212);
      };
      progress.ondragend = function (ev) {
        ev.preventDefault();
        console.log("asd");
      };
    },
    // 算出百分比
    _countCurPercent() {
      this.tempPercent = Math.ceil(
        parseInt((this.sliderLeft / this.width) * this.max)
      ); //最大值56

      // this.progressWidth = this.sliderLeft + 20 // ⚪滑块
      this.progressWidth = this.sliderLeft + 10;
      // 取整的时候宽度可能不为0，所以在0和100的时候也将宽度取整
      // if (this.tempPercent <= 0) {
      //   this.progressWidth = 0;
      //   this.sliderLeft = 0;
      //   this.$refs.numInput.focus();
      // } else {
      //   this.$refs.numInput.blur();
      //   this.value=''
      // }
      if (this.tempPercent >= 100) {
        this.progressWidth = this.width + 20;
        this.sliderLeft = this.width;
      }
      if(this.tempPercent>32&&this.tempPercent<36){
         this.$emit('update:wordRange',100)
      }else if(this.tempPercent>64&&this.tempPercent<68){
 this.$emit('update:wordRange',500)
      }else if(this.tempPercent>98){
 this.$emit('update:wordRange',500)
      }
      else if(this.tempPercent<2){
 this.$emit('update:wordRange',this.value)
      }
       
      console.log(this.tempPercent, "this.tempPercent");
      // this.$emit("percentChange", this.tempPercent);
    },
    // 监听父组件输入框变化
    // handleParentChange(num) {
    //   console.log(num, "num");
    //   this.sliderLeft = (num / this.max) * this.width;
    //   this.progressWidth = this.sliderLeft + 10;
    //   if (num >= this.max) {
    //     this.progressWidth = this.width + 20;
    //     this.sliderLeft = this.width;
    //   }
    // },
  },
};
</script>

<style scoped lang="less">
.c-progress {
  @width: 300px;
  @radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;

  span {
    margin-left: 5px;
    font-size: 14px;
    color: #666;
  }

  .c-progress-outer {
    width: @width;
    height: 6px;
    border-radius: @radius;
    background: #ebeef5;
    position: relative;
    display: flex;
    align-items: center;
    .progress {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0px;

      span {
        display: inline-block;
        width: 6px;
        height: 6px;
        opacity: 1;
        border-radius: 50% 50%;
        /* Grey/1 */
        background: #f2f4f7;
        position: absolute;
        cursor: pointer;
        top: 0px;
&.item0 {
          left: 0;
          margin-left: 0;
        }
        &.item1 {
          left: 33%;
        }
        &.item2 {
          left: 66%;
        }
        &.item3 {
          left: 98%;
        }
      }
    }
    .c-progress-inner {
      width: 100px;
      height: 6px;
      background: #1467ff;
      border-radius: @radius;
    }

    .c-progress-slider {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: #1467ff;
      border: 1px solid #f2f4f7;
      position: absolute;
      z-index: 2;
      left: 10px;
      cursor: pointer;
      box-sizing: border-box;
    }
  }
  .progressText {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    .item {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;

      .el-input {
        text-align: center;
        margin-top: 4px !important;
        /deep/.el-input__inner {
          width: 52px;
          height: 22px !important;
          padding: 0 5px !important;
          border-radius: 6px;
          margin: 0 auto !important;
        }
      }
      span {
        display: block;
        width: 100%;
        font-family: Source Han Sans CN;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        /* Grey/10 */
        color: #1f2126;
        &.text {
          font-family: Source Han Sans CN;
          font-size: 14px;
          font-weight: normal;
          line-height: 20px;
          letter-spacing: 0em;
          margin-top: 4px;
          /* Grey/4 */
          color: #acb1bf;
        }
      }
    }
  }
  .labelError {
    position: absolute;
    width: 300px;
    bottom: -28px;
    display: flex;
    align-items: center;
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    letter-spacing: 0em;

    /* Red/6 */
    color: #f5222d;
    img {
      margin-right: 12px;
      width: 16px;
      height: 16px;
    }
  }
}
.el-input__inner {
  height: 20px;
}
</style>



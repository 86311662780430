<template>
  <div class="face">
    <div class="face_container">
      <div
        v-show="showUpload"
        class="nodata"
      >
        <div
          class="uploads"
        >
          <div class="upload_main">
            <p>上传人脸图片</p>
            <p>尽量保持人脸居中，图像清晰，生成的图片质量更佳</p>
            <div :style="{height: upHeight + 'px',marginTop: '38px',maxHeight: upWidth + 'px',minHeight: '300px'}">
              <el-upload
                ref="upload1"
                v-loading="fileLoading"
                :action="'/attachments/img/upload'"
                list-type="picture"
                accept=".jpg,.png"
                :show-file-list="false"
                :auto-upload="true"
                :on-success="handleSuccess"
                :before-upload="beforeUpload"
                :on-error="handleError"
                :drag="true"
                class="upload"
                :class="file ? 'uploaded' : ''"
              >
                <div
                  v-show="!file"
                  class="upload_img"
                >
                  <img
                    src="../../static/ai/upload2.png"
                    alt=""
                  >
                  <p>拖拽或点击以上传图片</p>
                  <p>支持 JPG/PNG格式，最大 3MB</p>
                </div>
                <img
                  v-show="file"
                  class="uploaded_pic"
                  :src="process == 'production' ? file?.data?.aigoPreViewUrl : file?.data?.aigoPreViewUrl?.split('idcfengye.com')[1]"
                  alt=""
                >
              </el-upload>
            </div>
          </div>
          <div class="upload_main">
            <p>上传需要替换人脸的图片</p>
            <p>尽量上传清晰的图片，生成的图片质量更佳</p>
            <div :style="{height: upHeight + 'px',marginTop: '38px',maxHeight: upWidth + 'px',minHeight: '300px'}">
              <el-upload
                ref="upload2"
                v-loading="fileTargetLoading"
                :action="'/attachments/img/upload'"
                list-type="picture"
                accept=".jpg,.png"
                :show-file-list="false"
                :auto-upload="true"
                :on-success="handleSuccessTarget"
                :before-upload="beforeUploadTarget"
                :on-error="handleErrorTarget"
                :drag="true"
                class="upload"
                :class="fileTarget ? 'uploaded' : ''"
              >
                <div
                  v-show="!fileTarget"
                  class="upload_img"
                >
                  <img
                    src="../../static/ai/upload2.png"
                    alt=""
                  >
                  <p>拖拽或点击以上传图片</p>
                  <p>支持 JPG/PNG格式，最大 3MB</p>
                </div>
                <img
                  v-show="fileTarget"
                  class="uploaded_pic"
                  :src="process == 'production' ? fileTarget?.data?.aigoPreViewUrl : fileTarget?.data?.aigoPreViewUrl?.split('idcfengye.com')[1]"
                  alt=""
                >
              </el-upload>
            </div>
          </div>
        </div>
        <div class="btns f jsb">
          <el-button
            round
            class="btn"
            @click="handleSend"
          >
            开始换脸
            <span
              v-if="chargerule?.userType == 'VIP0'"
              class="cash"
            >-{{ chargerule?.chargeNum }}<img src="@/static/index/money.png"></span>
            <span
              v-else
              class="cash2"
            >剩：{{ chargerule?.surplusNum }}次</span>
          </el-button>
        </div>
      </div>
      <div
        v-show="loading"
        class="loading"
        :style="{height: loadingHeight + 'px', maxWidth: maxWidth + 'px'}"
      >
        <img
          src="../../static/ai/loading3.png"
          alt=""
        >
        <span>正在处理中...</span>
        <span>即将生成换脸结果</span>
      </div>
      <div
        v-show="showError"
        class="failed"
        :style="{height: failedHeight + 'px', maxWidth: maxWidth + 'px'}"
      >
        <img
          src="../../static/ai/error.png"
          alt=""
        >
        <p>阿哦，生成失败~</p>
        <p>{{ errorMessage }}</p>
      </div>
      <div
        v-show="showResult" 
        class="container"
      >
        <div class="image">
          <img
            :src="process == 'production' ? faceData?.imageUrl : faceData?.imageUrl?.split('idcfengye.com')[1]"
            alt=""
          >
        </div>
        <div class="btns">
          <el-button
            class="btn"
            s
            @click="handleRegeneration"
          >
            重新上传
          </el-button>
          <el-button
            class="btn btn2"
            @click="handleDownload"
          >
            下载
          </el-button>
        </div>
      </div>
    </div>
    <div class="draw_history">
      <div class="title">
        <span>历史记录</span>
        <img
          src="../../static/ai/edit.png"
          alt=""
          @click="handdleMultiple"
        >
      </div>
      <div
        v-scrollActive
        class="pic_list"
        :style="{height: picMultiple ? 'calc(100% - 100px)' : 'calc(100% - 70px)'}"
        @scroll="handleScroll"
      >
        <div
          v-for="item, index in picList"
          :key="'drawUid' + index"
          class="draw_item"
          @click="handleChoose(item)"
        >
          <div
            v-if="!item?.status || item.status == 'WAIT' || item.status == 'PROCESSING' || item.status == 'PARSEING'"
            class="draw_item"
          >
            <div class="generating flex jc">
              <span>生成中...</span>
              <!-- <span class="str">{{ item.progress }}%</span>
              <el-progress class="progress" :show-text="false" :stroke-width="10" :percentage="item.progress"></el-progress> -->
            </div>
          </div>
          <div
            v-if=" item.status == 'FAIL'"
            class="draw_item"
          >
            <div class="failing flex c">
              <img
                src="../../static/ai/fail.png"
                alt=""
              >
              <span>生成失败</span>
              <span>查看详情</span>
            </div>
          </div>
          <div
            v-if="item.status == 'DONE'"
            class="image_list"
          >
            <div class="pic">
              <img
                :src="process == 'production' ? item.imageUrl : item.imageUrl.split('idcfengye.com')[1]"
                alt=""
              >
              <!-- <img src="../../static/ai/ex.png" alt="" v-for="item in 4" :key="item"> -->
            </div>
          </div>
          <div
            v-show="picMultiple"
            class="check"
            :class="item.checked ? 'border' : ''"
          >
            <img
              v-if="item.checked"
              src="../../static/ai/checked.png"
              alt=""
            >
            <img
              v-else
              src="../../static/ai/circle.png"
              alt=""
            >
          </div>
        </div>
        <div
          v-show="listLoading"
          class="list_loading"
        >
          <span><i class="el-icon-loading" /> 加载中...</span>
        </div>
        <div
          v-show="inBottom && picList.length > 9"
          class="list_loading"
        >
          <span>已经到底啦...</span>
        </div>
      </div>
      <div
        v-if="picMultiple"
        class="btn_delete"
        :class="checkedList.length > 0 ? 'bg_color' : ''"
      >
        <el-popconfirm
          title="确定删除选中的图片吗？"
          class="popconfirm"
          @confirm="handleBatchDelete"
        >
          <el-button
            slot="reference"
            icon="el-icon-delete"
            :disabled="checkedList.length == 0"
          >
            删除
          </el-button>
        </el-popconfirm>
      </div>
    </div>
    <noTimes
      ref="noTimes"
      :no-times-visible.sync="noTimesVisible"
    />
  </div>
</template>

<script>
import { facePage, faceDetail, faceRemove, download, drawHotChargerule } from '@/api/index'
import defaultImage from '@/static/ai/white.png'
import mixinFaceFench from "@/mixin/faceFench";
import noTimes from "@/components/noTimes";

export default {
  name: 'Face',
  components: {
    noTimes,
  },
  mixins: [mixinFaceFench],
  data () {
    return {
      showUpload: true,
      loading: false,
      showError: false,
      showResult: false,
      picList: [],
      picMultiple: false,
      multipleList: [],
      checkedList: [],
      showBtns: false,
      pageNum: 1,
      listLoading: false,
      inBottom: false,
      process: '',
      showBG: true,
      file: '',
      fileTarget: '',
      upHeight: '',
      upWidth: '',
      fileLoading: false,
      fileTargetLoading: false,
      faceData: '',
      total: 0,
      maxWidth: '',
      loadingHeight: '',
      failedHeight: '',
      chargerule: '',
      noTimesVisible: false,
      errorMessage: '',
      faceDataTimer: null,
      isAdd: false,
    }
  },
  watch: {
    faceFetchData(val) {
      if(this.isAdd){
        this.picList[0] = val
      }
      switch (val.status) {
        case 'FAIL':
          this.loading = false
          this.showUpload = false
          this.showResult = false
          this.showError = true
          this.errorMessage = val.errorMsg
          this.faceData = val
          clearInterval(this.faceDataTimer)
          this.faceDataTimer = null
          break
        case 'DONE':
          this.loading = false
          this.showUpload = false
          this.showResult = true
          this.showError = false
          this.faceData = val
          clearInterval(this.faceDataTimer)
          this.faceDataTimer = null
          break
        case 'WAIT':
        case 'PROCESSING':
          // this.loading = true
          this.showUpload = false
          this.showError = false
          this.showResult = false
          if(this.faceDataTimer == null){
            this.faceDataSetInterval(val?.taskUid)
          }
          break
        case 'PARSEING':
          // this.loading = true
          this.showUpload = false
          this.showError = false
          this.showResult = false
          if(this.faceDataTimer == null){
            this.faceDataSetInterval(val?.taskUid)
          }
          break
      }
      // if(val.imageUrl != null){
      //   this.picList[0] = val
      //   this.faceData = val
      //   this.showResult = true
      //   this.showUpload = false
      //   this.showError = false
      // } else {
      //   this.showError = true
      //   this.showUpload = false
      //   this.showResult = false
      // }
    },
    picList(val) {
      var arr = []
      val.map(item => {
        if(item.progress && item.progress < 100 && item.imageUrl){
          arr.push(item)
        }
      })
      if(arr.length >= 4){
        this.$bus.emit('disabled')
      }
    }
  },
  mounted () {
    this.process = process.env.NODE_ENV
    this.getDrawPage()
    this.getDrawHotChargerule()
    this.maxWidth = document.getElementsByClassName('face_container')[0].offsetHeight
    this.$nextTick(() => {
      const resizeObserver = new ResizeObserver((entries, b) => {
        for (let entry of entries) {
          this.upHeight = entry.target.offsetWidth
          this.upWidth = document.getElementsByClassName('uploads')[0].offsetHeight - 78 || this.upHeight
        }
      });
      resizeObserver.observe(document.getElementsByClassName('upload')[0]);
      const resizeObserver2 = new ResizeObserver(entries => {
        for (let entry of entries) {
          if(entry.target.offsetWidth > this.maxWidth) return
          this.loadingHeight = entry.target.offsetWidth
        }
      });
      resizeObserver2.observe(document.getElementsByClassName('loading')[0]);
      const resizeObserver3 = new ResizeObserver(entries => {
        for (let entry of entries) {
          if(entry.target.offsetWidth > this.maxWidth) return
          this.failedHeight = entry.target.offsetWidth
        }
      });
      resizeObserver3.observe(document.getElementsByClassName('failed')[0]);
    })
    this.$bus.on('faceDrawError', val => {
      this.errorMessage = val
        this.showError = true
        this.showUpload = false
        this.showResult = false
      this.getDrawPage()
    })
  },
  methods: {
    getFaceDrawData(taskUid) {
      faceDetail(taskUid).then(res => {
        this.faceFetchData = res.data
      }).catch(() => {
        this.faceDataSetInterval(taskUid)
      })
    },
    faceDataSetInterval(taskUid) {
      this.faceDataTimer = setInterval(() => {
        faceDetail(taskUid).then(res => {
          this.faceFetchData = res.data
          if(!this.isAdd){
            this.picList.forEach(item => {
              if(item.taskUid == res.data.taskUid){
                this.picList[idx] = res.data
                this.picList[idx] = {
                  ...this.picList[idx],
                  accessSubType: res.data.benefitSubType,
                  imageUrl: res.data.thumbnailUrl,
                }
              }
            })
          }
        })
      }, 3000)
    },
    getDrawHotChargerule() {
      drawHotChargerule('AI_DRAW_POPULAR_FEATURES').then(res => {
        this.chargerule = res.data
      })
    },
    async handleSend() {
      if(!this.$store.state.isLogin){
        this.$store.commit('SET_LOGINDISLOG', true)
        return
      }
      if(!this.file) return this.$message.error({message: '请上传人脸图片', duration: 2000})
      if(!this.fileTarget) return this.$message.error({message: '请上传需要替换人脸的图片', duration: 2000})
      if(this.chargerule.userType == 'VIP0' && this.chargerule.recharge){
        this.$refs.noTimes.type = 'gold'
        this.noTimesVisible = true
        return
      }
      if(this.chargerule.userType != 'VIP0' && this.chargerule.recharge && this.chargerule.defaultUseBenefitRule && !this.$store.state.chargerule.baseBenefitRuleVO.recharge){
        this.$refs.noTimes.type = 'times'
        this.$refs.noTimes.num = this.chargerule.baseBenefitRuleVO.chargeNum
        this.noTimesVisible = true
        return
      }
      if(this.chargerule.userType != 'VIP0' && this.chargerule.recharge && this.chargerule.baseBenefitRuleVO.recharge){
        this.$refs.noTimes.type = 'nothing'
        this.noTimesVisible = true
        return
      }
      this.send()
    },
    async send() {
      const params = {
        benefitSubType: 'AI_DRAW_FACE',
        targetFileRequest: {
          fileName: this.file.data.fileName,
          encryOssNo: this.file.data.encryOssNo,
          aigoPreViewUrl: this.file.data.aigoPreViewUrl,
        },
        sourceFileRequest: {
          fileName: this.fileTarget.data.fileName,
          encryOssNo: this.fileTarget.data.encryOssNo,
          aigoPreViewUrl: this.fileTarget.data.aigoPreViewUrl,
        },
      }
      this.showUpload = false
      this.loading = true
      this.isAdd = true
      this.picList.unshift({})
      await this.getFaceData('/ai/draw/face/send', params)
    },
    handleError() {
      this.$message.error({message: '上传失败，请重试', duration: 2000})
    },
    beforeUpload(file) {
      if(!this.$store.state.isLogin){
        this.$store.commit('SET_LOGINDISLOG', true)
        return false
      }
      const isPNG = file.type == 'image/png'
      const isJPG = file.type == 'image/jpg'
      const isJPEG = file.type == 'image/jpeg'
      if (file.size > 1024 * 1024 * 3) {
        this.$message.error({message: '图片太大，请上传 3MB 以下的图片', duration: 2000})
        return  false
      }
      if (!isJPG && !isPNG && !isJPEG) {
        this.$message.error({message: '仅支持 JPG/PNG格式，请重新上传', duration: 2000})
        return false
      }
      this.fileLoading = true
      this.file = ''
    },
    //
    handleSuccess(res, file, fileList) {
      this.file = res
      this.fileLoading = false
    },
    handleErrorTarget() {
      this.$message.error({message: '上传失败，请重试', duration: 2000})
    },
    beforeUploadTarget(file) {
      if(!this.$store.state.isLogin){
        this.$store.commit('SET_LOGINDISLOG', true)
        return false
      }
      const isPNG = file.type == 'image/png'
      const isJPG = file.type == 'image/jpg'
      const isJPEG = file.type == 'image/jpeg'
      if (file.size > 1024 * 1024 * 3) {
        this.$message.error({message: '图片太大，请上传 3MB 以下的图片', duration: 2000})
        return false
      }
      if (!isJPG && !isPNG && !isJPEG) {
        this.$message.error({message: '仅支持 JPG/PNG格式，请重新上传', duration: 2000})
        return false
      }
      this.fileTargetLoading = true
      this.fileTarget = ''
    },
    //
    handleSuccessTarget(res, file, fileList) {
      this.fileTarget = res
      this.fileTargetLoading = false
    },
    handleScroll(e) {
      if(e.srcElement.scrollTop + e.srcElement.offsetHeight > e.srcElement.scrollHeight - 6 ) {
        if(!this.listLoading && !this.picMultiple && !this.inBottom){
          if(this.total == this.picList.length) return
          this.listLoading = true
          this.pageNum ++
          facePage({pageNum: this.pageNum, pageSize: 10}).then(res => {
            var data = res.data.records
            data.forEach(item => {
              this.$set(item, 'checked', false)
            })
            this.picList = this.picList.concat(data)
            this.listLoading = false
            if(this.total == this.picList.length){
              this.inBottom = true
            }
          }).catch(() => {
            this.listLoading = false
            this.pageNum --
          })
        }
      }
    },
    //
    handleChoose(val) {
      if(this.picMultiple) {
        this.checkedList = []
        val.checked = !val.checked
        this.picList.forEach(item => {
          if(item.checked) {
            this.checkedList.push(item.drawUid)
          }
        }) 
      } else { 
        faceDetail(val.taskUid).then(res => {
          this.faceData = res.data
          this.showUpload = false
          this.showResult = true
          this.showError = false
          this.showUpload = false
          this.isAdd = false
        })
      }
    },
    // 重新上传
    handleRegeneration() {
      this.showUpload = true
      this.loading = false
      this.showError = false
      this.showResult = false
      this.file = ''
      this.fileTarget = ''
      this.faceData = ''
      setTimeout(() => {
        this.upWidth = this.upHeight
      }, 10)
    },
    handleDownload() {
      window.open(this.faceData.downloadUrl)
    },
    handleBatchDelete() {
      var drawUids = this.checkedList.join(',')
      faceRemove(drawUids).then(res => {
        if(res.code == 200){
          this.$message.success({message: '删除成功', duration: 2000})
        }
        this.picMultiple = false
        this.getDrawPage()
      })
    },
    // 历史记录多选
    handdleMultiple() {
      this.picMultiple = !this.picMultiple
      this.picList.forEach(item => item.checked = false)
    },
    getDrawPage() {
      this.pageNum = 1
      facePage({pageNum: this.pageNum, pageSize: 10}).then(res => {
        var data = res.data.records
        data.forEach(item => {
          this.$set(item, 'checked', false)
        })
        this.picList = data
        this.total = res.data.total
      })
    },
  }
}
</script>

<style lang="less">

.face{
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  .face_container{
    width: 100%;
    height: 100%;
    padding: 0 24px 24px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    .nodata{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .uploads{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-height: calc(100% - 120px);
        .upload_main{
          width: calc(50% - 8px);
          height: 100%;
          text-align: center;
        }
        .upload{
          min-width: 300px;
          min-height: 300px;
          width: 100%;
          height: 100%;
          position: relative;
        }
        .el-upload, .el-upload .el-upload-dragger{
          width: 100%;
          height: 100%;
        }
        .el-upload-dragger{
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          border-radius: 24px;
        }
        .upload_img{
          img{
            width: 120px;
            height: 120px;
          }
        }
        p:nth-of-type(1){
          font-size: 20px;
          color: #1F2126;
          margin: 12px 0 16px;
          line-height: 28px;
        }
        p:nth-of-type(2){
          font-size: 14px;
          line-height: 22px;
          color: #9498A6;
        }
        .uploaded{
          .el-upload-dragger{
            background-color: transparent;
          }
        }
        .uploaded_pic{
          max-height: 100%;
          max-width: 100%;
            object-fit: contain;
            border-radius: 24px;
        }
        .el-loading-mask{
          border-radius: 24px;
          background: #fff;
        }
      }
      .btns{
        width: 100%;
        text-align: center;
        margin-top: 72px;
        .btn{
          color: #FFFFFF;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          border-radius: 999px;
          width: 187px;
          height: 48px;
          gap: 5px;
          padding: 12px 0;
          background: linear-gradient(165deg, #2E61FC -36%, #6185FC 185%);
          box-shadow: 0px 1.5px 0px 0px #E6EAF0;
          img {
            width: 18px;
            height: 18px;
            margin-left: 6px;
            position: relative;
            top: 3px;
          }
          .cash{
            margin-left: 12px;
          }
          .cash2{
            margin-left: 12px;
            font-size: 14px;
            color: #BFCEFF;
            font-weight: normal;
          }
        }
      }
    }
    .loading{
      width: 100%;
      max-height: 100%;
      min-width: 300px;
      min-height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #fff;
      border-radius: 24px;
      img{
        width: 48px;
        height: 48px;
        animation: rotate 1.5s linear infinite;
      }
      @keyframes rotate {
        to{
              transform: rotate(360deg);
          }
      }
      span:nth-of-type(1){
        margin: 40px 0 15px;
        font-family: Source Han Sans CN;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #1F2126;
      }
      span:nth-of-type(2){
        font-family: Source Han Sans CN;
        font-size: 14px;
        line-height: 22px;
        color: #ACB1BF;
      }
    }
    .failed{
      width: 100%;
      max-height: 100%;
      min-width: 300px;
      min-height: 300px;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      img{
        width: 179px;
        height: 109px;
      }
      p:nth-of-type(1){
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #1F2126;
        margin: 12px;
      }
      p:nth-of-type(2){
        font-size: 14px;
        line-height: 22px;
        color: #ACB1BF;
      }
    }
    .container{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      .image{
        max-height: calc(100% - 75px);
        img{
          max-width: 100%;
          min-height: 100%;
          object-fit: contain;
          border-radius: 16px;
          width: 100%;
          height: 100%;
        }
      }
      .btns{
        margin-top: 24px;
        .btn{
          width: 187px;
          height: 48px;
          padding: 12px 32px;
          gap: 5px;
          border: 1px solid #4773FD;
          color: #2F54EB;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          border-radius: 999px;
        }
        .btn2{
          background: #4773FD;
          border: none;
          color: #fff;
        }
      }
    }
  }
  .draw_history{
    width: 192px;
    height: calc(100% - 24px);
    background: #FFFFFF;
    border-radius: 16px 0 0 16px;
    box-shadow: 0px 15px 35px -8px rgba(59, 119, 174, 0.08);
    .title{
      width: 100%;
      height: 46px;
      line-height: 46px;
      padding: 12px 16px;
      font-family: Source Han Sans CN;
      font-size: 14px;
      color: #3D3D3D;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      img{
        cursor: pointer;
        width: 16px;
        height: 16px;
      }
    }
    .pic_list{
      padding: 0 16px;
      height: 100%;
      height: calc(100% - 70px);
      overflow-y: scroll;
      overflow-x: hidden;
      .draw_item{
        width: 160px;
        height: 160px;
        margin-bottom: 16px;
        border-radius: 8px;
        position: relative;
        .generating{
          width: 100%;
          height: 100%;
          border-radius: 8px;
          background: linear-gradient(135deg, #E7EFFF 0%, #F3EFFD 98%);
          text-align: center;
          font-size: 14px;
          .str{
            display: block;
          }
          .str:nth-of-type(1){
            padding-top: 32px;
          }
          .str:nth-of-type(2){
            font-size: 20px;
            margin: 6px 0 12px 0;
          }
          .progress{
            margin: 0 20px;
            .el-progress-bar__inner{
              background: linear-gradient(149deg, #2E61FC 10%, #6185FC 102%);
            }
            .el-progress-bar__outer{
              background: #D6E4FF !important;
            }
          }
        }
        .flex{
          display: flex;
          align-items: center;
          &.c{
            flex-direction: column;
          }
          &.jc{
            justify-content: center;

          }
        }
        .failing{
          width: 100%;
          height: 100%;
          border-radius: 8px;
          border: 1px solid #E6E9F0;
          padding: 32px 0 40px;
          box-sizing: border-box;
          span:nth-of-type(1){
            font-size: 14px;
            color: #3D3D3D;
            margin: 16px;
          }
          span:nth-of-type(2){
            font-size: 12px;
            color: #ACB1BF;
            cursor: pointer;
          }
          img{
            width: 24px;
            height: 24px;
          }
        }
        .image_list{
          width: 160px;
          height: 160px;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          border-radius: 8px;
          background: #F2F4F7;
          .pic{
            img{
              width: 160px;
              height: 160px;
              border-radius: 8px;
              max-height: 100%;
              max-width: 100%;
              object-fit: contain;
            }
          }
        }
        .check{
          position: absolute;
          width: 160px;
          height: 160px;
          top: 0;
          right: 0px;
          z-index: 2;
          border-radius: 8px;
          box-sizing: border-box;
          img{
            position: absolute;
            top: 4px;
            right: 4px;
            width: 24px;
            height: 24px;
          }
        }
        .border{
          border: 2px solid #1467FF;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
        }
      }
      .list_loading{
        text-align: center;
        font-size: 14px;
        color: #ACB1BF;
        margin-bottom: 10px;
      }
    }
    .btn_delete{
      margin: 10px 0;
      padding: 0 16px;
      .el-button{
        width: 160px;
        height: 32px;
        border-radius: 6px;
        background: #CACDD9;
        color: #fff;
        box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.05);
        border: none;
        padding: 5px 16px;
      }
    }
    .bg_color{
      .el-button{
        background: #FF4D4F;
      }
    }
  }
}

</style>
<template>
  <div class="aiContent">
    <div class="contentBox">
      <div class="left">
        <el-tabs
          v-model="activeName"
          class="tabsAi"
          @tab-click="handleClick"
        >
          <el-tab-pane name="first">
            <span slot="label">自由对话</span>
            <div
              v-scrollActive
              class="tabContent"
            >
              <p class="text">
                历史记录
                <el-popover
                  placement="top-start"
                  width="200"
                  popper-class="tip"
                  trigger="hover"
                  content="超过30天且未使用过的对话将会被删除，您可以通过重新使用该对话来延长保存时长。"
                >
                  <img
                    slot="reference"
                    src="@/static/index/tip.png"
                  >
                </el-popover>
              </p>
              
              <div
                v-for="(item, index) in this.$store.state.listChatRoom"
                :key="index"
                class="item2"
              >
                <div
                  v-show="!item.edit"
                  class="too2"
                >
                  <img
                    src="@/static/index/edit.png"
                    @click="editTitle(item,index)"
                  >
                  <el-popconfirm
                    title="确定删除该数据吗？"
                    @confirm="deleteData(item)"
                  >
                    <img
                      slot="reference"
                      src="@/static/index/del.png"
                    >
                  </el-popconfirm>
                </div> 
                <div
                  class="item"
                  :class="item.uid==activeUid?'active':''"
                  @click="getItem(item)"
                >
                  <div class="title">
                    <p v-show="!item.edit">
                      {{ item.roomTitle }}
                    </p>
                    <el-input
                      v-show="item.edit"
                      v-model="item.roomTitle"
                      placeholder=""
                      width="133px"
                      :autofocus="true"
                      @blur="editClose(item)"
                    />
                  
                    <div
                      v-show="item.edit"
                      class="tool1"
                    >
                      <i
                        class="el-icon-check"
                        style="margin-left:8px;"
                        @click.stop="check(item)"
                      />
                      <i
                        class="el-icon-close"
                        style="margin-left:8px;"
                        @click.stop="editClose(item)"
                      />
                    </div>
                  </div>
                  <p class="text">
                    <span>{{ item.messageNum }}条对话</span>
                    <span>{{ item.createTimeStr }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="addTalk"
              @click="addTalk"
            >
              <div><img src="@/static/index/add.png"> 新建对话</div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="热门功能"
            name="second"
          >
            <div
              class="tabContent"
              style="overflow-y: hidden"
            >
              <p class="text">
                通过热门工具快速完成任务
              </p>
              <ul class="navList">
                <li>
                  <router-link
                    to="/ai/copywriting"
                    active-class="active"
                  >
                    <div class="navImg">
                      <img src="@/static/index/ai-icon2.png">
                    </div>
                    <div class="navItem">
                      <p class="title">
                        文案撰写
                      </p>
                      <p class="text">
                        快速完成一篇文案
                      </p>
                    </div>
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/ai/line"
                    active-class="active"
                  >
                    <div class="navImg">
                      <img src="@/static/index/ai-icon3.png">
                    </div>
                    <div class="navItem">
                      <p class="title">
                        拟定提纲
                      </p>
                      <p class="text">
                        为主题构建提纲目录
                      </p>
                    </div>
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/ai/wgrs"
                    active-class="active"
                  >
                    <div class="navImg">
                      <img src="@/static/index/ai-icon4.png">
                    </div>
                    <div class="navItem">
                      <p class="title">
                        文稿润色 & 纠错
                      </p>
                      <p class="text">
                        提升文稿的文学水平
                      </p>
                    </div>
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/ai/summarize"
                    active-class="active"
                  >
                    <div class="navImg">
                      <img src="@/static/index/ai-icon5.png">
                    </div>
                    <div class="navItem">
                      <p class="title">
                        内容总结
                      </p>
                      <p class="text">
                        梳理文章内容要点
                      </p>
                    </div>
                  </router-link>
                </li>
              </ul>
              <p class="textBottom">
                更多功能，敬请期待
              </p>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="right">
        <keep-alive>
          <router-view
            :uid="activeUid"
            :default-show="defaultShow"
            :scroll="scroll"
            kee
          />
        </keep-alive>
      </div>
    </div>
    <company />
  </div>
</template>

<script>
import { updateTitle,remove } from "@/api/index";
  import company from  "@/components/company.vue"
export default {
 components: {
    company
  },
  data() {
    return {
      active:false,
      activeName: "first",
      listChat: this.$store.state.listChat,
        activeUid: '',
    defaultShow:false,
   tilte:'',
   scroll:false,
    };
  },

  watch: {
    $route(to, from) {
      console.log(to, from);
      if (to.name == "FreeTalk") {
        this.activeName = "first";
       
        
      } else {
        this.activeName = "second";
      }
    },
    '$store.state.listChatRoom'(value){
       console.log(this.active)
       if(value){
        if(this.active){
          this.activeUid=this.$store.state.listChatRoom[0].uid
        }
          
       }
    }
  },
  async mounted() {
   await this.$store.dispatch("getChatList");
 
 
    if (this.$route.name == "FreeTalk") {
      this.activeName = "first";
        this.defaultShow=true
       this.activeUid=''
     this.active=true
    } else {
      this.activeName = "second";
    }
  },

  methods: {
    titleInput(item){
     item.edit = false;
     item.roomTitle = item.text;
    },
   async editTitle(item) {
      this.$store.state.listChatRoom.forEach(element => {
          element.edit = false;
          element.roomTitle= element.text;
      });
      item.edit = true;
    },
    addTalk(){
      this.activeUid=''
      this.defaultShow=true
    },
    check(item) {
      item.edit = false;
      updateTitle({ roomUid: item.uid, roomTitle: item.roomTitle });
    },
    editClose(item) {
      item.edit = false;
      item.roomTitle = item.text;
    },
    async deleteData(item) {
     
      await remove({roomUid:item.uid})
       this.$store.dispatch("getChatList");
       if(this.$store.state.listChatRoom.length==0){
        this.$router.replace({path:'/ai/freetalk',query:{}})
       }

    },
    getItem(item) {
      if(item.edit){
        return 
      }
 this.scroll=true
   this.active=false
      this.$store.state.listChatRoom.forEach(element => {
    element.edit = false;
    element.roomTitle= element.text;
    
});
// item.edit=true
      this.activeUid=item.uid
     
    },

    handleClick(tab, event) {
      console.log(tab, event);
      if (tab.index === "1") {
        this.$router.push("/ai/copywriting");
      } else if (tab.index === "0") {
        this.$router.push("/ai/freetalk");
      }
    },
  },
};
</script>

<style lang="less">
.aiContent {
  width: 100%;
  box-sizing: border-box;
  padding: 76px 16px 16px 16px;
  height: 100%;
  overflow: hidden;
  background: url(../../static/index/ai-bg.png);
  background-size: 100% 100%;

  .contentBox {
    display: flex;
    justify-content: space-between;
    height: calc(100% - 24px);
    width: 100%;

    .left {
      width: 232px;
      height: 100%;
      margin-right: 16px;

      .tabsAi {
        border-radius: 12px;
        background: #ffffff;

        height: 100%;
        overflow: hidden;
        /* 侧边栏 */
        box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.03);
        .el-tabs__content {
          height: 100%;

          .tabContent {
            overflow: hidden;
            overflow-y: scroll;
            height: calc(100% - 92px);
            padding: 12px;
            width: 100%;
            .texTop {
              font-family: Source Han Sans CN;
              font-size: 12px;
              font-weight: normal;
              line-height: 20px;
              letter-spacing: 0em;
              padding: 16px;
              /* Grey/5 */
              color: #9498a6;
              display: flex;
              justify-content: space-between;
            }
            box-sizing: border-box;
            .text {
              font-family: Source Han Sans CN;
              font-size: 12px;
              font-weight: normal;
              line-height: 20px;
              letter-spacing: 0em;

              /* Grey/5 */
              color: #9498a6;
              display: flex;
              justify-content: space-between;
              img {
                width: 12px;
                height: 12px;
                cursor: pointer;
              }
            }
            .item2{
              display: inline-block;
              position: relative;
                .too2 {
                  white-space: nowrap;
                  visibility: hidden;
                      position: absolute;
    top: 31px;
    right: 6px;
                  img {
                    width: 16px !important;
                    margin-left: 10px !important;
                    height: 16px !important;
                    cursor: pointer;
                  }
                }
              &:hover {
                  .too2 {
                    visibility: inherit;
                  }
                }
            .item {
                &.active {
                background: #f2f4f7;
              }
              border-radius: 8px;
              margin-top: 16px;
              opacity: 1;
              width: 205px;
              height: 79px;
              box-sizing: border-box;
              text-decoration: none;
              /* 自动布局 */
              display: flex;
              flex-direction: column;
              padding: 12px;
              cursor: pointer;
              .title {
                font-family: Source Han Sans CN;
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0em;
                margin-bottom: 13px;
                /* Grey/10 */
                color: #1f2126;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                .el-input__inner {
                  height: 22px;
                  font-family: Source Han Sans CN;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 22px;
                  letter-spacing: 0em;
                  padding: 0 8px;
                  /* Grey/10 */
                  color: #1F2126;
                }
                p {
                      width: 138px;
                      height: 22px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .tool {
                  white-space: nowrap;
                  visibility: hidden;
                  img {
                    width: 16px;
                    height: 16px;
                  }
                }
                .tool1 {
                  white-space: nowrap;
                }
              
              }
              
            }
            }
            .navList {
              background: #ffffff;

              padding-left: 0px;

              box-sizing: border-box;
              li {
                width: 100%;
                display: inline-block;
                padding: 0;
                margin: 0;
                margin-top: 16px;
                height: 70px;
                box-sizing: border-box;
                white-space: wrap;

                &:last-child {
                  margin-bottom: 0px;
                }
                a {
                  /* 自动布局 */
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  text-decoration: none;
                  padding: 12px;
                  gap: 12px;
                  height: 70px;
                  border-radius: 8px;
                  box-sizing: border-box;
                  width: 100%;
                  white-space: nowrap;
                  position: relative;

                  .navImg {
                    width: 45px;
                    height: 45px;
                    border-radius: 6px;
                    display: flex;
                    justify-content: center;
                    text-align: center;
                    align-items: center;
                    border-radius: 6px;
                    background: #f8f9fc;
                    flex-shrink: 0;
                    img {
                      width: 32px;
                      height: 32px;
                      margin-left: 0;
                      margin: 0 auto;
                    }
                  }
                  &:hover {
                    background: #f2f4f7;
                    .navImg {
                      background: #fff;
                    }
                  }
                  &.active {
                    background: #f2f4f7;
                    .navImg {
                      background: #fff;
                    }
                    &::after {
                      content: "";
                      position: absolute;
                      right: 4px;
                      top: 50%;
                      margin-top: -16px;
                      width: 4px;
                      height: 32px;
                      border-radius: 8px;
                      opacity: 1;

                      /* Main/06 */
                      background: #1467ff;
                    }
                  }
                  .navItem {
                    .title {
                      font-family: Source Han Sans CN;
                      font-size: 16px;
                      font-weight: 500;
                      line-height: 24px;
                      letter-spacing: 0em;

                      /* Grey/9 */
                      color: #373940;
                    }
                    .text {
                      padding-top: 8px;
                      /* Body/regular */
                      font-family: Source Han Sans CN;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 22px;
                      letter-spacing: 0em;

                      /* Grey/6 */
                      color: #7a7f8c;
                    }
                  }
                }
              }
            }
            .textBottom {
              font-family: Source Han Sans CN;
              font-size: 10px;
              font-weight: normal;
              line-height: 18px;
              letter-spacing: 0em;

              /* Grey/5 */
              color: #9498a6;
              text-align: center;
              margin: 0 auto;
              position: relative;
              margin-top: 56px;
              width: 186px;
              &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 32px;
                height: 1px;
                background: #cacdd9;
              }
              &::after {
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                width: 32px;
                height: 1px;
                background: #cacdd9;
              }
            }
          }
          .addTalk {
            margin: 0 16px;
            padding: 16px 0;
            border-top: solid 1px #f2f4f7;
            bottom: 10px;
            position: relative;
            background: #fff;
            cursor: pointer;
            div {
              img {
                width: 16px;
                height: 16px;
              }
              display: flex;
              border-radius: 6px;
              justify-content: center;
              align-items: center;
              padding: 5px 16px;
              gap: 8px;

              /* Grey/0 */
              background: #ffffff;

              box-sizing: border-box;
              /* Grey/2 */
              border: 1px solid #dadde5;

              /* 按钮/标准 */
              box-shadow: 0px 1.5px 0px 0px #f5f6f7;
              /* Body/regular */
              font-family: Source Han Sans CN;
              font-size: 14px;
              font-weight: normal;
              line-height: 22px;
              letter-spacing: 0em;

              /* Grey/4 */
              color: #acb1bf;
            }
          }
        }
        .el-tabs__item {
          text-align: center;
          width: 116px;
          padding: 0px;
          font-family: Source Han Sans CN;
          height: 38px;
          font-size: 14px;
          font-weight: 500;
          line-height: 38px;
          letter-spacing: 0em;

          /* Main/06 */
          color: #646873;
          &.is-active {
            color: #1467ff;
            border-radius: 0px 12px 0px 0px;
            background: #fff;
          }
          &:last-child {
            border-radius: 12px 12px 0px 0px;
          }
        }

        .el-tabs__header {
          margin: 0px;
        }
      }
    }
  }
}
.el-tabs__nav {
  background: #e6e9f0;
  border-radius: 12px 12px 0px 0px;

  .el-tabs__active-bar {
    display: none;
    height: 0px !important;
  }
}
.el-tab-pane {
  height: 100%;
}

.right {
  flex: 1;
  width: 100%;
  height: 100%;
  margin-right: 16px;
}
.tip{
  /*  */
 
  border-radius: 6px !important;
opacity: 1;

 
padding: 8px 12px !important;

/* Grey/10 */
background: #1F2126 !important;
border: none !important;
font-size: 12px !important;
font-weight: normal;
line-height: 20px !important;
letter-spacing: 0em;

/* Grey/1.5 */
color: #E6E9F0 !important;
/* 提示条 */
box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15),0px 8px 15px 0px rgba(0, 0, 0, 0.25) !important;
}
.popper__arrow,.popper__arrow::after{
  border-color: #1F2126 !important;
  border-style: none !important;
}
 
</style>
<template>
  <el-dialog
    :title="title"
    :visible.sync="centerDialogVisible"
    width="418px"
    custom-class="cancelDialog"
    :show-close="false"
    center
    :before-close="handClose"
      append-to-body
  >
    <div class="center">{{content}}</div>
    <span slot="footer" class="dialog-footer">
      <el-button class="cancelButton" @click="handClose"
        >取 消</el-button
      >
      <el-button
        class="submitButton"
        type="primary"
        :loading="loading"
        @click="submit"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>

export default {
  props: {
    centerDialogVisible: { type: Boolean, default: false },
    title: { type: String, default: "确认取消订单" },
    loading:{type:Boolean,default:false},
     content:{ type: String, default: "" },
  },
 
  methods:{
    handClose(){
         this.$emit("update:centerDialogVisible", false);
    },
    submit(){
        console.log(2323)
         this.$emit("update:loading", true);
        this.$emit("submitcancel")
        
    }
  }
};
</script>

<style lang='less'>
.cancelDialog {
  border-radius: 16px !important;
  padding: 24px;
  .center {
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0em;
    text-align: center;
    /* Grey/9 */
    color: #373940;
  }
  .el-dialog__body {
    padding: 24px !important;
  }
  .cancelButton {
    box-sizing: border-box;
    /* Grey/2 */
    border: 1px solid #dadde5;

    /* 按钮/标准 */
    box-shadow: 0px 1.5px 0px 0px #f5f6f7;
    width: 92px;
    height: 32px;
    border-radius: 6px;
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-weight: normal;
    padding: 0px;
    letter-spacing: 0em;

    /* Grey/9 */
    color: #373940;
    margin-right: 16px;
  }
  .submitButton {
    width: 92px;
    padding: 0px;
    font-size: 14px;
    height: 32px;
    border-radius: 6px;
  }
}
</style>
import { render, staticRenderFns } from "./aiMarkdown.vue?vue&type=template&id=747eb6ac&scoped=true&"
import script from "./aiMarkdown.vue?vue&type=script&lang=js&"
export * from "./aiMarkdown.vue?vue&type=script&lang=js&"
import style0 from "./aiMarkdown.vue?vue&type=style&index=0&id=747eb6ac&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "747eb6ac",
  null
  
)

export default component.exports
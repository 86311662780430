<template>
  <div class="public">
    <div
      v-if="pubList.length > 0"
      class="pub_container"
    >
      <div class="title">
        <span class="text-right">已公开的作品</span>
      </div>
      <div
        v-scrollActive
        class="pub_list"
        @scroll="handleScroll"
      >
        <div
          v-for="item, idx in pubList"
          :key="idx"
          class="item"
        >
          <div class="pub_img">
            <img :src="item.thumbnailViewUrl">
          </div>
          <div class="more">
            <!-- @click="handleShowBtn(item)" -->
            <i
              class="el-icon-more"
              @mouseover="handleShowBtn(item)"
            />
            <div @mouseleave="handleShowBtn2(item)">
              <el-button
                v-show="item.showBtn"
                class="cancel_btn"
                @click="handleOff(item, idx)"
              >
                取消公开
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="nodata"
    >
      <img src="@/static/user/nodata.png">
      <p>暂无创作记录</p>
    </div>
  </div>
</template>

<script>
import { interactRecordPage, interactOff } from '@/api/index'

export default {
  data() {
    return {
      pubList:[],
      pageNum: 1,
      pageSize: 20,
      total: 0,
    }
  },
  mounted() {
    this.getInteractRecordPage()
  },
  methods: {
    handleScroll(e) {
      if(e.srcElement.scrollTop + e.srcElement.offsetHeight >= e.srcElement.scrollHeight ) {
        if(this.total == this.pubList.length){
          return false
        }
        this.pageNum ++ 
        const params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        interactRecordPage(params).then(res => {
          let arr = []
          res.data.records.forEach(item => {
            let obj = {...item}
            this.$set(obj, 'showBtn', false)
            arr.push(obj)
          })
          this.pubList = this.pubList.concat(arr)
          this.total = res.data.total
        }).catch(err => {
          this.pageNum --
        })
      }
    },
    getInteractRecordPage() {
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      interactRecordPage(params).then(res => {
        let arr = []
        res.data.records.forEach(item => {
          let obj = {...item}
          this.$set(obj, 'showBtn', false)
          arr.push(obj)
        })
        this.pubList = arr
        this.total = res.data.total
      })
    },
    handleShowBtn(val) {
      val.showBtn = !val.showBtn
    },
    handleShowBtn2(val) {
      val.showBtn = false
    },
    handleOff(val, idx) {
      interactOff({taskUid: val.bizDataUid}).then(res => {
        if(res.code == 200){
          this.$message.success({message: '已取消公开该作品', duration: 2000})
          this.pubList.splice(idx, 1)
        }
      })
    },
  }
}
</script>

<style lang="less">
.public{
  width: 100%;
  height: 100%;
  font-family: '思源黑体';
  padding: 24px 0 24px 20px;
  box-sizing: border-box;
  .pub_container{
    width: 100%;
    height: 100%;
    .title {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      .text-right {
        position: relative;
        font-family: '思源黑体';
        font-size: 16px;
        font-weight: 500;
        line-height: 34px;
        letter-spacing: 0em;
        padding-left: 10px;
        color: #1f2126;
        &::before {
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -7px;
          content: "";
          width: 4px;
          height: 14px;
          border-radius: 999px;
          opacity: 1;
          background: #1f2126;
        }
      }
    }
    .pub_list{
      width: 100%;
      height: calc(100% - 46px);
      overflow-y: scroll;
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;
      // padding-right: 20px;
      box-sizing: border-box;
      .item{
        width: 202px;
        margin-right: 15px;
        height: 240px;
        display: inline-block;
        .pub_img{
          width: 100%;
          height: 202px;
          border-radius: 12px;
          background: #F2F4F7; 
          img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 12px;
          }
        }
        .more{
          color: #ACB1BF;
          font-size: 14px;
          width: 100%;
          text-align: right;
          margin: 2px 0 16px;
          position: relative;
          i{
            cursor: pointer;
          }
          .cancel_btn{
            position: absolute;
            right: 0px;
            top: 20px;
            width: 96px;
            height: 42px;
            line-height: 22px;
            color: #1F2126;
            border-radius: 8px;
            padding: 4px;
            gap: 4px;
            background: #FFFFFF;
            box-sizing: border-box;
            border: 1px solid #E6E9F0;
            box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.08);
          }
        }
      }
    }
  }
  .nodata{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img{
      width: 120px;
      margin-bottom: 12px;
    }
    p{
      font-size: 12px;
      line-height: 20px;
      color: #B1B7C8;
    }
  }
}
</style>
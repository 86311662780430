<template>
  <div class="toolbox">
    <div
      v-scrollActive
      class="toolbox_left"
    >
      <p class="menu_title">
        图片工具
      </p>
      <router-link
        to="/toolbox/compress"
        active-class="item_active"
      >
        <div class="menu_item ">
          <img src="@/static/ai/compress.png">
          <span>图片压缩</span>
        </div>
      </router-link>
      <router-link
        to="/toolbox/stretch"
        active-class="item_active"
      >
        <div class="menu_item">
          <img src="@/static/ai/stretch.png">
          <span>拉伸图像恢复</span>
        </div>
      </router-link>
      <router-link
        to="/toolbox/black"
        active-class="item_active"
      >
        <div class="menu_item">
          <img src="@/static/ai/black.png">
          <span>黑白图像上色</span>
        </div>
      </router-link>
      <router-link
        to="/toolbox/enlarge"
        active-class="item_active"
      >
        <div class="menu_item">
          <img src="@/static/ai/enlarge.png">
          <span>图像无损放大</span>
        </div>
      </router-link>
      <router-link
        to="/toolbox/moire"
        active-class="item_active"
      >
        <div class="menu_item">
          <img src="@/static/ai/moire.png">
          <span>去摩尔纹</span>
        </div>
      </router-link>
      <router-link
        to="/toolbox/repair"
        active-class="item_active"
      >
        <div class="menu_item">
          <img src="@/static/ai/repair.png">
          <span>文档图片去底纹</span>
        </div>
      </router-link>
      <router-link
        to="/toolbox/enhance"
        active-class="item_active"
      >
        <div class="menu_item">
          <img src="@/static/ai/enhance.png">
          <span>图片清晰度增强</span>
        </div>
      </router-link>
      <!-- <div class="menu_item">
            <img src="@/static/ai/black.png">
            <span>图像清晰度增强</span>
          </div> -->
      <!-- <div class="menu_item">
            <img src="@/static/ai/black.png">
            <span>人像动漫化</span>
          </div> -->
      <p class="menu_title">
        文档工具
      </p>
      <router-link
        to="/toolbox/pdf"
        active-class="item_active"
      >
        <div class="menu_item">
          <img src="@/static/ai/pdf2.png">
          <span>图片转PDF</span>
        </div>
      </router-link>
      <router-link
        to="/toolbox/doc"
        active-class="item_active"
      >
        <div class="menu_item">
          <img src="@/static/ai/doc.png">
          <span>PDF转Word</span>
        </div>
      </router-link>
      <router-link
        to="/toolbox/ppt"
        active-class="item_active"
      >
        <div class="menu_item">
          <img src="@/static/ai/ppt.png">
          <span>PDF转PPT</span>
        </div>
      </router-link>
    </div>
    <div class="toolbox_right">
      <keep-alive>
        <router-view
          ref="drawingBoard"
          kee
        />
      </keep-alive>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: '1'
    }
  },
  mounted () {

  },
  methods:{
    handleChangeTab(val) {
      this.activeTab = val
      if(val == '1') {
        // this.$router.push("/aidraw/quickdraw");
      } else {
        // this.$router.push("/aidraw/word");
      }
    },
  }
}
</script>

<style lang="less">
.toolbox{
  width: 100%;
  height: calc(100% - 56px);
  margin-top: 56px;
  background: url('../../static/ai/toolboxbg.png') center no-repeat;
  background-size: 100% 100%;
  padding-top: 64px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  .toolbox_left{
    width: 246px;
    height: 648px;
    border-radius: 16px;
    box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
    background: #FFFFFF;
    padding: 8px 16px;
    box-sizing: border-box;
    overflow-y: scroll;
    .tabs{
      height: 72px;
      display: flex;
      background: #E6E9F0;
      border-radius: 16px 16px 0 0 ;
      font-size: 14px;
      color: #646873;
      justify-content: space-between;
      .item{
        line-height: 46px;
        width: 123px;
        text-align: center;
        color: #646873;
        cursor: pointer;
      }
      .active{
        width: 123px;
        height: 100px;
        color: #1467FF;
        font-weight: bold;
        background-color: #fff;
        border-radius: 16px 100px 0 0 ;
        position: relative;
      }
      .active2{
        border-radius: 100px 16px 0 0 ;
      }
      .active_bg{
        width:  130px;
        height: 46px;
        background: url('../../static/ai/tabbg.png');
        background-size: 100% 100%;
        &.active_bg::before{
          content: '';
          width: 16px;
          height: 2px;
          background: #1467FF;
          border-radius: 1px;
          transform: translateX(25%);
          display: block;
          position: absolute;
          top: 33px;
          left: 53px;
        }
      }
      .active_bg2{
        width:  130px;
        height: 46px;
        background: url('../../static/ai/tabbg2.png');
        background-size: 100% 100%;
        position: relative;
        right: 17px;
        &.active_bg2::before{
          content: '';
          width: 16px;
          height: 2px;
          background: #1467FF;
          border-radius: 1px;
          transform: translateX(25%);
          display: block;
          position: absolute;
          top: 33px;
          left: 53px;
        }
      }
    }
    .tab_card{
      width: 100%;
      height: calc(100% - 46px);
      padding: 16px;
      box-sizing: border-box;
      background: #FFFFFF;
      position: relative;
      z-index: 1;
      top: -26px;
      border-radius: 16px;
    }
    .menu_title{
      margin: 16px 0 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: #7A7F8C;
    }
    .menu_item{
      height: 58px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 13px 16px;
      box-sizing: border-box;
      margin-bottom: 8px;
      cursor: pointer;
      img{
        width: 32px;
        height: 32px;
        margin-right: 12px;
      }
      span{
        font-size: 16px;
        font-family: '思源黑体';
        line-height: 24px;
        color: #1F2126;
      }
    }
    .item_active{
      position: relative;
      .menu_item{
        background: #F2F4F7;
        border-radius: 8px 0px 0px 8px;
      }
      &.item_active::before{
        content: '';
        display: block;
        width: 9px;
        height: 58px;
        border-radius: 8px 0px 0px 8px;
        background: #1467FF;
        position: absolute;
        left: 0;
        top: 0;
      }
      span{
        color: #1467FF;
      }
    }
    a{
      text-decoration: none;
      display: flex;
      align-items: center;
    }
  }
  .toolbox_right{
    margin-left: 31px;
    width: 866px;
    height: 648px;
  }
}
</style>
<template>
  <div class="itemLabel">
    <div
      v-for="(item, index) in labelValueArray "
      :key="index" 
      class="itemSpan"
    >
      <span
        v-show="item.text!=''"
        :class="seletedLabel === item.text ? 'active' : ''"
        @click="changeLabel(item)"
      >
        <img
          v-if="index == 0"
          style="width: 14px; height: 14px"
          src="@/static/index/auto.png"
        >{{ item.text }}
      </span>
    </div>
    <div class="itemSpan custom">
      <span
        v-show="!isInput "
        @click="changeInput"
      ><i class="el-icon-plus" /> 自定义
      </span>
    </div>
    
    <el-input
      v-if="isInput1"
      ref="inputRef"
      v-model="inputText"
      class="inputText"
      :class="inputText ? 'active' : ''"
      autofocus
      placeholder="请输入"
      @blur="review"
    />
    <p
      v-show="labelError"
      class="labelError"
    >
      <img src="@/static/index/close.png"> 该输入框仅支持输入数字，请重新输入
    </p>
  </div>
</template>

<script>
export default {
  props: {
    labelValues: {
      default: () => {
        return [];
      },
      type: Array,
    },
    text: {
      type: [Number, String],
      default: "",
    },
    groupNo: {
      type: String,
      default: "",
    },
    inputNum: {
      type: String,
      default: "",
    },
    unit:{default:'',type:String}
  },
  data() {
    return {
      seletedLabel: "自动",
      isInput: false,
       isInput1: false,
      inputText: '',
      labelError: false,
      changeInputValue:false,
      textData:'',
        data: {
            dictUid: "",
            groupNo: " ",
            id: "100",
            sort: null,
            text: '',
            value:'',
            isInput:false,
          }
    };
  },
  computed: {
    labelValueArray() {
     
          
        let   labelValueArray =this.labelValues
        labelValueArray.push(this.data)
      return  labelValueArray;
    },
   
  },
  watch: {
    text(value) {
      if (!value) {
        this.seletedLabel = "自动";
       
      }
    },
    inputText(value) {
      const pattern = /^[1-9]\d*$/;
      if (this.inputNum == "number") {
        if (!pattern.test(value) && value) {
          this.labelError = true;
        } else {
          this.$emit("update:text", this.inputText);
          this.labelError = false;
        }
      } else {
        if (this.inputText == "") {
          // this.isInput = false;
          this.seletedLabel = 0;
        } else {
          this.labelError = false;
          this.$emit("update:text", this.inputText);
        }
      }
    },
  },
  methods: {
    changeInput() {
      this.isInput = true;
      
       this.isInput1 = true;
      this.seletedLabel = -1;

      this.data.text = "";

      // this.$nextTick(() => {
      //   this.$refs.inputRef.focus();
      // });
    },
    changeLabel(item) {
     if(item.id=="100"){
       this.isInput1 = true;
        this.isInput = true;
        this.data.text= ''
         this.seletedLabel = item.text;
         this.$emit("update:text", this.inputText);
      return
     }
      this.seletedLabel = item.text;
      this.isInput = false;
       this.isInput1 =false;
    
       console.log('isInput',this.isInput);
       this.data.text=''
      this.$emit("update:text",  item.value);
    },
    review() {
      const pattern = /^[1-9]\d*$/;
     
      if (this.inputNum == "number") {
        if (!pattern.test(this.inputText)) {
        
          this.$emit("update:text", this.inputText);
          this.labelError = true;
        }else{
          this.labelError = false;
          this.isInput = true;
           this.isInput1 = false;
          this.changeInputValue=true
         this.data.text=this.inputText+this.unit
       
         this.data.value=this.inputText
          this.seletedLabel =this.inputText+this.unit;
          this.$emit("update:text", this.inputText);
        }
           console.log(this.data.text)
      } else {
        if (this.inputText == "") {
          this.isInput = false;
           this.isInput1 = false;
          this.seletedLabel = 0;
        } else {
          this.labelError = false;
          this.isInput = true;
           this.isInput1 = false;
          this.changeInputValue=true
         this.data.text=this.inputText+this.unit
         this.data.value=this.inputText
          this.seletedLabel =this.inputText;
          this.$emit("update:text", this.inputText);
        }
      }
    },
  },
};
</script>

<style lang='less'>
.itemLabel {
  display: flex;
  flex-wrap: wrap;
  .itemSpan {
    display: flex;
    justify-content: center;
    align-items: center;
   

    /* Grey/9 */
    color: #373940;
  span{
     display: flex;
     align-items: center;
     padding: 2px 9px;
    gap: 2px;
    margin-right: 6px;
    margin-bottom: 10px;
    /* Grey/0 */
    background: #ffffff;

    box-sizing: border-box;
    /* Grey/2 */
    border: 1px solid #dadde5;
    border-radius: 4px;
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-weight: normal;

    letter-spacing: 0em;
      img {
      width: 14px;
      height: 14px;
    }
    &.active {
      background: #1467ff;
      color: #fff;
      border: 1px solid #1467ff;
    }
    &.custom {
      border: dashed 1px #dadde5;
    }
    cursor: pointer;
  }
  &.custom{
    span{
      border:dashed 1px #dadde5; 
    }
  }
  }
  .inputText {
    width: 74px;
    height: 22px;
    .el-input__inner {
      height: 25px;
      border-radius: 4px;
    }
  }
  .labelError {
    display: flex;
    align-items: center;
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    letter-spacing: 0em;

    /* Red/6 */
    color: #f5222d;
    img {
      margin-right: 12px;
      width: 16px;
      height: 16px;
    }
  }
}
</style>
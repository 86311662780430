<template>
  <div class="quickdraw">
    <div class="drawing_board">
      <div
        :style="{background: showBG ? '#fff' : 'transparent'}"
        class="board"
      >
        <div
          v-show="!generating && !showError"
          class="nodata"
        >
          <img
            src="../../static/ai/nodata.png"
            alt=""
          >
          <p>暂无结果</p>
          <p>快尝试创作一副作品吧</p>
        </div>
        <div
          v-if="loading"
          class="loading"
        >
          <img
            src="../../static/ai/loading.png"
            alt=""
          >
          <span>正在排队等待模型响应，请稍等.....</span>
          <span>{{ sentence }}</span>
        </div>
        <div
          v-show="generating"
          class="container"
        >
          <!-- <div class="progress"> -->
          <div
            v-if="drawData?.taskStatusCode == 'PARSEING'"
            class="progress"
          >
            <div class="num">
              <span>正在生成中...</span>
              <!-- <span>{{ isCut ? '绘图完成，切图处理中，请稍等......' : '正在生成中...' }}</span> -->
              <span>{{ drawData.progress }}%</span>
            </div>
            <img
              ref="progressImage"
              :src="drawData.imageUrl"
              alt=""
              @error="progressImageError"
            >
          </div>
          <div
            v-show="drawData?.taskStatusCode == 'DONE'"
            class="pic_list"
          >
            <img
              class="res_img"
              :src="process == 'production' ? drawData?.imageUrl : drawData?.imageUrl?.split('idcfengye.com')[1]"
              @error="progressImageError"
            >
          </div>  
        </div>
        <div
          v-if="showError"
          class="failed"
        >
          <img
            src="../../static/ai/error.png"
            alt=""
          >
          <p>阿哦，图片生成失败~</p>
          <p>{{ errorMessage }}</p>
        </div>
      </div>
      <div
        v-show="showBtns"
        class="btns"
      >
        <div class="btns_left">
          <div
            v-show="drawData?.taskApiActionCodeList?.includes('upsample1') || drawData?.taskApiActionCodeList?.includes('upsample2') || drawData?.taskApiActionCodeList?.includes('upsample3') || drawData?.taskApiActionCodeList?.includes('upsample4')"
            v-click-outside="handleBlur2"
            class="btns_list"
          >
            <el-button
              class="btn2"
              @click="handleShowEnlarge"
            >
              <img src="@/static/ai/btnIcon.png">
              放大
            </el-button>
            <div
              v-show="showEnlarge"
              class="op_list"
            >
              <div
                v-show="drawData?.taskApiActionCodeList?.includes('upsample1')"
                :class="drawData?.subTaskProgressBl ? 'op_disabled' : ''"
                class="op_item"
                @click="handleUpsample('upsample1')"
              >
                <img
                  src="@/static/ai/btnIcon11.png"
                  alt=""
                >
                <span>左上</span>
              </div>
              <div
                v-show="drawData?.taskApiActionCodeList?.includes('upsample3')"
                :class="drawData?.subTaskProgressBl ? 'op_disabled' : ''"
                class="op_item"
                @click="handleUpsample('upsample3')"
              >
                <img
                  src="@/static/ai/btnIcon10.png"
                  alt=""
                >
                <span>左下</span>
              </div>
              <div
                v-show="drawData?.taskApiActionCodeList?.includes('upsample2')"
                :class="drawData?.subTaskProgressBl ? 'op_disabled' : ''"
                class="op_item"
                @click="handleUpsample('upsample2')"
              >
                <img
                  src="@/static/ai/btnIcon9.png"
                  alt=""
                >
                <span>右上</span>
              </div>
              <div
                v-show="drawData?.taskApiActionCodeList?.includes('upsample4')"
                :class="drawData?.subTaskProgressBl ? 'op_disabled' : ''"
                class="op_item"
                @click="handleUpsample('upsample4')"
              >
                <img
                  src="@/static/ai/btnIcon8.png"
                  alt=""
                >
                <span>右下</span>
              </div>
            </div>
          </div>
          <div
            v-show="drawData?.taskApiActionCodeList?.includes('variation1') || drawData?.taskApiActionCodeList?.includes('variation2') || drawData?.taskApiActionCodeList?.includes('variation3') || drawData?.taskApiActionCodeList?.includes('variation4')"
            v-click-outside="handleBlur3"
            class="btns_list"
          >
            <el-button
              class="btn2"
              @click="handleShowVariation"
            >
              <img src="@/static/ai/btnIcon2.png">
              变体
            </el-button>
            <div
              v-show="showVariation"
              class="op_list"
            >
              <div
                v-show="drawData?.taskApiActionCodeList?.includes('variation1')"
                class="op_item"
                :class="drawData?.subTaskProgressBl ? 'op_disabled' : ''"
                @click="handleUpsample('variation1')"
              >
                <img
                  src="@/static/ai/btnIcon11.png"
                  alt=""
                >
                <span>左上</span>
              </div>
              <div
                v-show="drawData?.taskApiActionCodeList?.includes('variation3')"
                class="op_item"
                :class="drawData?.subTaskProgressBl ? 'op_disabled' : ''"
                @click="handleUpsample('variation3')"
              >
                <img
                  src="@/static/ai/btnIcon10.png"
                  alt=""
                >
                <span>左下</span>
              </div>
              <div
                v-show="drawData?.taskApiActionCodeList?.includes('variation2')"
                class="op_item"
                :class="drawData?.subTaskProgressBl ? 'op_disabled' : ''"
                @click="handleUpsample('variation2')"
              >
                <img
                  src="@/static/ai/btnIcon9.png"
                  alt=""
                >
                <span>右上</span>
              </div>
              <div
                v-show="drawData?.taskApiActionCodeList?.includes('variation4')"
                class="op_item"
                :class="drawData?.subTaskProgressBl ? 'op_disabled' : ''"
                @click="handleUpsample('variation4')"
              >
                <img
                  src="@/static/ai/btnIcon8.png"
                  alt=""
                >
                <span>右下</span>
              </div>
            </div>
          </div>
          <div
            v-show="drawData?.taskApiActionCodeList?.includes('high_variation') || drawData?.taskApiActionCodeList?.includes('low_variation')"
            v-click-outside="handleBlur4"
            class="btns_list"
          >
            <el-button
              class="btn2"
              @click="handleShowVariation2"
            >
              <img src="@/static/ai/btnIcon2.png">
              变换
            </el-button>
            <div
              v-show="showVariation2"
              class="op_list"
            >
              <div
                v-show="drawData?.taskApiActionCodeList?.includes('high_variation')"
                class="op_item"
                :class="drawData?.subTaskProgressBl ? 'op_disabled' : ''"
                @click="handleUpsample('high_variation')"
              >
                <span>高变换度</span>
              </div>
              <div
                v-show="drawData?.taskApiActionCodeList?.includes('low_variation')"
                class="op_item"
                :class="drawData?.subTaskProgressBl ? 'op_disabled' : ''"
                @click="handleUpsample('low_variation')"
              >
                <span>低变换度</span>
              </div>
            </div>
          </div>
          <div
            v-show="drawData?.taskApiActionCodeList?.includes('pan_left') || drawData?.taskApiActionCodeList?.includes('pan_right') || drawData?.taskApiActionCodeList?.includes('pan_up') || drawData?.taskApiActionCodeList?.includes('pan_down')"
            v-click-outside="handleBlur5"
            class="btns_list"
          >
            <el-button
              class="btn2"
              @click="handleShowPan"
            >
              <img src="@/static/ai/btnIcon3.png">
              平移
            </el-button>
            <div
              v-show="showPan"
              class="op_list"
            >
              <div
                v-show="drawData?.taskApiActionCodeList?.includes('pan_left')"
                class="op_item"
                :class="drawData?.subTaskProgressBl ? 'op_disabled' : ''"
                @click="handleUpsample('pan_left')"
              >
                <i class="el-icon-caret-left" />
                <span>左移</span>
              </div>
              <div
                v-show="drawData?.taskApiActionCodeList?.includes('pan_right')"
                class="op_item"
                :class="drawData?.subTaskProgressBl ? 'op_disabled' : ''"
                @click="handleUpsample('pan_right')"
              >
                <i class="el-icon-caret-right" />
                <span>右移</span>
              </div>
              <div
                v-show="drawData?.taskApiActionCodeList?.includes('pan_up')"
                class="op_item"
                :class="drawData?.subTaskProgressBl ? 'op_disabled' : ''"
                @click="handleUpsample('pan_up')"
              >
                <i class="el-icon-caret-top" />
                <span>上移</span>
              </div>
              <div
                v-show="drawData?.taskApiActionCodeList?.includes('pan_down')"
                class="op_item"
                :class="drawData?.subTaskProgressBl ? 'op_disabled' : ''"
                @click="handleUpsample('pan_down')"
              >  
                <i class="el-icon-caret-bottom" />
                <span>下移</span>
              </div>
            </div>
          </div>
          <div
            v-show="drawData?.taskApiActionCodeList?.includes('zoom_out_1_5x') || drawData?.taskApiActionCodeList?.includes('zoom_out_2x')"
            v-click-outside="handleBlur6"
            class="btns_list"
          >
            <el-button
              class="btn2"
              @click="handleShowZoomout"
            >
              <img src="@/static/ai/btnIcon4.png">
              视野缩放
            </el-button>
            <div
              v-show="showZoomout"
              class="op_list"
            >
              <div
                v-show="drawData?.taskApiActionCodeList?.includes('zoom_out_1_5x')"
                class="op_item"
                :class="drawData?.subTaskProgressBl ? 'op_disabled' : ''"
                @click="handleUpsample('zoom_out_1_5x')"
              >
                <span>1.5x 缩放</span>
              </div>
              <div
                v-show="drawData?.taskApiActionCodeList?.includes('zoom_out_2x')"
                class="op_item"
                :class="drawData?.subTaskProgressBl ? 'op_disabled' : ''"
                @click="handleUpsample('zoom_out_2x')"
              >
                <span>2.0x 缩放</span>
              </div>
            </div>
          </div>
          <div
            v-show="drawData?.taskApiActionCodeList?.includes('upsample_2x') || drawData?.taskApiActionCodeList?.includes('upsample_4x')"
            v-click-outside="handleBlur7"
            class="btns_list"
          >
            <el-button
              class="btn2"
              @click="handleShowUpsample"
            >
              <img src="@/static/ai/btnIcon5.png">
              倍数放大
            </el-button>
            <div
              v-show="showUpsample"
              class="op_list"
            >
              <div
                v-show="drawData?.taskApiActionCodeList?.includes('upsample_2x')"
                class="op_item"
                :class="drawData?.subTaskProgressBl ? 'op_disabled' : ''"
                @click="handleUpsample('upsample_2x')"
              >
                <span>2x 放大</span>
              </div>
              <div
                v-show="drawData?.taskApiActionCodeList?.includes('upsample_4x')"
                class="op_item"
                :class="drawData?.subTaskProgressBl ? 'op_disabled' : ''"
                @click="handleUpsample('upsample_4x')"
              >
                <span>4x 放大</span>
              </div>
            </div>
          </div>
        </div>
        <div class="btns_right">
          <el-button
            v-show="drawData?.taskApiActionCodeList?.includes('reroll')"
            class="btn"
            icon="el-icon-refresh-right"
            :disabled="drawData?.subTaskProgressBl"
            @click="handleUpsample('reroll')"
          >
            重新生成
          </el-button>
          <div
            v-show="drawData?.taskApiActionCodeList?.includes('redo_upsample_2x') || drawData?.taskApiActionCodeList?.includes('redo_upsample_4x')"
            v-click-outside="handleBlur9"
            class="btns_list"
            style="margin-right:0;"
          >
            <el-button
              class="btn"
              icon="el-icon-refresh-right"
              :disabled="drawData?.subTaskProgressBl"
              @click="handleShowRedo"
            >
              重新生成
            </el-button>
            <div
              v-show="ShowRedo"
              class="op_list"
            >
              <div
                v-show="drawData?.taskApiActionCodeList?.includes('redo_upsample_2x')"
                class="op_item"
                :class="drawData?.subTaskProgressBl ? 'op_disabled' : ''"
                @click="handleUpsample('redo_upsample_2x')"
              >
                <span>2K图像</span>
              </div>
              <div
                v-show="drawData?.taskApiActionCodeList?.includes('redo_upsample_4x')"
                class="op_item"
                :class="drawData?.subTaskProgressBl ? 'op_disabled' : ''"
                @click="handleUpsample('redo_upsample_4x')"
              >
                <span>4K图像</span>
              </div>
            </div>
          </div>
          <!-- <el-button
            v-show="drawData?.taskApiActionCodeList?.includes('redo_upsample_2x')"
            class="btn"
            icon="el-icon-refresh-right"
            :disabled="drawData?.subTaskProgressBl"
            @click="handleUpsample('redo_upsample_2x')"
          >
            重新生成
          </el-button>
          <el-button
            v-show="drawData?.taskApiActionCodeList?.includes('redo_upsample_4x')"
            class="btn"
            icon="el-icon-refresh-right"
            :disabled="drawData?.subTaskProgressBl"
            @click="handleUpsample('redo_upsample_4x')"
          >
            重新生成
          </el-button> -->
          <el-button
            v-show="drawData?.taskApiActionCodeList?.includes('cut')"
            class="btn"
            @click="handleViewCut"
          >
            <img src="@/static/ai/btnIcon7.png">
            查看切图
          </el-button>
          <div
            v-click-outside="handleBlur"
            style="position: relative;"
          >
            <el-button
              class="btn"
              @click="handleShowTips"
            >
              <img
                src="@/static/ai/btnIcon6.png"
                alt=""
              >
              提示词
            </el-button>
            <div
              v-show="showTips"
              class="show_tips"
            >
              <div
                v-scrollActive
                class="tip_content"
              >
                {{ drawData.translatedPrompts }}
              </div>
              <div class="show_copy">
                <div
                  v-copy="drawData.translatedPrompts"
                  class="copys"
                >
                  <img
                    src="@/static/ai/copy.png"
                    alt=""
                  >
                  <span>一键复制</span>
                </div>
              </div>
            </div>
            <div
              v-show="showTips"
              class="triangle_mask"
            />
            <img
              v-show="showTips"
              class="triangle"
              src="@/static/ai/triangle.png"
              alt=""
            >
          </div>
          <el-button
            class="btn del_btn"
            icon="el-icon-delete"
            @click="handleDelete"
          >
            删除
          </el-button>
        </div>
      </div>
      <div
        v-show="showDialog"
        class="dialog"
      >
        <div class="dialog_container">
          <p><i class="el-icon-warning" /><span>确认删除？</span></p>
          <p>删除后，图片将不可恢复</p>
          <p>
            <el-button
              round
              class="btn"
              @click="showDialog = false"
            >
              取消
            </el-button>
            <el-button
              round
              class="btn2"
              @click="handleConfirm"
            >
              确认
            </el-button>
          </p>
        </div>
      </div>
    </div>
    <div class="draw_history">
      <div class="title">
        <span>历史记录</span>
        <img
          src="../../static/ai/edit.png"
          alt=""
          @click="handdleMultiple"
        >
      </div>
      <div
        v-scrollActive
        class="pic_list"
        :style="{height: picMultiple ? 'calc(100% - 100px)' : 'calc(100% - 70px)'}"
        @scroll="handleScroll"
      >
        <div
          v-for="item, index in picList"
          :key="'drawUid' + index"
          class="draw_item"
          @click="handleChoose(item)"
        >
          <div
            v-if="item.taskStatusCode == 'PARSEING'"
            class="draw_item"
          >
            <div class="generating flex jc">
              <span>生成中...</span>
              <!-- <span class="str">{{ item.progress }}%</span>
              <el-progress class="progress" :show-text="false" :stroke-width="10" :percentage="item.progress"></el-progress> -->
            </div>
          </div>
          <div
            v-if="!item?.taskStatusCode || item.taskStatusCode == 'WAIT' || item.taskStatusCode == 'PROCESSING'"
            class="draw_item"
          >
            <div class="generating flex jc">
              <span>准备生成中...</span>
            </div>
          </div>
          <div
            v-if="item.taskStatusCode == 'FAIL'"
            class="draw_item"
          >
            <div class="failing flex c">
              <img
                src="../../static/ai/fail.png"
                alt=""
              >
              <span>生成失败</span>
              <span @click="handleCheckDetail(item)">查看详情</span>
            </div>
          </div>
          <div
            v-if="item.taskStatusCode == 'DONE'"
            class="image_list"
          >
            <div class="pic">
              <img
                :src="item?.imageUrl"
                alt=""
              >
            </div>
            <div class="pic_hover">
              <span
                v-if="item.interactSwitch"
                class="pub"
              >已公开</span>
              <el-button
                class="share_btn m_b_16"
                @click.stop="handleCheckDetail(item)"
              >
                <img src="@/static/index/view2.png">
                查看
              </el-button>
              <el-button
                v-if="!item.interactSwitch"
                class="share_btn"
                @click.stop="handleShare(item)"
              >
                <img src="@/static/ai/share.png">
                公开
              </el-button>
            </div>
          </div>
          <div
            v-show="picMultiple"
            class="check"
            :class="item.checked ? 'border' : ''"
          >
            <img
              v-if="item.checked"
              src="../../static/ai/checked.png"
              alt=""
            >
            <img
              v-else
              src="../../static/ai/circle.png"
              alt=""
            >
          </div>
        </div>
        <div
          v-show="listLoading"
          class="list_loading"
        >
          <span><i class="el-icon-loading" /> 加载中...</span>
        </div>
        <div
          v-show="inBottom && picList.length > 9"
          class="list_loading"
        >
          <span>已经到底啦...</span>
        </div>
      </div>
      <div
        v-if="picMultiple"
        class="btn_delete"
        :class="checkedList.length > 0 ? 'bg_color' : ''"
      >
        <el-button
          class="share_btn"
          :disabled="checkedList.length == 0"
          @click.stop="handleSharecMultiple"
        >
          <img src="@/static/ai/share2.png">
          公开
        </el-button>
        <el-popconfirm
          title="确定删除选中的图片吗？"
          class="popconfirm"
          @confirm="handleBatchDelete"
        >
          <el-button
            slot="reference"
            icon="el-icon-delete"
            :disabled="checkedList.length == 0"
            class="del_btn"
          >
            删除
          </el-button>
        </el-popconfirm>
      </div>
    </div>
    <div
      v-if="showViewCut"
      class="view_cut"
      @click="handleCloseViewCut"
    >
      <div
        class="cut_list"
        @click.stop
      >
        <div
          v-for="item in cutData"
          :key="item.actionNum"
          class="cut_item"
        >
          <img
            :src="process == 'production' ? item?.imageUrl : item?.imageUrl?.split('idcfengye.com')[1]"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { drawPage, drawDetail, drawRemoveBatch, drawRemove, detailView, interactOn, interactBatchOn } from '@/api/index'
import defaultImage from '@/static/ai/white.png'
import { tips } from '@/utils/common'

const clickOutside = {
  bind(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};
export default {
  name: 'QuickDraw',
  directives: {
    "click-outside": clickOutside, // 注册自定义指令
  },
  props: {
    drawData: { type: Object, default: () => {} },
    code: { type: Number, default: 500 }
  },
  data () {
    return {
      showDialog: false,
      loading: false,
      generating: false,
      picList: [],
      picMultiple: false,
      multipleList: [],
      picData: '',
      showEnlarged: false,
      checkedList: [],
      showError: false,
      maskStyle:{},
      showBtns: false,
      pageNum: 1,
      listLoading: false,
      inBottom: false,
      progressImage: '',
      progress: 0,
      isAdd: false,
      process: '',
      index: '',
      style:'',
      showBG: true,
      showMask: false,
      isCut: false,
      sentence: '',
      timer: '',
      showTips: false,
      showEnlarge: false,
      showVariation: false,
      showVariation2: false,
      showPan: false,
      showZoomout: false,
      showUpsample: false,
      errorMessage: '',
      isSingleSheet: false,
      showViewCut: false,
      ShowRedo: false,
      cutData: [],
      drawDataTimer: null,
    }
  },
  watch: {
    drawData(val) {
      if(val?.code){
        this.$parent.code = val?.code
        console.log(this.$parent.code)
        this.loading = false
        this.generating = false
        this.showBG = true
        this.showError = true
        this.getDrawPage()
      }
      if(this.isAdd){
        this.picList[0] = val
      }
      switch(val?.taskStatusCode) {
        case 'FAIL':
          this.loading = false
          this.generating = false
          this.showBG = true
          this.showError = true
          this.showBtns = true
          this.errorMessage = val.errorMsg
          clearInterval(this.timer)
          clearInterval(this.drawDataTimer)
          this.drawDataTimer = null
          break
        case 'DONE':
          this.loading = false
          this.generating = true
          this.showError = false
          this.showBG = false
          this.showBtns = true
          clearInterval(this.timer)
          clearInterval(this.drawDataTimer)
          this.drawDataTimer = null
          break
        case 'WAIT':
        case 'PROCESSING':
          this.loading = true
          this.generating = false
          this.showError = false
          this.showBG = true
          this.showBtns = false
          if(this.drawDataTimer == null){
            this.drawDataSetInterval(val?.taskUid)
          }
          this.handleShowSentence()
          break
        case 'PARSEING':
          this.loading = false
          this.generating = true
          this.showError = false
          this.showBG = false
          this.showBtns = false
          if(this.drawDataTimer == null){
            this.drawDataSetInterval(val?.taskUid)
          }
          break
      }
    },
    picList(val) {
      var arr = []
      val.map(item => {
        if(item.progress && item.progress < 100 && item.imageUrl){
          arr.push(item)
        }
      })
      if(arr.length >= 4){
        this.$bus.emit('disabled')
      }
    }
  },
  mounted () {
    this.process = process.env.NODE_ENV
    this.$bus.off('DrawError')
    this.$bus.on('DrawError', (message) => {
      this.showError = true
      this.loading = false
      this.generating = false
      this.showBG = true
      this.errorMessage = message
      this.getDrawPage()
    })
    this.getDrawPage()
  },
  methods: {
    getDrawData(taskUid) {
      drawDetail(taskUid).then(res => {
        this.$parent.drawData = res.data
      }).catch(() => {
        this.drawDataSetInterval(taskUid)
      })
    },
    drawDataSetInterval(taskUid) {
      this.drawDataTimer = setInterval(() => {
        drawDetail(taskUid).then((res,idx) => {
          this.$parent.drawData = res.data
          if(!this.isAdd){
            this.picList.forEach(item => {
              if(item.taskUid == res.data.taskUid){
                this.picList[idx] = res.data
                this.picList[idx] = {
                  ...this.picList[idx],
                  accessSubType: res.data.benefitSubType,
                  imageUrl: res.data.thumbnailUrl,
                  progress: res.data.progress,
                  taskStatusCode: res.data.taskStatusCode,
                  taskStatusDesc: res.data.taskStatusDesc,
                }
              }
            })
          }
        })
      }, 10000)
    },
    handleShowRedo() {
      this.ShowRedo = !this.ShowRedo
    },
    handleBlur9(event) {
      this.ShowRedo = false
    },
    handleCloseViewCut() {
      this.showViewCut = false
    },
    handleBlur8(event) {
    },
    handleViewCut() {
      this.showViewCut = true
      detailView(this.drawData.taskUid).then(res => {
        this.cutData = res.data
      })
    },
    handleBlur7(event) {
      this.showUpsample = false
    },
    handleShowUpsample() {
      this.showUpsample = !this.showUpsample
    },
    handleBlur6(event) {
      this.showZoomout = false
    },
    handleShowZoomout() {
      this.showZoomout = !this.showZoomout
    },
    handleBlur5(event) {
      this.showPan = false
    },
    handleShowPan() {
      this.showPan = !this.showPan
    },
    handleBlur4(event) {
      this.showVariation2 = false
    },
    handleShowVariation2() {
      this.showVariation2 = !this.showVariation2
    },
    handleBlur3(event) {
      this.showVariation = false
    },
    handleShowVariation() {
      this.showVariation = !this.showVariation
    },
    handleUpsample(val) {
      if(this.drawData.subTaskProgressBl){
        return false
      }
      const params = {
        prompt: null,
        apiKeyModel: this.$parent.mode,
        drawUid: null,
        taskUid: this.drawData.taskUid,
        customArtStyleType: null,
        aspectRatioType: null,
        apiAction: val,
        benefitSubType: this.drawData.benefitSubType,
        taskPublishBl: true,
      }
      this.loading = true
      this.generating = false
      this.showError = false
      this.showBtns = false
      this.isAdd = true
      this.showBG = true
      this.showMask = false
      this.$bus.emit('drawing', params, this.drawData, val)
      this.picList.unshift({})
      this.handleShowSentence()
    },
    handleBlur2(event) {
      this.showEnlarge = false
    },
    handleShowEnlarge() {
      this.showEnlarge = !this.showEnlarge
    },
    handleBlur(event) {
      this.showTips = false
    },
    handleShowTips() {
      this.showTips = !this.showTips
    },
    handleShowSentence() {
      let num =  Math.floor(Math.random()*10)
      this.sentence = tips[num]
      this.timer = setInterval(() => {
        num ++
        if(num == tips.length) {
          num = 0
        }
        this.sentence = tips[num]
      },10000)
    },
    progressImageError(e) {
      e.target.src = defaultImage
    },
    imageOnload() {
      this.showMask = true
    },
    handleScroll(e) {
      if(e.srcElement.scrollTop + e.srcElement.offsetHeight > e.srcElement.scrollHeight - 6 ) {
        if(!this.listLoading && !this.picMultiple && !this.inBottom){
          this.listLoading = true
          this.pageNum ++
          drawPage({pageNum: this.pageNum, pageSize: 10}).then(res => {
            var data = res.data.records
            data.forEach(item => {
              this.$set(item, 'checked', false)
            })
            this.picList = this.picList.concat(data)
            this.listLoading = false
            if(res.data.records.length == 0 && this.picList.length > 0){
              this.inBottom = true
            }
          }).catch(() => {
            this.listLoading = false
            this.pageNum --
          })
        }
      }
    },
    handleLeave() {
      this.index = ''
    },
    handleOver(e, idx) {
      this.index = idx
      let width = e.target.offsetWidth
      let height = e.target.offsetHeight
      if(width != 0 && this.showMask) {
        this.style = {width: width + 'px', height: height + 'px'}
        this.maskStyle = {width: width + 'px', height: height + 'px', opacity: 1, zIndex: 1,background: 'rgba(0, 0, 0, 0.3)'}
      } else {
        this.maskStyle = {opacity: 0, zIndex: -1}
      }
    },
    //
    handleChoose(val) {
      if(this.picMultiple) {
        val.checked = !val.checked
        this.checkedList = []
        this.picList.forEach(item => {
          if(item.checked) {
            this.checkedList.push(item.drawUid)
          }
        })
      }
    },
    handleSharecMultiple() {
      let arr = []
      this.picList.forEach(item => {
        if(item.checked) {
          arr.push(item.taskUid)
        }
      })
      let params = arr.join(',')
      interactBatchOn(params).then(res => {
        if(res.code == 200){
          this.$message.success({message: '已成功公开作品至社区', duration: 2000})
          this.picList.forEach(item => {
            if(item.checked) {
              item.interactSwitch = true
              this.checkedList = []
              this.handdleMultiple()
              // this.picMultiple = false
            }
          })
        }
      })
    },
    handleCheckDetail(val) {
      drawDetail(val.taskUid).then(res => {
        console.log(res.data)
        clearInterval(this.timer)
        clearInterval(this.drawDataTimer)
        this.drawDataTimer = null
        this.isAdd = false
        this.$parent.drawData = res.data
      })
    },
    handleShare(val) {
      interactOn({taskUid: val.taskUid}).then(res => {
        if(res.code == 200){
          this.$message.success({message: '已成功公开作品至社区', duration: 2000})
          val.interactSwitch = true
        }
      }).catch(err => {
        // if(err?.response?.data?.code == 500) {
        //   this.$message.error({message: err?.response?.data?.message, duration: 2000})
        // }
      })
    },
    // 删除生成 
    handleDelete() {
      this.showDialog = true
    },
    handleBatchDelete() { 
      let drawUids = this.checkedList.join(',')
      drawRemoveBatch(drawUids).then(res => {
        if(res.code == 200){
          this.$message.success({message: '删除成功', duration: 2000})
        }
        this.getDrawPage()
        this.generating = false
        this.picMultiple = false
        this.checkedList = []
        this.showBG = true
        this.showBtns = false
      })
    },
    handleConfirm() {
      this.showDialog = false
      drawRemove(this.drawData.drawUid).then(res => {
        if(res.code == 200){
          this.$message.success({message: '删除成功', duration: 2000})
        }
        let index = ''
        this.picList.forEach((item,idx) => {
          if(item.drawUid == this.drawData.drawUid) {
            index = idx
          }
        })
        this.picList.splice(index, 1)
        this.isAdd = false
        this.$parent.drawData = {}
        this.$nextTick(() => {
          this.generating = false
          this.showError = false
          this.showBG = true
          this.showBtns = false
        })
      })
    },
    // 历史记录多选
    handdleMultiple() {
      this.picMultiple = !this.picMultiple
      this.picList.forEach(item => item.checked = false)
    },
    getDrawPage() {
      this.pageNum = 1
      drawPage({pageNum: this.pageNum, pageSize: 10}).then(res => {
        var data = res.data.records
        data.forEach(item => {
          this.$set(item, 'checked', false)
        })
        this.picList = data
      })
    },
  }
}
</script>

<style lang="less">

.quickdraw{
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  .drawing_board{
    width: calc(100% - 216px);
    height: 100%;
    margin: 0 24px 86px 0;
    text-align: center;
    position: relative;
    .board{
      width: 100%;
      height: calc(100% - 86px);
      background: #fff;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .nodata{
        img{
          width: 168px;
          height: 103px;
        }
        p{
          font-size: 14px;
          color: #ACB1BF;
          margin: 14px;
        }
        p:nth-of-type(1){
          margin-top: 32px;
        }
      }
      .loading{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        background: #fff;
        border-radius: 24px;
        img{
          animation: rotate 1.5s ease-in-out infinite;
        }
        @keyframes rotate {
          to{
                transform: rotate(360deg);
            }
        }
        span:nth-of-type(1){
          margin: 40px 0 15px;
          font-family: Source Han Sans CN;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #1F2126;
        }
        span:nth-of-type(2){
          font-family: Source Han Sans CN;
          font-size: 14px;
          line-height: 22px;
          color: #ACB1BF;
        }
      }
      .container{
        width: 100%;
        height: 100%;
        display: flex;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        border-radius: 24px;
        .progress{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          .num{
            // width: 196px;
            // height: 76px;
            border-radius: 12px;
            background: rgba(0, 0, 0, 0.7);
            padding: 10px 39px;
            box-sizing: border-box;
            color: #fff;
            font-size: 20px;
            line-height: 28px;
            font-weight: 500;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            z-index: 5;
          }
          img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            position: absolute;
            top: 0;
            // left: 0;
            border-radius: 24px;
          }
          .progress_mask{
            backdrop-filter: blur(10px);
            position: absolute;
            z-index: 3;
          }
        }
        .pic_list{
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .res_img{
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            border-radius: 24px;
          }
          // .item{
          //   width: calc(50% - 4px);
          //   height:  calc(50% - 4px);
          //   position: relative;
          //   display: flex;
          //   img{
          //     max-width: 100%;
          //     max-height: 100%;
          //     object-fit: contain;
          //     border-radius: 12px;
          //   }
          //   .mask{
          //     width: calc(100% - 4px);
          //     height: calc(100% - 4px);
          //     background: transparent;
          //     backdrop-filter: blur(10px);
          //     border-radius: 12px;
          //     position: absolute;
          //     // top: 0;
          //     z-index: -1;
          //     display: flex;
          //     align-items: center;
          //     justify-content: center;
          //     opacity: 0;
          //     .el-button{
          //       width: 84px;
          //       height: 32px;
          //       border-radius: 6px;
          //       opacity: 0.7;
          //       background: #1F2126;
          //       color: #FFFFFF;
          //       padding: 5px 16px;
          //       border: none;
          //     }
          //     .el-button:hover{
          //       opacity: 1;
          //     }
          //   }
          // }
          // .item:hover{
          //   .mask{
          //     z-index: 1;
          //     background: rgba(0, 0, 0, 0.3);
          //   }
          // }
          // .item:nth-of-type(1){
          //   padding: 0 4px 4px 0;
          //   justify-content: end;
          //   align-items: flex-end;
          // }
          // .item:nth-of-type(2){
          //   padding: 0 0 4px 4px;
          //   align-items: flex-end;
          // }
          // .item:nth-of-type(3){
          //   padding: 4px 4px 0 0;
          //   justify-content: end;
          //   align-items: flex-start;
          // }
          // .item:nth-of-type(4){
          //   padding: 4px 0 0 4px;
          //   align-items: flex-start;
          // }
        }
      }
      .failed{
        img{
          width: 179px;
          height: 109px;
        }
        p:nth-of-type(1){
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #1F2126;
          margin: 12px;
        }
        p:nth-of-type(2){
          font-size: 14px;
          line-height: 22px;
          color: #ACB1BF;
        }
      }
    }
    .btns{
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .btns_left, .btns_right{
        display: flex;
      }
      .btns_left{
        img{
          width: 16px;
          height: 16px;
          position: relative;
          top: 3px;
          margin-right: 4px;
        }
        .btn2{
          width: 102px;
          height: 38px;
          border-radius: 8px;
          padding: 8px 0;
          background: #FFFFFF;
          box-sizing: border-box;
          border: 1px solid #E6E9F0;
          box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
          font-family: '思源黑体';
          font-size: 14px;
          line-height: 22px;
          color: #1F2126;
        }
      }
      .btns_list{
        position: relative;
        margin-right: 8px;
        .op_list{
          position: absolute;
          bottom: 46px;
          width: 104px;
          background: #FFFFFF;
          box-sizing: border-box;
          border: 1px solid #E6E9F0;
          box-shadow: 0px 8px 35px 0px rgba(0, 0, 0, 0.1);
          padding: 6px;
          border-radius: 8px;
          color: #1F2126;
          font-size: 14px;
          font-family: '思源黑体';
          line-height: 22px;
          .op_item{
            height: 34px;
            line-height: 34px;
            border-radius: 6px;
            width: 100%;
            text-align: center;
            cursor: pointer;
            img{
              margin-right: 8px;
            }
            i{
              color: #ACB1BF;
              font-size: 16px;
              margin-right: 8px;
              position: relative;
              top: 1px;
            }
          }
          .op_disabled{
            cursor: no-drop;
          }
          .op_item:hover{
            background: #F2F4F7;
          }
        }
      }
      .btns_right{
        .btn{
          height: 38px;
          width: 102px;
          background: #FFFFFF;
          padding: 8px 0px;
          gap: 8px;
          border-radius: 8px;
          box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
          border: 1px solid #E6E9F0;
          box-sizing: border-box;
          font-family: '思源黑体';
          font-size: 14px;
          line-height: 22px;
          color: #1F2126;
          margin-right: 8px;
          img{
            width: 16px;
            position: relative;
            top: 2px;
            margin-right: 4px;
          }
          .el-icon-refresh-right{
            color: #ACB1BF;
          }
        }
        // .del_btn{
        //   margin-left: 8px;
        // }
        .btn:last-child{
          margin-right: 0px;
        }
        .el-icon-delete{
          color: #FF4D4F;
        }
        .show_tips{
          width: 360px;
          border-radius: 16px;
          background: #FFFFFF;
          border: 1px solid #DADDE5;
          box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
          padding: 25px 0 0 20px;
          box-sizing: border-box;
          position: absolute;
          bottom: 55px;
          left: -117px;
          z-index: 2;
          font-size: 14px;
          line-height: 22px;
          color: #3D3D3D;
          .tip_content{
            text-align: left;
            max-height: 296px;
            overflow-y: scroll;
            padding-right: 14px;
            word-wrap:break-word;
          }
          ::-webkit-scrollbar{
            width: 6px !important;
          }
          .show_copy{
            text-align: right;
            padding: 16px 0;
            margin: 24px 20px 0 0 ;
            box-sizing: border-box;
            display: flex;
            justify-content: end;
            align-items: center;
            border-top: 1px solid #E6E9F0;
            .copys{
              display: contents;
              cursor: pointer;
              img{
                width: 16px;
                padding-right: 8px;
              }
            }
          }
        }
        .triangle_mask{
          position: absolute;
          width: 28px;
          height: 10px;
          background-color: #fff;
          bottom: 56px;
          left: 48px;
          z-index: 4;
        }
        .triangle{
          position: absolute;
          width: 24px;
          bottom: 45px;
          left: 50px;
          z-index: 3;
        }
      }
    }
    .dialog{
      width: 100%;
      height: 100%;
      text-align: center;
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.5);
      z-index: 30;
      .dialog_container{
        width: 400px;
        height: 204px;
        border-radius: 16px;
        background: #FFFFFF;
        p:nth-of-type(1){
          margin-top: 24px;
          font-family: Source Han Sans CN;
          font-size: 16px;
          font-weight: 500;
          color: #1F2126;
          i{
            color: #FFA940;
            margin-right: 8px;
          }
        }
        p:nth-of-type(2){
          margin-top: 24px;
          font-family: Source Han Sans CN;
          color: #646873;
          font-size: 14px;
        }
        p:nth-of-type(3){
          margin-top: 48px;
          .btn{
            width: 92px;
            height: 38px;
            padding: 8px 32px;
            border: 1px solid #DADDE5;
            box-sizing: border-box;
            box-shadow: 0px 1.5px 0px 0px #F5F6F7;
            color: #373940;
          }
          .btn2{
            width: 92px;
            height: 38px;
            padding: 8px 32px;
            background: #1467FF;
            box-shadow: 0px 1.5px 0px 0px #E6EAF0;
            border: none;
            color: #fff;
          }
          .btn:focus, .btn:hover{
            border: 1px solid #DADDE5;
            background: transparent;
            color: #373940;
          }
        }
      }
    }
  }
  .draw_history{
    width: 192px;
    height: calc(100% - 24px);
    background: #FFFFFF;
    border-radius: 16px 0 0 16px;
    box-shadow: 0px 15px 35px -8px rgba(59, 119, 174, 0.08);
    .title{
      width: 100%;
      height: 46px;
      line-height: 46px;
      padding: 12px 16px;
      font-family: Source Han Sans CN;
      font-size: 14px;
      color: #3D3D3D;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      img{
        cursor: pointer;
        width: 16px;
        height: 16px;
      }
    }
    .pic_list{
      padding: 0 16px;
      height: 100%;
      height: calc(100% - 70px);
      overflow-y: scroll;
      overflow-x: hidden;
      .draw_item{
        width: 160px;
        height: 160px;
        margin-bottom: 16px;
        border-radius: 8px;
        position: relative;
        .generating{
          width: 100%;
          height: 100%;
          border-radius: 8px;
          background: linear-gradient(135deg, #E7EFFF 0%, #F3EFFD 98%);
          text-align: center;
          font-size: 14px;
          .str{
            display: block;
          }
          .str:nth-of-type(1){
            padding-top: 32px;
          }
          .str:nth-of-type(2){
            font-size: 20px;
            margin: 6px 0 12px 0;
          }
          .progress{
            margin: 0 20px;
            .el-progress-bar__inner{
              background: linear-gradient(149deg, #2E61FC 10%, #6185FC 102%);
            }
            .el-progress-bar__outer{
              background: #D6E4FF !important;
            }
          }
        }
        .flex{
          display: flex;
          align-items: center;
          &.c{
            flex-direction: column;
          }
          &.jc{
            justify-content: center;

          }
        }
        .failing{
          width: 100%;
          height: 100%;
          border-radius: 8px;
          border: 1px solid #E6E9F0;
          padding: 32px 0 40px;
          box-sizing: border-box;
          span:nth-of-type(1){
            font-size: 14px;
            color: #3D3D3D;
            margin: 16px;
          }
          span:nth-of-type(2){
            font-size: 12px;
            color: #ACB1BF;
            cursor: pointer;
          }
          img{
            width: 24px;
            height: 24px;
          }
        }
        .image_list{
          width: 160px;
          height: 160px;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          border-radius: 8px;
          background: #F2F4F7;
          position: relative;
          overflow: hidden;
          .pic{
            img{
              width: 160px;
              height: 160px;
              border-radius: 8px;
              max-height: 100%;
              max-width: 100%;
              object-fit: contain;
            }
          }
          .pic_hover{
            width: 100%;
            height: 100%;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(10px);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            opacity: 0;
            .pub{
              display: inline-block;
              width: 48px;
              height: 25px;
              border-radius: 999px;
              background: rgba(214, 228, 255,.3);
              position: absolute;
              top: 8px;
              right: 8px;
              z-index: 3;
              font-family: '思源黑体';
              font-size: 12px;
              font-feature-settings: "kern" on;
              color: #FFFFFF;
              padding: 4px 6px;
              box-sizing: border-box;
              text-align: center;
            }
            .share_btn{
              width: 84px;
              height: 32px;
              border-radius: 6px;
              padding: 5px 0;
              border: none;
              background: rgb(31, 33, 38,.7);
              font-family: '思源黑体';
              font-size: 14px;
              line-height: 22px;
              color: #FFFFFF;
              margin: 0;
              opacity: 0.7;
              img{
                width: 16px;
                margin-right: 8px;
                position: relative;
                top: 3px;
              }
            }
            .m_b_16{
              margin-bottom: 16px;
            }
          }
        }
        .image_list:hover{
          .pic_hover{
            opacity: 1;
          }
        }
        .check{
          position: absolute;
          width: 160px;
          height: 160px;
          top: 0;
          right: 0px;
          z-index: 2;
          border-radius: 8px;
          box-sizing: border-box;
          img{
            position: absolute;
            top: 4px;
            right: 4px;
            width: 24px;
            height: 24px;
          }
        }
        .border{
          border: 2px solid #1467FF;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
        }
      }
      .list_loading{
        text-align: center;
        font-size: 14px;
        color: #ACB1BF;
        margin-bottom: 10px;
      }
    }
    .btn_delete{
      margin: 10px 0;
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
      .share_btn{
        width: 76px;
        height: 32px;
        font-family: '思源黑体';
        font-size: 14px;
        line-height: 22px;
        color: #373940;
        border-radius: 6px;
        padding: 5px 0;
        background: #FFFFFF;
        box-sizing: border-box;
        border: 1px solid #DADDE5;
        box-shadow: 0px 1.5px 0px 0px #F5F6F7;
        img{
          width: 16px;
          margin-right: 4px;
          position: relative;
          top: 3px;
        }
      }
      .del_btn{
        width: 76px;
        height: 32px;
        border-radius: 6px;
        background: #CACDD9;
        color: #fff;
        box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.05);
        border: none;
        padding: 5px 0;
      }
    }
    .bg_color{
      .del_btn{
        background: #FF4D4F;
      }
    }
  }
  .view_cut{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    .cut_list{
      max-width: 816px;
      max-height: 816px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      .cut_item{
        width: calc(50% - 8px);
        height: calc(50% - 8px);
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          max-height: 100%;
          max-width: 100%;
          object-fit: contain;
          border-radius: 16px;
        }
      }
      .cut_item:nth-of-type(1){
        margin: 0 8px 8px 0;
        justify-content: end;
        align-items: flex-end;
      }
      .cut_item:nth-of-type(2){
        margin: 0 0 8px 8px;
        align-items: flex-end;
        justify-content: flex-start;
      }
      .cut_item:nth-of-type(3){
        margin: 8px 8px 0 0;
        justify-content: end;
        align-items: flex-start;
      }
      .cut_item:nth-of-type(4){
        margin: 8px 0 0 8px;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }
}

</style>
<template>
  <div class="Circular" :class="$store.state.isMobile ? 'Circular_mobile' : ''">
    <div class="ball">
      <div class="circle-line1"></div>
      <!-- <div class="circle-line2"></div>
    <div class="circle-line3"></div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'CircularAnimation',
}
</script>
 <style lang='less'>
 .Circular_mobile{
  transform: scale(0.5);
  top: -56% !important;
  right: -6% !important;
 }
.Circular {
  position: absolute;
top: 9%;
    right: -10%;
    z-index: -1;
 
}
.i-b {
  display: inline-block;
}

.p-3d {
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}

.ball {
  &:extend(.i-b);
  width: 148px;
  height: 148px;
  margin: 0;
  border-radius: 50%;
  position: relative;

  &:extend(.p-3d);

  &:before {
    content: '';
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    z-index: 2;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      circle at 50% 50%,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.1) 40%,
      rgba(0, 0, 0, 0) 50%
    );
    .trans-all(translateX(64px) rotateX(90deg) translateZ(-113px) rotateY(-30deg));
    z-index: -1;
  }
}

.trans-all(@content) {
  -webkit-transform: @content;
  -moz-transform: @content;
  -ms-transform: @content;
  -o-transform: @content;
  transform: @content;
}

.line {
  border-radius: 50%;
  position: absolute;
  border: 2px solid #1f2126;
  transform: rotateZ(60deg) rotateY(120deg);
 -webkit-transform: rotateZ(60deg) rotateY(120deg);
  &:extend(.p-3d);
}

.create-circleLine(@a, @b) {
  width: @a*2;
  height: @b*2;
  left: 50%;
  top: 50%;
  margin-left: -@a;
  margin-top: -@b;
  &:extend(.line);
}

.dot-center {
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.create-circle(@a, @b, @color, @aname) {
  content: '';
  width: @a;
  height: @b;
  background: @color;

  animation: @aname 3s linear infinite;
  &:extend(.dot-center);
}

.create-keyframes(@name, @z, @z1, @radius, @y) {
  @keyframes @name {
   .create-animation(@z, @z1, @radius, @y);
  }
}

.create-animation(@z, @z1, @radius, @y) {
  from {
    transform: rotateZ(@z) translateX(@radius) rotateZ(-@z) rotateY(-@y);
  }

  to {
    transform: rotateZ(@z1) translateX(@radius) rotateZ(-@z1) rotateY(-@y);
  }
}

.circle-line1 {
  .create-circleLine(74px, 74px);

  &::before {
    .create-circle(20px, 20px, #1f2126, move);
    transform: rotateZ(0deg) translateX(74px) rotateZ(-0deg) rotateY(-105deg);
    .create-keyframes(move, 0deg, 360deg, 74px, 105deg);
  }

  &::after {
    .create-circle(10px, 10px, #1f2126, move4);
    transform: rotateZ(180deg) translateX(74px) rotateZ(-180deg)
      rotateY(-105deg);
    .create-keyframes(move4, -180deg, 180deg, 74px, 105deg);
  }
}

//   .circle-line2 {
//     .create-circleLine(250px, 250px);

//     &::before {
//       .create-circle(10px, 10px, #87ceeb, move2);
//       .create-keyframes(move2, 0deg, 360deg, 250px, 105deg);

//     }

//     &::after {
//       .create-circle(10px, 10px, #48dbbb, move5);
//       .create-keyframes(move5, -180deg, 180deg, 250px, 105deg);

//     }
//   }

//   .circle-line3 {
//     .create-circleLine(160px, 160px);

//     &::before {
//       .create-circle(10px, 10px, #e44818, move3);
//       .create-keyframes(move3, 0deg, 360deg, 160px, 105deg);
//     }

//     &::after {
//       .create-circle(10px, 10px, #4396ce, move6);
//       .create-keyframes(move6, -180deg, 180deg, 160px, 105deg);
//     }

//   }
</style>
<template>
  <div class="copy">
    Copyright ©2023 五点下班 保留所有权利 <a
      target="_blank"
      href="https://beian.miit.gov.cn/#/Integrated/index"
    >蜀ICP备2023007406号-2</a>成都咚佳旭科技有限公司
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
}
</script>

<style lang="less">

.copy{
    text-align: center;
    height: 40px;
    font-family: Source Han Sans CN;
    font-size: 12px;
    font-weight: normal;
    line-height:40px;
    letter-spacing: 0em;
    color: #7A7F8C;
    a{
      font-family: Source Han Sans CN;
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      letter-spacing: 0em;
      text-decoration: none;
      color: #7A7F8C;
      margin-right: 12px;
    }
  }
</style>
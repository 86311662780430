import { fetchEventSource } from '@microsoft/fetch-event-source';
import { Message } from 'element-ui'
import store from '../store';
import axios from 'axios'
//  import store from '../store';
let controller = new AbortController()
let signal = controller.signal
// let content = ''
// let roomUid=''
const mixinFetch = {
  data () {
    return {
      fetchData: {
        messageReplyContent: '',
        question: '',
        responseErrorData: null,
        loading: true,
        fetchMessage: '',
        roomUid: '',
      },
      appendShow: false,
      stopFetch: false,
      fetchShow: false,
      fetchDataArray: [],
      index: 0,
      roomUid: '',
      scrollY: false,
      prompt: '',
    }
  },
  methods: {
    getBaseData (url, data) {
      let that = this
      let fetchData = {
        messageReplyContent: '',
        question: data.prompt,
        responseErrorData: null,
        loading: true,
        fetchMessage: '',
        roomUid: '',
      }
      console.log(that.roomUid)
      if (that.roomUid) {
        data.roomUid = that.roomUid
      }
      fetchData.question = data.prompt || data.textSubject || data.contentSubject || data.draftContent || data.textContent
      this.fetchDataArray.push(fetchData)
      store.commit('SET_SEARCHINDEX', true)
      axios.post(
        url,
        data,
        {
          headers: {
            "content-type": "application/json",
            accept: "text/event-stream"
          },
          onDownloadProgress: progressEvent => {
            console.log('progressEvent', progressEvent)
            const response = progressEvent.event.target.response;
            const lines = response.split('data:').filter(line => !!line)
            const lastLine = JSON.parse(lines[lines.length - 1])
            if (lastLine?.code != 200) {
              Message({
                message: lastLine.message,
                type: "error",
                customClass: "tiv",
              });
            }
            console.log(lastLine.data)
            if (lastLine.data.roomUid) {
              that.roomUid = lastLine.data.roomUid
            }

            fetchData.loading = false
            that.stopFetch = true
            fetchData.createDateTimeStr = lastLine.data.createDateTimeStr
            fetchData.messageReplyContent = lastLine.data.text
            console.log(fetchData)
          }
        }
      ).then((response) => {
        // that.roomUid = lastLine.data.roomUid
        that.scrollY = false,

          store.commit('SET_SEARCHINDEX', false)
        that.index = 1;
        that.appendShow = false
        if (data.accessSubType == 'AI_CHAR_BASE') {
          store.dispatch("getChatList")
        }
        store.dispatch('GetUserInfo')
        that.stopFetch = false

        console.log(response.data.roomUid);
        if (response.data.code == 401) {
          // this.fetchMessage=result.message
          //  that.fetchDataArray.pop()
          that.fetchDataArray.pop()
          Message({
            message: response.data.message,
            type: "error",
            customClass: "tiv",
          });
          store.commit('SET_LOGINDISLOG', true)

          //  controller.abort()
        }
        else if (response.data.code == 400) {
          that.fetchDataArray.pop()
          Message({
            message: response.data.message,
            type: "error",
            customClass: "tiv",
          });
          // store.commit('SET_LOGINDISLOG', true)
        }

        else {

          fetchData.responseErrorData = response.data.message
          //  controller.abort()

        }
      }).catch(error => {
        console.log(error)
        that.stopFetch = false
      })
    },
    getFetchData (url, data) {

      let fetchData = {
        messageReplyContent: '',
        question: data.prompt,
        responseErrorData: null,
        loading: true,
        fetchMessage: '',
        roomUid: '',
      }
      fetchData.question = data.prompt || data.textSubject || data.contentSubject || data.draftContent || data.textContent
      this.fetchDataArray.push(fetchData)
      store.commit('SET_SEARCHINDEX', true)
      this.appendShow = true
      let that = this
      if (this.roomUid != "") {
        data.roomUid = this.roomUid
      } else {
        this.fetchDataArray = []
      }
      console.log(data.roomUid)
      fetchEventSource(url, {
        method: 'POST',
        signal: signal,
        headers: {
          /* 请求头配置 */
          'accept': 'text/event-stream;charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        onmessage (msg) {

          fetchData.loading = false
          that.stopFetch = true


          let result = JSON.parse(msg.data);  // 得到的数据

          if (result.code == 200) {
            that.fetchShow = true
            // content = content.concat(result.data.text) 
            // that.fetchDataArray[that.fetchDataArray.length-1]
            that.roomUid = result.data.roomUid
            fetchData.createDateTimeStr = result.data.createDateTimeStr
            fetchData.messageReplyContent = result.data.text

            //  console.log(result.data.text)
          }
          else if (result.code == 401) {
            // this.fetchMessage=result.message
            //  that.fetchDataArray.pop()
            that.fetchDataArray.pop()
            Message({
              message: result.message,
              type: "error",
              customClass: "tiv",
            });
            store.commit('SET_LOGINDISLOG', true)

            //  controller.abort()
          }
          else if (result.code == 400) {
            that.fetchDataArray.pop()
            Message({
              message: result.message,
              type: "error",
              customClass: "tiv",
            });
            // store.commit('SET_LOGINDISLOG', true)
          }

          else {

            fetchData.responseErrorData = result.message
            //  controller.abort()

          }


        },
        onclose () {

          that.scrollY = false,
            store.commit('SET_SEARCHINDEX', false)
          store.commit('SET_SEARCHINDEX', false)
          that.index = 1;
          that.appendShow = false
          if (data.accessSubType == 'AI_CHAR_BASE') {
            store.dispatch("getChatList")
          }
          store.dispatch('GetUserInfo')
          that.stopFetch = false
          //  that.cancel()
          //  store.dispatch("getChatList")
          that.stopFetch = false
          //  that.roomUid=that.fetchDataArray[that.fetchDataArray.length-1].data.roomUid
        },
        onerror (error) {

          console.log('7878878', error)
          that.stopFetch = false
          that.cancel()
          throw error

          // reject(err)
          // 必须抛出错误才会停止

        }
      })
    },
    cancel () {
      this.stopFetch = false
      controller.abort()
      this.scrollY = false,
        controller = new AbortController();
      signal = controller.signal

      store.dispatch("getChatList")

      store.commit('SET_SEARCHINDEX', false)
    },
  },
  //   created() {
  //     console.log(this.msg3)
  //   }
}
export default mixinFetch
<template>
  
      <div class="defultPro">
              <p>您可以继续追问</p>
              <ul class="defultProlist">
                <li @click="addReply('继续回答')">继续回答</li>
                <li>
                  针对<el-input
                    class="replyInput"
                    @blur="Reply"
                    v-model="reply"
                    placeholder=""
                  ></el-input
                  >展开回答
                </li>
                <li @click="addReply('更详细一点')">更详细一点</li>
                <li @click="addReply('更精简一点')">更精简一点</li>
                <li @click="addReply('用更通俗的方式表达')">
                  用更通俗的方式表达
                </li>
                <li @click="addReply('用更专业的方式表达')">
                  用更专业的方式表达
                </li>
              </ul>
              <div class="contentInput"><el-input
                class="defultInput"
                v-model="value"
                placeholder="输入自定义追加的问题"
              > <el-button :slot="value.length>0?'append':''" @click="addReply(value)"   >发送</el-button> </el-input></div>
              
            </div>
  
</template>

<script>
export default {
    data(){
        return{
            value:'',
            reply:''
        }
    },
methods:{
      Reply() {
    //   this.content.textSubject ="针对"+ this.reply+"展开回答";
    if(this.reply){
      this.$emit("append","针对"+ this.reply+"展开回答")
    }
    
    //   this.send();
    },
    addReply(value) {
         this.$emit("append",value)
    //   this.content.textSubject = value;
    //   this.send();
    },
}
}
</script>

<style lang='less'>
 
      .defultPro {
           
            width: 352px;
            margin: 0 auto;
             margin-top: 24px;
            p {
              font-family: Source Han Sans CN;
              font-size: 12px;
              font-weight: normal;
              line-height: 20px;
              letter-spacing: 0em;

              /* Grey/5 */
              color: #9498a6;
              text-align: center;
              margin-bottom: 16px;
            }
            .defultProlist {
              padding-left: 0;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              li {
                list-style: none;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 5px 56px;
                margin-bottom: 16px;
                gap: 8px;
                height: 32px;
                border-radius: 999px;
                /* 渐变/01 */
                background: linear-gradient(143deg, #ecf2fd 5%, #f3effd 130%);
                font-family: Source Han Sans CN;
                font-size: 14px;
                font-weight: normal;
                line-height: 22px;
                letter-spacing: 0em;
                white-space: nowrap;
                /* Geek Blue/07 */
                color: #1d39c4;
                width: 168px;
                box-sizing: border-box;
                cursor: pointer;
                &:hover {
                  border: 1px solid #597ef7;
                }
                .replyInput {
                  .el-input__inner {
                    width: 56px;
                    height: 22px;
                    padding: 0 8px;
                  }
                }
              }
            }
            .defultInput {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              padding: 5px 4px;
              height: 30px;
              line-height: 30px;
              /* Grey/0 */
              background: #ffffff;

              box-sizing: border-box;
              /* 渐变/02 */
              border: 1px solid #597ef7;
              border-radius: 32px;
              .el-input__inner {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 0px;
                height: 22px;
                line-height: 22px;
                border: none;
                /* Grey/0 */
                background: #ffffff;

                box-sizing: border-box;
                /* 渐变/02 */
                border: 1px solid #fff;
                border-radius: 32px;
                // clip-path: inset(0 round 32px);
              }
              .el-input-group__append {
                width: 60px;
                height: 24px;
                border-radius: 999px;
                opacity: 1;
                box-sizing: border-box;
                /* 自动布局 */
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
               
                gap: 8px;

                /* Main/06 */
                background: #1467ff;

                /* 按钮/主要 */
                box-shadow: 0px 1.5px 0px 0px #e6eaf0;
                font-family: Source Han Sans CN;
                font-size: 14px;
                font-weight: normal;
                line-height: 22px;
                letter-spacing: 0em;

                /* Grey/0 */
                color: #ffffff;
              }
            }
          }
 
</style>
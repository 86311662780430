<template>
  <div
    v-if="data.length>0"
    class="aiMarkdown"
  >
    <div
      v-for="(item, index) in data"
      :key="index"
    >
      <div class="userContent">
        <div class="title">
          <p>
            {{ item.createDateTimeStr }}
            <img src="@/static/index/default.png">
          </p>
         
          <div class="replyText">
            {{ item.question }}
          </div>
        </div>
      </div>
      <div class="gptContent">
        <div class="title">
          <img src="@/static/index/gpt.png"> {{ item.createDateTimeStr }}
        </div>
        <loading
          v-show="item.loading"
          style="margin-top:12px;"
        />
        <div
          v-show="item.messageReplyContent"
          class="reply"
        >
          <markdown-it-vue-light
            v-if="item.messageReplyContent!=null"
            class="md-body"
            :content="item.messageReplyContent"
          />
        </div>

        <error
          v-show="item.messageReplyContent==null&& item.responseErrorData"
          style="margin-bottom:16px;"
          :text="item.responseErrorData"
        />
        <div
          v-show="!item.loading"
          class="aiButtom"
        >
          <img
            v-copy="item.messageReplyContent"
            src="@/static/index/vetcor2.png"
          >
          <img
            src="@/static/index/vector1.png"
            @click="reset(item)"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import error from "@/components/error";
import loading from "@/components/loading.vue";
import MarkdownItVueLight from "markdown-it-vue/dist/markdown-it-vue-light.umd.min.js";
import "markdown-it-vue/dist/markdown-it-vue-light.css";

export default {
  name: "AiMarkdown",
  components: {
    error,
    loading,
    MarkdownItVueLight,
  },
  props: {
    data: { type: Array, default: () => {} },
  },
  data(){
    return {
            test: "如果您选择使用`highlight.js`来实现代码高亮为黑色，您可以按照以下步骤进行操作：\n\n1. 首先，确保您已经安装了`markdown-it-vue`和`highlight.js`。您可以使用以下命令进行安装：\n\n```shell\nnpm install markdown-it-vue highlight.js\n```\n\n2. 在您的Vue组件中，导入所需的库和样式文件。例如，您可以在组件中添加以下导入语句：\n\n```javascript\nimport MarkdownItVue from 'markdown-it-vue';\nimport 'highlight.js/styles/monokai.css';\nimport hljs from 'highlight.js';\n```\n\n3. 在Vue组件的`template`中，使用`markdown-it-vue`组件，并将`highlight`属性设置为一个函数，该函数将使用`highlight.js`来进行代码高亮。例如：\n\n```html\n<markdown-it-vue :highlight=\"highlightCode\">\n  <!-- 在这里插入您的Markdown内容 -->\n</markdown-it-vue>\n```\n\n4. 在Vue组件的`methods`中，定义`highlightCode`方法来处理代码高亮。使用`hljs.highlightAuto`方法来自动检测代码语言并进行高亮。例如：\n\n```javascript\nmethods: {\n  highlightCode: function (code, lang) {\n    if (lang && hljs.getLanguage(lang)) {\n      try {\n        return hljs.highlight(lang, code).value;\n      } catch (__) {}\n    }\n\n    return hljs.highlightAuto(code).value;\n  }\n}\n```\n\n通过按照上述步骤操作，您应该能够将代码高亮为黑色。请注意，上述代码假设您已经正确安装和配置了`highlight.js`。如果您遇到任何问题，请确保您已正确安装和配置该库，并检查浏览器控制台是否有任何错误信息。",
   
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // console.log( document.querySelector('code.language-markdown'))
    })
  },
  methods: {
    reset(item) {
      let data = {
        messageUid: item.messageUid,
        prompt: item.question,
        apiKeyModel: this.$store.state.version || this.$store.state.versions[0].code,
        accessSubType: "AI_CHAR_BASE",
        roomUid: item.roomUid,
        // browsingBl: this.$parent.$refs.search.browsingBl,
      };
      this.$parent.resetSend(data);
    },
  }
};
</script>

<style lang='less' scoped>
.aiMarkdown {
  .gptContent {
    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &::before{
        width: 0;
      }
      img {
        width: 36px;
        height: 36px;
        margin-right: 16px;
      }
      font-family: Source Han Sans CN;
      font-size: 12px;
      font-weight: normal;

      text-align: right;
      letter-spacing: 0em;

      /* Grey/4 */
      color: #acb1bf;
    }
    .reply {
      margin: 16px 0;
      padding: 12px;
      max-width: 768px;
      min-width: 208px;
      display: inline-block;
      border-radius: 8px;
      /* Grey/0 */
      background: #ffffff;

      /* 对话条目/GPT */
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.03);
      font-family: Source Han Sans CN;
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      letter-spacing: 0em;

      /* Grey/10 */
      color: #1f2126;
    }
    .aiButtom {
      img {
        width: 12px;
        height: 12px;
        margin-right: 14px;
        cursor: pointer;
      }
    }
  }
  .userContent {
    .title {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
  &::before{
        width: 0;
      }
      p {
        margin-bottom: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 12px;
        font-weight: normal;
        font-family: Source Han Sans CN;
        text-align: right;
        letter-spacing: 0em;

        /* Grey/4 */
        color: #acb1bf;

        img {
          width: 36px;
          height: 36px;
          margin-left: 16px;
          border-radius: 50% 50%;
        }
      }
      .replyText {
        border-radius: 8px;
        opacity: 1;
        margin-bottom: 24px;
        /* 自动布局 */
        display: flex;
        flex-direction: column;
        padding: 12px;

        /* Main/06 */
        background: #1467ff;

        /* 对话条目/User */
        box-shadow: 0px 2px 2px 0px rgba(0, 19, 93, 0.1);
        font-family: Source Han Sans CN;
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        letter-spacing: 0em;

        /* Grey/0 */
        color: #ffffff;
      }
    }
  }
}
</style>
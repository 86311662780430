<template>
  <div class="PDF_black">
    <div class="PDF_title">
      <p>图片转PDF</p>
      <p>将多张图片转换为单个PDF文件，方便阅读和传送</p>
    </div>
    <div
      class="PDF_container"
      :class="showUpload || uploadLoading ? 'dashed' : !showUpload && !uploadLoading ? 'h_524' : ''"
    >
      <div
        v-if="showUpload"
        class="nodata"
      >
        <el-upload
          :action="'/attachments/img/bigUpload'"
          list-type="picture"
          accept=".jpg,.png,.bmp,.jpeg,.gif"
          :show-file-list="false"
          :auto-upload="true"
          :file-list="fileList"
          :on-success="handleSuccess"
          :before-upload="beforeUpload"
          :on-error="handleError"
          :on-exceed="handleOnExceed"
          multiple
          :drag="true"
          :limit="30"
          class="upload"
        >
          <div
            class="upload_img"
          >
            <img src="../../static/ai/upload2.png">
            <p>拖拽或点击以上传图片</p>
            <p>最大支持上传30张图片；支持jpg、jpeg、png、bmp、gif格式；单张图片最大10 MB、8K 分辨率</p>
          </div>
        </el-upload>
      </div>
      <div
        v-if="uploadLoading"
        class="PDF_loading"
      >
        <img src="../../static/ai/loading3.png">
        <p>正在上传...</p>
        <p>请耐等待文件上传完成</p>
      </div>
      <div
        v-show="handleBefore"
        class="handle_before"
      >
        <div
          v-scrollActive
          class="pic_list"
        >
          <div
            v-for="item,index in fileList"
            :key="index"
            class="pic_item"
          >
            <img
              :src="process == 'production' ? item?.data?.aigoPreViewUrl : item?.data?.aigoPreViewUrl?.split('idcfengye.com')[1]"
              alt=""
            >
            <img
              class="check"
              src="@/static/ai/check.png"
            >
            <div class="file_name">
              {{ item?.data?.fileName }}
            </div>
            <div class="del_mask">
              <i
                class="el-icon-delete"
                @click="handleDeletePic(item,index)"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="handling"
        class="handling"
      >
        <img src="../../static/ai/loading3.png">
        <p>正在转换中...</p>
        <p>请耐等待文件转换完成</p>
      </div>
      <div
        v-if="showResult"
        class="result"
      >
        <div class="res_title">
          <span>已合并</span>
        </div>
        <div
          class="pdf_img"
        >
          <img
            src="@/static/ai/pdf.png"
            alt=""
          >
          <p>大小：{{ afterSize }}</p>
        </div>
      </div>
    </div>
    <div class="pdf_bottom">
      <p
        class="tool_privacy"
      >
        *为保护您的隐私，已上传文件将会在一小时后删除
      </p>
      <div
        v-if="showResult"
        class="res_btns"
      >
        <el-button
          class="btn"
          @click="handleRenew"
        >
          重新上传
        </el-button>
        <el-button
          class="btn btn2"
          @click="handleDownload"
        >
          下载
        </el-button>
      </div>
      <div
        v-if="!handling && !showResult"
        class="handle_btns"
      >
        <el-upload
          v-show="handleBefore"
          :action="'/attachments/img/bigUpload'"
          list-type="picture"
          accept=".jpg,.png,.bmp,.jpeg,.gif"
          :show-file-list="false"
          :auto-upload="true"
          :on-success="handleSuccess"
          :before-upload="beforeUpload"
          :on-error="handleError"
          multiple
          :drag="true"
          :limit="30"
          class="upload2"
        >
          <el-button
            icon="el-icon-plus"
            class="add_btn"
          >
            添加图片
          </el-button>
        </el-upload>
        <el-button
          class="handle_btn"
          :disabled="showUpload || uploadLoading"
          :class="showUpload || uploadLoading ? 'dis_btn' : ''"
          @click="handleSend"
        >
          开始处理
          <span
            v-if="chargerule?.userType == 'VIP0'"
            class="cash"
          >-{{ chargerule?.chargeNum }}<img src="@/static/index/money.png"></span>
          <span
            v-else
            class="cash2"
          >剩：{{ chargerule?.surplusNumDesc?.split('次数')[0] }}次</span>
        </el-button>
      </div>
    </div>
    <noTimes
      ref="noTimes"
      :no-times-visible.sync="noTimesVisible"
    />
  </div>
</template>

<script>
import mixinToolkitFench from "@/mixin/toolboxFench";
import noTimes from "@/components/noTimes";
import { drawHotChargerule } from '@/api/index'

export default {
  components: {
    noTimes,
  },
  mixins: [mixinToolkitFench],
  data() {
    return {
      showUpload: true,
      uploadLoading: false,
      handleBefore: false,
      handling: false,
      showResult: false,
      file: '',
      fileList: [],
      process: '',
      chargerule: '',
      noTimesVisible: false,
      PDFData: '',
      afterSize: '',
    }
  },
  watch: {
    PDFFetchData(val) {
      this.handling = false
      if(val.data?.ossNo != null){
        this.PDFData = val.data
        this.uploadLoading = false
        this.showUpload = false
        this.showResult = true
        let size = Math.ceil(val.data.fileSise / 1024)
        if(size < 1024){
          this.afterSize = size + 'KB'
        } else {
          this.afterSize =  Math.ceil(size / 1024) + 'MB'
        }
      } else {
        this.handleBefore = true
      }
    },
  },
  mounted () {
    this.process = process.env.NODE_ENV
    this.getChargerule()
  },
  methods:{
    getChargerule() {
      drawHotChargerule('TOOL_BOX').then(res => {
        this.chargerule = res.data
      })
    },
    handleDeletePic(val, idx) {
      this.fileList.splice(idx, 1)
    },
    handleDownload() {
      window.open(this.PDFData.downloadUrl)
    },
    handleRenew() {
      this.showUpload = true
      this.uploadLoading = false
      this.handleBefore = false
      this.handling = false
      this.showResult = false
      this.file = ''
      this.fileList = []
      this.PDFData = ''
    },
    async handleSend() {
      if(!this.$store.state.isLogin){
        this.$store.commit('SET_LOGINDISLOG', true)
        return
      }
      if(this.chargerule.userType == 'VIP0' && this.chargerule.recharge){
        this.$refs.noTimes.type = 'gold'
        this.noTimesVisible = true
        return
      }
      if(this.chargerule.userType != 'VIP0' && this.chargerule.recharge && this.chargerule.defaultUseBenefitRule && !this.$store.state.chargerule.baseBenefitRuleVO.recharge){
        this.$refs.noTimes.type = 'times'
        this.$refs.noTimes.num = this.chargerule.baseBenefitRuleVO.chargeNum
        this.noTimesVisible = true
        return
      }
      if(this.chargerule.userType != 'VIP0' && this.chargerule.recharge && this.chargerule.baseBenefitRuleVO.recharge){
        this.$refs.noTimes.type = 'nothing'
        this.noTimesVisible = true
        return
      }
      this.send()
    },
    async send() {
      this.handleBefore = false
      this.handling = true
      let arr = []
      this.fileList.map(item => {
        let obj = {}
        obj = {
          fileName: item.data.fileName,
          encryOssNo: item.data.encryOssNo,
        }
        arr.push(obj)
      })
      const params = {
        benefitSubType: 'TOOLKIT_IMAGE_TO_PDF',
        imageFileRequests: arr
      }
      await this.getPDFData('/ai/toolkit/send/imageToPdf', params)
    },
    handleError() {
      this.$message.error({message: '上传失败，请重试', duration: 2000})
      this.showUpload = true
      this.uploadLoading = false
      this.handleBefore = false
      this.handling = false
      this.showResult = false
    },
    handleOnExceed() {
      this.$message.error({message:'最多只能上传30张图片', duration: 2000})
    },
    beforeUpload(file) {
      if(!this.$store.state.isLogin){
        this.$store.commit('SET_LOGINDISLOG', true)
        return false
      }
      const isPNG = file.type == 'image/png'
      const isJPG = file.type == 'image/jpg'
      const isJPEG = file.type == 'image/jpeg'
      if (file.size > 1024 * 1024 * 10) {
        this.$message.error({message: '图片太大，请上传 10MB 以下的图片', duration: 2000})
        return false
      }
      if (!isJPG && !isPNG && !isJPEG) {
        this.$message.error({message: '不支持JPG/PNG以外的格式，请重试', duration: 2000})
        return false
      }
      if(this.fileList.length >= 30){
        this.handleOnExceed()
        return false
      }
      this.showUpload = false
      this.uploadLoading = true
      this.handleBefore = false
      this.handling = false
      this.file = ''
    },
    //
    handleSuccess(res, file, fileList) {
      this.file = res
      if(this.fileList.length < 30){
        this.fileList.push(res)
      }
      // this.fileList = fileList
      this.uploadLoading = false
      this.handleBefore = true
      console.log('this.file', this.file)
    },
  }
}
</script>

<style lang="less">
.PDF_black{
  width: 100%;
  height: 100%;
  color: #1F2126;
  font-family: '思源黑体';
  .PDF_title{
    display: flex;
    align-items: flex-end;
    p:nth-of-type(1){
      font-size: 28px;
      font-weight: 500;
      line-height: 36px;
      margin-right: 16px;
    }
    p:nth-of-type(2){
      font-size: 14px;
      line-height: 22px;
      color: #7A7F8C;
    }
  }
  .PDF_container{
    width: 100%;
    height: 510px;
    margin-top: 16px;
    .nodata{
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
      border: 2px dashed #ADC6FF;
      border-radius: 16px;
      .upload, .upload .el-upload, .upload .el-upload .el-upload-dragger{
        width: 100%;
        height: 100%;
      }
      .el-upload-dragger{
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 24px;
        background: transparent;
      }
      .upload_img{
        img{
          width: 120px;
          height: 120px;
        }
      }
      p:nth-of-type(1){
        font-size: 20px;
        font-weight: 500;
        color: #1F2126;
        margin: 12px 0 16px;
        line-height: 28px;
      }
      p:nth-of-type(2){
        font-size: 14px;
        line-height: 22px;
        color: #9498A6;
      }
      .uploaded_pic{
        max-height: 100%;
        max-width: 100%;
        border-radius: 24px;
        object-fit: contain;
      }
      .el-loading-mask{
        border-radius: 24px;
        background: #fff;
      }
    }
    .PDF_loading{
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
      border: 2px dashed #ADC6FF;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img{
        width: 48px;
        height: 48px;
        animation: rotate 1.5s linear infinite;
      }
      @keyframes rotate {
        to{
              transform: rotate(360deg);
          }
      }
      p:nth-of-type(1){
        margin: 12px 0 16px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #1F2126;
      }
      p:nth-of-type(2){
        font-size: 14px;
        line-height: 22px;
        color: #ACB1BF;
      }
    }
    .handle_before{
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
      border-radius: 16px;
      padding: 24px 0 24px 43px;
      box-sizing: border-box;
      border: none;
      .pic_list{
        width: 100%;
        max-height: 100%;    
        overflow-y: scroll;
        display: flex;
        padding-right: 23px;
        box-sizing: border-box;
        flex-wrap: wrap;
        .pic_item{
          width: 120px;
          height: 120px;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          margin: 0 12px 12px 0;
          img{
            max-height: 100%;
            max-width: 100%;
            border-radius: 4px;
            // object-fit: contain;
            width: 100%;
            height: 100%;
          }
          .check{
            width: 24px;
            height: 24px;
            position: absolute;
            top: 8px;
            right: 8px;
            z-index: 1;
          }
          .file_name{
            width: 100%;
            position: absolute;
            bottom: 5px;
            font-size: 12px;
            font-weight: normal;
            line-height: 20px;
            color: #fff;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            padding: 2px;
            box-sizing: border-box;
            text-align: center;
          }
          .del_mask{
            width: 120px;
            height: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            background: rgba(0,0,0,.7);
            border-radius: 4px;
            z-index: 1;
            opacity: 0;
            .el-icon-delete{
              font-size: 24px;
              font-weight: bold;
              color: #fff;
            }
          }
        }
        .pic_item:hover{
          .check{
            z-index: -1;
            opacity: 0;
          }
          .del_mask{
            opacity: 1;
          }
        }
      }
    }
    .handling{
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img{
        width: 48px;
        height: 48px;
        animation: rotate 1.5s linear infinite;
      }
      @keyframes rotate {
        to{
              transform: rotate(360deg);
          }
      }
      p:nth-of-type(1){
        margin: 12px 0 16px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #1F2126;
      }
      p:nth-of-type(2){
        font-size: 14px;
        line-height: 22px;
        color: #ACB1BF;
      }
    }
    .result{
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
      border-radius: 16px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .res_title{
        margin: 24px 0 109px;
        span{
          display: inline-block;
          width: 74px;
          height: 30px;
          border-radius: 999px;
          background: linear-gradient(161deg, #ECF2FD -22%, #F3EFFD 214%);
          padding: 4px 16px;
          gap: 10px;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          color: #2F54EB;
          box-sizing: border-box;
        }
      }
      .res_title2{
        span{
          background: #3D87FF;
          color: #fff;
        }
      }
      .original{
        width: 360px;
        height: 360px;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          max-height: 100%;
          max-width: 100%;
          border-radius: 16px;
        }
      }
    }
  }
  
  .pdf_img{
    text-align: center;
    img{
      width: 192px;
    }
    p{
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: #646873;
    }
  }
  .res_btns{
    margin-top: 34px;
    text-align: center;
    .btn{
      width: 120px;
      height: 38px;
      border-radius: 999px;
      padding: 8px 32px;
      gap: 8px;
      background: #FFFFFF;
      box-sizing: border-box;
      border: 1px solid #DADDE5;
      box-shadow: 0px 1.5px 0px 0px #F5F6F7;
      font-size: 14px;
      line-height: 22px;
      color: #3D87FF;
    }
    .btn2{
      background: #1467FF;
      color: #FFFFFF;
      border: none;
    }
  }
  .handle_btns{
    text-align: right;
    margin-top: 34px;
    display: flex;
    .add_btn{
      width: 144px;
      height: 48px;
      border-radius: 999px;
      padding: 8px 32px;
      gap: 8px;
      background: #FFFFFF;
      box-sizing: border-box;
      border: 1px solid #3D87FF;
      box-shadow: 0px 1.5px 0px 0px #F5F6F7;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: #3D87FF;
    }
    .handle_btn{
      width: 173px;
      height: 48px;
      border-radius: 999px;
      background: linear-gradient(166deg, #2E61FC -41%, #6185FC 195%);
      box-shadow: 0px 1.5px 0px 0px #E6EAF0;
      color: #fff;
        img {
          width: 18px;
          height: 18px;
          margin-left: 6px;
          position: relative;
          top: 3px;
        }
        .cash{
          margin-left: 12px;
        }
        .cash2{
          margin-left: 12px;
          font-size: 14px;
          color: #fff;
          font-weight: normal;
        }
    }
    .dis_btn{
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), linear-gradient(166deg, #2E61FC -41%, #6185FC 195%);
    }
    .upload2{
      margin-right: 16px;
      .el-upload-dragger{
        width: 144px;
        height: 48px;
        background: transparent;
        border: none;
      }
    }
  }
  .tool_privacy{
    font-size: 14px;
    line-height: 22px;
    color: #9498A6;
    margin-top: 16px;
  }
  .pdf_bottom{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}
</style>
<template>
  <div
    class="index_preview"
    @contextmenu="disableRightClick"
  >
    <div class="pre_img">
      <img :src="preImgData?.imgViewUrl">
    </div>
    <div class="pre_detail">
      <div class="detail_top">
        <div class="first_line">
          <span>作品浏览</span>
          <!-- <i
            class="el-icon-close"
            @click="handleClose"
          /> -->
          <el-button
            class="back_home"
            @click="handleClose"
          >
            首页
          </el-button>
        </div>
        <div class="second_line">
          <span>创作于</span>
          <span>{{ preImgData?.taskCreateTimeStr }}</span>
        </div>
      </div>
      <hr>
      <div class="detail_bottom">
        <div class="third_line">
          <span>提示词</span>
          <img
            v-copy="preImgData?.originalPrompt"
            src="@/static/index/copy.png"
          >
        </div>
        <div class="fourth_line">
          {{ preImgData?.originalPrompt }}
        </div>
        <div class="bottom_btns">
          <div class="fifth_line">
            <div class="op">
              <div class="check_view">
                <img src="@/static/index/view2.png">
                <span>{{ preImgData?.previewCount || 0 }}</span>
              </div>
              <div class="septal_line" />
              <div
                class="zan"
                @click="handleFavor"
              >
                <img :src="require('@/static/index/' + (preImgData.isFavor ? 'zan4.png' : 'zan5.png'))">
                <span>{{ preImgData?.favorCount || 0 }}</span>
              </div>
            </div>
            <el-button
              v-show="preImgData?.imgDownloadUrl && preImgData?.imgDownloadUrl != null"
              class="down_btn"
              @click="handleDownload"
            >
              <img src="@/static/index/download.png">
              下载
            </el-button>
          </div>
          <el-button
            class="draw_btn"
            @click="handleGoDraw(preImgData?.originalPrompt)"
          >
            画同款
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { interactPage, interactDetail, favorIncrease, favorReduce } from '@/api/index'

export default {
  name: "InteractDetail",
  data() {
    return {
      preImgData: '',
      interactUid: ''
    };
  },
  created() {
  },
  mounted() {
    this.interactUid = window?.interactUid
    interactDetail(this.interactUid).then(res => {
      if(res.code == 200){
        this.preImgData = res.data
      }
    })
  },
  methods: {
    disableRightClick(event) {
      event.preventDefault()
    },
    handleClose() {
      this.$router.push('/')
    },
    handleFavor() {
      if(!this.$store.state.isLogin){
        this.$store.commit('SET_LOGINDISLOG', true)
        return
      }
      if(this.preImgData.isFavor){
        favorReduce(this.preImgData.interactUid).then(res => {
          this.preImgData.isFavor = false
          this.preImgData.favorCount = this.preImgData.favorCount - 1
          this.waterfallList.forEach(item => {
            if(item.interactUid == this.preImgData.interactUid){
              item.isFavor = this.preImgData.isFavor
              item.favorCount = this.preImgData.favorCount
            }
          })
        })
      } else {
        favorIncrease(this.preImgData.interactUid).then(res => {
          this.preImgData.isFavor = true
          this.preImgData.favorCount = this.preImgData.favorCount + 1
          this.waterfallList.forEach(item => {
            if(item.interactUid == this.preImgData.interactUid){
              item.isFavor = this.preImgData.isFavor
              item.favorCount = this.preImgData.favorCount
            }
          })
        })
      }
    },
    handleDownload() {
      if(!this.$store.state.isLogin){
        this.$store.commit('SET_LOGINDISLOG', true)
        return
      }
      window.open(this.preImgData.imgDownloadUrl)
    },
    handleGoDraw(val) {
      this.$router.push({path: '/aidraw/quickdraw', query: {prompt: val}})
    },
  },
};
</script>

<style lang="less">
.index_preview{
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2000;
  background-color: #000;
  top: 0;
  left: 0;
  display: flex;
  box-sizing: border-box;
  // padding-top: 56px;
  .pre_img{
    width: calc(100% - 320px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .previous_img, .next_img{
      position: absolute;
      font-size: 32px;
      color: #ACB1BF;
      cursor: pointer;
      i{
        font-weight: bold;
      }
    }
    .previous_img{
      left: 16px;
    }
    .next_img{
      right: 16px;
    }
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
  .pre_detail{
    width: 320px;
    height: 100%;
    background-color: #10131B;
    color: #fff;
    font-size: 14px;
    .detail_top{
      padding: 20px 16px 0;
      box-sizing: border-box;
      .first_line{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .back_home{
          width: 70px;
          height: 30px;
          color: #DADDE5;
          border-radius: 999px;
          box-sizing: border-box;
          padding: 8px 0;
          background: #323742;
          box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
          border: none;
        }
        i{
          cursor: pointer;
          font-weight: bold;
          font-size: 18px;
          width: 30px;
          height: 30px;
          text-align: center;
          line-height: 30px;
        }
        i:hover{
          display: inline-block;
          background: #2C2F36;
          border-radius: 50%;
          // color: #000;
        }
      }
      .second_line{
        margin: 10px 0 16px;
        color: #9498A6;
        font-size: 12px;
        span:first-child{
          margin-right: 16px;
        }
      }
    }
    hr{
      height: 1px;
      border: none;
      background: #373940;
    }
    .detail_bottom{
      padding: 32px 16px 0;
      height: calc(100% - 88px);
      box-sizing: border-box;
      position: relative;
      .third_line{
        display: flex;
        justify-content: space-between;
        align-items: center;
        img{
          width: 16px;
          cursor: pointer;
        }
      }
      .fourth_line{
        width: 100%;
        color: #CACDD9;
        line-height: 22px;
        box-sizing: border-box;
        padding: 15px 13px;
        background: #2C2F36;
        border-radius: 12px;
        margin-top: 12px;
      }
      .bottom_btns{
        position: absolute;
        bottom: 0;
        width: 100%;
        .fifth_line{
          width: calc(100% - 32px);
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 28px;
          .op{
            width: 156px;
            height: 36px;
            border-radius: 999px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px;
            gap: 8px;
            background: #323742;
            box-sizing: border-box;
            img{
              width: 16px;
            }
            .check_view{
              width: 70px;
              height: 28px;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 6px;
            }
            .septal_line{
              width: 1px;
              height: 12px;
              background-color: #4E5159;
            }
            .zan{
              width: 70px;
              height: 28px;
              border-radius: 999px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 4px 16px;
              gap: 6px;
              box-sizing: border-box;
              cursor: pointer;
              img{
              }
            }
            .zan:hover{
              background: #565E79;
            }
          }
          .down_btn{
            width: 100px;
            height: 36px;
            color: #DADDE5;
            border-radius: 999px;
            box-sizing: border-box;
            padding: 8px 24px;
            background: #323742;
            box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
            border: none;
            img{
              width: 16px;
              position: relative;
              top: 3px;
            }
            span{
              position: relative;
              top: -2px;
            }
          }
        }
        .draw_btn{
          width: 288px;
          height: 46px;
          border-radius: 999px;
          padding: 12px 32px;
          gap: 8px;
          background: linear-gradient(99deg, #406FFD 5%, #CF7DFF 99%);
          border: none;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          color: #FFFFFF;
          margin-bottom: 40px;
        }
      }
    }
  }
}
</style>
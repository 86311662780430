<template>
  <div class="privacy">
    <div class="content">
      <div class="top">
        <router-link to="/privacy/policies" active-class="active"
          >隐私政策</router-link
        ><router-link active-class="active" to="/privacy/agreements">用户协议</router-link>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
.privacy {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding: 76px 16px 16px 16px;
  box-sizing: border-box;
  background: url(../../static/index/ai-bg.png);
  background-size: 100% 100%;
  .content {
   
    max-width: 1200px;
    min-width: 780px;
    height: calc(100% - 120px);
    margin: 0 auto;
    padding: 56px 0px;
    border-radius: 12px;
 overflow: hidden;
    background: #ffffff;
    .top {
        padding-bottom: 24px;
        padding-left: 56px;
      a {
        font-family: Source Han Sans CN;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        display: inline-block;
        /* Grey/5 */
        color: #9498a6;
        display: inline-block;
        text-decoration: none;
        margin-right: 24px;
        border-radius: 999px;
         &.active {
        padding: 5px 16px;
        color: #1f2126;
        /* Grey/1 */
        background: #f2f4f7;
      }
      }
     
    }
    .policies{
    padding:0px 56px;
font-family: Source Han Sans CN;
font-size: 14px;
font-weight: normal;
line-height: 32px;
letter-spacing: 0em;
height:calc(100% - 120px);
 overflow-y: scroll;
/* Grey/10 */
color: #1F2126;
padding-bottom: 56px;

}
  }
}
</style>
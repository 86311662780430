<template>
  <div class="loading"><div class="bounce1"></div>  <div class="bounce2"></div>
    <div class="bounce3"></div></div>
</template>

<script>
export default {

}
</script>

<style lang='less'>
            .loading{
            
width: 72px;
height: 34px;
border-radius: 999px;
 
opacity: 1;

/* 自动布局 */
display: flex;
flex-direction: row;
align-items: center;
padding: 12px 15px;
gap: 10px;
box-sizing: border-box;
/* Main/06 */
background: #1467FF;
div{
  width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
    animation: bouncedelay 1.4s infinite ease-in-out;
    /* Prevent first frame from flickering when animation starts */
    
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
 &.bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
  &.bounce3 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
}
            }
               @keyframes  bouncedelay {
            0%, 80%, 100% {
                -webkit-transform: scale(0);
                transform: scale(0);
            } 40% {
                  -webkit-transform: scale(1.0);
                  transform: scale(1.0);
              }
        }
        
</style>
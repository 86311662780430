<template>
  <div class="history">
     <div class="title">
      <span class="text-right">使用记录</span>
      <span class="text-left"></span>
    </div>
    <!--  height="calc(100% - 80px)" -->
    <div class="orderContent" v-scrollActive>
         <el-table
            :data="tableData"
            style="width: 100%"
            
            header-row-class-name="qyTable"
          >
            <el-table-column prop="qy" label="使用功能"> 
                <template slot-scope="scope">
                    <div class="top">
                        <p class="title">{{scope.row.recordDesc}}</p>
                        <p class="time">{{scope.row.serviceTimeStr}}</p>
                    </div>
                </template>
            </el-table-column>

            <el-table-column prop="useUnitNumDesc" align="right" label="消耗数量">
            </el-table-column>
          </el-table>
      
    </div>
          <div style="text-align:center;padding-top:10px;">
         <el-pagination
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout=" prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {recordPage } from '@/api/index'
export default {
    data(){
        return {
              pageNum: 1,
      pageSize: 10,
      total: 0,
             tableData: [
    
      ],
        }
    },
    methods:{
      recordPageList(){
        recordPage({ pageNum: this.pageNum, pageSize: this.pageSize }).then(res=>{
          this.tableData=res.data.records
          this.total=res.data.total
        })
      },
        handleCurrentChange(e) {
      this.pageNum=e
      this.recordPageList();
    },
    },
    mounted() {
      this.recordPageList()
    },

}
</script>

<style lang="less">
.history{
padding: 32px 0px;
    height: calc(100% - 64px);
  overflow-y: hidden;
  .title {
      width: 720px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    .text-right {
      position: relative;
      font-family: Source Han Sans CN;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      padding-left: 10px;
      /* Grey/10 */
      color: #1f2126;
      &::before {
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -7px;
        content: "";
        width: 4px;
        height: 14px;
        border-radius: 999px;
        opacity: 1;

        /* Grey/10 */
        background: #1f2126;
      }
    }
  }
  .orderContent {
     width: 720px;
    margin: 0 auto;
    height: calc(100% - 80px);
    overflow-y: scroll;
    .top{
        .title{
            font-family: Source Han Sans CN;
font-size: 14px;
font-weight: normal;
line-height: 22px;
letter-spacing: 0em;

/* Grey/10 */
color: #1F2126;
        }
        .time{
            margin-top:4px;
font-family: Source Han Sans CN;
font-size: 12px;
font-weight: normal;
line-height: 20px;
letter-spacing: 0em;

/* Grey/5 */
color: #9498A6;
        }
    }
   
     .el-table th.el-table__cell>.cell{
      padding-left: 24px;
      padding-right: 24px;
     }
    .el-table--enable-row-transition .el-table__body td.el-table__cell {
          border-bottom: 1px solid #dadde5;
          
        }
        .qyTable {
          height: 56px;
             th.el-table__cell>.cell{
   
          font-family: Source Han Sans CN;
    }
          th {
            background-color: #f2f4f7;
            color: #1f2126;
            font-size: 14px;
            border-bottom: 1px solid #fff;
            font-weight: 500;
           
            &:first-child {
              border-radius: 8px 0px 0px 8px;
            }
            &:nth-child(2) {
              border-radius: 0px 8px 8px 0px;
            }
          }
        }
        .el-table--enable-row-hover
          .el-table__body
          tr:hover
          > td.el-table__cell {
          background-color: #fff;
        }
         .el-table .cell{
           padding-left: 24px;
            color: #9498A6;
      padding-right: 24px;
        }
    
  }
}

</style>
<template>
  <div class="member">
    <div class="content1">
      <div class="top">
        <img src="@/static/user/memberText.png">
        <p>五点下班，让先行者更先一步</p>
      </div>
      <div class="box">
        <div
          v-for="(item,index) in data"
          :key="index"
          class="item"
          :class="item.productName=='永久会员'?'item2':item.productName=='年度会员'?'item3':''"
        >
          <div class="itemTitle">
            <h1 v-if="item.productName=='月度会员'">
              月度会员
            </h1>
            <h1 v-if="item.productName=='永久会员'">
              <span><img src="@/static/user/max.png">永久会员</span>
            </h1>
            <h1 v-if="item.productName=='年度会员'">
              <span><img src="@/static/user/plus.png">年度会员</span>
            </h1>
            <div
              v-if="item.productName=='永久会员'"
              class="subscript"
            >
              限时特惠！
            </div>
            <p>{{ item.displayText }}</p>
            <div>
              <div
                v-if="item.productName=='月度会员'"
                class="itemButtom"
              >
                <span>￥<span>{{ item.timeRuleVOS[0].practicalPrice }}</span>/月</span>
                <span
                  class="vipButtom"
                  @click="charge(item, item.timeRuleVOS[0])"
                >立即开通</span>
              </div>
              <div
                v-if="item.productName=='永久会员'"
                class="itemButtom"
              >
                <div class="countdown">
                  <p class="span">
                    ￥<span>{{ item.timeRuleVOS[0].practicalPrice }}</span>
                  </p>
                  <p v-if="timeStamp">
                    优惠倒计时：{{ day }}<span style="color: #9498A6;"> 天 </span>{{ hour > 10 ? hour : '0' + hour }}<span style="color: #9498A6;"> : </span>{{ minute > 10 ? minute : '0' + minute }}<span style="color: #9498A6;"> : </span>{{ second > 10 ? second : '0' + second }}
                  </p>
                </div>
                <span
                  class="vipButtom"
                  @click="charge(item, item.timeRuleVOS[0])"
                >立即开通</span>
              </div>
              <div
                v-if="item.productName=='年度会员'"
                class="itemButtom"
              >
                <span>￥<span>{{ item.timeRuleVOS[0].practicalPrice }}</span>/年</span>
                <span
                  class="vipButtom"
                  @click="charge(item, item.timeRuleVOS[0])"
                >立即开通</span>
              </div>
            </div>
          </div>
          <div class="itemContent">
            <p class="title">
              核心权益
            </p>
            <div v-if="item.productName=='月度会员'">
              <p
                v-for="(item1,index) in item.benefitVOS"
                :key="index"
                class="text"
              >
                <span
                  class="textTitle"
                  :class="!item1.hasExist?'coin':''"
                >{{ item1.benefitName }}</span>
                <span class="coin">{{ item1.benefitUnitDesc }}</span>
              </p>
            </div>
            <div v-if="item.productName=='永久会员'">
              <p
                v-for="(item1,index) in item.timeRuleVOS[activeMax1].benefitVOS"
                :key="index"
                class="text"
              >
                <span
                  class="textTitle"
                  :class="!item1.hasExist?'coin':''"
                >{{ item1.benefitName }}</span>
                <span class="coin">{{ item1.benefitUnitDesc }}</span>
              </p>
            </div>
            <div v-if="item.productName=='年度会员'">
              <p
                v-for="(item1,index) in item.timeRuleVOS[activeMax].benefitVOS"
                :key="index"
                class="text"
              >
                <span
                  class="textTitle"
                  :class="!item1.hasExist?'coin':''"
                >{{ item1.benefitName }}</span>
                <span class="coin">{{ item1.benefitUnitDesc }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content3">
      <div class="title">
        常见问题
      </div>
      <div class="box">
        <el-collapse
          v-model="activeName"
          accordion
        >
          <el-collapse-item
            title="网站能帮助我做些什么？"
            name="1"
          >
            <div>"五点下班"网站提供多种AI工具和功能，包括AI文字、AI绘图和AI工具箱等。AI文字和AI技能可用于回答问题、提供信息和建议，并进行创造性的写作和内容生成。它们还可用于学习、教育和娱乐。AI绘图可用于艺术创作、视觉辅助和学习，能够生成多种类型的艺术作品并提供辅助工具。AI工具箱可用于图像处理和文档处理。它提供图像编辑、格式转换、文字提取和批量处理等功能，可大大提高工作效率。通过这些功能，用户可以更好地利用AI技术，在各个领域提升生产力和创造力。</div>
          </el-collapse-item>
          <el-collapse-item
            title="AI文字的基础版和高级版有什么区别？"
            name="2"
          >
            <div>相比基础版，高级版具有更好的处理复杂对话能力。它能够生成更精准、连贯的语言表达。当然，基础版也足以满足大部分情况下的需求，您可以根据自身需求进行选择。</div>
          </el-collapse-item>
          <el-collapse-item
            title="AI绘画的基础模式、专业模式、热门功能有什么区别？"
            name="3"
          >
            <div>快速绘画分为‘基础模式’和‘专业模式’，‘基础模式’可以通过简单的描述词和预置的设置项来快速生成绘画作品；‘专业模式’适用于那些希望更精细化调整画面设置项目的用户，能够更加精准的生成目标作品；‘热门功能’是一些受欢迎的AI 趣味功能，可以为作品增添特殊效果或风格，或创作出有趣的作品。</div>
          </el-collapse-item>
          <el-collapse-item
            title="内测的AI技能和工具箱多久上线？"
            name="4"
          >
            <div>尚未上线的功能我们无法提供具体的上线时间表，但我们保证不会让您等待太久。我们正在紧张地进行内测阶段，以确保最终产品的稳定性和用户体验。我们会尽快推出这些功能，并在确定上线日期后通过微信公众号通知您。感谢您的关注和耐心等待。</div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <Recharge
      ref="recharge"
      :dialog-visible.sync="dialogVisible"
      :product-uid="productUid"
      :way="way"
      :charge-data="chargeData"
      :type-name="typeName"
    />
  </div>
</template>

<script>
import tab from "./common/tab";
import Recharge from './common/Recharge'
import {productList, contrast, getProductType} from "@/api/index"

export default {
  components: {
    // tab,
    Recharge
  },
  data() {
    return {
      activeMax2: 0,
      activeMax1: 0,
      activeMax: 0,
      activeName:'1',
      dialogVisible:false,
      productTimeRule:'TIMELESS',
      productUid:'',
      data:[],
      //   -1 关闭图标 -2成功图标 0待上线
      tableData: [],
      contrastList: [],
      way: '开通会员',
      chargeData: {},
      typeName: '',
      day: '',
      hour: '',
      minute: '',
      second: '',
      timeStamp: '',
      timer: null,
    };
  },
  watch: {
    activeMax2(val) {
      if(val == 0){
        this.tableData = this.contrastList.monthlyContrastVOList
      } else {
        this.tableData = this.contrastList.yearContrastVOList
      }
    },
  },
  mounted(){
    this.getProductList()
    this.getcontrast()
    this.timeStamp = localStorage.getItem('timeStamp')
    this.getCountdown()
  },
  destroyed () {
    clearInterval(this.timer)
  },
  methods:{
    getCountdown() {
      if(!this.timeStamp) this.timeStamp = 60 * 60 * 24 * 7
      this.day = Math.floor(this.timeStamp/60/60/24)
      this.hour = Math.floor(this.timeStamp/3600) - (this.day * 24)
      this.minute =  Math.floor(this.timeStamp/60) - (this.day * 24 *60) - (this.hour * 60)
      this.second =  this.timeStamp -  (this.day * 24 *60*60) - (this.hour * 60 * 60) - (this.minute *60)
      localStorage.setItem('timeStamp', this.timeStamp)
      this.timer = setInterval(() => {
        this.timeStamp --
        this.day = Math.floor(this.timeStamp/60/60/24)
        this.hour = Math.floor(this.timeStamp/3600) - (this.day * 24)
        this.minute =  Math.floor(this.timeStamp/60) - (this.day * 24 *60) - (this.hour * 60)
        this.second =  this.timeStamp -  (this.day * 24 *60*60) - (this.hour * 60 * 60) - (this.minute *60)
        localStorage.setItem('timeStamp', this.timeStamp)
      }, 1000)
    },
    async getProductList(){
      const {data}=await  productList()
      this.data=data
    },
    getcontrast() {
      contrast().then(res => {
        this.contrastList = res.data
        this.tableData = this.contrastList.monthlyContrastVOList
      })
    },
    charge(item, chargeData){
      console.log(item, chargeData)
      this.chargeData = item
      this.typeName = item.productName
      this.way = '开通会员'
      this.$refs.recharge.money = this.chargeData.timeRuleVOS[0].practicalPrice
      this.$refs.recharge.activePro = 0
      this.$refs.recharge.productTimeRule = this.chargeData.timeRuleVOS[0].value
      this.$refs.recharge.productUid = item.productUid
      this.dialogVisible=true
    }
  },
};
</script>

<style lang="less">
.member {
  margin-top: 56px;
  .content1 {
    background: linear-gradient(180deg, #fafcff 0%, rgba(250, 252, 255, 0) 96%);
    padding-bottom: 56px;
    background: url(../../static/user/bg.png) no-repeat;
    background-size: cover;
    .top {
      text-align: center;
      padding-top: 40px;
      padding-bottom: 81px;
      img {
        width: 468px;
       
      }
      p {
        font-family: Source Han Sans CN;
        font-size: 20px;
        font-weight: normal;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0em;

        /* Geek Blue/10 */
        color: #030852;
        margin-top: 32px;
      }
    }
    .box {
      text-align: center;
      .item {
        display: inline-block;
        width: 368px;

        border-radius: 24px;
        opacity: 1;
        margin: 0 12px;
        background: #fdfdfd;

        box-sizing: border-box;
        text-align: left;
        .itemTitle {
          padding: 24px 16px 28px 16px;
          h1 {
            font-family: Source Han Sans CN;
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0em;

            /* Grey/8 */
            color: #4e5159;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              display: flex;
              align-items: center;
              img {
                width: 33px;
              }
            }
          }
          p {
            font-family: Source Han Sans CN;
            font-size: 14px;
            font-weight: normal;
            line-height: 22px;
            letter-spacing: 0em;

            /* Grey/5 */
            color: #9498a6;
            padding-top: 22px;

            padding-bottom: 24px;
          }
          .itemButtom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 68px;
            span {
              font-family: Source Han Sans CN;
              font-size: 16px;
              font-weight: 500;
              line-height: 36px;
              letter-spacing: 0em;

              /* Grey/10 */
              color: #1f2126;
              span{
                font-family: MiSans;
                font-weight: 600;
                font-size: 40px;
              }
            }
            .vipButtom {
              width: 120px;
              height: 42px;
              border-radius: 999px;
              padding: 8px 24px;
              background: linear-gradient(115deg, #EDD7A4 -4%, #CBA06C 99%), linear-gradient(155deg, #C2A07B -5%, #75532F 111%);
              font-family: 思源黑体;
              font-size: 18px;
              font-weight: 500;
              line-height: 28px;
              color: #FFFFFF;
              box-sizing: border-box;
              cursor: pointer;
            }
          }
        }
        .itemContent {
          width: 368px;

          border-radius: 24px;
          opacity: 1;
          padding: 16px 24px;
          box-sizing: border-box;

          /* Grey/0 */
          background: #ffffff;

          box-shadow: 0px -10px 30px -10px rgba(0, 0, 0, 0.08);
          .title {
            font-family: Source Han Sans CN;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0em;
            margin-bottom: 24px;
            color: #3d3d3d;
          }
          .text {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            .textTitle {
              font-family: Source Han Sans CN;
              font-size: 14px;
              font-weight: normal;
              line-height: 22px;
              letter-spacing: 0em;

              /* Grey/10 */
              color: #1f2126;
            }
            .coin {
              font-family: Source Han Sans CN;
              font-size: 14px;
              font-weight: normal;
              line-height: 22px;
              text-align: right;
              letter-spacing: 0em;

              /* Grey/6 */
              color: #7a7f8c;
            }
          }
        }
        &.item2 {
          background: linear-gradient(154deg, #fff4e7 0%, #f9c19f 99%);
          .itemTitle {
            position: relative;
            h1{
              color: #643F3A;
            }
            p {
              color: #a77f69;
            }
            .subscript{
              font-family: 思源黑体;
              font-size: 16px;
              font-weight: 500;
              line-height: 20px;
              color: #FFF3E8;
              width: 120px;
              height: 40px;
              border-radius: 2px 24px 2px 24px;
              padding: 0px 6px;
              gap: 10px;
              background: linear-gradient(90deg, #FA5821 0%, #FA8A21 100%);
              position: absolute;
              top: 0;
              right: 0;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
          }
          .itemButtom {
            .countdown{
              .span {
                font-family: Source Han Sans CN;
                font-weight: 500;
                font-size: 16;
                letter-spacing: 0em;
                background: linear-gradient(169deg, #F52100 -38%, #FFAA8D 159%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                span {
                  font-family: Source Han Sans CN;
                  font-size: 40px;
                  font-weight: 600;
                  line-height: 36px;
                }
              }
              p{
                margin: 0;
                padding: 0;
              }
              p:nth-of-type(2){
                background: #FFF4ED;
                padding: 5px 6px;
                border-radius: 4px;
                margin-top: 10px;
                font-family: Source Han Sans;
                font-size: 12px;
                line-height: 14px;
                color: #F14328;
                font-feature-settings: "kern" on;
                box-sizing: border-box;
                span{
                  font-size: 12px;
                  line-height: 14px;
                }
              }
            }
            .vipButtom {
              width: 120px;
              height: 42px;
              border-radius: 999px;
              padding: 8px 24px;
              background: linear-gradient(289deg, #BB6F40 0%, #E8AE78 100%);
              color: #fff;
              font-family: 思源黑体;
              font-size: 18px;
              font-weight: 500;
              line-height: 26px;
              box-sizing: border-box;
            }
          }
        }
        &.item3 {
          background: linear-gradient(154deg, #e8ebf3 0%, #afb1b8 100%);
          .itemTitle {
            p {
              color: #7a7f8c;
            }
          }
          .itemButtom {
            span {
              font-family: Source Han Sans CN;
              font-weight: 500;
              font-size: 16;
              span {
                font-family: Source Han Sans CN;
                font-size: 40px;
                font-weight: 600;
                line-height: 36px;
                letter-spacing: 0em;

                /* Grey/10 */
                color: #1f2126;
              }
            }
            .vipButtom {
              width: 120px;
              height: 42px;
              border-radius: 999px;
              padding: 8px 24px;
              background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), linear-gradient(289deg, #3A3C44 0%, #656875 99%);
              box-sizing: border-box;
              font-family: 思源黑体;
              font-size: 18px;
              font-weight: 500;
              line-height: 28px;
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }
  .content2 {
    padding-top: 61px;
    width: 1152px;
    margin: 0 auto;
    .title {
      font-family: Source Han Sans CN;
      font-size: 40px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: 0em;

      color: #3d3d3d;
      text-align: center;
      padding-bottom: 44px;
    }
    .box {
      .box-top {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 16px;
      }
      .box-content{
        width: 100%;
        .fu{
          color: #DEA569;
        }
        .el-table--enable-row-transition .el-table__body td.el-table__cell{
        border-bottom: 1px solid #DADDE5;
}
        .qyTable{
             height: 56px;
             th{
                 background-color: #F2F4F7;
                             color: #1F2126;
            font-size: 16px;
             border-bottom: 1px solid #fff;
font-weight: 500;
                     &:first-child{
                border-radius: 16px 0px 0px 16px;
            }
            &:nth-child(4){
                border-radius: 0px 16px 16px 0px;
            }
             }
        }
        .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
           background-color: #fff;
        }

      }
    }
  }
  .content3{
      width: 1152px;
    margin: 0 auto;
  
    .title{
        font-family: Source Han Sans CN;
font-size: 40px;
font-weight: 500;
line-height: 48px;
letter-spacing: 0em;

color: #3D3D3D;
padding-bottom: 40px;
    }
    .box{
        .el-collapse{
                border-top: 0px solid #EBEEF5;
    border-bottom: 0px solid #EBEEF5;
        }
        .el-collapse-item{
            background: #F2F4F7;
            padding:12px;
            border-radius: 16px;
            margin-bottom: 24px;
            
        }
        .el-collapse-item__header{
            font-family: Source Han Sans CN;
font-size: 20px;
font-weight: 500;
line-height: 28px;
letter-spacing: 0em;
background-color:rgba(0,0,0,0);
color: #3D3D3D;
border-bottom:solid 0px #eee;

        }
        .el-collapse-item__wrap{
            background-color:rgba(0,0,0,0);
            .el-collapse-item__content{
                font-family: Source Han Sans CN;
font-size: 14px;
font-weight: normal;
line-height: 22px;
letter-spacing: 0em;

/* Grey/7 */
color: #646873;
            }
        }
    }
  }
}
</style>
<template>
  <div
    class="input_tags"
    @click.stop="mouseLeave"
  >
    <!-- 父盒子 -->
    <div
      class="father_box"
      @click.stop="onclick"
    >
      <!-- 生成的标签 -->
      <div
        v-for="(item, index) in TagsAll"
        :key="index"
        class="spanbox"
      >
        <span class="tagspan">{{ item }}</span>
        <i
          class="el-icon-close"
          @click="removeTag(index, item)"
        />
      </div>
      <!-- 输入框 -->
      <!-- @keyup.delete="deleteTags" -->
      <input
        ref="inputTag"
        v-model="currentval"
        :placeholder="valueList.length == 0 && showPlaceholder ? `选择${label}，按回车键完成输入` : '' "
        :style="inputStyle"
        class="inputTag"
        type="text"
        @keyup.enter="addTags"
        @focus="inputFocus"
        @blur="inputBlur"
      >
    </div>
    <div class="tags_list">
      <div
        v-show="showTagsList"
        class="tags"
      >
        <p>热门选项</p>
        <div class="tag_option">
          <div
            v-for="item, idx in optionList"
            :key="item.value + 'o'"
            class="item"
            :class="item.check ? 'active' : ''"
            @click.stop="handlecheck(item, idx)"
          >
            <span>{{ item.text }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import { listByGroup } from '@/api/index'


export default {
  name: "InputTags",
  props: {
    label: { type: String, default: '' }
  },
  data() {
    return {
      //输入框
      currentval: "",
      //tag
      TagsAll: [],
      //输入框宽度
      inputLength: "",
      //计算删除位置
      n: 0,
      showTagsList: false,
      optionList: [],
      valueList: [],
      showPlaceholder: true,
    };
  },
  computed: {
    //计算属性：计算出动态输入框宽度
    inputStyle() {
      let style = {};
      if(this.valueList.length == 0 ) {
        style.width = '100%'
      } else {
        style.width = this.inputLength == 0 ? '30px' :  `${this.inputLength}px`;
      }
      return style;
    },
  },
  watch: {
    TagsAll(val) {
      if(val.length == 0) {
        this.$refs.inputTag.style.paddingLfet = '16px'
      } else {
        this.$refs.inputTag.style.paddingLfet = '8px'
      }
    },
    //监听输入的值越多，输入框越长
    currentval() {
      // 实时改变input输入框宽度，防止输入内容超出input默认宽度显示不全
      this.inputLength = this.$refs.inputTag.value.length * 12 + 50;
    },
    // parentArr() {
    //   this.TagsAll = this.parentArr.length ? this.parentArr : [];
    // },
  },
  created () {
      switch (this.label) {
        case '绘画风格':
          listByGroup({groupNo:'AI_DRAW_PRO_ARTISTICSTYLES'}).then(res => {
            let data = res.data
            data.forEach(item => {
              this.$set(item, 'check', false)
            })
            this.optionList = data
          })
          break
        case '艺术家名字':
          listByGroup({groupNo:'AI_DRAW_PRO_ARTISTS'}).then(res => {
            let data = res.data
            data.forEach(item => {
              this.$set(item, 'check', false)
            })
            this.optionList = data
          })
          break
        case '构图方式':
          listByGroup({groupNo:'AI_DRAW_PRO_COMPOSITIONS'}).then(res => {
            let data = res.data
            data.forEach(item => {
              this.$set(item, 'check', false)
            })
            this.optionList = data
          })
          break
        case '光线效果':
          listByGroup({groupNo:'AI_DRAW_PRO_LIGHTINGS'}).then(res => {
            let data = res.data
            data.forEach(item => {
              this.$set(item, 'check', false)
            })
            this.optionList = data
          })
          break
        case '渲染风格':
          listByGroup({groupNo:'AI_DRAW_PRO_RENDERERS'}).then(res => {
            let data = res.data
            data.forEach(item => {
              this.$set(item, 'check', false)
            })
            this.optionList = data
          })
          break
      }
    
  },
  methods: {
    mouseLeave() {
      // this.showTagsList = false
    },
    handlecheck(val) {
      val.check = !val.check
      if(val.check) {
        this.TagsAll.push(val.text)
        this.valueList.push(val.value)
      } else {
        let idx = this.TagsAll.indexOf(val.text)
        this.TagsAll.splice(idx, 1)
        this.valueList.splice(idx, 1)
      }
    },
    inputBlur() {
      this.showPlaceholder = true
    },
    inputFocus() {
      this.$bus.emit('closeInputTags')
      this.showTagsList = true
      this.showPlaceholder = false
    },
    //点击叉叉删除tag
    removeTag(index, val) {
      this.TagsAll.splice(index, 1);
      this.valueList.splice(index, 1)
      this.optionList.forEach(item => {
        if(val == item.text) {
          item.check = false
        }
      })
    },

    //回车增加tag
    addTags(e) {
      if(!e.shiftKey && !e.ctrlKey && e.keyCode == 13) {
        if (this.currentval) {
          //限制输入长度
          // if (this.currentval.length > 10) {
          //   alert('单标签长度不能超过10个字符！')
          // } else {
            //添加tag
            this.TagsAll.push(this.currentval);
            this.valueList.push(this.currentval)
            this.optionList.forEach(item => {
              if(this.currentval == item.text) {
                item.check = true
                this.valueList[this.valueList.length - 1] = item.value
              }
            })
            //清空输入框
            this.currentval = "";
          // }
        }
      }
    },

    //键盘删除键删除tag
    // deleteTags() {
    //   //逻辑：当删除到最后一个字符的时候，删除后currentval为空，所以继续执行，n++。这时候n=1.然后判断n是否等于2，不等于不执行。
    //   //这里是保证当你删除最后一个字符的时候不会执行删除tag的方法，当我们删完了字符后再按一次删除的时候，n就等于2了。就开始删除tag。
    //   //当有多个tag时，我们连续删除，就会出现，因为currentval为空，所以一直执行n++，导致n不等于2了，所以没法删除后面的tag。
    //   //所以增加判断，当n大于2的时候我们看tag的长度有没有，有那就继续删除，没有就归0，从来。
    //   if (this.currentval == "") {
    //     this.n++;
    //     if (this.n == 2) {
    //       this.TagsAll.pop();
    //     }
    //     if (this.n > 2) {
    //       if (this.TagsAll.length) {
    //         this.TagsAll.pop();
    //       } else {
    //         this.n = 0;
    //       }
    //     }
    //   } else {
    //     this.n = 0;
    //   }
    // },
    //点击父盒子输入框获取焦点
    onclick() {
      this.$nextTick(() => {
        this.$refs.inputTag.focus();
      });
    },
  },
};
</script>
 
<style lang="less">
.input_tags{
  // position: relative;
  .father_box {
    width: 356px;
    min-height: 46px;
    border-radius: 12px;
    padding: 4px;
    box-sizing: border-box;
    background-color: white;
    border: 1px solid #DADDE5;
    font-size: 12px;
    text-align: left;
    word-wrap: break-word;
    overflow: hidden;
    .spanbox {
      font-size: 12px;
      background: #F2F4F7;
      height: 28px;
      line-height: 20px;
      padding: 4px 8px;
      color: #1F2126;
      display: inline-block;
      border-radius: 4px;
      margin: 4px;
      box-sizing: border-box;
      i {
        margin-left: 7px;
        cursor:pointer;
      }
      i:hover{
        width: 16px;
        height: 16px;
        background: #ACB1BF;
        border-radius: 50%;
        color: #fff;
        line-height: 16px;
        text-align: center;
      }
    }
    .spanbox:hover{
      background: #E6E9F0;
    }
    .inputTag {
      width: auto;
      font-size: 14px;
      border: none;
      box-shadow: none;
      outline: none;
      background-color: transparent;
      padding: 8px 16px;
      height: 20px;
      line-height: 20px;
    }
    .inputTag::placeholder {
      color: #CACDD9;
    }
  }
  .father_box:hover, .father_box:focus-within{
    border: 1px solid #3D87FF;
  }
  .tags_list{
    position: relative;
    padding-top: 8px;
    .tags{
      width: 356px;
      // max-height: 188px;
      border-radius: 12px;
      box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.1);
      background: #fff;
      position: absolute;
      // top: 8px;
      z-index: 1;
      padding: 8px;
      box-sizing: border-box;
      font-size: 12px;
      p{
        color: #3D3D3D;
        margin: 4px;
      }
      .tag_option{
        display: flex;
        flex-wrap: wrap;
        overflow-y: scroll;
        max-height: 136px;
        .item{
          min-width: 40px;
          height: 28px;
          border-radius: 4px;
          margin: 4px;
          background: #F2F4F7;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 8px;
        }
        .active{
          background: #1467FF;
          color: #fff;
        }
      }
      .tag_option::-webkit-scrollbar {
        width: 4px;
      }
    }
  }
}
</style>
 

<template>
  <div
    v-scrollActive
    class="indexContent"
    @contextmenu="disableRightClick"
  >
    <div
      v-show="!showPreview"
      ref="indexContainer"
    >
      <div class="top_content">
        <div class="top_left">
          <img src="@/static/index/indexTop.png">
        </div>
        <div class="top_right">
          <router-link
            to="/ai/freetalk"
            class="a1"
          >
            <div class="right_item bg_color">
              <div class="right_img right_img_bg">
                <img src="@/static/index/ai-icon1.png">
              </div>
              <div class="item_right">
                <div class="first_floor">
                  <span class="item_text">AI 文字</span>
                  <span class="arrow"><i class="el-icon-arrow-right" /></span>
                </div>
                <p>智能对话，高效获取信息</p>
              </div>
            </div>
          </router-link>
          <router-link
            to="/aidraw/quickdraw"
            class="a2"
          >
            <div class="right_item bg_color2">
              <div class="right_img right_img_bg2">
                <img src="@/static/index/ai-icon7.png">
              </div>
              <div class="item_right">
                <div class="first_floor">
                  <span class="item_text">AI 绘画</span>
                  <span class="arrow"><i class="el-icon-arrow-right" /></span>
                </div>
                <p>简单操作，完成惊艳作品</p>
              </div>
            </div>
          </router-link>
          <router-link
            to="/out"
            class="a3"
          >
            <div class="right_item bg_color3">
              <div class="right_img right_img_bg3">
                <img src="@/static/index/ai-icon22.png">
              </div>
              <div class="item_right">
                <div class="first_floor">
                  <span class="item_text">AI 技能</span>
                  <span class="arrow"><i class="el-icon-arrow-right" /></span>
                </div>
                <p>进阶技能，解决复杂难题</p>
              </div>
            </div>
          </router-link>
          <router-link
            to="/toolbox/compress"
            class="a4"
          >
            <div class="right_item bg_color4">
              <div class="right_img right_img_bg4">
                <img src="@/static/index/ai-icon23.png">
              </div>
              <div class="item_right">
                <div class="first_floor">
                  <span class="item_text">工具箱</span>
                  <span class="arrow"><i class="el-icon-arrow-right" /></span>
                </div>
                <p>常用工具，适配多个场景</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div
        v-show="showImage"
        ref="indexContainer"
        class="index_container"
      >
        <!-- <div
          v-for="item,idx in waterfallList"
          :key="idx"
          class="ex_item"
          :style="{top:item.top+'px',left:item.left+'px',width:ImgWidth+'px',height:item.height}"
        >
          <div
            class="item_img"
            @click="handleshowPreview(item, idx)"
          >
            <img :src="item.thumbnailViewUrl">
            <div class="img_mask" />
          </div>
          <div class="item_bottom">
            <div class="ex_num">
              <div
                v-show="!item?.isFavor"
                class="zan_img"
              />
              <div
                v-show="item?.isFavor"
                class="zan_img2"
              />
              <span>{{ item.favorCount || 0 }}</span>
              <div class="view_img" />
              <span>{{ item.previewCount || 0 }}</span>
            </div>
            <el-button
              class="draw_btn"
              @click="handleGoDraw(item?.prompt)"
            >
              画同款
            </el-button>
          </div>
        </div> -->
        <!-- :style="{gridRow: `auto / span ${item.height + 67}`}" -->
        <div
          v-for="item,idx in waterfallList"
          :key="idx"
          class="ex_item"
          :style="item.style"
        >
          <div
            class="item_img"
            @click="handleshowPreview($event, item, idx)"
          >
            <img :src="item.thumbnailViewUrl">
            <div class="img_mask" />
          </div>
          <div class="item_bottom">
            <div class="ex_num">
              <div
                v-show="!item?.isFavor"
                class="zan_img"
                @click="handleFavor2(item)"
              />
              <div
                v-show="item?.isFavor"
                class="zan_img2"
                @click="handleFavor3(item)"
              />
              <span>{{ item.favorCount || 0 }}</span>
              <div class="view_img" />
              <span>{{ item.previewCount || 0 }}</span>
            </div>
            <el-button
              class="draw_btn"
              @click="handleGoDraw(item?.originalPrompt)"
            >
              画同款
            </el-button>
          </div>
        </div>
      </div>
      <div class="load_more">
        <el-button
          v-if="!loading && waterfallList.length < total"
          class="more_btn"
          @click="handleLoadMore"
        >
          查看更多
        </el-button>
        <el-button
          v-if="loading && waterfallList.length < total"
          class="more_btn"
        >
          <img
            src="@/static/index/loading.png"
            alt=""
          >
          加载中
        </el-button>
        <div
          v-if="waterfallList.length >= total"
          class="all"
        >
          已加载全部
        </div>
      </div>
      <hr class="hr">
      <footer-default />
    </div>
    <div
      v-show="showPreview"
      class="index_preview"
    >
      <div class="pre_img">
        <div
          class="previous_img"
          @click="handlePreviousImg"
        >
          <i class="el-icon-arrow-left" />
        </div>
        <img :src="preImgData?.imgViewUrl">
        <div
          class="next_img"
          @click="handleNextImg"
        >
          <i class="el-icon-arrow-right" />
        </div>
      </div>
      <div class="pre_detail">
        <div class="detail_top">
          <div class="first_line">
            <span>作品浏览</span>
            <i
              class="el-icon-close"
              @click="handleClose"
            />
          </div>
          <div class="second_line">
            <span>创作于</span>
            <span>{{ preImgData?.taskCreateTimeStr }}</span>
          </div>
        </div>
        <hr>
        <div class="detail_bottom">
          <div class="third_line">
            <span>提示词</span>
            <img
              v-copy="preImgData?.originalPrompt"
              src="@/static/index/copy.png"
            >
          </div>
          <div class="fourth_line">
            {{ preImgData?.originalPrompt }}
          </div>
          <div class="bottom_btns">
            <div class="fifth_line">
              <div class="op">
                <div class="check_view">
                  <img src="@/static/index/view2.png">
                  <span>{{ preImgData?.previewCount || 0 }}</span>
                </div>
                <div class="septal_line" />
                <div class="zan">
                  <img
                    :src="require('@/static/index/' + (preImgData.isFavor ? 'zan4.png' : 'zan5.png'))"
                    @click="handleFavor"
                  >
                  <span>{{ preImgData?.favorCount || 0 }}</span>
                </div>
              </div>
              <el-button
                v-show="preImgData?.imgDownloadUrl && preImgData?.imgDownloadUrl != null"
                class="down_btn"
                @click="handleDownload"
              >
                <img src="@/static/index/download.png">
                下载
              </el-button>
            </div>
            <el-button
              class="draw_btn"
              @click="handleGoDraw(preImgData?.originalPrompt)"
            >
              画同款
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { interactPage, interactDetail, favorIncrease, favorReduce } from '@/api/index'
import CircularAnimation from "@/components/CircularAnimation";
import vueWaterfallEasy from 'vue-waterfall-easy'
import FooterDefault from  "@/components/FooterDefault.vue"

export default {
  name: "Index",
  components: {
    // CircularAnimation,
    FooterDefault
  },
  data() {
    return {
      showPreview: false,
      preImgData: '',
      preIndex: '',
      num: 12,
      pageNum: 1,
      pageSize: 40,
      interactList: [
        // {thumbnailViewUrl: require('../static/index/e1.png')},
        // {thumbnailViewUrl: require('../static/index/e2.png')},
        // {thumbnailViewUrl: require('../static/index/e3.png')},
        // {thumbnailViewUrl: require('../static/index/e1.png')},
        // {thumbnailViewUrl: require('../static/index/e2.png')},
        // {thumbnailViewUrl: require('../static/index/e3.png')},
        // {thumbnailViewUrl: require('../static/index/e1.png')},
        // {thumbnailViewUrl: require('../static/index/e2.png')},
        // {thumbnailViewUrl: require('../static/index/e3.png')},
        // {thumbnailViewUrl: require('../static/index/e1.png')},
        // {thumbnailViewUrl: require('../static/index/e2.png')},
        // {thumbnailViewUrl: require('../static/index/e3.png')},
        // {thumbnailViewUrl: require('../static/index/e1.png')},
        // {thumbnailViewUrl: require('../static/index/e2.png')},
        // {thumbnailViewUrl: require('../static/index/e3.png')},
        // {thumbnailViewUrl: require('../static/index/e1.png')},
        // {thumbnailViewUrl: require('../static/index/e2.png')},
        // {thumbnailViewUrl: require('../static/index/e3.png')},
        // {thumbnailViewUrl: require('../static/index/e1.png')},
        // {thumbnailViewUrl: require('../static/index/e2.png')},
        // {thumbnailViewUrl: require('../static/index/e3.png')},
      ],
      screenWidth:document.body.clientWidth,
      contentWidth: '',
      timer: true,
      deviationHeight: [],
      waterfallList: [],
      newImgList:[],
      ImgCol: 4,
      ImgRight:24,
      ImgBottom:62,
      footTop: '',
      total: 0,
      loading: true,
      showImage: false,
      scrollTopVal: 0,
      scrollHeight: 0,
    };
  },
  computed: {
  },
  watch: {
    contentWidth: {
      handler(val)  {
        if(!this.timer){
          this.timer = true
          // setTimeout(() =>{
            this.ImgWidth = (this.contentWidth - 72)/this.ImgCol;
            let arr = [...this.waterfallList]
            arr.forEach(item => {
              item.height = this.ImgWidth / item.thumbnailIndexWidth * item.thumbnailIndexHeight
              item.style = {
                'grid-row': `auto / span ${parseInt(item.height + 67)}`
              }
              // let img = new Image()
              // img.src = item.thumbnailViewUrl
              // img.onload = () => {
              //   item.height = this.ImgWidth / img.width * img.height
              //   item.style = {
              //     'grid-row': `auto / span ${parseInt(item.height + 67)}`
              //   }
              // }
            })
            setTimeout(() => {
              this.timer = false
              this.waterfallList = [...arr]
            },10)
          // },100)
        }
      },
    }
  },
  created() {
  },
  mounted() {
		this.$nextTick(() => {
			window.addEventListener("scroll", this.scrollTop, true);
		});
    this.contentWidth = this.$refs.indexContainer.offsetWidth < 1200 ? 1200 : ( this.$refs.indexContainer.offsetWidth >= 1200 && this.$refs.indexContainer.offsetWidth < 1800) ? this.$refs.indexContainer.offsetWidth : 1800
    this.getInteractPage()
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        this.screenWidth = window.screenWidth
        this.contentWidth = this.$refs.indexContainer.offsetWidth < 1200 ? 1200 : ( this.$refs.indexContainer.offsetWidth >= 1200 && this.$refs.indexContainer.offsetWidth < 1800) ? this.$refs.indexContainer.offsetWidth : 1800
      })()
    }
  },
  methods: {
    scrollTop(e) {
			this.scrollTopVal = document.getElementById('app').scrollTop;
      // console.log('this.scrollTopVal', this.scrollTopVal)
		},
    disableRightClick(event) {
      event.preventDefault()
    },
    getHeight() {
      this.ImgWidth = (this.contentWidth - 72)/this.ImgCol;
      this.newImgList.forEach(item => {
        item.height = this.ImgWidth / item.thumbnailIndexWidth * item.thumbnailIndexHeight
        item.style = {
          'grid-row': `auto / span ${parseInt(item.height + 67)}`
        }
        // let img = new Image()
        // img.src = item.thumbnailViewUrl
        // img.onload = () => {
        //   let imgData = {...item};
        //   item.height = this.ImgWidth / img.width * img.height
        //   item.style = {
        //     'grid-row': `auto / span ${parseInt(item.height + 67)}`
        //   }
        // }
      })
    },
    handleLoadMore() {
      this.loading = true
      // this.newImgList = this.interactList
      // this.getHeight()
      // setTimeout(() => {
      //   this.waterfallList = this.waterfallList.concat(...this.newImgList)
      //   this.showImage = true
      //   this.loading = false
      // },1000)
      this.pageNum ++
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }
      interactPage(params).then(res => {
        if(res.code == 200){
          this.newImgList = res.data.records
          this.total = res.data.total
          this.getHeight()
          setTimeout(() => {
            this.waterfallList = this.waterfallList.concat(...this.newImgList)
            this.loading = false
          },1000)
          if(res.data.records.length == 0){
            this.pageNum --
            this.loading = false
          }
        } else {
          this.pageNum --
          this.loading = false
        }
      }).catch(err => {
        this.pageNum --
        this.loading = false
      })
    },
    getInteractPage() {
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }
      interactPage(params).then(res => {
        this.waterfallList = []
        // this.newImgList = this.interactList
        this.newImgList = res.data.records
        this.total = res.data.total
        this.getHeight()
        setTimeout(() => {
          this.waterfallList = this.waterfallList.concat(...this.newImgList)
          this.showImage = true
          this.loading = false
        },100)
        this.timer = false
      })
    },
    // 预览上一张
    handlePreviousImg() {
      if(this.preIndex < 0){
        return false
      }
      interactDetail(this.waterfallList[this.preIndex - 1].interactUid).then(res => {
        if(res.code == 200){
          this.showPreview = true
          this.preImgData = res.data
          this.waterfallList[this.preIndex - 1].previewCount = this.waterfallList[this.preIndex - 1].previewCount + 1
          this.preIndex = this.preIndex - 1
        }
      })
    },
    // 预览下一张
    handleNextImg() {
      if(this.preIndex >= (this.waterfallList.length - 1)){
        return false
      }
      interactDetail(this.waterfallList[this.preIndex + 1].interactUid).then(res => {
        if(res.code == 200){
          this.showPreview = true
          this.preImgData = res.data
          this.waterfallList[this.preIndex + 1].previewCount = this.waterfallList[this.preIndex + 1].previewCount + 1
          this.preIndex = this.preIndex + 1
        }
      })
    },
    handleshowPreview(event, val, idx) {
      this.scrollHeight = this.scrollTopVal
      this.preIndex = idx
      // document.getElementById('app').scrollTo(0, 600) 
      interactDetail(val.interactUid).then(res => {
        if(res.code == 200){
          this.showPreview = true
          this.preImgData = res.data
          val.previewCount = res.data.previewCount
        }
      })
    },
    handleClose() {
      this.showPreview = false
      this.$nextTick(() => {
        document.getElementById('app').scrollTo(0, this.scrollHeight) 
      })
    },
    handleFavor() {
      if(this.preImgData.isFavor){
        favorReduce(this.preImgData.interactUid).then(res => {
          this.preImgData.isFavor = false
          this.preImgData.favorCount = this.preImgData.favorCount - 1
          this.waterfallList.forEach(item => {
            if(item.interactUid == this.preImgData.interactUid){
              item.isFavor = this.preImgData.isFavor
              item.favorCount = this.preImgData.favorCount
            }
          })
        })
      } else {
        favorIncrease(this.preImgData.interactUid).then(res => {
          this.preImgData.isFavor = true
          this.preImgData.favorCount = this.preImgData.favorCount + 1
          this.waterfallList.forEach(item => {
            if(item.interactUid == this.preImgData.interactUid){
              item.isFavor = this.preImgData.isFavor
              item.favorCount = this.preImgData.favorCount
            }
          })
        })
      }
    },
    handleFavor2(val) {
      favorIncrease(val.interactUid).then(res => {
        val.isFavor = true
        val.favorCount = val.favorCount + 1
      })
    },
    handleFavor3(val) {
      favorReduce(val.interactUid).then(res => {
        val.isFavor = false
        val.favorCount = val.favorCount - 1
      })
    },
    handleDownload() {
      window.open(this.preImgData.imgDownloadUrl)
    },
    handleGoDraw(val) {
      this.$router.push({path: '/aidraw/quickdraw', query: {prompt: val}})
    },
    //计算每个图片的宽度
    calculationWidth(){
      this.ImgWidth = (this.contentWidth - 72)/this.ImgCol;
      //初始化偏移高度数组
      this.deviationHeight = new Array(this.ImgCol);
      for (let i = 0;i < this.deviationHeight.length;i++){
        this.deviationHeight[i] = 0;
      }
      this.imgPreloading()
    },
    //图片预加载
    imgPreloading(){
      this.waterfallList = [];
      for (let i = 0;i < this.interactList.length;i++){
        let aImg = new Image();
        aImg.src = this.interactList[i].thumbnailViewUrl;
        aImg.onload = aImg.onerror = ()=>{
          let imgData = {...this.interactList[i]};
          imgData.height = this.ImgWidth/aImg.width*aImg.height; //按比例计算图片高度
          this.waterfallList.push(imgData);
          this.rankImg(imgData); //渲染页面
          if(i == (this.interactList.length - 1) ){
            this.footTop = (imgData.top + imgData.height) + 'px'
          }
        }
      }
      console.log('this.waterfallList', this.waterfallList)
      // this.$nextTick(() => {
      //   console.log('this.footTop', this.waterfallList)
      // })
    },
    //瀑布流布局
    rankImg(imgData){
      let {ImgWidth,ImgRight,ImgBottom,deviationHeight} = this;
      let minIndex = this.filterMin();//获得高度最小的一列的下标
      imgData.top = deviationHeight[minIndex];//插入图片的top值
      imgData.left = minIndex*(ImgRight+ImgWidth);//插入图片的left值
      deviationHeight[minIndex] += imgData.height + ImgBottom;//更新当前列的高度
    },
    //找到最短的列并返回下标
    filterMin(){
      const min = Math.min.apply(null, this.deviationHeight);
      return this.deviationHeight.indexOf(min);
    },
  },
};
</script>

<style lang="less">
  .indexContent {
    width: 100%;
    padding: 132px 64px 0;
    box-sizing: border-box;
    background: url('../static/index/indexBG.png') no-repeat center;
    background-size: 100% 100%;
    height: 100%;
    font-family: '思源黑体';
    .top_content{
      max-width: 1800px;
      min-width: 1200px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      .top_left{
        width: 45.75%;
        img{
          width: calc(100% - 24px);
          margin-right: 24px;
        }
      }
      .top_right{
        width: 54.25%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        a{
          text-decoration: none;
          color: #fff;
          display: flex;
          // width: 100%;
          width: calc(50% - 12px);
        }
        .right_item{
          padding: 26px 24px;
          box-sizing: border-box;
          // width: calc(50% - 12px);
          border-radius: 20px;
          color: #fff;
          height: 124px;
          margin-bottom: 24px;
          display: flex;
          cursor: pointer;
          width: 100%;
          .item_right{
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            width: calc(100% - 87px);
            .first_floor{
              display: flex;
              justify-content: space-between;
              align-items: center;
              .item_text{
                font-family: Alimama ShuHeiTi;
                font-size: 26px;
                font-weight: bold;
                line-height: 36px;
              }
              .arrow{
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                i{
                  font-size: 16px;
                  font-weight: bold;
                }
              }
            }
            p{
              font-family: '思源黑体';
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              color: rgba(255, 255, 255, 0.75);
            }
          }
          .right_img{
            width: 71px;
            height: 71px;
            background-color: #fff;
            border-radius: 16px;
            margin-right: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              width: 48px;
              height: 48px;
            }
          }
          .right_img_bg{
            background: linear-gradient(135deg, #E3EAFF 0%, #FFFFFF 99%);
            box-shadow: 0px 20px 10px -8px rgba(65, 102, 176, 0.3);
          }
          .right_img_bg2{
            background: linear-gradient(135deg, #FFF2E3 0%, #FFFFFF 99%);
            box-shadow: 0px 20px 10px -8px rgba(208, 132, 1, 0.3);
          }
          .right_img_bg3{
            background: linear-gradient(135deg, #FDE4E4 0%, #FFFFFF 99%);
            box-shadow: 0px 20px 10px -8px rgba(252, 62, 62, 0.3);
          }
          .right_img_bg4{
            background: linear-gradient(135deg, #F2E3FF 0%, #FFFFFF 99%);
            box-shadow: 0px 20px 10px -8px rgba(154, 24, 240, 0.3);
          }
        }
        .bg_color{
          background: linear-gradient(292deg, #447CFF 0%, #89B4FF 100%);
          transition: transform 0.2s;
          i{
            color: #2098E8;
          }
        }
        .a1:hover{
          width: 50%;
          .bg_color{
            background: linear-gradient(286deg, #447CFF 0%, #89B4FF 100%);
            border: 6px solid #CDDDFD;
            box-shadow: 0px 15px 30px 0px rgba(108, 157, 255, 0.3);
            height: 134px;
            margin-bottom: 14px;
            position: relative;
            top: -5px;
            left: -6px;
            transform: translateY(-11px)
          }
        }
        .bg_color2{
          background: linear-gradient(284deg, #FFAE33 -4%, #FFCF7D 99%);
          transition: transform 0.2s;
          i{
            color: #F2B30B;
          }
        }
        .a2:hover{
          width: 50%;
          .bg_color2{
            background: linear-gradient(286deg, #FFAE33 0%, #FFCF7D 100%);
            border: 6px solid #FFE5C5;
            box-shadow: 0px 15px 30px 0px rgba(250, 180, 104, 0.3);
            height: 134px;
            margin-bottom: 14px;
            position: relative;
            top: -5px;
            right: -6px;
            transform: translateY(-11px)
          }
        }
        .bg_color3{
          background: linear-gradient(284deg, #FF8282 -1%, #FFB1B1 100%);
          transition: transform 0.2s;
          i{
            color: #FF8686;
          }
        }
        .a3:hover{
          width: 50%;
          .bg_color3{
            background: linear-gradient(286deg, #FF8282 0%, #FFB1B1 100%);
            border: 6px solid #FFDCDC;
            box-shadow: 0px 15px 30px 0px rgba(255, 140, 140, 0.3);
            height: 134px;
            position: relative;
            bottom: -5px;
            left: -6px;
            margin-top: -10px;
            transform: translateY(-11px)
          }
        }
        .bg_color4{
          background: linear-gradient(284deg, #8342F4 -4%, #A58CFF 99%);
          transition: transform 0.2s;
          i{
            color: #9350FF;
          }
        }
        .a4:hover{
          width: 50%;
          .bg_color4{
            background: linear-gradient(286deg, #8342F4 0%, #A58CFF 100%);
            border: 6px solid #E5D8FF;
            box-shadow: 0px 15px 30px 0px rgba(140, 120, 255, 0.3);
            height: 134px;
            position: relative;
            bottom: -5px;
            right: -6px;
            margin-top: -10px;
            transform: translateY(-11px)
          }
        }
      }
    }
    .index_container{
      max-width: 1800px;
      min-width: 1200px;
      height: 100%;
      margin: 40px auto 0;
      // position: relative;
      display: grid;
      justify-content: space-between;
      grid-template-columns: calc(25% - 18px) calc(25% - 18px) calc(25% - 18px) calc(25% - 18px);
      // grid-auto-rows: auto;
      grid-auto-rows: minmax(1px, 1px);
      // grid-gap: 24px 24px;
      grid-auto-flow: row dense;
      .ex_item{
        // width: calc(25% - 18px);
        width: 100%;
        // position: absolute;
        .item_img{
          width: 100%;
          position: relative;
          cursor: pointer;
          img{
            width: 100%;
            height: auto;
            border-radius: 16px;
          }
          .img_mask{
            width: 100%;
            height: calc(100% - 5px);
            border-radius: 16px;
            position: absolute;
            top: 0;
            background: rgba(0,0,0,.3);
            z-index: -1;
          }
        }
        .item_img:hover{
          .img_mask{
            z-index: 2;
          }
        }
        .item_bottom{
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 12px;
          .ex_num{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .zan_img, .zan_img2, .view_img{
              width: 16px;
              height: 16px;
              margin-right: 6px;
              cursor: pointer;
            }
            .zan_img{
              background: url('../static/index/zan.png') no-repeat center;
              background-size: 100% 100%;
            }
            .zan_img2{
              background: url('../static/index/zan3.png') no-repeat center;
              background-size: 100% 100%;
            }
            .view_img{
              background: url('../static/index/view.png');
              background-size: 100% 100%;
            }
            .zan_img:hover{
              background: url('../static/index/zan2.png') no-repeat center;
              background-size: 100% 100%;
            }
            .zan_img2:hover{
              background: url('../static/index/zan4.png') no-repeat center;
              background-size: 100% 100%;
            }
            .view_img:hover{
              background: url('../static/index/view3.png');
              background-size: 100% 100%;
            }
            span{
              font-size: 14px;
              color: #1F2126;
              margin-right: 16px;
            }
          }
          .draw_btn{
            font-family: '思源黑体';
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            color: #FFFFFF;
            width: 66px;
            height: 26px;
            border-radius: 999px;
            padding: 2px 0;
            border: none;
            background: linear-gradient(112deg, #5789FF 0%, #877CFF 99%);
          }
          .draw_btn:hover{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(112deg, #5789FF 1%, #877CFF 99%);
          }
        }
      }
    }
    .load_more{
      max-width: 1800px;
      min-width: 1200px;
      text-align: center;
      width: 100%;
      margin: 76px auto 120px;
      .more_btn{
        width: 104px;
        height: 38px;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: #5B74DC;
        border-radius: 999px;
        padding: 8px 0;
        gap: 12px;
        background: #EEF1FB;
        border: none;
        img{
          width: 12px;
          position: relative;
          top: 1px;
          margin-right: 8px;
          animation: loading 1.5s linear infinite;
        }
        @keyframes loading {
          to{
            transform: rotate(360deg);
          }
        }
      }
      .all{
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: #5B74DC;
      }
    }
    .hr{
      height: 1px;
      border: none;
      background: #DADDE5;
    }
    .index_preview{
      width: 100vw;
      height: 100vh;
      position: fixed;
      z-index: 2000;
      background-color: #000;
      top: 0;
      left: 0;
      display: flex;
      box-sizing: border-box;
      // padding-top: 56px;
      .pre_img{
        width: calc(100% - 320px);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .previous_img, .next_img{
          position: absolute;
          font-size: 32px;
          color: #ACB1BF;
          cursor: pointer;
          i{
            font-weight: bold;
          }
        }
        .previous_img{
          left: 16px;
        }
        .next_img{
          right: 16px;
        }
        img{
          max-width: 100%;
          max-height: 100%;
        }
      }
      .pre_detail{
        width: 320px;
        height: 100%;
        background-color: #10131B;
        color: #fff;
        font-size: 14px;
        .detail_top{
          padding: 20px 16px 0;
          box-sizing: border-box;
          .first_line{
            display: flex;
            align-items: center;
            justify-content: space-between;
            i{
              cursor: pointer;
              font-weight: bold;
              font-size: 18px;
              width: 30px;
              height: 30px;
              text-align: center;
              line-height: 30px;
            }
            i:hover{
              display: inline-block;
              background: #2C2F36;
              border-radius: 50%;
              // color: #000;
            }
          }
          .second_line{
            margin: 10px 0 16px;
            color: #9498A6;
            font-size: 12px;
            span:first-child{
              margin-right: 16px;
            }
          }
        }
        hr{
          height: 1px;
          border: none;
          background: #373940;
        }
        .detail_bottom{
          padding: 32px 16px 0;
          height: calc(100% - 88px);
          box-sizing: border-box;
          position: relative;
          .third_line{
            display: flex;
            justify-content: space-between;
            align-items: center;
            img{
              width: 16px;
              cursor: pointer;
            }
          }
          .fourth_line{
            width: 100%;
            color: #CACDD9;
            line-height: 22px;
            box-sizing: border-box;
            padding: 15px 13px;
            background: #2C2F36;
            border-radius: 12px;
            margin-top: 12px;
          }
          .bottom_btns{
            position: absolute;
            bottom: 0;
            width: 100%;
            .fifth_line{
              width: calc(100% - 32px);
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 28px;
              .op{
                width: 156px;
                height: 36px;
                border-radius: 999px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 4px;
                gap: 8px;
                background: #323742;
                box-sizing: border-box;
                img{
                  width: 16px;
                }
                .check_view{
                  width: 70px;
                  height: 28px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 6px;
                }
                .septal_line{
                  width: 1px;
                  height: 12px;
                  background-color: #4E5159;
                }
                .zan{
                  width: 70px;
                  height: 28px;
                  border-radius: 999px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 4px 16px;
                  gap: 6px;
                  box-sizing: border-box;
                  img{
                    cursor: pointer;
                  }
                }
                .zan:hover{
                  background: #565E79;
                }
              }
              .down_btn{
                width: 100px;
                height: 36px;
                color: #DADDE5;
                border-radius: 999px;
                box-sizing: border-box;
                padding: 8px 24px;
                background: #323742;
                box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
                border: none;
                img{
                  width: 16px;
                  position: relative;
                  top: 3px;
                }
                span{
                  position: relative;
                  top: -2px;
                }
              }
            }
            .draw_btn{
              width: 288px;
              height: 46px;
              border-radius: 999px;
              padding: 12px 32px;
              gap: 8px;
              background: linear-gradient(99deg, #406FFD 5%, #CF7DFF 99%);
              border: none;
              font-size: 14px;
              font-weight: 500;
              line-height: 22px;
              color: #FFFFFF;
              margin-bottom: 40px;
            }
          }
        }
      }
    }
  }
</style>
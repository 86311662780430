<template>
  <div class="search">
    <div
      ref="search"
      class="search_container"
      :style="{ borderRadius: heightText != 24 ? 12 + 'px' : 56 + 'px',background:disabled?'#F5F7FA':'' }"
    >
      <el-input
        ref="input"
        v-model="value"
        class="searchInput"
        type="textarea"
        resize="none"
        placeholder="请尽管向我提问"
        :disabled="disabled"
        :autosize="{ maxRows: 8 }"
        @input="changeHeight"
        @keydown.native="keyupEnter"
      />
  
      <div class="searchBox">
        <el-button
          v-show="value.length > 0"
          type="primary"
          round
          icon="el-icon-s-promotion"
          :loading="disabled"
          class="buttonhover"
          @click="clickSend"
        >
          发送
        </el-button>
        <span
          v-show="value.length > 0 && $store.state.userInfo.userType == 'VIP0'"
          class="text"
        >
          {{ '-' + $store.state.chargerule.chargeNum }} <img
            src="@/static/index/money.png"
          ></span>
        <span
          v-show="value.length > 0 && $store.state.userInfo.userType != 'VIP0'"
          class="text"
        >
          <span>剩余：<span style="color: #3D87FF;">{{ $store.state.chargerule.surplusNum }}</span> 次</span>
        </span>
      </div>
    </div>
    <!-- <div
      class="browsingBl"
      :style="{bottom: heightText < 21 ? '85px' : heightText < 41 ? '105px' :heightText < 61 ? '125px' : '145px'}"
    >
      <el-switch
        v-model="browsingBl"
        @change="handleChangeBrowsing"
      />
      <div
        class="browsing"
        @mouseover="handleShowBrowsingTip"
        @mouseleave="showBrowsingTip = false"
      >
        <span>联网模式</span>
        <img
          src="@/static/index/tip.png"
        >
        <div
          v-show="showBrowsingTip"
          class="browsing_tip"
        >
          开启联网模式后，可从互联网获取最新的实时数据，生成的答案更精准，但会消耗更多金豆
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>

export default {
  props: ["text"],
  data() {
    return {
      heightText:24,
      tt:'',
      value: "",
      radius: 56,
      eventSource: null,
      chargeNum: "",
      disabled:false,
      noTimesVisible: false,
      showBrowsingTip: false,
      browsingBl: false,
    };
  },
  watch: {
    "$store.state.searchIndex"(value) {
      if (!value) {
        this.value = "";
        this.disabled=false;
      }
      if(value){
        this.disabled=true;
      }
    },
    text(value) {
      this.value = value;
      console.log("444", value);
    },
    "$store.state.version"() {
      this.$store.dispatch("getChargerule", this.browsingBl);
    }
  },
  mounted() {
  },
  methods: {
    handleChangeBrowsing() {
      this.$store.dispatch("getChargerule", this.browsingBl);
    },
    handleShowBrowsingTip() {
      this.showBrowsingTip = true
    },
    clickSend() {
      console.log('this.$store.state.isLogin', this.$store.state.isLogin)
      if(!this.$store.state.isLogin){
        this.$store.commit('SET_LOGINDISLOG', true)
        return
      }
      const data = {
        prompt: this.value,
        apiKeyModel: this.$store.state.version || this.$store.state.versions[0].code,
        accessSubType: "AI_CHAR_BASE",
        roomUid: null,
        messageUid: null,
        // browsingBl: this.browsingBl,
      };
      this.$parent.resetSend(data);
    },
    changeHeight() {
      const textarea =this.$refs.input.$refs.textarea;
      this.heightText = textarea.scrollHeight
      if(!this.value) {
        this.heightText = 24
      }
    },
    keyupEnter(e) {
      if(!e.shiftKey && !e.ctrlKey && e.keyCode == 13) {
        e.cancelBubble = true; //ie阻止冒泡行为
        e.stopPropagation();//Firefox阻止冒泡行为
        e.preventDefault(); //取消事件的默认动作*换行
        this.clickSend()
      }
    }
  },
};
</script>

<style lang="less">
.search{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}
.search_container {
  // width: calc(100% - 152px);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 24px;
  background: #ffffff;
  box-sizing: border-box;
  border: 1px solid #dadde5;
  border-radius: 12px;
  box-shadow: 0px 4px 10px 0px rgba(0, 40, 132, 0.08);
  .searchBox {
    display: flex;
    // width: 129px;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    margin-left: 12px;
    .el-button {
      color: #fff;
    }
    .text {
      margin-left: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: Source Han Sans CN;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0em;
      white-space: nowrap;
      color: #3d3d3d;
      img {
        width: 16px;
        height: 16px;
        margin-left: 6px;
      }
    }
  }
  .searchInput {
    margin-top: 2px;
    .el-textarea__inner {
      border: none;
      height: 100%;
      padding: 0px;
      line-height: 1.5;
      font-size: 16px;
      color: #1f2126;
      padding-right: 12px;
    }
  }
}

.browsingBl{
    right: 16px;
    display: flex;
    align-items: center;
    .browsing{
      font-size: 14px;
      line-height: 22px;
      color: #3D3D3D;
      margin-left: 8px;
      position: relative;
      display: flex;
      align-items: center;
      img{
        width: 16px;
        margin-left: 8px;
      }
      .browsing_tip{
        position: absolute;
        bottom: 28px;
        right: -96px;
        width: 320px;
        border-radius: 6px;
        padding: 6px 12px;
        background: rgba(0, 0, 0, 0.9);
        color: #fff;
        font-size: 14px;
        line-height: 22px;
        box-sizing: border-box;
      }
    }
  }

</style>
<template>
  <div
    class="tab"
    :style="{background:bg}"
  >
    <span
      :class="active == 0 ? 'active' : ''"
      :style="{color:color}"
      @click="change(0)"
    >月度</span>
    <span
      :class="active == 1 ? 'active' : ''"
      :style="{color:color}"
      @click="change(1)"
    >年度</span>
  </div>
</template>

<script>
export default {
  name: "Tab",
  props: {
    active: {
      type: Number,
      default: 0,
    },
    bg:{
        type:String,
        default:''
    },
    color:{
        type:String,
        default:''
    }
  },
  methods: {
    change(index) {
      this.$emit("update:active", index);
    },
  },
};
</script>

<style lang="less">
.tab {
  width: 114px;
  height: 34px;
  border-radius: 24px;
  opacity: 1;
  padding: 4px;
  box-sizing: border-box;
  background: #ffc0a2;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  span {
    width: 52px;
    height: 26px;

    font-family: Source Han Sans CN;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    justify-content: center;
    align-items: center;
    color: #61361f;
    box-sizing: border-box;
     padding: 2px 12px;
    &.active {
      border-radius: 999px;
      opacity: 1;

     

      /* Grey/0 */
      background: #ffffff;

      z-index: 0;
    }
  }
}
</style>
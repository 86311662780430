<template>
  <el-dialog
    :title="way"
    custom-class="recharge"
    :visible.sync="dialogVisible"
    width="580px"
    :before-close="handleClose"
    append-to-body
  >
    <div class="boxDialog">
      <div class="item">
        <div>
          <div
            class="title"
            style="display: block;"
          >
            {{ way == '开通会员' ? '开通类型' : '续费类型' }}
          </div>
          <div class="member_list">
            <div
              v-if="from != 'noTimes'"
              class="box_item"
              :class="'active'"
              @click="handleChange()"
            >
              <div class="item_top">
                <img
                  :src="require('@/static/user/' + (chargeData.productName == '永久会员' ? 'max.png' : 'plus.png'))"
                >
                <span>{{ chargeData.productName }}</span>
              </div>
              <div class="item_bottom">
                <span>{{ chargeData.productName == '月度会员' ? '30天' : chargeData.productName == '永久会员' ? '终身使用' : '365天' }}</span>
              </div>
            </div>
            <div
              v-for="item, index in productList"
              v-else
              :key="index"
              class="box_item"
              :class="activePro == index ? 'active' : ''"
              @click="handleChange(item,index)"
            >
              <div class="item_top">
                <img
                  :src="require('@/static/user/' + (item.productName == '永久会员' ? 'max.png' : 'plus.png'))"
                >
                <span>{{ item.productName }}</span>
              </div>
              <div class="item_bottom">
                <span>{{ item.productName == '月度会员' ? '30天' : item.productName == '永久会员' ? '终身使用' : '365天' }}</span>
              </div>
            </div>
          </div>
          <div
            v-if="way == '续费会员'"
            class="explain"
          >
            <p>*续费说明：</p>
            <p>续费后会员时长和会员权益将在原套餐基础上进行累积叠加</p>
            <p>例：原来是月度会员（剩余 10 天，10 次 AI 绘画快速模式），那么续费后，权益则变为：剩余 40 天，AI 绘画快速模式 10+10=20 次）</p>
          </div>
        </div>
      </div>
      <div class="item2">
        <p class="title">
          支付方式
        </p>
        <span
          class="weixin"
        ><img src="@/static/user/weixin.png"> 微信支付</span>
      </div>
      <div
        v-if="way != '充值金豆'"
        class="item4"
      >
        <p class="title">
          邀请码（填写后可获得10%优惠，如无则不填）
        </p>
        <p class="verify_p">
          <el-input
            v-model="invitationCode"
            placeholder="输入邀请码"
          /> <el-button
            v-loading="verifyLoading"
            round
            class="btn"
            @click="handleVerify"
          >
            {{ verifyLoading ? '' : '验证' }}
          </el-button>
        </p>
        <p
          v-if="verifyStatus != ''"
          class="verify_tip"
          :style="{color: verifyStatus == '0' ? '#FF4D4F' : '#49BC5C'}"
        >
          <img
            :src="require('@/static/user/' + (verifyStatus == '0' ? 'err.png' : 'suc.png'))"
            alt=""
          >{{ verifyStatus == '0' ? '该邀请码不可用，请重新输入' : '邀请码可用' }}
        </p>
      </div>
      <div>
        <div class="item3">
          <p>
            支付金额：<span>￥</span><span class="text">{{ money }}</span>
          </p>
        </div>
      </div>
      <qrcode
        :state-code="stateCode"
        :inner-visible.sync="innerVisible"
        :product-time-rule="productTimeRule"
        :pay-bill-uid="payBillUid"
        :pay-amount="payAmount"
        :jindou-num="jindouNum"
        :way="way"
        :type-name="typeName"
      />
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          class="dialogButtom"
          :loading="loading"
          round
          @click="summbit"
        >
          立即支付
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import qrcode from "./qrcode.vue";
import { orderCreate, computeRecharge, getByProductType } from "@/api/index";
// ,paymentqrcode
export default {
  components: {
    qrcode,
  },
  props: {
    dialogVisible: { type: Boolean, default: false },
    // productUid: { type: String, default: "" },
    // productTimeRule: { type: String, default: "" },
    way: { type: String, default: "" },
    chargeData: { type: Object, default: () => {}},
    productList: { type: Array, default: () => []},
    typeName: { type: String, default: "" },
    from: { type: String, default: "" },
  },
  data() {
    return {
      active:1,
      loading: false,
      payBillUid: "",
      innerVisible: false,
      jindouNum: "330",
      money: "",
      stateCode: "",
      payAmount: "",
      datalist: [
        // {
        //   jindouNum: "10000",
        //   money: "1",
        //   text: "无优惠",
        //   tuijian: false,
        // },
        {
          jindouNum: "100",
          money: "10",
          text: "无优惠",
          tuijian: false,
        },
        {
          jindouNum: "330",
          money: "30",
          text: "多送10%",
          tuijian: true,
        },
        {
          jindouNum: "580",
          money: "50",
          text: "多送15%",
          tuijian: false,
        },
        {
          jindouNum: "1200",
          money: "100",
          text: "多送20%",
          tuijian: false,
        },
      ],
      invitationCode: '',
      verifyLoading: false,
      verifyStatus: '',
      activePro: '',
      productTimeRule: '',
      productUid: '',
    };
  },
  mounted () {
  },
  methods: {
    handleCheckRights() {
      this.$parent.$refs.rights.visible = true
    },
    handleChange(val, idx) {
      this.activePro = idx
      if(this.from == 'noTimes') {
        this.productUid = val.productUid
        this.productTimeRule = val.timeRuleVOS[0].value
        this.money = val.timeRuleVOS[0].practicalPrice
      } else {
        this.productTimeRule = val.value
        this.money = val.practicalPrice
      }
    },
    handleVerify() {
      this.verifyLoading = true
      const params = {
        productUid: this.productUid,
        productTimeRule: this.productTimeRule,
        invitationCode: this.invitationCode,
      }
      computeRecharge(params).then(res => {
        this.verifyLoading = false
        if(res.data.effectInvitationCode == true){
          this.verifyStatus = '1'
          this.money = res.data.amount
        } else {
          this.verifyStatus = '0'
        }
      }).catch(err => {
        this.verifyLoading = false
        this.verifyStatus = ''
      })
    },
    handleClose() {
      console.log(false);
      this.$emit("update:dialogVisible", false);
    },
    charge(item, index) {
      this.jindouNum = item.jindouNum;
      this.money = item.money;
      this.active = index;
    },
    summbit() {
      // if(this.way != '充值金豆') {
      //   this.money = this.chargeData.practicalPrice
      // } 
      let action = this.way == '升级会员' ? 'UPGRADE' : this.way == '续费会员' ? 'RENEWAL' : this.way == '充值金豆' ? 'RECHARGE_JINDOU' : 'NEW_ACTIVATION'
      let data = {
        productUid: this.productUid,
        productTimeRule: this.productTimeRule,
        jindouNum: this.way == '充值金豆' ? this.jindouNum : '',
        payToolType: "WECHAT_PAY",
        action,
        invitationCode: this.invitationCode,
        baiduPopularizeVid: this.$store.state.baiduPopularizeVid || ''
      };
      this.loading = true;
      orderCreate(data)
        .then((res) => {
          this.innerVisible = true;
          this.loading = false;
          // let data2={payToolType: 'WECHAT_PAY',orderUid:res.data.orderUid}
          this.stateCode = res.data.qrCodeUrl;
          this.payAmount = res.data.payAmount;
          this.payBillUid = res.data.payBillUid;
          // paymentqrcode(data2).then(res=>{
          //   console.log(res)
          // })
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
};
</script>

<style lang='less'>
.recharge {
  border-radius: 24px;
  opacity: 1;

  background: linear-gradient(134deg, #f9f6f0 0%, #f2f6fc 99%);
  padding: 26px 32px;
  .el-dialog__header {
    text-align: center;
    .el-dialog__title {
      font-family: Source Han Sans CN;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;

      /* Grey/10 */
      color: #1f2126;
    }
  }
  .el-dialog__body {
    padding: 24px 0px !important;
    .boxDialog {
      .item {
        position: relative;
        .title {
          font-family: Source Han Sans CN;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          padding-bottom: 16px;

          /* Grey/10 */
          color: #1f2126;
        }
        .boxt {
          display: flex;
          justify-content: space-between;
          align-content: center;
          &.dc{
            flex-direction: column;
            justify-content: center;
            width: 516px;
            height: 128px;
            background: #fff;
            border-radius: 12px;
            padding: 28px 24px;
            color: #3D3D3D;
            box-sizing: border-box;
            p:nth-of-type(1){
              margin-bottom: 24px;
            }
          }
          .itemt {
            width: 120px;
            position: relative;
            height: 120px;
            border-radius: 8px;
            cursor: pointer;
            opacity: 1;
            position: relative;
            /* Grey/0 */
            background: #ffffff;
            border: 2px solid #f9f6f0;
            &.active {
              // box-sizing: border-box;
              border: 2px solid #ff6135;
              &::after {
                position: absolute;
                right: 6px;
                top: 6px;
                background: url(../../../static/user/ch.png);
                background-size: 16px 16px;
                width: 16px;
                height: 16px;
                content: "";
              }}
              .tuijian {
                position: absolute;
                right: -13px;
                top: -6px;
                width: 36px;
                height: 20px;
                border-radius: 999px;
                opacity: 1;
                display: inline-block;
                z-index: 99;
                /* 自动布局 */

                padding: 0px 6px;
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;
                box-sizing: border-box;
                text-align: center;
                color: #fff3e8;

                background: #fa6d21;
              }
              .title {
                padding-top: 20px;
                font-family: Source Han Sans CN;
                font-size: 24px;
                font-weight: 500;
                line-height: 32px;
                text-align: center;
                letter-spacing: 0em;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #3d3d3d;
                padding-bottom: 6px;
                img {
                  width: 16px;
                  margin-left: 2px;
                }
              }
              .text {
                font-family: Source Han Sans CN;
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0em;

                color: #3d3d3d;
              }
              .chargetit {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 28px;
                border-radius: 0px 0px 6px 6px;
                opacity: 1;
                line-height: 28px;
                background: linear-gradient(103deg, #e4d5c5 0%, #f4ebe2 100%);
                font-family: Source Han Sans CN;
                font-size: 12px;
                font-weight: normal;

                text-align: center;
                letter-spacing: 0em;

                color: #84674d;
              }
              
          }
          .itembox {
            padding: 16px 24px;
            width: 516px;
            margin-top: 16px;
            border-radius: 12px;
            opacity: 1;
            box-sizing: border-box;
            /* Grey/0 */
            background: #ffffff;
            p {
              color: #3d3d3d;
              padding: 12px 0px;
              font-size: 16;
              span {
                font-weight: 400;
              }
              /* 1 年（365天） */
              font-family: Source Han Sans CN;
              font-weight: 500;
            }
          }
        }
        .explain{
          margin-top: 18px;
        }
        .member_list {
          display: flex;
          .box_item{
            width: 173px;
            height: 118px;
            border-radius: 12px;
            margin-right: 24px;
            border: 2px solid transparent;
            &.active{
              border: 2px solid #FA8C16;
              .item_bottom{
              border-radius: 0px 0px 10px 10px;
              }
            }
            .item_top{
              background: #FFFFFF;
              height: 78px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 12px 12px 0px 0px;
              img{
                width: 32px;
                height: 32px;
                margin-right: 8px;
              }
              span{
                font-size: 20px;
                font-weight: 500;
                line-height: 28px;
                color: #3D3D3D;
              }
            }
            .item_bottom{
              font-size: 16px;
              font-weight: 500;
              color: #84674D;
              height: 40px;
              border-radius: 0px 0px 12px 12px;
              background: linear-gradient(103deg, #E4D5C5 0%, #F4EBE2 100%), #D8D8D8;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
      .item2 {
        margin-top: 24px;
        .title {
          font-family: Source Han Sans CN;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;

          /* Grey/10 */
          color: #1f2126;
        }
        .weixin {
          width: 104px;
          margin-top: 16px;
          height: 40px;
          border-radius: 8px;
          opacity: 1;
          border-radius: 8px;
          cursor: pointer;
          font-family: Source Han Sans CN;
          font-size: 14px;
          font-weight: 500;
          line-height: 40px;
          letter-spacing: 0em;
          display: flex;
          justify-content: space-around;
          align-items: center;
          /* Grey/8 */
          color: #4e5159;
          box-sizing: border-box;
          /* Grey/2 */
          border: 1px solid #dadde5;
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
      .item3 {
        font-family: Source Han Sans CN;
        font-weight: 500;
        font-size: 16;
        color: #1f2126;
        margin-top: 32px;
        span {
          color: #fa8c16;
          &.text {
            font-family: Source Han Sans CN;
            font-size: 40px;
            font-weight: 500;
            line-height: 48px;
          }
        }
      }
      .item4 {
        margin-top: 24px;
        .title {
          font-family: Source Han Sans CN;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #1f2126;
          margin-bottom: 16px;
        }
        .verify_p{
          display: flex;
          align-items: center;
        }
        .el-input{
          width: 304px;
          margin-right: 16px;
        }
        .el-input__inner{
          width: 304px;
          height: 32px;
          border-radius: 999px;
          padding: 5px 12px;
          background: #FFFFFF;
          box-sizing: border-box;
          border: 1px solid #DADDE5;
        }
        .btn{
          width: 60px;
          height: 32px;
          padding: 5px 16px;
          background: linear-gradient(173deg, #2E61FC -81%, #6185FC 266%);
          box-shadow: 0px 1.5px 0px 0px #E6EAF0;
          color: #fff;
        }
        .el-loading-mask{
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), linear-gradient(173deg, #2E61FC -81%, #6185FC 266%);
          border-radius: 999px;
        }
        .el-loading-spinner .path{
          stroke:#fff;
          stroke-width: 5px;
        }
        .el-loading-spinner .circular{
          width: 16px;
        }
        .verify_tip{
          position: absolute;
          margin-top: 4px;
          img{
            width: 16px;
            height: 16px;
            position: relative;
            top: 4px;
            margin-right: 4px;
          }
          p{
            font-size: 12px;
          }
        }
      }
    }
  }
  .el-dialog__footer {
    text-align: center !important;
  }
  .dialog-footer {
    text-align: center !important;

    .dialogButtom {
      background: linear-gradient(114deg, #f4c796 -1%, #eb8d5b 100%),
        linear-gradient(108deg, #ff9514 1%, #ff7e14 99%);

      /* 按钮/主要 */
      box-shadow: 0px 1.5px 0px 0px #e6eaf0;
      font-family: Source Han Sans CN;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      width: 144px;
      height: 48px;
      border: none;
      /* Grey/0 */
      border-radius: 999px !important;
      color: #ffffff;
      margin-top: 56px;
    }
  }
}
</style>
<template>
  <div class="toolbox_black">
    <div class="toolbox_title">
      <p>PDF转PPT</p>
      <p>将单个PDF文件转换成PPT文件</p>
    </div>
    <div
      class="toolbox_container"
      :class="showUpload || uploadLoading ? 'dashed' : !showUpload && !uploadLoading ? 'h_524' : ''"
    >
      <div
        v-if="showUpload"
        class="nodata"
      >
        <el-upload
          :action="'/attachments/file/bigUpload'"
          list-type="picture"
          accept=".pdf"
          :show-file-list="false"
          :auto-upload="true"
          :on-success="handleSuccess"
          :before-upload="beforeUpload"
          :on-error="handleError"
          :drag="true"
          class="upload"
        >
          <div
            class="upload_img"
          >
            <img src="../../static/ai/upload2.png">
            <p>拖拽或点击以上传文件</p>
            <p>文件类型支持PDF，大小不超过8M。</p>
          </div>
        </el-upload>
      </div>
      <div
        v-if="uploadLoading"
        class="toolbox_loading"
      >
        <img src="../../static/ai/loading3.png">
        <p>正在上传中...</p>
        <p>请耐等待文件上传完成</p>
      </div>
      <div
        v-if="handleBefore"
        class="handle_before"
      >
        <div
          class="pdf_img"
        >
          <img
            src="@/static/ai/pdf.png"
            alt=""
          >
          <p>文件名：{{ file?.data?.fileName }}</p>
        </div>
      </div>
      <div
        v-if="handling"
        class="handling"
      >
        <img src="../../static/ai/loading3.png">
        <p>正在处理中...</p>
        <p>请耐等待文件上传完成</p>
      </div>
      <div
        v-if="showResult"
        class="result"
      >
        <div class="res_left">
          <div class="res_title">
            <span>原文件</span>
          </div>
          <div
            class="pdf_img"
          >
            <img
              src="@/static/ai/pdf.png"
              alt=""
            >
            <p>文件名：{{ file?.data?.fileName }}</p>
          </div>
        </div>
        <div class="">
          <div class="res_title res_title2">
            <span>处理后</span>
          </div>
          <div
            class="pdf_img"
          >
            <img
              src="@/static/ai/PPT2.png"
              alt=""
            >
            <p>文件名：{{ toolboxData.ossNo }}</p>
          </div>
        </div>
      </div>
      <div
        v-if="showResult"
        class="res_btns"
      >
        <el-button
          class="btn"
          @click="handleRenew"
        >
          重新上传
        </el-button>
        <el-button
          class="btn btn2"
          @click="handleDownload"
        >
          下载
        </el-button>
      </div>
    </div>
    <p
      v-show="!showResult"
      class="tool_privacy"
    >
      *为保护您的隐私，已上传文件将会在一小时后删除
    </p>
    <div
      v-if="handleBefore || handling"
      class="handle_btns"
    >
      <el-button
        class="handle_btn"
        :disabled="handling"
        :class="handling ? 'dis_btn' : ''"
        @click="handleSend"
      >
        开始处理
        <span
          v-if="chargerule?.userType == 'VIP0'"
          class="cash"
        >-{{ chargerule?.chargeNum }}<img src="@/static/index/money.png"></span>
        <span
          v-else
          class="cash2"
        >剩：{{ chargerule?.surplusNumDesc?.split('次数')[0] }}次</span>
      </el-button>
    </div>
    <noTimes
      ref="noTimes"
      :no-times-visible.sync="noTimesVisible"
    />
  </div>
</template>

<script>
import mixinToolkitFench from "@/mixin/toolboxFench";
import noTimes from "@/components/noTimes";
import { drawHotChargerule } from '@/api/index'

export default {
  components: {
    noTimes,
  },
  mixins: [mixinToolkitFench],
  data() {
    return {
      showUpload: true,
      uploadLoading: false,
      handleBefore: false,
      handling: false,
      showResult: false,
      file: '',
      process: '',
      chargerule: '',
      noTimesVisible: false,
      toolboxData: '',
      benefitSubType: '',
    }
  },
  watch: {
    pptFetchData(val) {
      this.handling = false
      if(val.data?.ossNo != null){
        this.toolboxData = val.data
        this.uploadLoading = false
        this.showUpload = false
        this.showResult = true
      } else {
        this.handleBefore = true
      }
    },
  },
  mounted () {
    this.process = process.env.NODE_ENV
    this.getChargerule()
  },
  methods:{
    getChargerule() {
      drawHotChargerule('TOOL_BOX').then(res => {
        this.chargerule = res.data
      })
    },
    handleDownload() {
      window.open(this.toolboxData.downloadUrl)
    },
    handleRenew() {
      this.showUpload = true
      this.uploadLoading = false
      this.handleBefore = false
      this.handling = false
      this.showResult = false
      this.file = ''
      this.toolboxData = ''
    },
    async handleSend() {
      if(!this.$store.state.isLogin){
        this.$store.commit('SET_LOGINDISLOG', true)
        return
      }
      if(this.chargerule.userType == 'VIP0' && this.chargerule.recharge){
        this.$refs.noTimes.type = 'gold'
        this.noTimesVisible = true
        return
      }
      if(this.chargerule.userType != 'VIP0' && this.chargerule.recharge && this.chargerule.defaultUseBenefitRule && !this.$store.state.chargerule.baseBenefitRuleVO.recharge){
        this.$refs.noTimes.type = 'times'
        this.$refs.noTimes.num = this.chargerule.baseBenefitRuleVO.chargeNum
        this.noTimesVisible = true
        return
      }
      if(this.chargerule.userType != 'VIP0' && this.chargerule.recharge && this.chargerule.baseBenefitRuleVO.recharge){
        this.$refs.noTimes.type = 'nothing'
        this.noTimesVisible = true
        return
      }
      this.send()
    },
    async send() {
      this.handleBefore = false
      this.handling = true
      const params = {
        benefitSubType: 'TOOLKIT_PDF_TO_PPTX',
        model:null,
        pdfFileRequest: {
          fileName: this.file.data.fileName,
          encryOssNo: this.file.data.encryOssNo,
        }
      }
      await this.getPPTData('/ai/toolkit/send/pdfToPptx', params)
    },
    handleError() {
      this.$message.error({message: '上传失败，请重试', duration: 2000})
      this.showUpload = true
      this.uploadLoading = false
      this.handleBefore = false
      this.handling = false
      this.showResult = false
    },
    beforeUpload(file) {
      if(!this.$store.state.isLogin){
        this.$store.commit('SET_LOGINDISLOG', true)
        return false
      }
      const pdf = file.type == 'application/pdf'
      if (file.size > 1024 * 1024 * 8) {
        this.$message.error({message: '文件太大，请上传 8MB 以下的文件', duration: 2000})
        return false
      }
      if (!pdf) {
        this.$message.error({message: '不支持PDF以外的格式，请重试', duration: 2000})
        return false
      }
      this.showUpload = false
      this.uploadLoading = true
      this.file = ''
    },
    //
    handleSuccess(res, file, fileList) {
      this.file = res
      this.uploadLoading = false
      this.handleBefore = true
      console.log('this.file', this.file)
    },
  }
}
</script>

<style lang="less">
.toolbox_black{
  width: 100%;
  height: 100%;
  color: #1F2126;
  font-family: '思源黑体';
  .toolbox_title{
    display: flex;
    align-items: flex-end;
    p:nth-of-type(1){
      font-size: 28px;
      font-weight: 500;
      line-height: 36px;
      margin-right: 16px;
    }
    p:nth-of-type(2){
      font-size: 14px;
      line-height: 22px;
      color: #7A7F8C;
    }
  }
  .toolbox_container{
    width: 100%;
    height: 510px;
    margin-top: 16px;
    .nodata{
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
      border: 2px dashed #ADC6FF;
      border-radius: 16px;
      .upload, .upload .el-upload, .upload .el-upload .el-upload-dragger{
        width: 100%;
        height: 100%;
      }
      .el-upload-dragger{
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 24px;
        background: transparent;
      }
      .upload_img{
        img{
          width: 120px;
          height: 120px;
        }
      }
      p:nth-of-type(1){
        font-size: 20px;
        font-weight: 500;
        color: #1F2126;
        margin: 12px 0 16px;
        line-height: 28px;
      }
      p:nth-of-type(2){
        font-size: 14px;
        line-height: 22px;
        color: #9498A6;
      }
      .uploaded_pic{
        max-height: 100%;
        max-width: 100%;
        border-radius: 24px;
        object-fit: contain;
      }
      .el-loading-mask{
        border-radius: 24px;
        background: #fff;
      }
    }
    .toolbox_loading{
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
      border: 2px dashed #ADC6FF;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img{
        width: 48px;
        height: 48px;
        animation: rotate 1.5s linear infinite;
      }
      @keyframes rotate {
        to{
              transform: rotate(360deg);
          }
      }
      p:nth-of-type(1){
        margin: 12px 0 16px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #1F2126;
      }
      p:nth-of-type(2){
        font-size: 14px;
        line-height: 22px;
        color: #ACB1BF;
      }
    }
    .handle_before{
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
      border-radius: 16px;    
      display: flex;
      justify-content: center;
      align-items: center;
      .image{
        width: 802px;
        height: 360px;    
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          max-height: 100%;
          max-width: 100%;
          border-radius: 16px;
        }
      }
    }
    .handling{
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
      border: 2px dashed #ADC6FF;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img{
        width: 48px;
        height: 48px;
        animation: rotate 1.5s linear infinite;
      }
      @keyframes rotate {
        to{
              transform: rotate(360deg);
          }
      }
      p:nth-of-type(1){
        margin: 12px 0 16px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #1F2126;
      }
      p:nth-of-type(2){
        font-size: 14px;
        line-height: 22px;
        color: #ACB1BF;
      }
    }
    .result{
      margin-top: 79px;
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
      .res_title{
        margin-bottom: 32px;
        span{
          display: inline-block;
          width: 74px;
          height: 30px;
          border-radius: 999px;
          background: linear-gradient(161deg, #ECF2FD -22%, #F3EFFD 214%);
          padding: 4px 16px;
          gap: 10px;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          color: #2F54EB;
          box-sizing: border-box;
        }
      }
      .res_title2{
        span{
          background: #3D87FF;
          color: #fff;
        }
      }
      .original{
        width: 360px;
        height: 360px;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          max-height: 100%;
          max-width: 100%;
          border-radius: 16px;
        }
      }
      .res_left{
        margin-right: 66px;
      }
      .res_right{

      }
    }
  }
  
  .pdf_img{
    min-height: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img{
      width: 192px;
    }
    p{
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: #646873;
    }
  }
  .res_btns{
    margin-top: 34px;
    text-align: center;
    .btn{
      width: 120px;
      height: 38px;
      border-radius: 999px;
      padding: 8px 32px;
      gap: 8px;
      background: #FFFFFF;
      box-sizing: border-box;
      border: 1px solid #DADDE5;
      box-shadow: 0px 1.5px 0px 0px #F5F6F7;
      font-size: 14px;
      line-height: 22px;
      color: #3D87FF;
    }
    .btn2{
      background: #1467FF;
      color: #FFFFFF;
      border: none;
    }
  }
  .handle_btns{
    text-align: right;
    .handle_btn{
      width: 173px;
      height: 48px;
      border-radius: 999px;
      background: linear-gradient(166deg, #2E61FC -41%, #6185FC 195%);
      box-shadow: 0px 1.5px 0px 0px #E6EAF0;
      color: #fff;
        img {
          width: 18px;
          height: 18px;
          margin-left: 6px;
          position: relative;
          top: 3px;
        }
        .cash{
          margin-left: 12px;
        }
        .cash2{
          margin-left: 12px;
          font-size: 14px;
          color: #fff;
          font-weight: normal;
        }
    }
    .dis_btn{
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), linear-gradient(166deg, #2E61FC -41%, #6185FC 195%);
    }
  }
  .tool_privacy{
    font-size: 14px;
    line-height: 22px;
    color: #9498A6;
    margin-top: 16px;
  }
}
</style>
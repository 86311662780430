<template>
  <div class="comic">
    <div class="comic_title">
      <p>人像动漫化</p>
      <p>量身定制千人千面的二次元动漫形象，并支持设置生成戴口罩的二次元动漫人像</p>
    </div>
    <div
      class="comic_container"
      :class="showUpload || uploadLoading ? 'dashed' : !showUpload && !uploadLoading ? 'h_524' : ''"
    >
      <div
        v-if="showUpload"
        class="nodata"
      >
        <el-upload
          :action="'/attachments/img/upload'"
          list-type="picture"
          accept=".jpg,.png"
          :show-file-list="false"
          :auto-upload="true"
          :on-success="handleSuccess"
          :before-upload="beforeUpload"
          :on-error="handleError"
          :drag="true"
          class="upload"
        >
          <div
            class="upload_img"
          >
            <img src="../../static/ai/upload2.png">
            <p>拖拽或点击以上传图片</p>
            <p>图片类型支持PNG、JPG、JPEG、BMP，大小不超过8M。</p>
          </div>
        </el-upload>
      </div>
      <div
        v-if="uploadLoading"
        class="comic_loading"
      >
        <img src="../../static/ai/loading3.png">
        <p>正在上传...</p>
        <p>请耐等待文件上传完成</p>
      </div>
      <div v-if="!showUpload && !uploadLoading">
        <div
          v-if="!showResult"
          class="result"
        >
          <div class="res_left">
            <div
              v-if="!making"
              class="res_title"
              style="margin-top: 63px;"
            >
              <span>已上传</span>
            </div>
            <div
              v-if="making"
              class="res_title3"
              style="margin-top: 83px;"
            >
              <span>正好在努力处理中...</span>
            </div>
            <div class="original">
              <img
                src=""
                @load="handleLoad"
              >
              <div
                v-if="making"
                ref="makingMask"
                class="making_mask"
              />
            </div>
          </div>
        </div>
        <div
          v-if="showResult"
          class="result"
        >
          <div
            class="res_left"
            style="margin-top: 73px;"
          >
            <div class="res_title">
              <span>原图</span>
            </div>
            <div class="original">
              <img
                src=""
                alt=""
              >
            </div>
          </div>
          <div style="margin-top: 73px;">
            <div class="res_title res_title2">
              <span>处理后</span>
            </div>
            <div class="original">
              <img
                src=""
                alt=""
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!showUpload && !uploadLoading"
      class="comic_bottom"
    >
      <div>
        <span class="radio_label">口罩设置：</span>
        <el-radio-group v-model="radio">
          <el-radio :label="1">
            不戴口罩
          </el-radio>
          <el-radio :label="2">
            戴口罩
          </el-radio>
        </el-radio-group>
      </div>
      <div class="res_btns">
        <el-button
          v-if="!showResult"
          class="btn3"
          :class="making ? 'dis_btn' : ''"
          :disabled="making"
        >
          开始制作
        </el-button>
        <div v-if="showResult">
          <el-button class="btn">
            重新上传
          </el-button>
          <el-button class="btn btn2">
            下载
          </el-button>
        </div>
      </div>
    </div>
    <p
      v-show="showUpload || uploadLoading"
      class="tool_privacy"
    >
      *为保护您的隐私，已上传文件将会在一小时后删除
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showUpload: false,
      uploadLoading: false,
      showResult: true,
      making: true,
      file: '',
      process: '',
      radio: 1
    }
  },
  mounted () {
    this.process = process.env.NODE_ENV

  },
  methods:{
    handleLoad(e){
      console.log(this.$refs.makingMask,e.target.offsetHeight,e.target.offsetWidth)
      this.$refs.makingMask.style.width =  e.target.offsetWidth + 'px'
      this.$refs.makingMask.style.height =  e.target.offsetHeight + 'px'
    },
    handleError() {
      this.$message.error({message: '上传失败，请重试', duration: 2000})
    },
    beforeUpload(file) {
      if(!this.$store.state.isLogin){
        this.$store.commit('SET_LOGINDISLOG', true)
        return false
      }
      const isPNG = file.type == 'image/png'
      const isJPG = file.type == 'image/jpg'
      const isJPEG = file.type == 'image/jpeg'
      if (file.size > 1024 * 1024 * 3) {
        this.$message.error({message: '图片太大，请上传 3MB 以下的图片', duration: 2000})
        return false
      }
      if (!isJPG && !isPNG && !isJPEG) {
        this.$message.error({message: '不支持JPG/PNG以外的格式，请重试', duration: 2000})
        return false
      }
      this.uploadLoading = true
      this.showBG = true
      this.file = ''
    },
    //
    handleSuccess(res, file, fileList) {
      this.file = res
      this.uploadLoading = false
      this.showBG = false
      console.log('this.file', this.file)
    },
  }
}
</script>

<style lang="less">
.comic{
  width: 100%;
  height: 100%;
  color: #1F2126;
  font-family: '思源黑体';
  .comic_title{
    display: flex;
    align-items: flex-end;
    p:nth-of-type(1){
      font-size: 28px;
      font-weight: 500;
      line-height: 36px;
      margin-right: 16px;
    }
    p:nth-of-type(2){
      font-size: 14px;
      line-height: 22px;
      color: #7A7F8C;
    }
  }
  .dashed{
    border: 2px dashed #CACDD9;
  }
  .h_524{
    height: 524px !important;
  }
  .comic_container{
    width: 100%;
    height: 558px;
    margin-top: 16px;
    background: #fff;
    box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
    .nodata{
      width: 100%;
      height: 100%;
      .upload, .upload .el-upload, .upload .el-upload .el-upload-dragger{
        width: 100%;
        height: 100%;
      }
      .el-upload-dragger{
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 24px;
        background: transparent;
      }
      .upload_img{
        img{
          width: 120px;
          height: 120px;
        }
      }
      p:nth-of-type(1){
        font-size: 20px;
        font-weight: 500;
        color: #1F2126;
        margin: 12px 0 16px;
        line-height: 28px;
      }
      p:nth-of-type(2){
        font-size: 14px;
        line-height: 22px;
        color: #9498A6;
      }
      .uploaded_pic{
        max-height: 100%;
        max-width: 100%;
        border-radius: 24px;
        object-fit: contain;
      }
      .el-loading-mask{
        border-radius: 24px;
        background: #fff;
      }
    }
    .comic_loading{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img{
        width: 48px;
        height: 48px;
        animation: rotate 1.5s linear infinite;
      }
      @keyframes rotate {
        to{
              transform: rotate(360deg);
          }
      }
      p:nth-of-type(1){
        margin: 12px 0 16px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #1F2126;
      }
      p:nth-of-type(2){
        font-size: 14px;
        line-height: 22px;
        color: #ACB1BF;
      }
    }
    .result{
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
      .res_title{
        margin-bottom: 28px;
        span{
          display: inline-block;
          width: 74px;
          height: 30px;
          border-radius: 999px;
          background: linear-gradient(161deg, #ECF2FD -22%, #F3EFFD 214%);
          padding: 4px 16px;
          gap: 10px;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          color: #2F54EB;
          box-sizing: border-box;
        }
      }
      .res_title2{
        span{
          background: #3D87FF;
          color: #fff;
        }
      }
      .res_title3{
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: #1F2126;
        margin-bottom: 16px;
      }
      .original{
        width: 300px;
        height: 300px;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        img{
          max-height: 100%;
          max-width: 100%;
          border-radius: 16px;
        }
        .making_mask{
          position: absolute;
          top: 0;
          z-index: 2;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
          border-radius: 16px;
        }
      }
      .res_left{
        margin-right: 66px;
      }
      .res_right{

      }
    }
  }
  .comic_bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    .res_btns{
      .btn{
        margin-top: 29px;
        width: 120px;
        height: 38px;
        border-radius: 999px;
        padding: 8px 32px;
        gap: 8px;
        background: #FFFFFF;
        box-sizing: border-box;
        border: 1px solid #DADDE5;
        box-shadow: 0px 1.5px 0px 0px #F5F6F7;
        font-size: 14px;
        line-height: 22px;
        color: #3D87FF;
      }
      .btn2{
        background: #1467FF;
        color: #FFFFFF;
        border: none;
      }
      .btn3{
        margin-top: 24px;
        width: 187px;
        height: 48px;
        border-radius: 999px;
        padding: 12px 32px;
        gap: 5px;
        background: #1467FF;
        box-shadow: 0px 1.5px 0px 0px #E6EAF0;
        color: #fff;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
      }
      .dis_btn{
        border: 1px solid #CACDD9;
        background: #F2F4F7;
        color: #ACB1BF;
        box-shadow: none;
      }
    }
    .el-radio-group{
      margin-left: 16px;
    }
    .el-radio__input.is-checked+.el-radio__label, .el-radio__label{
      color: #1F2126;
    }
    .radio_label{
      font-size: 14px;
      font-weight: 500;
      color: #1F2126;
      position: relative;
      top: -2px;
    }
  }
  .tool_privacy{
    font-size: 14px;
    line-height: 22px;
    color: #9498A6;
    margin-top: 16px;
  }
}
</style>
<template>
  <div class="aiTop">
    <span class="title">新对话<span
      class="incantation"
      @click="checkIncantation"
    >查看咒语</span></span>
    <!-- <span class="case">查看案例</span> -->
    <el-select
      v-model="value"
      placeholder="请选择"
      @change="changeValue"
    >
      <el-option
        v-for="item in versions"
        :key="item.code"
        :label="item.desc"
        :value="item.code"
      />
    </el-select>
    <!-- <div class="dialog">

    </div> -->
    <el-dialog
      title="自由对话-咒语（Prompt）合集"
      :visible.sync="visible"
      top="80px"
      width="1200px"
    >
      <div class="dialog">
        <div class="left">
          <div
            v-scrollActive
            class="tree"
          >
            <div
              v-for="e in menuList"
              :key="e.categoryNo"
              class="firstLevel"
            >
              <div
                class="label first"
                @click="handleExpand(e)"
              >
                <span>{{ e.categoryName }}</span>
                <i
                  v-show="e.expand"
                  class="el-icon-arrow-down"
                />
                <i
                  v-show="!e.expand"
                  class="el-icon-arrow-up"
                />
              </div>
              <div
                v-for="item in e.aiPromptsCategoryVos"
                v-show="e.expand"
                :key="item.categoryNo"
                class="secondLevel"
              >
                <div
                  class="label second"
                  @click="handleChange(item)"
                >
                  <span :style="{color: item.categoryNo == checkedId ? '#3D87FF' : '#646873'}">{{ item.categoryName }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-scrollActive
          class="right"
        >
          <div
            v-for="item in contentList"
            :key="item.sort"
            class="item"
          >
            <div class="label">
              <span>{{ item.title }}</span>
            </div>
            <div class="content">
              {{ item.content }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { promptsList } from '@/api/index'

export default {
  name:'AiTop',
  data(){
    return{
      value:  '',
      visible: false,
      menuList: [],
      contentList: [],
      checkedId: ''
    }
  },
  computed:{
    versions(){
      return this.$store.state.versions
    }
  },
  async mounted(){
    await this.$store.dispatch('getVersions').then(res => {
      // this.value = 'gpt-3.5-16K'
      this.value=this.$store.state.versions[0].code
      this.$store.commit('SET_VERSION', this.value)
    })
    this.menuList = [
      {
        label: '一级分类',
        id: 1,
        expand: true,
        children: [
          {
            label: '二级分类',
            id: 2,
          },
          {
            label: '二级分类',
            id: 3,
          },
          {
            label: '二级分类',
            id: 4,
          },
        ]
      },
      {
        label: '一级分类',
        id: 5,
        expand: true,
        children: [
          {
            label: '二级分类',
            id: 6,
          },
          {
            label: '二级分类',
            id: 7,
          },
          {
            label: '二级分类',
            id: 8,
          },
        ]
      },
    ]
  },
  methods:{
    getList() {
      promptsList().then(res => {
        var data = res.data
        data.forEach(item => {
          this.$set(item, 'expand', true)
        })
        this.menuList = data
        this.checkedId = this.menuList[0].aiPromptsCategoryVos[0].categoryNo
        this.contentList = this.menuList[0].aiPromptsCategoryVos[0].aiPromptsVos
      })
    },
    handleExpand(val) {
      val.expand = !val.expand
    },
    handleChange(val) {
      console.log('val', val)
      this.checkedId = val.categoryNo
      this.contentList = val.aiPromptsVos
    },
    checkIncantation() {
      this.visible = true
      this.getList()
    },
    changeValue(){
      this.$store.commit('SET_VERSION',this.value)
    }
  }
}
</script>

<style lang='less'>

.aiTop{
 display: flex;
 justify-content: space-between;
 border-bottom: 1px solid #DADDE5 ;
 margin:0px 32px;
 padding-bottom: 24px;
  .title{
    display: inline-block;
    font-family: Source Han Sans CN;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    position: relative;
    padding-left:16px;
    /* Grey/10 */
    color: #1F2126;
    &::before{
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      top: 4px;
      width: 8px;
      height: 20px;
      border-radius: 2px;
      opacity: 1;

      /* Main/06 */
      background: #1467FF;
    }
    .incantation{
      color: #1467FF;
      cursor: pointer;
      font-size: 14px;
      margin-left: 12px;
    }
 }
 .el-input__inner{
    width: 106px;
height: 32px;
border-radius: 6px;
opacity: 1;

/* 自动布局 */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 0px 16px;
gap: 8px;

box-sizing: border-box;
/* Grey/2 */
border: 1px solid #DADDE5;
font-family: Source Han Sans CN;
font-size: 14px;
font-weight: normal;
 
letter-spacing: 0em;

/* Grey/10 */
color: #1F2126;
 }
 .el-select__caret{
   color: #1F2126 !important; 
 }
 .el-input__icon{
    line-height: 22px !important;
 }
//  .dialog{
//   position: absolute;
//   top: 80px;
//  }
  .el-dialog {
    border-radius: 16px !important;
    height: calc(100vh - 160px);
    overflow-y: hidden !important;
  }
  .el-dialog__header{
    margin: 32px 24px 0px 32px;
    border-bottom: 1px solid #DADDE5;
    padding-bottom: 16px !important;
    font-family: Source Han Sans CN;
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;
    color: #1F2126;
  }
  .el-dialog__headerbtn{
    right: 30px;
    top: 32px;
  }
  .el-dialog__headerbtn .el-dialog__close {
    color: #646873;
  }
  .el-dialog__body{
    padding: 0px 0 0px 32px !important;
    height: calc(100% - 75px);
  }
  .dialog{
      height: 100%;
      display: flex;
      ::-webkit-scrollbar-thumb {
        background: #DADDE5;
      }
    .left{
      width: 228px;
      height: calc(100% - 72px);
      margin: 16px 24px 32px 0px;
      background: #F8F9FC;
      border-radius: 12px;
      padding: 24px 0 0 24px ;
      .tree{
        width: calc(100% - 24px);
        height: 100%;
        padding-right: 24px;
        overflow-y: scroll;
        .label{
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
        }
        .firstLevel:first-child{
          padding-top: 0;
        }
        .firstLevel:last-child{
          border-bottom: 0;
        }
        .firstLevel{
          font-family: Source Han Sans CN;
          font-size: 16px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0em;
          color: #1F2126;
          padding: 20px 0;
          border-bottom: 1px solid #DADDE5;
          .first{
            // margin-bottom: 20px;
          }
          .secondLevel{
            font-family: Source Han Sans CN;
            font-size: 14px;
            font-weight: normal;
            line-height: normal;
            letter-spacing: 0em;
            color: #646873;
            .second{
              margin: 16px 0;
            }
            .second:first-child{
              margin-top: 20px;
            }
            .second:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .right{
      height: 100%;
      overflow-y: scroll;
      flex: 1;
      margin: 0;
      .item{
        margin: 32px 16px 32px 0;
        .label{
          font-family: Source Han Sans CN;
          font-size: 22px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0em;
          color: #1F2126;
          margin-bottom: 16px;
        }
        .label::before{
          content: '';
          width: 6px;
          height: 20px;
          border-radius: 2px;
          background: #1F2126;
          display: inline-block;
          top: 3px;
          position: relative;
          margin-right: 8px;
        }
        .content{
          padding: 16px;
          background: #F8F9FC;
          border-radius: 8px;
          color: #1F2126;
          font-size: 14px ;
        }
      }
    }
  }
}
</style>
<template>
  <el-dialog
    class="no_times"
    :visible.sync="noTimesVisible"
    width="402px"
    :before-close="handleClose"
    :show-close="false"
    top="30vh"
    :title="type == 'gold' ? '金豆已用完，无法使用' : type == 'times' ? '套餐剩余次数不足，无法生成': '套餐次数和金豆不足，无法生成'"
    append-to-body
  >
    <span class="text">{{ type == 'gold' ? '您的金豆已用完，请开通会员后使用' : type == 'times' ? '您的套餐剩余次数不足，您可以续费后使用' : '您可以续费会员后使用' }}</span>
    <div class="notice_btns f jc">
      <el-button
        class="cancel_btn"
        @click="handleClose"
      >
        取消
      </el-button>
      <el-button
        v-if="type == 'times'"
        class="button gold"
        @click="handleToRecharge"
      >
        使用金豆<span style="margin-left: 4px;">-{{ num }}</span>
        <img
          src="@/static/index/money.png"
          alt=""
        >
      </el-button>
      <el-button
        class="button"
        @click="handleToRecharge"
      >
        {{ type == 'gold' ? '开通会员' : '续费会员' }}
      </el-button>
    </div>
    <Recharge
      ref="recharge"
      :dialog-visible.sync="dialogVisible"
      :product-list="productList"
      :way="way"
      :from="'noTimes'"
    />
  </el-dialog>
</template>

<script>
import Recharge from "@/pages/user/common/Recharge";
import { getProductType, productList } from "@/api/index";
export default {
  components: {
    Recharge,
  },
  props: {
    noTimesVisible: { type: Boolean, default: true },
  },
  data() {
    return {
      dialogVisible: false,
      type: 'times',
      productUid: '',
      way: '',
      num: '',
      productList: [],
    };
  },
  mounted() {

  },
  methods: {
    handleClose() {
      this.$emit("update:noTimesVisible", false);
    },
    handleToRecharge() {
      if(this.type == 'gold' || this.type == 'nothing'){
        this.way = this.type == 'gold' ? '开通会员' : '续费会员'
        productList().then(res => {
          this.productList = res.data
          this.productList.sort(function(a, b) {
            return a.membershipType.slice(3,4) - b.membershipType.slice(3,4)
          })
          console.log('this.productList[0].productUid', this.productList[0].productUid)
          this.$refs.recharge.productUid = this.productList[0].productUid
          this.$refs.recharge.money = this.productList[0].timeRuleVOS[0].practicalPrice
          this.$refs.recharge.productTimeRule = this.productList[0].timeRuleVOS[0].value
          this.dialogVisible = true
        })
        this.dialogVisible = true;
      } else {
        this.$emit("update:noTimesVisible", false);
        this.$parent.send()
      }
    },
  },
};
</script>
<style lang='less'>
.no_times {
  .el-dialog {
    border-radius: 12px !important;
    padding: 24px 32px;
    .el-dialog__header {
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      color: #3D3D3D;
    }
    .el-dialog__body{
      padding: 20px 0 0 0 ;
    }
    .text {
      display: block;
      text-align: center;
      font-size: 14px;
      color: #3D3D3D;
    }
    .notice_btns{
      text-align: center;
      margin-top: 40px;
      .button{
        width: 88px;
        height: 36px;
        border-radius: 8px;
        cursor: pointer;
        padding: 8px 0;
        gap: 10px;
        background: #1467FF;
        font-size: 14px;
        color: #FFFFFF;
      }
      .gold{
        width: 114px;
        img{
          width: 12px;
          height: 12px;
          position: relative;
          top: 1px;
          left: 2px;
        }
      }
      .cancel_btn{
        width: 88px;
        height: 36px;
        border-radius: 8px;
        gap: 10px;
        background: #FFFFFF;
        border: 1px solid #DADDE5;
        color: #1F2126;
        font-size: 14px;
      }
    }
    
  }
}
</style>
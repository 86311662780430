<template>
  <div class="order">
    <div class="title">
      <span class="text-right">我的订单</span>
      <span class="text-left" />
    </div>
    <div
      v-scrollActive
      class="orderContent"
    >
      <el-table
        :data="tableData"
        style="width: 100%"
        header-row-class-name="qyTable"
        row-class-name="orderCell"
      >
        <el-table-column
          prop="info"
          label="订单信息"
          width="325"
        >
          <template slot-scope="scope">
            <div class="item">
              <p class="ordertext">
                <span>订单号</span>{{ scope.row.orderUid }}
              </p>

              <div class="info">
                <div class="icon">
                  <img
                    v-if="scope.row.productType == 'RECHARGE_BEAN_QUANTITY'"
                    src="@/static/index/money.png"
                  >
                  <img
                    v-if="scope.row.productType == 'RECHARGE_PLUS_MEMBERSHIP'"
                    src="@/static/user/plus.png"
                  >
                  <img
                    v-if="scope.row.productType == 'RECHARGE_MAX_MEMBERSHIP' || scope.row.productType == 'RECHARGE_MONTHLY_MEMBERSHIP' || scope.row.productType == 'RECHARGE_YEAR_MEMBERSHIP' || scope.row.productType == 'RECHARGE_PERMANENT_MEMBERSHIP'"
                    src="@/static/user/max.png"
                  >
                </div>
                <div class="right">
                  <p>{{ scope.row.productTypeName }}</p>
                  <p>X<span>{{ scope.row.materialNum }}</span></p>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="info"
          label="订单金额"
        >
          <template slot-scope="scope">
            <div class="item">
              <p class="ordertext">
                <span />
              </p>
              <div class="con">
                ￥{{ scope.row.orderAmount }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="info"
          label="订单状态"
          width="142"
        >
          <template slot-scope="scope">
            <div class="item">
              <p class="ordertext">
                <span />
              </p>
              <div class="con">
                <div class="context">
                  <p>{{ scope.row.orderStatusDesc }}</p>
                  <p>
                    <i
                      v-show="scope.row.expirationTimeDesc"
                      class="el-icon-time"
                    />{{ scope.row.expirationTimeDesc }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="info"
          label="订单操作"
          width="174"
        >
          <template slot-scope="scope">
            <div class="item">
              <p class="ordertext">
                <span>{{ scope.row.createDateTimeDesc }}</span>
              </p>
              <div class="con">
                <div class="context">
                  <div
                    v-if="scope.row.orderStatus!='DONE' && scope.row.orderStatus!='CANCEL'"
                    class="zf"
                    @click="submit(scope.row)"
                  >
                    立即支付
                  </div>
                  <div
                    v-if=" scope.row.orderStatus!='DONE' && scope.row.orderStatus!='CANCEL'"
                    class="qx"
                    @click="cancel(scope.row.orderUid)"
                  >
                    取消订单
                  </div>
                  <!-- <el-popconfirm title="确定删除吗？" @confirm=" cancel(scope.row.orderUid)">
                    <div class="qx">
                      取消订单
                    </div>
                  </el-popconfirm> -->
                </div>
                <div
                  v-if="scope.row.orderStatus=='CANCEL' || scope.row.orderStatus=='DONE'"
                  class="context"
                >
                  <div
                    class="qx"
                    @click="delData(scope.row.orderUid)"
                  >
                    删除订单
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--   @size-change="handleSizeChange" -->
    <div style="text-align: center; padding-top: 10px">
      <el-pagination
        :current-page="pageNum"
        :page-size="pageSize"
        layout=" prev, pager, next, jumper"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </div>
    <cancel-dialog
      content="该订单包含限时优惠，是否取消订单"
      :loading.sync="loading"
      :center-dialog-visible.sync="centerDialogVisible"
      :title="title"
      @submitcancel="submitCancel"
    />
    <cancel-dialog
      content="确定删除订单"
      :loading.sync="loading"
      :center-dialog-visible.sync="centerDialogVisible1"
      :title="title"
      @submitcancel="submitCancel"
    />
    <qrcode
      :state-code="stateCode"
      :pay-amount="payAmount"
      :pay-bill-uid="payBillUid" 
      :inner-visible.sync="innerVisible"
    />
  </div>
</template>

<script>
import qrcode from "./common/qrcode"
import { orderPage, paymentqrcode, cancelOrder,removeOrder } from "@/api/index";
import cancelDialog from '@/pages/user/common/cancelDialog'
import CancelDialog from './common/cancelDialog.vue';
 
export default {
   components: {
    qrcode,
    cancelDialog,
      CancelDialog
    
  },
  data() {
    return {
      title:"",
      centerDialogVisible:false,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      payAmount:'',
      loading:false,
      stateCode:'',
      innerVisible:false,
      payBillUid:"",
      centerDialogVisible1:false,
      id:'',
      tableData: [],
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    getlist() {
      orderPage({ pageNum: this.pageNum, pageSize: this.pageSize }).then(
        (res) => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      );
    },
    handleCurrentChange(e) {
      this.pageNum=e
      this.getlist();
    },
    delData(id){
      removeOrder
      this.title="确认删除订单"
      this.centerDialogVisible1=true
      this.id=id
    },
    cancel(id) {
      this.title="确认取消订单"
      this.centerDialogVisible=true
      this.id=id
    
    },
    submitCancel(){
      if(this.centerDialogVisible1){
      removeOrder(this.id).then(res=>{
        if (res.data) {
          this.$message({
          message: "删除成功",
          type: "success",
          customClass: "tiv",
        })
        this.centerDialogVisible1=false
        this.loading=false
        this.getlist()
        } else {
          this.$message({
            message: "删除失败",
            type: "warning",
            customClass: "tiv",
          })
          this.centerDialogVisible1=false
          this.loading=false
        }
      }).catch(err => {
        this.centerDialogVisible1=false
        this.loading=false
      })
      }else{
              
        cancelOrder(this.id).then((res) => {
        if (res.data) {
          this.$message({
          message: "取消成功",
          type: "success",

          customClass: "tiv",
                         
        })
        this.centerDialogVisible=false
        this.loading=false
        this.getlist()
        }
      });
   
      }

   },
    submit(item){
       let data2={payToolType: 'WECHAT_PAY',orderUid:item.orderUid}
       
          paymentqrcode(data2).then(res=>{
        
          this.payAmount=res.data.payAmount
              this.stateCode=res.data.qrCodeUrl
              this.payBillUid=res.data.payBillUid
                this.innerVisible=true
          })
    }
  },
};
</script>

<style lang="less">
.order {
  padding: 32px 0px;
  height: calc(100% - 64px);
  overflow-y: hidden;
  .title {
    width: 720px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    .text-right {
      position: relative;
      font-family: Source Han Sans CN;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      padding-left: 10px;
      /* Grey/10 */
      color: #1f2126;
      &::before {
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -7px;
        content: "";
        width: 4px;
        height: 14px;
        border-radius: 999px;
        opacity: 1;

        /* Grey/10 */
        background: #1f2126;
      }
    }
  }
  .orderContent {
    width: 729px;
    margin: 0 auto;
    height: calc(100% - 80px);
    overflow-y: scroll;
    .orderCell {
      border: solid 1px #e6e9f0;
      border-radius: 12px;
      td {
        &:first-child {
          .cell {
            border-width: 1px 0px 1px 1px;
            border-style: solid;
            border-color: #e6e9f0;
            border-radius: 12px 0px 0px 12px;
          }
        }
        &:nth-child(2) {
          .cell {
            border-width: 1px 0px 1px 0px;
            border-style: solid;
            border-color: #e6e9f0;
            border-radius: 0px;
          }
        }
        &:nth-child(3) {
          .cell {
            border-width: 1px 0px 1px 0px;
            border-style: solid;
            border-color: #e6e9f0;
            border-radius: 0px;
          }
        }
        &:last-child {
          .cell {
            border-width: 1px 0px 1px 0px;
            border-style: solid;
            border-color: #e6e9f0;
            border-radius: 0px 12px 12px 0px;
          }
        }
      }
      .cell {
        padding-left: 0px;
        padding-right: 0;
        height: 142px;

        .item {
          p {
            font-family: Source Han Sans CN;
            font-size: 12px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 0em;
            color: #1f2126;
            &.ordertext {
              background: #f2f4f7;
              padding: 6px 16px;
              height: 32px;
              box-sizing: border-box;
            }
            span {
              color: #7a7f8c;
              margin-right: 10px;
            }
          }
          .con {
            height: 109px;
            border-right: solid 1px #e6e9f0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            flex-wrap: wrap;
            .context {
              text-align: center;

              p {
                width: 100%;
                margin: 6px 0px;
                i {
                  margin-right: 5px;
                }
                &:last-child {
                  font-size: 12px;
                  color: #9498a6;
                }
              }
              .zf {
                font-size: 14px;
                font-weight: normal;
                cursor: pointer;
                letter-spacing: 0em;

                /* Main/06 */
                color: #1467ff;
                display: inline-block;
                background: #ffffff;
                width: 88px;
                height: 32px;
                box-sizing: border-box;
                border-radius: 6px;
                box-sizing: border-box;
                /* Grey/1.5 */
                border: 1px solid #e6e9f0;

                /* 按钮/标准 */
                box-shadow: 0px 1.5px 0px 0px #f5f6f7;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 12px;
              }
              .qx {
                cursor: pointer;
                color: #9498a6;
              }
            }
          }
          .info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            border-right: solid 1px #e6e9f0;
            .icon {
              width: 76px;
              height: 76px;
              border-radius: 8px;
              opacity: 1;

              /* Grey/0.5 */
              background: #f8f9fc;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 16px;
              img {
                width: 32px;
                height: 32px;
              }
            }
            .right {
              display: flex;
              flex-wrap: wrap;
              align-items: center;

              p {
                width: 100%;
                padding: 6px 0px;
                font-family: Source Han Sans CN;
                font-size: 14px;
                font-weight: normal;
                line-height: 22px;
                letter-spacing: 0em;

                /* Grey/10 */
                color: #1f2126;
                span{
                  font-size: 20px;
                  color: #1F2126;
                }
              }
            }
          }
        }
      }
    }
    .qyTable {
      height: 42px;
            th.el-table__cell>.cell{
      text-align: center;
          font-family: Source Han Sans CN;
    }
      th {
        background-color: #f2f4f7;
        color: #1f2126;
        font-size: 14px;
        border-bottom: 1px solid #fff;
        font-weight: 500;
        &:first-child {
          border-radius: 8px 0px 0px 8px;
        }
        &:nth-child(4) {
          border-radius: 0px 8px 8px 0px;
        }
      }
    }
    .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
      border-bottom: solid 0px #fff;
    }
    .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background-color: #fff;
    }
    .el-table::before {
      height: 0px;
    }
  }
}
</style>
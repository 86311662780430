import { fetchEventSource } from '@microsoft/fetch-event-source';
import { Message } from 'element-ui'
import store from '../store';
import axios from 'axios'
//  import store from '../store';
let controller = new AbortController()
let signal = controller.signal
// let content = ''
// let roomUid=''
const mixinFetchDraw = {
  data () {
    return {
      drawData: {
        progress: '',
        imageUrl: ''
      },
      code: null
    }
  },
  methods: {
    getDrawBaseData (url, data) {
      axios.post(
        url,
        data,
        {
          timeout: 60000 * 10,
          headers: {
            "content-type": "application/json",
            accept: "text/event-stream"
          },
          onDownloadProgress: progressEvent => {
            const response = progressEvent.event.target.response;
            // console.log('response', response)
            const lines = response.split('data:').filter(line => !!line)
            // console.log('lines', lines)
            const lastLine = lines[lines.length - 1]
            console.log('lastLine', JSON.parse(lastLine))
            let data = JSON.parse(lastLine)
            this.code = data.code || null
            if (this.code != null && this.code != 200) {
              this.$bus.emit('DrawError', data?.message)
              return false
            }
            if (this.$refs.drawingBoard.drawDataTimer == null && this.$refs.drawingBoard.isAdd) {
              this.$refs.drawingBoard.getDrawData(data.taskUid)
            }
            // if (this.$refs.drawingBoard.isAdd) {
            //   this.drawData = JSON.parse(lastLine)
            // }
          }
        }
      ).then(response => {
        this.$store.dispatch("CheckLogin")
        this.handleChangeMode()
        this.code = response.data.code || null
        if (this.code != null && this.code != 200) {
          this.$bus.emit('DrawError', response.data.message)
        }
      }).catch(err => {
        this.code = err.response.status || null
        if (this.code != null && this.code != 200) {
        }
        this.$bus.emit('DrawError', err.response.data.message)
      })
    },
  },
}
export default mixinFetchDraw
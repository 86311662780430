<template>
  <el-dialog
    title=""
    :visible.sync="dialogVisible"
    width="1136px"
    custom-class="aiDialog"
    center
    :show-close="false"
  >
    <div class="dialogBox">
      <div class="dialogTitle">
        <span>案例标题</span
        ><i class="el-icon-close close" @click="handleClose"></i>
      </div>
      <swiper :options="swiperOption" ref="aiSwiper" class="swiper-no-swiping">
        <swiper-slide>I'm Slide 1</swiper-slide>
        <swiper-slide>I'm Slide 2</swiper-slide>
      </swiper>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </div>

    <span slot="footer" class="dialog-footer"> </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      swiperOption: {
        // effect: "fade",

        autoplay: true,

        speed: 100,

        on: {
          paginationRender() {
            const pages = document.getElementsByClassName(
              "swiper-pagination-bullet"
            );
            for (let i = 0; i < pages.length; i++) {
              pages[i].onmouseover = function (e) {
                e.target.click();
              };
            }
          },
        },
      },
    };
  },
  methods: {
    handleClose() {
      this.$emit("update:dialogVisible", false);
    },
  },
};
</script>

<style lang="less">
.aiDialog {
  background: rgba(0, 0, 0, 0);
  .dialogBox {
    width: 960px;
    height: 560px;
    border-radius: 24px;
    margin: 0 auto;
    padding: 26px 64px;
    box-sizing: border-box;
    opacity: 1;

    background: #ffffff;
    .dialogTitle {
      text-align: center;
      padding-bottom: 26px;
      position: relative;
      .close {
        position: absolute;
        right: 0;
        top: 8px;
        cursor: pointer;
      }
      span {
        font-family: Source Han Sans CN;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0em;

        color: #3d3d3d;
      }
    }
    .swiper-container {
      height: calc(100% - 106px);
      background: #d8d8d8;
    }
    .swiper-button-prev {
      background-image: url("../../../static/index/arrow-l.png");
      background-size: 27px 27px;
      width: 53px;
      height: 53px;
      background-color: #fff;
      border-radius: 50% 50%;

      box-shadow: 0px 10px 20px 0px rgba(116, 169, 250, 0.2);
    }
    .swiper-button-next {
      background-image: url("../../../static/index/arrow-r.png");

      background-size: 27px 27px;
      width: 53px;
      height: 53px;
      background-color: #fff;
      border-radius: 50% 50%;
    }
  }
}
</style>
<template>
  <el-dialog
    :title="way == '升级会员' ? '升级成功' : way == '续费会员' ? '续费成功' : way == '开通会员' ? '开通成功' :'充值成功'"
    :visible.sync="sccessDialogVisible"
    width="418px"
    custom-class="successDialog"
    center
    :before-close="handClose"
    append-to-body
  >
    <div class="center">
      <p class="imgContent">
        <img src="@/static/user/success.png">
      </p>
      <p class="item">
        <span class="title">{{ way == '升级会员' ? '升级项目' : way == '续费会员' ? '续费项目' : way == '开通会员' ? '开通项目' :'充值数量' }}</span>
        <span class="text">
          <img
            v-if="way == '充值金豆'"
            src="@/static/index/money.png"
          >
          <img
            v-if="way != '充值金豆'"
            :src="require('@/static/user/' + (typeName == '永久会员' ? 'max.png' : 'plus.png'))"
          >
          <!-- {{ way == '充值金豆' ? jindouNum : (typeName + (productTimeRule == 'MONTHLY' ? '  1月' : '  1年' )) }} -->
          {{ typeName }}
        </span>
      </p>
      <p class="item">
        <span class="title">{{ way == '升级会员' ? '升级金额' : way == '续费会员' ? '续费金额' : way == '开通会员' ? '开通金额' :'充值金额' }}</span><span class="text">{{ payAmount }}</span>
      </p>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
     
      <el-button
        class="successButton"
        
       
        @click="handClose"
      >完成</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    sccessDialogVisible: { type: Boolean, default: false },
    title: { type: String, default: "充值成功" },
    payAmount:{type:String,default:''},
    jindouNum:{type:String,default:''},
    way: { type: String, default: "" },
    typeName: { type: String, default: "" },
    productTimeRule: { type: String, default: "" },
  },
  methods:{
    handClose(){
      this.$emit("update:sccessDialogVisible", false)
      this.$store.dispatch("GetUserInfo");
      window.location.reload()
    }
  }
}
</script>

<style lang="less">
.successDialog{
  
padding: 32px 24px;

/* Grey/0 */
background: #FFFFFF;
border-radius: 32px;
/* 卡片阴影 */
box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
.el-dialog__title{
    font-family: Source Han Sans CN;
font-size: 28px;
font-weight: 500;
line-height: 36px;
letter-spacing: 0em;

/* Grey/10 */
color: #1F2126;
}
.imgContent{
    text-align: center;
    img{
        width: 122px;
height: 100px;
margin: 0 auto;
    }
}
.item{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    align-items: center;
    img{
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    span{
        font-family: Source Han Sans CN;
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: center;
letter-spacing: 0em;

/* Grey/10 */
color: #1F2126;
img{
    width: 16px;
    height: 16px;
    margin-right: 8px;
}
    }
}
.successButton{
    width: 262px;
height: 38px;
border-radius: 999px;
    /* Geek Blue/01 */
background: #F0F5FF;
font-family: Source Han Sans CN;
font-size: 14px;
font-weight: 500;
line-height: 22px;
letter-spacing: 0em;
border: solid 1px #fff;
padding: 0px;
/* Main/06 */
color: #1467FF;
}
.el-dialog__body{
    padding:24px !important;
}
}
</style>
<template>
  <el-dialog
    width="480px"
    title=""
    :visible.sync="innerVisible"
    :before-close="handleClose"
    append-to-body
  >
    <div class="content">
      <success
        :sccess-dialog-visible.sync="success"
        :jindou-num="jindouNum"
        :pay-amount="payAmount"
        :way="way"
        :type-name="typeName"
        :product-time-rule="productTimeRule"
      />
      <cancelDialog
        :center-dialog-visible.sync="cancel "
        title="提示信息"
        content="确认取消支付吗"
        @submitcancel="submitcancel"
      />
      <p class="title">
        请使用微信扫码支付<img src="@/static/user/zf.png">
      </p>
      <p class="moneyText">
        ￥{{ payAmount }}
      </p>
      <div class="code">
        <div class="img">
          <div id="qrcode" />
          <!-- <el-image :src="QRImgUrl">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image> -->
        </div>
      </div>
      <p class="text">
        若遇到支付问题，请查看首页下方的联系方式
      </p>
    </div>
  </el-dialog>
</template>

<script>
// import QRCode from "qrcode";
import success from '@/pages/user/common/success'
import cancelDialog from './cancelDialog.vue'
import QRCode from "qrcodejs2";
import {  getPayBillUid } from "@/api/index";
export default {
  components:{
    cancelDialog,
    success
  },
  
  props: {
    innerVisible: { type: Boolean, default: false },
    stateCode: { type: String, default: "" },
    payBillUid: { type: String, default: "" },
    payAmount:{type:String,default:''},
    jindouNum:{type:String,default:''},
    content: {
      type: String,
      default: "测试二维码",
    },
    width: {
      type: String,
      default: "250",
    },
    height: {
      type: String,
      default: "250",
    },
    codeName: {
      type: String,
      default: "二维码",
    },
    load: {
      type: Boolean,
      default: true,
    },
    view: {
      type: Boolean,
      default: true,
    },
    way: { type: String, default: "" },
    typeName: { type: String, default: "" },
    productTimeRule: { type: String, default: "" },
  },
  data() {
    return {
      canvasId: "qrcode",
      QRImgUrl: "",
      timer: null,
      qrcode: "",
      success:false,
      cancel:false,
    };
  },
  watch: {
    innerVisible(value) {
      if (value) {
        this.$nextTick(()=>{
          this.init();
        })
        this.timer = setInterval(() => {
          getPayBillUid({ payBillUid: this.payBillUid }).then((res) => {
            if (res.data) {
              this.submitcancel();
              this.$parent.handleClose()
              this.success=true
              this.$store.dispatch("CheckLogin");
            }
          });
        }, 5000);
      }
    },
  },
  mounted() {
    //  this.init();
  },
   
  methods: {
    handleClose() {
      this.cancel=true
      // this.$emit("update:innerVisible", false);
      // clearInterval(this.timer);
      // document.getElementById("qrcode").innerHTML = ""
    },
  
    convertCanvasToImage() {
      let myCanvas = this.$refs.canvasId;
      let image = new Image();
      image.src = myCanvas.toDataURL("image/jpeg");
      document.getElementById("qrcode").innerHTML = "";
      document.getElementById("qrcode").appendChild(image);
    },
    submitcancel(){
       this.$emit("update:innerVisible", false);
       this.cancel=false
      clearInterval(this.timer);
      document.getElementById("qrcode").innerHTML = ""
    },

    init() {
      this.qrcode = document.getElementById("qrcode");
      console.log("000", document.getElementById("qrcode"));
      // console.log(url, '44544')
      let qrcode = new QRCode(this.qrcode, {
        width: 300, // 宽
        height: 300, // 高
        text: this.stateCode, // 二维码内容,也可以直接放接口获取的数据
        // render: 'canvas'     // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        background: "#f0f", // 自定义颜色默认黑白
        foreground: "#ff0",
      });
      console.log("999", qrcode);
    },
  
  },
};
</script>

<style lang='less'>
.content {
  .title {
    img {
      width: 36px;
      height: 36px;
      margin-left: 12px;
    }
    font-family: Source Han Sans CN;
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;

    /* Grey/10 */
    color: #1f2126;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .moneyText {
    font-family: Source Han Sans CN;
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    text-align: center;
    letter-spacing: 0em;
    margin-top: 24px;

    /* Grey/10 */
    color: #1f2126;
  }
  .code {
    position: relative;
    /* 自动布局 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 16px;
    box-sizing: border-box;
    /* Grey/0 */
    background: #ffffff;

    box-sizing: border-box;
    /* Grey/1.5 */
    border: 1px solid #e6e9f0;

    /* 卡片阴影 */
    box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
    width: 300px;
    height: 300px;
    border-radius: 24px;
    margin: 0 auto;
    margin-top: 32px;
  }
  .text {
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0em;
    padding-top: 24px;
    span {
      width: 100%;
      display: inline-block;
      margin: 27px 0px;
      /* Body/regular */
      font-family: Source Han Sans CN;
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      letter-spacing: 0em;

      /* Grey/10 */
      color: #1f2126;
    }

    /* Grey/4 */
    color: #acb1bf;
    a {
      text-decoration: underline;
      color: #acb1bf;
    }
  }
  .img {
    position: relative;
    img {
      width: 250px;
      height: 250px;
    }
    .code1 {
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 24px;
      flex-direction: column;
      img {
        height: 48px;
        width: 48px;
      }
      p {
        font-family: Source Han Sans CN;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;

        /* Grey/0 */
        color: #ffffff;
        margin-top: 12px;
      }
    }
  }
}
</style>
<template>
  <div class="compress">
    <div class="compress_title">
      <p>图片压缩</p>
      <p>智能压缩图片，将图片压缩为需要的大小、分辨率和格式</p>
    </div>
    <div
      class="compress_container"
    >
      <div
        v-if="showUpload"
        class="nodata"
      >
        <el-upload
          :action="'/attachments/img/bigUpload'"
          list-type="picture"
          accept=".jpg,.png,.bmp,.jpeg"
          :show-file-list="false"
          :auto-upload="true"
          :on-success="handleSuccess"
          :before-upload="beforeUpload"
          :on-error="handleError"
          :drag="true"
          class="upload"
        >
          <div
            class="upload_img"
          >
            <img src="../../static/ai/upload2.png">
            <p>拖拽或点击以上传文件</p>
            <p>图片类型支持PNG、JPG、JPEG、BMP，大小不超过8M。</p>
          </div>
        </el-upload>
      </div>
      <div
        v-if="uploadLoading"
        class="compress_loading"
      >
        <img src="../../static/ai/loading3.png">
        <p>正在上传...</p>
        <p>请耐等待文件上传完成</p>
      </div>
      <div
        v-if="!showUpload && !uploadLoading"
        class="result"
      >
        <div class="original">
          <img
            :src="process == 'production' ? file?.data?.aigoPreViewUrl : file?.data?.aigoPreViewUrl?.split('idcfengye.com')[1]"
            @load="handleLoad"
          >
          <div
            v-show="making && !showResult"
            ref="makingMask"
            class="making_mask"
          >
            <img
              src="@/static/ai/loading4.png"
              alt=""
            >
          </div>
        </div>
        <div class="desc">
          <p
            v-show="!making && !showResult"
            class="size"
          >
            图片大小：{{ originalSize }}MB
          </p>
          <p
            v-show="making && !showResult"
            class="size"
          >
            压缩中，请等待...
          </p>
          <p
            v-show="showResult"
            class="size"
          >
            压缩前：{{ originalSize }}MB
          </p>
          <p
            v-show="showResult"
            class="size size2"
          >
            压缩后：{{ afterSize }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="!showUpload && !uploadLoading"
      class="compress_bottom"
    >
      <div class="operate">
        <div class="op_tabs">
          <span class="label">压缩方式</span>
          <span
            :class="compressionModel == 'default' ? 'active' : ''"
            @click="handleChangeWay('default')"
          >默认推荐</span>
          <span
            :class="compressionModel == 'qualityModel' ? 'active' : ''"
            @click="handleChangeWay('qualityModel')"
          >指定比例</span>
          <span
            :class="compressionModel == 'sizeModel' ? 'active' : ''"
            @click="handleChangeWay('sizeModel')"
          >指定大小</span>
        </div>
        <div
          v-show="compressionModel != 'default'"
          class="op_card"
        >
          <div
            v-show="compressionModel == 'qualityModel'"
            class="op_item op_scale"
          >
            <span class="label">压缩比例</span>
            <div class="compress_scale">
              <div>
                <el-progress
                  :percentage="Number(scale)"
                  :show-text="false"
                />
              </div>
              <el-input
                v-model="scale"
                @input="handleInputScale"
              /><span>% (默认)</span>
            </div>
          </div>
          
          <div
            v-show="compressionModel == 'sizeModel'"
            class="op_item pic_size"
          >
            <span class="label">目标大小</span>
            <el-input
              v-model="targetSize"
              placeholder="填写数值"
              @input="handleInputTargetSize"
            />
            <span>KB</span>
          </div>
          <div class="op_size">
            <div class="op_item">
              <span class="label">输出尺寸</span>
              <div class="select">
                <el-select
                  v-model="scaleType"
                  placeholder="请选择"
                  @change="changeScaleType"
                >
                  <el-option
                    v-for="item in sizeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </div>
            </div>
            <div class="op_item">
              <span class="label">宽度(像素)</span>
              <el-input
                v-model="customWidth"
                :disabled="scaleType == 'default'"
                :placeholder="scaleType == 'default' ? '原宽度' : '输入数值'"
                @input="handleInputCustomWidth"
              />
            </div>
            <div class="op_item">
              <span class="label">高度(像素)</span>
              <el-input
                v-model="customHeight"
                :disabled="scaleType == 'default'"
                :placeholder="scaleType == 'default' ? '原宽度' : '输入数值'"
                @input="handleInputCustomHeight"
              />
            </div>
          </div>
          <div class="op_item">
            <span class="label">输出格式</span>
            <div class="select">
              <el-select
                v-model="imageFormat"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in formatList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!showResult"
        class="handle_btns"
      >
        <el-button
          class="handle_btn"
          :disabled="making"
          :class="making ? 'dis_btn' : ''"
          @click="handleSend"
        >
          开始处理
          <span
            v-if="chargerule?.userType == 'VIP0'"
            class="cash"
          >-{{ chargerule?.chargeNum }}<img src="@/static/index/money.png"></span>
          <span
            v-else
            class="cash2"
          >剩：{{ chargerule?.surplusNumDesc?.split('次数')[0] }}次</span>
        </el-button>
      </div>
      <div
        v-if="showResult"
        class="res_btns"
      >
        <el-button
          class="btn"
          @click="handleRenew"
        >
          重新上传
        </el-button>
        <el-button
          class="btn btn2"
          @click="handleDownload"
        >
          下载
        </el-button>
      </div>
    </div>
    <p
      v-show="showUpload || uploadLoading"
      class="tool_privacy"
    >
      *为保护您的隐私，已上传文件将会在一小时后删除
    </p>
    <noTimes
      ref="noTimes"
      :no-times-visible.sync="noTimesVisible"
    />
  </div>
</template>

<script>
import noTimes from "@/components/noTimes";
import mixinToolkitFench from "@/mixin/toolboxFench";
import { drawHotChargerule } from '@/api/index'

export default {
  components: {
    noTimes,
  },
  mixins: [mixinToolkitFench],
  data() {
    return {
      showUpload: true,
      uploadLoading: false,
      showResult: false,
      making: false,
      file: '',
      process: '',
      noTimesVisible: false,
      chargerule: '',
      compressionModel: 'default',
      scale: 75,
      formatList: [
        {label: '原格式(默认)', value: 'default'},
        {label: 'JPG', value: 'jpg'},
        {label: 'JPEG', value: 'jpeg'},
        {label: 'PNG', value: 'png'},
        {label: 'WEBP', value: 'webp'},
      ],
      imageFormat: 'default',
      sizeList: [
        {label: '原尺寸(默认)', value: 'default'},
        {label: '自定义', value: 'custom'},
      ],
      scaleType: 'default',
      customWidth: '',
      customHeight: '',
      originalSize: '',
      afterSize: '',
      compressData: '',
      fileType: '',
      targetSize: '',
    }
  },
  watch: {
    compressFetchData(val) {
      this.making = false
      if(val.data?.imageUrl != null){
        this.compressData = val.data
        this.uploadLoading = false
        this.showUpload = false
        this.showResult = true
        let size = Math.ceil(val.data.destImageSize / 1024)
        if(size < 1024){
          this.afterSize = size + 'KB'
        } else {
          this.afterSize =  Math.ceil(size / 1024) + 'MB'
        }
      } else {
      }
    },
  },
  mounted () {
    this.process = process.env.NODE_ENV
    this.getChargerule()
  },
  methods:{
    handleInputTargetSize(val) {
      this.targetSize = this.targetSize.replace(/[^\d]/g,'')
      if(this.targetSize.length <=1 && this.targetSize == '0' ){
        this.targetSize = ''
      }
      let imageSize = Number(this.file.data.imageSize) / 1024
      if(Number(this.targetSize) > imageSize){
        this.targetSize = ''
        this.$message.error({message: '自定义大小不能超过原图大小', duration: 2000})
      }
    },
    handleInputCustomWidth() {
      if(Number(this.customWidth) > this.file.data.imageWidth){
        this.customWidth = ''
        this.$message.error({message: '自定义宽度不能超过原图宽度', duration: 2000})
      }
      if(Number(this.customWidth) > 5000){
        if(this.customWidth.length == 4){
          this.customWidth = this.customWidth.slice(0,3)
        }
        if(this.customWidth.length == 5){
          this.customWidth = this.customWidth.slice(0,4)
        }
        this.$message.error({message: '自定义宽度像素大小只能在1-5000', duration: 2000})
      }
    },
    handleInputCustomHeight() {
      if(Number(this.customHeight) > this.file.data.imageHeight){
        this.customWidth = ''
        this.$message.error({message: '自定义高度不能超过原图高度', duration: 2000})
      }
      if(Number(this.customHeight) > 5000){
        if(this.customHeight.length == 4){
          this.customHeight = this.customHeight.slice(0,3)
        }
        if(this.customHeight.length == 5){
          this.customHeight = this.customHeight.slice(0,4)
        }
        this.$message.error({message: '自定义高度像素大小只能在1-5000', duration: 2000})
      }
    },
    handleInputScale(val) {
      this.scale = this.scale.replace(/[^\d]/g,'')
      if(this.scale.length >= 2){
        this.scale = this.scale.slice(0,2)
      }
      if(this.scale.length <=1 && this.scale == '0' ){
        this.scale = ''
      }
    },
    changeScaleType() {
      if(this.scaleType == 'default'){
        this.customWidth = ''
        this.customHeight = ''
      } else {
        this.customWidth = this.file.data.imageWidth
        this.customHeight = this.file.data.imageHeight
      }
    },
    getChargerule() {
      drawHotChargerule('TOOL_BOX').then(res => {
        this.chargerule = res.data
      })
    },
    handleRenew() {
      this.showUpload = true
      this.uploadLoading = false
      this.making = false
      this.showResult = false
      this.file = ''
      this.compressData = ''
      this.customWidth = ''
      this.customHeight = ''
      this.targetSize = ''
      this.scaleType = 'default'
      this.compressionModel = 'default'
      this.imageFormat = 'default'
      this.scale = 75
    },
    handleDownload() {
      window.open(this.compressData.downloadUrl)
    },
    handleChangeWay(val) {
      this.compressionModel = val
    },
    async handleSend() {
      if(!this.$store.state.isLogin){
        this.$store.commit('SET_LOGINDISLOG', true)
        return
      }
      if(this.chargerule.userType == 'VIP0' && this.chargerule.recharge){
        this.$refs.noTimes.type = 'gold'
        this.noTimesVisible = true
        return
      }
      if(this.chargerule.userType != 'VIP0' && this.chargerule.recharge && this.chargerule.defaultUseBenefitRule && !this.$store.state.chargerule.baseBenefitRuleVO.recharge){
        this.$refs.noTimes.type = 'times'
        this.$refs.noTimes.num = this.chargerule.baseBenefitRuleVO.chargeNum
        this.noTimesVisible = true
        return
      }
      if(this.chargerule.userType != 'VIP0' && this.chargerule.recharge && this.chargerule.baseBenefitRuleVO.recharge){
        this.$refs.noTimes.type = 'nothing'
        this.noTimesVisible = true
        return
      }
      this.send()
    },
    async send() {
      this.uploadLoading = false
      this.making = true
      var params
      switch (this.compressionModel) {
        case 'default':
          params = {
            benefitSubType: 'TOOLKIT_IMAGE_COMPRESSION',
            compressionModel: this.compressionModel,
            imageFileRequest: {
              encryOssNo: this.file.data.encryOssNo,
              fileSize: this.file.data.imageSize,
            },
          }
          break
        case 'qualityModel':
          params = {
            benefitSubType: 'TOOLKIT_IMAGE_COMPRESSION',
            compressionModel: this.compressionModel,
            imageFileRequest: {
              encryOssNo: this.file.data.encryOssNo,
              fileSize: this.file.data.imageSize,
            },
            qualityModelRequest: {
              quality: Number(this.scale),
              scaleType: this.scaleType,
              customWidth: this.customWidth ? Number(this.customWidth) : this.customWidth,
              customHeight: this.customHeight ? Number(this.customHeight) : this.customHeight,
              imageFormat: this.imageFormat,
            }
          }
          break
        case 'sizeModel':
          params = {
            benefitSubType: 'TOOLKIT_IMAGE_COMPRESSION',
            compressionModel: this.compressionModel,
            imageFileRequest: {
              encryOssNo: this.file.data.encryOssNo,
              fileSize: this.file.data.imageSize,
            },
            sizeModelRequest: {
              customSize: this.targetSize ? Number(this.targetSize) * 1024 : this.targetSize,
              scaleType: this.scaleType,
              customWidth: this.customWidth ? Number(this.customWidth) : this.customWidth,
              customHeight: this.customHeight ? Number(this.customHeight) : this.customHeight,
              imageFormat: this.imageFormat,
            }
          }
          break
      }
      await this.getCompressData('/ai/toolkit/send/imageCompression', params)
    },
    handleLoad(e){
      this.$refs.makingMask.style.width =  e.target.offsetWidth + 'px'
      this.$refs.makingMask.style.height =  e.target.offsetHeight + 'px'
    },
    handleError() {
      this.$message.error({message: '上传失败，请重试', duration: 2000})
    },
    beforeUpload(file) {
      if(!this.$store.state.isLogin){
        this.$store.commit('SET_LOGINDISLOG', true)
        return false
      }
      const isPNG = file.type == 'image/png'
      const isJPG = file.type == 'image/jpg'
      const isJPEG = file.type == 'image/jpeg'
      if (file.size > 1024 * 1024 * 8) {
        this.$message.error({message: '图片太大，请上传 8MB 以下的图片', duration: 2000})
        return false
      }
      if (!isJPG && !isPNG && !isJPEG) {
        this.$message.error({message: '不支持JPG/PNG以外的格式，请重试', duration: 2000})
        return false
      }
      this.fileType = file.type.split('/')[1]
      this.originalSize = (file.size / (1024*1024)).toFixed(1)
      this.uploadLoading = true
      this.showUpload = false
      this.file = ''
    },
    //
    handleSuccess(res, file, fileList) {
      this.file = res
      this.uploadLoading = false
      this.showBG = false
      console.log('this.file', this.file)
    },
  }
}
</script>

<style lang="less">
.compress{
  width: 100%;
  height: 100%;
  color: #1F2126;
  font-family: '思源黑体';
  .compress_title{
    display: flex;
    align-items: flex-end;
    p:nth-of-type(1){
      font-size: 28px;
      font-weight: 500;
      line-height: 36px;
      margin-right: 16px;
    }
    p:nth-of-type(2){
      font-size: 14px;
      line-height: 22px;
      color: #7A7F8C;
    }
  }
  .dashed{
  }
  .h_524{
    height: 524px !important;
  }
  .compress_container{
    width: 100%;
    // height: 558px;
    margin-top: 16px;
    background: #fff;
    box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
    border: 2px dashed #ADC6FF;
    border-radius: 16px;
    .nodata{
      width: 100%;
      height: 510px;
      .upload, .upload .el-upload, .upload .el-upload .el-upload-dragger{
        width: 100%;
        height: 100%;
      }
      .el-upload-dragger{
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 24px;
        background: transparent;
      }
      .upload_img{
        img{
          width: 120px;
          height: 120px;
        }
      }
      p:nth-of-type(1){
        font-size: 20px;
        font-weight: 500;
        color: #1F2126;
        margin: 12px 0 16px;
        line-height: 28px;
      }
      p:nth-of-type(2){
        font-size: 14px;
        line-height: 22px;
        color: #9498A6;
      }
      .uploaded_pic{
        max-height: 100%;
        max-width: 100%;
        border-radius: 24px;
        object-fit: contain;
      }
      .el-loading-mask{
        border-radius: 24px;
        background: #fff;
      }
    }
    .compress_loading{
      width: 100%;
      height: 510px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img{
        width: 48px;
        height: 48px;
        animation: rotate 1.5s linear infinite;
      }
      @keyframes rotate {
        to{
              transform: rotate(360deg);
          }
      }
      p:nth-of-type(1){
        margin: 12px 0 16px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #1F2126;
      }
      p:nth-of-type(2){
        font-size: 14px;
        line-height: 22px;
        color: #ACB1BF;
      }
    }
    .result{
      width: 100%;
      height: 338px;
      display: flex;
      // justify-content: center;
      text-align: center;
      flex-direction: column;
      align-items: center;
      .original{
        width: 560px;
        height: 200px;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-top: 46px;
        img{
          max-height: 100%;
          max-width: 100%;
          border-radius: 16px;
        }
        .making_mask{
          position: absolute;
          top: 0;
          z-index: 2;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
          border-radius: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            width: 48px;
            animation: rotate 1.5s linear infinite;
          }
          @keyframes rotate {
            to{
                  transform: rotate(360deg);
              }
          }
        }
      }
      .desc{
        .size{
          margin-top: 12px;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          color: #1F2126;
        }
        .size2{
          margin-top: 10px;
          color: #1467FF;
        }
      }
    }
  }
  .compress_bottom{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .operate{
      margin-top: 32px;
      height: 226px;
      .op_tabs{
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: #1F2126;
        span{
          display: inline-block;
          width: 88px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: #fff;
        }
        span:nth-of-type(1){
          font-weight: normal;
          margin-right: 8px;
          width: auto;
          background: transparent;
        }
        span:nth-of-type(2){
          border-radius: 6px 0px 0px 6px;
          border: 1px solid #DADDE5;
        }
        span:nth-of-type(3){
          border: 1px solid #DADDE5;

        }
        span:nth-of-type(4){
          border-radius: 0px 6px 6px 0px;
          border: 1px solid #DADDE5;
        }
        .active{
          background: #1467FF;
          color: #fff;
          border: 1px solid #DADDE5;
        }
      }
      .op_card{
        width: 620px;
        height: 180px;
        border-radius: 16px;
        background: #FFFFFF;
        margin-top: 20px;
        padding: 24px;
        box-sizing: border-box;
        .op_item{
          margin-bottom: 26px;
          display: flex;
          align-items: center;
          margin-right: 24px;
          .label{
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            color: #1F2126;
            margin-right: 8px;
            white-space: nowrap;
          }
          .compress_scale{
            display: flex;
            align-items: center;
            .el-progress-bar__outer{
              width: 184px;
            }
            .el-input__inner{
              width: 48px;
              height: 32px;
              border-radius: 6px;
              padding: 5px 12px;
              gap: 8px;
              background: #FFFFFF;
              box-sizing: border-box;
              border: 1px solid #DADDE5;
              margin: 0 8px 0 12px;
            }
            span{
              font-size: 14px;
              font-weight: normal;
              line-height: 22px;
              color: #1F2126;
              white-space: nowrap;
            }
          }
          .select{
            .el-input__inner{
              width: 136px;
              height: 26px;
              border-radius: 6px;
              gap: 8px;
              background: #FFFFFF;
              box-sizing: border-box;
              border: 1px solid #DADDE5;
              font-size: 14px;
              color: #646873;
            }
            .el-input__suffix{
              top: 7px;
            }
          }
        }
        .pic_size{
          .el-input__inner{
            width: 134px;
            height: 26px;
            border-radius: 6px;
            padding: 2px 12px;
            gap: 8px;
            background: #FFFFFF;
            box-sizing: border-box;
            border: 1px solid #DADDE5;
          }
          .el-input{
            width: 134px;
            margin-right: 8px;
          }
        }
        .op_size{
          display: flex;
          .el-input__inner{
            width: 88px;
            height: 26px;
            border-radius: 6px;
            padding: 2px 12px;
            gap: 8px;
            background: #FFFFFF;
            box-sizing: border-box;
            border: 1px solid #DADDE5;
          }
        }
        .op_scale{
          margin-bottom: 20px;
        }
      }
    }
    
  .res_btns{
    margin-top: 34px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .btn{
      width: 180px;
      height: 38px;
      border-radius: 999px;
      padding: 8px 32px;
      gap: 8px;
      background: #FFFFFF;
      box-sizing: border-box;
      border: 1px solid #DADDE5;
      box-shadow: 0px 1.5px 0px 0px #F5F6F7;
      font-size: 14px;
      line-height: 22px;
      color: #3D87FF;
    }
    .btn2{
      background: #1467FF;
      color: #FFFFFF;
      border: none;
      margin-top: 24px;
    }
  }
  }
  .tool_privacy{
    font-size: 14px;
    line-height: 22px;
    color: #9498A6;
    margin-top: 16px;
  }
  
  .handle_btns{
    text-align: right;
    .handle_btn{
      width: 173px;
      height: 48px;
      border-radius: 999px;
      background: linear-gradient(166deg, #2E61FC -41%, #6185FC 195%);
      box-shadow: 0px 1.5px 0px 0px #E6EAF0;
      color: #fff;
        img {
          width: 18px;
          height: 18px;
          margin-left: 6px;
          position: relative;
          top: 3px;
        }
        .cash{
          margin-left: 12px;
        }
        .cash2{
          margin-left: 12px;
          font-size: 14px;
          color: #fff;
          font-weight: normal;
        }
    }
    .dis_btn{
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), linear-gradient(166deg, #2E61FC -41%, #6185FC 195%);
    }
  }
}
</style>
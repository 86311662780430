import { fetchEventSource } from '@microsoft/fetch-event-source';
import { Message } from 'element-ui'
import store from '../store';
import axios from 'axios'
//  import store from '../store';
let controller = new AbortController()
let signal = controller.signal
// let content = ''
// let roomUid=''
const mixinFaceFench = {
  data () {
    return {
      faceFetchData: {
        progress: '',
        imageUrl: ''
      },
      faceCode: ''
    }
  },
  methods: {
    getFaceData (url, data) {
      axios.post(
        url,
        data,
        {
          timeout: 60000 * 10,
          headers: {
            "content-type": "application/json",
            accept: "text/event-stream"
          },
          onDownloadProgress: progressEvent => {
            const response = progressEvent.event.target.response;
            // console.log('response', response)
            const lines = response.split('data:').filter(line => !!line)
            const lastLine = lines[lines.length - 1]
            console.log('lastLine', JSON.parse(lastLine))
            // this.faceFetchData = JSON.parse(lastLine).data
            let data = JSON.parse(lastLine)
            this.faceCode = data?.code || null
            if (this.faceCode != null && this.faceCode != 200) {
              // this.$message.error({ message: this.faceFetchData?.message, duration: 2000 })
              this.$bus.emit('faceDrawError', data?.message)
            }
            if (this.faceDataTimer == null) {
              this.getFaceDrawData(data.data.taskUid)
            }
          }
        }
      ).then(response => {
        this.$store.dispatch("CheckLogin")
        this.getDrawHotChargerule()
        this.faceCode = response.data.code || null
        if (this.faceCode != null && this.faceCode != 200) {
          // this.$message.error({ message: response.data.message, duration: 2000 })
          this.$bus.emit('faceDrawError', response.data.message)
        }
      }).catch(err => {
        this.faceCode = err.response.status || null
        if (this.faceCode != null && this.faceCode != 200) {
          // this.$message.error({ message: err.response.data.message, duration: 2000 })
        }
        this.$bus.emit('faceDrawError', err.response.data.message)
      })
    }
  },
  //   created() {
  //     console.log(this.msg3)
  //   }
}
export default mixinFaceFench
<template>
  <div>
    <el-dialog
      :visible.sync="visible"
      custom-class="dialog"
      :before-close="handleClose"
      :show-close="false"
    >
      <div class="rights">
        <div class="rights_title">
          权益对比
        </div>
        <div class="rights_tab">
          <tab
            :active.sync="activeMax"
            bg="#E6E9F0"
            color="#646873"
          />
        </div>
        <div class="box-content">
          <el-table
            :data="tableData"
            style="width: 100%"
            header-row-class-name="qyTable"
          >
            <el-table-column
              prop="benefitFunTypeDesc"
              label="权益内容"
            />
            <el-table-column
              prop=" baseRuleValue"
              align="center"
              label="普通账户"
              width="260"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.baseRuleValue == 'NO' || scope.row.baseRuleValue == 'NONE'">
                  <img
                    style="width: 24px; height: 24px"
                    src="@/static/user/close.png"
                  >
                </span>
                <span v-if="scope.row.baseRuleValue == 'YES'">
                  <img
                    style="width: 24px; height: 24px"
                    src="@/static/user/sus.png"
                  >
                </span>
                <span
                  v-if="scope.row.baseRuleValue == '待上线'"
                  class="fu"
                > 待上线 </span>
                <span
                  v-if="
                    scope.row.baseRuleValue != 'NO' &&
                      scope.row.baseRuleValue != 'YES' &&
                      scope.row.baseRuleValue != '待上线' &&
                      scope.row.baseRuleValue != 'NONE'
                  "
                >
                  {{ scope.row.baseRuleValue }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="plusRuleValue"
              align="center"
              label="Plus会员"
              width="260"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.plusRuleValue == 'NO'">
                  <img
                    style="width: 24px; height: 24px"
                    src="@/static/user/close.png"
                  >
                </span>
                <span v-if="scope.row.plusRuleValue == 'YES'">
                  <img
                    style="width: 24px; height: 24px"
                    src="@/static/user/sus.png"
                  >
                </span>
                <span
                  v-if="scope.row.plusRuleValue == '待上线'"
                  class="fu"
                > 待上线 </span>
                <span
                  v-if="
                    scope.row.plusRuleValue != 'NO' &&
                      scope.row.plusRuleValue != 'YES' &&
                      scope.row.plusRuleValue != '待上线'
                  "
                >
                  {{ scope.row.plusRuleValue }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="maxRuleValue"
              align="center"
              label="Max会员"
              width="260"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.maxRuleValue == 'NO'">
                  <img
                    style="width: 24px; height: 24px"
                    src="@/static/user/close.png"
                  >
                </span>
                <span v-if="scope.row.maxRuleValue == 'YES'">
                  <img
                    style="width: 24px; height: 24px"
                    src="@/static/user/sus.png"
                  >
                </span>
                <span
                  v-if="scope.row.maxRuleValue == '待上线'"
                  class="fu"
                > 待上线 </span>
                <span
                  v-if="
                    scope.row.maxRuleValue != 'NO' &&
                      scope.row.maxRuleValue != 'YES' &&
                      scope.row.maxRuleValue != '待上线'
                  "
                >
                  {{ scope.row.maxRuleValue }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tab from "@/pages/user/common/tab";
import { contrast } from "@/api/index";

export default {
  components:{
    tab
  },
  data() {
    return {
      tableData: [],
      visible: false,
      activeMax: 0,
    };
  },
  watch: {
    activeMax(val) {
      if(val == 0){
        this.tableData = this.contrastList.monthlyContrastVOList
      } else {
        this.tableData = this.contrastList.yearContrastVOList
      }
    },
  },
  mounted() {
    this.getcontrast()

  },
  methods:{
    getcontrast() {
      contrast().then(res => {
        this.contrastList = res.data
        this.tableData = this.contrastList.monthlyContrastVOList
      })
    },
    handleClose() {
      this.visible = false
    },
  }
}
</script>
<style lang="less">

.dialog{
    width: 1168px;
    // height: 898px;
    background: linear-gradient(128deg, #F2F7FD 0%, #F5F4F7 100%);
    .rights{
      .rights_title{
        font-size: 28px;
        font-weight: 500;
        color: #3D3D3D;
        text-align: center ;
      }
      .rights_tab{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 16px;
      }
      .box-content{
        width: 100%;
        .fu{
          color: #DEA569;
        }
        .el-table--enable-row-transition .el-table__body td.el-table__cell{
          border-bottom: 1px solid #DADDE5;
        }
        .qyTable{
          height: 56px;
          th{
            background: linear-gradient(180deg, #E3E7F4 3%, #EAEEFA 100%);
            color: #1F2126;
            font-size: 16px;
            border-bottom: 1px solid #fff;
            font-weight: 500;
            &:first-child{
              border-radius: 16px 0px 0px 0px;
            }
            &:nth-child(4){
              border-radius: 0px 16px 0px 0px;
            }
          }
        }
        .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
           background-color: #fff;
        }
        .el-table, .el-table__expanded-cell{
          background: transparent;
        }
      }
    }
    
  .el-dialog__body{
    padding: 32px 64px !important;
  }
  }
</style>
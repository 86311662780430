<template>
  <div class="card">
    <div class="aiCard">
      <ai-top />
      <div
        ref="aiBox"
        v-scrollActive
        class="aiBox"
      >
        <div
          v-show="show"
          class="aiContent1"
        >
          <div class="proContent">
            <p class="text">
              你可以试着问我
            </p>
            <div class="boxCenter">
              <div class="item">
                <p class="title">
                  <img src="@/static/index/pro.png">
                  提出复杂问题
                </p>
                <div
                  v-for="(item, index) in complex"
                  :key="index"
                  class="itemText"
                  @click="getchange(item)"
                >
                  {{ item.text }}
                </div>
              </div>
              <div class="item">
                <p class="title">
                  <img src="@/static/index/book.png">
                  咨询专业问题
                </p>
                <div
                  v-for="(item, index) in major"
                  :key="index"
                  class="itemText"
                  @click="getchange(item)"
                >
                  {{ item.text }}
                </div>
              </div>
              <div class="item">
                <p class="title">
                  <img src="@/static/index/light.png">
                  获取创意灵感
                </p>
                <div
                  v-for="(item, index) in ideas"
                  :key="index"
                  class="itemText"
                  @click="getchange(item)"
                >
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="!show"
          v-resize="resize"
          class="aiContent2"
        >
          <aiMarkdown :data="fetchDataArray" />
        </div>
      </div>
      <div
        style="    position: absolute;
    left: 50%;
    bottom: 32px;
    margin-left: -66px;
}"
      >
        <span
          v-show="stopFetch"
          class="stopReply"
          @click="stopReply"
        >
          <i /> 停止回复</span>
      </div>
    </div>
    <search
      ref="search"
      :text="text"
      @send="send"
    />
    <noTimes
      ref="noTimes"
      :no-times-visible.sync="noTimesVisible"
    />
  </div>
</template>

<script>
import search from "@/components/search.vue";
import AiTop from "@/components/AiTop.vue";
import aiMarkdown from "@/pages/ai/common/aiMarkdown";
import noTimes from "@/components/noTimes";

import { listByGroup, listChatMessage, sendCancel } from "@/api/index";
import mixinFetch from "@/mixin/fench";

export default {
  components: {
    AiTop,
    search,
    noTimes,

    aiMarkdown,
  },
  mixins: [mixinFetch],
  props: {
    uid: {
      type: String,
      default: "",
    },
    defaultShow:{type:Boolean,default:false},
    scroll:{type:Boolean,default:false},
  },
  data() {
    return {
      value: "",
      complex: [],
      major: [],
      ideas: [],
      show: false,
      text: "",
      data: [],
      roomUid: "",
      noTimesVisible: false,
      params: '',

   };
  },
  computed: {
    dataArry() {
      return this.$store.state.listChatMessage;
    },
   
  },
  watch: {
    uid(value) {
      this.getlistChatMessage(value);
      this.stopFetch=false
    if(!value){
      this.show = true;
      this.roomUid=''
       this.fetchDataArray=[]
      
    }else{
       this.show = false;
    }
    },
    defaultShow(value){
      if(value){
        this.show=true
      }
    }

   

  },
  mounted() {
    this.getlist();
    console.log('999999999999999',!this.uid)
    if(!this.uid){this.show=true}
    // this.getlistChatMessage(this.uid);
  
  },

  methods: {
    resize(width,height){
      console.log(width,height)
      console.log(this.scroll)
      if(!this.scrollY){
        return
      }
      this.$refs.aiBox.scrollTo({
        'top':height.slice(0, -2)+300,
        'behavior': 'smooth'
      })
      // this.$refs.aiContent2.scrollTop=height
    },
    resetSend(data) {
      this.params = data
      if(this.$store.state.chargerule.userType == 'VIP0' && this.$store.state.chargerule.recharge){
        this.$refs.noTimes.type = 'gold'
        this.noTimesVisible = true
        return
      }
      if(this.$store.state.chargerule.userType != 'VIP0' && this.$store.state.chargerule.recharge && this.$store.state.chargerule.defaultUseBenefitRule && !this.$store.state.chargerule.baseBenefitRuleVO.recharge){
        this.$refs.noTimes.type = 'times'
        this.$refs.noTimes.num = this.$store.state.chargerule.baseBenefitRuleVO.chargeNum
        this.noTimesVisible = true
        return
      }
      if(this.$store.state.chargerule.userType != 'VIP0' && this.$store.state.chargerule.recharge && this.$store.state.chargerule.baseBenefitRuleVO.recharge){
        this.$refs.noTimes.type = 'nothing'
        this.noTimesVisible = true
        return
      }
      this.send()
    },
    async send() {
      this.show=false
      console.log(this.uid);
      
        this.scrollY=true
      // this.$nextTick(function () {
      //   let container = this.$refs.aiBox;
      //   container.scrollTop = 999999999;
      // });

      if (this.uid) {
        this.roomUid = this.uid;
        this.show = false;
      } 
     this.prompt= this.params.prompt
     console.log(this.params.roomUid)
      this.getBaseData("/ai/chat/send/stream/base", this.params)
      this.$store.dispatch("CheckLogin")
      this.$store.dispatch("getChargerule");
    },
    stopReply() {
      sendCancel({ accessSubType: "AI_CHAR_BASE" }).then((res) => {
        if (res.data) {
          this.cancel();
        }
      });
    },
    getchange(item) {
      this.text = item.text;
    },
    async getlist() {
      const complexRes = await listByGroup({ groupNo: "AI_CHAT_BASE_COMPLEX" });
      const majorRes = await listByGroup({ groupNo: "AI_CHAT_BASE_MAJOR" });
      const ideasRes = await listByGroup({ groupNo: "AI_CHAT_BASE_IDEAS" });
      this.complex = complexRes.data;
      this.major = majorRes.data;
      this.ideas = ideasRes.data;
    },
    getlistChatMessage(uid) {
      if (!uid) {
        return;
      }
      listChatMessage({ roomUid: uid })
        .then((res) => {
          this.fetchDataArray = res.data;
        
          // this.show = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang='less'>
.card {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  min-width: 480px;
  height: 100%;

  .aiCard {
    flex: 1;
    overflow: hidden;
    padding: 24px 0px;
    gap: 24px;
    height: calc(100% - 56px);
    background: rgba(255, 255, 255, 0.6);
    border-radius: 32px;
    box-sizing: border-box;
    border: 1px solid #ffffff;
    position: relative;

    /* 卡片阴影 */
    box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
    .aiBox {
      padding: 24px 32px;
      width: 100%;
      height: calc(100% - 57px);
      box-sizing: border-box;
      position: relative;
      overflow-y: scroll;
  .aiContent1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    .proContent{
             .text {
            font-family: Source Han Sans CN;
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: center;

            /* Grey/6 */
            color: #7a7f8c;
            padding-bottom: 64px;
          }
          .boxCenter {
            display: flex;
            justify-content: space-around;
            .item {
              width: 30%;
              .title {
                justify-content: center;
                font-family: Source Han Sans CN;
                font-size: 20px;
                font-weight: 500;
                line-height: 28px;
                letter-spacing: 0em;
                display: flex;
                align-items: center;
                padding-bottom: 32px;
                color: #3d3d3d;
                white-space: nowrap;
                img {
                  width: 24px;
                  height: 24px;
                  margin-right: 8px;
                }
              }
              .itemText {
                cursor: pointer;
                white-space: nowrap;
                border-radius: 999px;
                opacity: 1;
                width: 220px;
                margin: 0 16px;
                /* 自动布局 */
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 5px 24px;
                align-self: stretch;

                /* Grey/0 */
                background: #ffffff;
                font-family: Source Han Sans CN;
                font-size: 14px;
                font-weight: normal;
                line-height: 54px;
                letter-spacing: 0em;

                /* Main/08 */
                color: #0033b3;
                margin-bottom: 16px;
              }
            }
          }
    }
   
        }
      .aiContent2 {
      

    
      }
    }
  }
      .stopReply {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background: #ffffff;

          box-sizing: border-box;
          /* Grey/2 */
          border: 1px solid #dadde5;
          width: 132px;
          height: 46px;
          border-radius: 8px;
          box-shadow: 0px 2px 4px 0px rgba(151, 172, 205, 0.3);
          font-family: Source Han Sans CN;
          font-size: 14px;
          font-weight: 500;
          line-height: 46px;
          letter-spacing: 0em;
          text-align: center;
          box-sizing: border-box;
          /* Grey/10 */
          color: #1f2126;
          margin: 0 auto;
          i {
            width: 16px;
            height: 16px;
            border-radius: 2px;
            margin-right: 12px;
            opacity: 1;
            display: inline-block;

            /* Grey/3 */
            background: #cacdd9;
          }
        }
}
</style>
<template>
  <div class="word">
    <div
      v-if="generating"
      class="drawing_board"
    >
      <div
        :style="{height: upHeight + 'px', maxWidth: maxWidth + 'px'}"
        class="board"
        :class="showBG ? '' : 'showBG'"
      >
        <div
          v-show="showUpload"
          class="nodata"
        >
          <el-upload
            :action="'/attachments/img/upload'"
            list-type="picture"
            accept=".jpg,.png"
            :show-file-list="false"
            :auto-upload="true"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
            :on-error="handleError"
            :drag="true"
            class="upload"
          >
            <div
              v-show="!file"
              class="upload_img"
            >
              <img
                src="../../static/ai/upload2.png"
                alt=""
              >
              <p>拖拽或点击以上传图片</p>
              <p>支持 JPG/PNG格式，最大 3MB</p>
            </div>
            <img
              v-show="file"
              class="uploaded_pic"
              :src="process == 'production' ? file?.data?.aigoPreViewUrl : file?.data?.aigoPreViewUrl?.split('idcfengye.com')[1]"
              alt=""
            >
          </el-upload>
        </div>
        <div
          v-if="uploadLoading"
          class="loading"
        >
          <img
            src="../../static/ai/loading3.png"
            alt=""
          >
          <span>正在上传...</span>
          <span>请耐心等待文件上传完成</span>
        </div>
        <div
          v-if="showError"
          class="failed"
        >
          <img
            src="../../static/ai/error.png"
            alt=""
          >
          <p>阿哦，图片生成失败~</p>
          <p>{{ errorMessage }}</p>
        </div>
      </div>
      <div
        v-if="showError"
        class="btns"
      >
        <el-button
          class="btn2"
          icon="el-icon-refresh-right"
          @click="handleRegeneration"
        >
          重新上传
        </el-button>
      </div>
      <div
        v-if="!showError"
        class="btns f jsb"
      >
        <el-button
          round
          class="btn"
          :disabled="btnDisabled"
          @click="handleSend"
        >
          生成提示词
          <span
            v-if="chargerule?.userType == 'VIP0'"
            class="cash"
          >-{{ chargerule?.chargeNum }}<img src="@/static/index/money.png"></span>
          <span
            v-else
            class="cash2"
          >剩：{{ chargerule?.surplusNum }}次</span>
        </el-button>
      </div>
    </div>
    <div
      v-else
      class="container"
    >
      <div class="con_right">
        <img
          :src="process == 'production' ? describeData.imageUrl : describeData.imageUrl.split('idcfengye.com')[1]"
          alt=""
        >
        <el-button
          class="btn"
          icon="el-icon-refresh-right"
          @click="handleRegeneration"
        >
          重新上传
        </el-button>
      </div>
      <div class="con_left">
        <div
          v-scrollActive
          class="item_list"
        >
          <div class="left_item">
            <p class="item_title">
              描述词 1
              <img
                v-copy="describeData.prompt1"
                src="@/static/ai/copy.png"
                alt=""
              >
            </p>
            <div class="item_content">
              {{ describeData.prompt1 }}
            </div>
          </div>
          <div class="left_item">
            <p class="item_title">
              描述词 2
              <img
                v-copy="describeData.prompt2"
                src="@/static/ai/copy.png"
                alt=""
              >
            </p>
            <div class="item_content">
              {{ describeData.prompt2 }}
            </div>
          </div>
          <div class="left_item">
            <p class="item_title">
              描述词 3
              <img
                v-copy="describeData.prompt3"
                src="@/static/ai/copy.png"
                alt=""
              >
            </p>
            <div class="item_content">
              {{ describeData.prompt3 }}
            </div>
          </div>
          <div class="left_item">
            <p class="item_title">
              描述词 4
              <img
                v-copy="describeData.prompt4"
                src="@/static/ai/copy.png"
                alt=""
              >
            </p>
            <div class="item_content">
              {{ describeData.prompt4 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="draw_history">
      <div class="title">
        <span>历史记录</span>
        <img
          src="../../static/ai/edit.png"
          alt=""
          @click="handdleMultiple"
        >
      </div>
      <div
        v-scrollActive
        class="pic_list"
        :style="{height: picMultiple ? 'calc(100% - 100px)' : 'calc(100% - 70px)'}"
        @scroll="handleScroll"
      >
        <div
          v-for="item, index in picList"
          :key="'drawUid' + index"
          class="draw_item"
          @click="handleChoose(item)"
        >
          <div
            v-if="!item.drawUid"
            class="draw_item"
          >
            <div class="generating flex jc">
              <span>生成中...</span>
              <!-- <span class="str">{{ item.progress }}%</span>
              <el-progress class="progress" :show-text="false" :stroke-width="10" :percentage="item.progress"></el-progress> -->
            </div>
          </div>
          <div
            v-if="item.drawUid && item.imageUrl == null && item.statusName == null"
            class="draw_item"
          >
            <div class="failing flex c">
              <img
                src="../../static/ai/fail.png"
                alt=""
              >
              <span>生成失败</span>
              <span>查看详情</span>
            </div>
          </div>
          <div
            v-if="item.imageUrl"
            class="image_list"
          >
            <div class="pic">
              <img
                :src="process == 'production' ? item.imageUrl : item.imageUrl?.split('idcfengye.com')[1]"
                alt=""
              >
            </div>
          </div>
          <div
            v-show="picMultiple"
            class="check"
            :class="item.checked ? 'border' : ''"
          >
            <img
              v-if="item.checked"
              src="../../static/ai/checked.png"
              alt=""
            >
            <img
              v-else
              src="../../static/ai/circle.png"
              alt=""
            >
          </div>
        </div>
        <div
          v-show="listLoading"
          class="list_loading"
        >
          <span><i class="el-icon-loading" /> 加载中...</span>
        </div>
        <div
          v-show="inBottom && picList.length > 9"
          class="list_loading"
        >
          <span>已经到底啦...</span>
        </div>
      </div>
      <div
        v-if="picMultiple"
        class="btn_delete"
        :class="checkedList.length > 0 ? 'bg_color' : ''"
      >
        <el-popconfirm
          title="确定删除选中的图片吗？"
          class="popconfirm"
          @confirm="handleBatchDelete"
        >
          <el-button
            slot="reference"
            icon="el-icon-delete"
            :disabled="checkedList.length == 0"
          >
            删除
          </el-button>
        </el-popconfirm>
      </div>
    </div>
    <noTimes
      ref="noTimes"
      :no-times-visible.sync="noTimesVisible"
    />
  </div>
</template>

<script>
import { describePage, describeDetail, describeRemove, drawHotChargerule } from '@/api/index'
import defaultImage from '@/static/ai/white.png'
import mixinDescribeFetch from "@/mixin/describeFench";
import noTimes from "@/components/noTimes";

export default {
  name: 'Word',
  components: {
    noTimes,
  },
  mixins: [mixinDescribeFetch],
  data () {
    return {
      generating: true,
      showUpload: true,
      loading: false,
      showError: false,
      picList: [],
      picMultiple: false,
      multipleList: [],
      checkedList: [],
      pageNum: 1,
      listLoading: false,
      inBottom: false,
      process: '',
      showBG: true,
      file: '',
      describeData: '',
      total: 0,
      upHeight: '',
      maxWidth: '',
      chargerule: '',
      uploadLoading: false,
      btnDisabled: true,
      noTimesVisible: false,
      errorMessage: '',
    }
  },
  watch: {
    file(val) {
      if(val == ''){
        this.btnDisabled = true
      } else {
        this.btnDisabled = false
      }
    },  
    describeFetchData(val) {
      this.loading = false
      if(val.imageUrl != null){
        this.describeData = val
        this.picList[0] = val
        this.generating = false
        this.showUpload = false
        this.showError = false
      } else {
        this.generating = true
        this.showError = true
        this.showUpload = false
      }
    },
    picList(val) {
      var arr = []
      val.map(item => {
        if(item.progress && item.progress < 100 && item.imageUrl){
          arr.push(item)
        }
      })
      if(arr.length >= 4){
        this.$bus.emit('disabled')
      }
    }
  },
  mounted () {
    this.process = process.env.NODE_ENV
    this.getDrawPage()
    this.getDrawHotChargerule()
    this.maxWidth = document.getElementsByClassName('drawing_board')[0].offsetHeight - 48
    this.$nextTick(() => {
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          if(entry.target.offsetWidth > this.maxWidth) return
          this.upHeight = entry.target.offsetWidth
        }
      });
      resizeObserver.observe(document.getElementsByClassName('board')[0]);
    })
    this.$bus.on('wordDrawError', val => {
      this.errorMessage = val
        this.generating = true
        this.showError = true
        this.showUpload = false
      this.getDrawPage()
    })
  },
  methods: {
    getDrawHotChargerule() {
      drawHotChargerule('AI_DRAW_POPULAR_FEATURES').then(res => {
        this.chargerule = res.data
      })
    },
    async handleSend() {
      if(!this.$store.state.isLogin){
        this.$store.commit('SET_LOGINDISLOG', true)
        return
      }
      if(this.chargerule.userType == 'VIP0' && this.chargerule.recharge){
        this.$refs.noTimes.type = 'gold'
        this.noTimesVisible = true
        return
      }
      if(this.chargerule.userType != 'VIP0' && this.chargerule.recharge && this.chargerule.defaultUseBenefitRule && !this.$store.state.chargerule.baseBenefitRuleVO.recharge){
        this.$refs.noTimes.type = 'times'
        this.$refs.noTimes.num = this.chargerule.baseBenefitRuleVO.chargeNum
        this.noTimesVisible = true
        return
      }
      if(this.chargerule.userType != 'VIP0' && this.chargerule.recharge && this.chargerule.baseBenefitRuleVO.recharge){
        this.$refs.noTimes.type = 'nothing'
        this.noTimesVisible = true
        return
      }
      this.send()
    },
    async send() {
      this.showBG = true
      this.loading = true
      this.picList.unshift({})
      const params = {
        benefitSubType: "AI_DRAW_DESCRIBE",
        aigoFileRequest: {
          fileName: this.file.data.fileName,
          encryOssNo: this.file.data.encryOssNo,
          aigoPreViewUrl: this.file.data.aigoPreViewUrl
        }
      }
      await this.getDescribeData('/ai/draw/describe/send', params)
    },
    handleError() {
      this.$message.error({message: '上传失败，请重试', duration: 2000})
    },
    beforeUpload(file) {
      if(!this.$store.state.isLogin){
        this.$store.commit('SET_LOGINDISLOG', true)
        return false
      }
      const isPNG = file.type == 'image/png'
      const isJPG = file.type == 'image/jpg'
      const isJPEG = file.type == 'image/jpeg'
      if (file.size > 1024 * 1024 * 3) {
        this.$message.error({message: '图片太大，请上传 3MB 以下的图片', duration: 2000})
        return false
      }
      if (!isJPG && !isPNG && !isJPEG) {
        this.$message.error({message: '仅支持 JPG/PNG格式，请重新上传', duration: 2000})
        return false
      }
      this.uploadLoading = true
      this.showBG = true
      this.file = ''
    },
    //
    handleSuccess(res, file, fileList) {
      this.file = res
      this.uploadLoading = false
      this.showBG = false
      console.log('this.file', this.file)
    },
    handleScroll(e) {
      if(e.srcElement.scrollTop + e.srcElement.offsetHeight > e.srcElement.scrollHeight - 6 ) {
        if(!this.listLoading && !this.picMultiple && !this.inBottom){
          if(this.total == this.picList.length) return
          this.listLoading = true
          this.pageNum ++
          describePage({pageNum: this.pageNum, pageSize: 10}).then(res => {
            var data = res.data.records
            data.forEach(item => {
              this.$set(item, 'checked', false)
            })
            this.picList = this.picList.concat(data)
            this.total = res.data.total
            this.listLoading = false
            if(this.total == this.picList.length){
              this.inBottom = true
            }
          }).catch(() => {
            this.listLoading = false
            this.pageNum --
          })
        }
      }
    },
    //
    handleChoose(val) {
      if(this.picMultiple) {
        this.checkedList = []
        val.checked = !val.checked
        this.picList.forEach(item => {
          if(item.checked) {
            this.checkedList.push(item.drawUid)
          }
        }) 
      } else { 
        if(val.imageUrl){
          describeDetail(val.taskUid).then(res => {
            this.generating = false
            this.describeData = res.data
          })
        } else {
          this.generating = true
          this.showError = true
          this.showUpload = false
        }
      }
    },
    // 重新上传
    handleRegeneration() {
      this.generating = true
      this.showUpload = true
      this.showError = false
      this.loading = false
      this.showBG = true
      this.file = ''
    },
    handleBatchDelete() {
      var drawUids = this.checkedList.join(',')
      describeRemove(drawUids).then(res => {
        if(res.code == 200){
          this.$message.success({message: '删除成功', duration: 2000})
          this.picMultiple = false
          this.getDrawPage()
        }
      })
    },
    // 历史记录多选
    handdleMultiple() {
      this.picMultiple = !this.picMultiple
      this.picList.forEach(item => item.checked = false)
    },
    getDrawPage() {
      this.pageNum = 1
      describePage({pageNum: this.pageNum, pageSize: 10}).then(res => {
        var data = res.data.records
        data.forEach(item => {
          this.$set(item, 'checked', false)
        })
        this.picList = data
        this.total = res.data.total
      })
    },
  }
}
</script>

<style lang="less">

.word{
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  .drawing_board{
    width: 100%;
    height: 100%;
    margin: 0 24px 86px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .board{
      height: calc(100% - 24px);
      width: 100%;
      max-height: calc(100% - 138px);
      min-width: 300px;
      min-height: 300px;
      background: #fff;
      border-radius: 24px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
      .nodata{
        width: 100%;
        height: 100%;
        .upload, .upload .el-upload, .upload .el-upload .el-upload-dragger{
          width: 100%;
          height: 100%;
        }
        .el-upload-dragger{
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          border-radius: 24px;
          background: transparent;
        }
        .upload_img{
          img{
            width: 120px;
            height: 120px;
          }
        }
        p:nth-of-type(1){
          font-size: 20px;
          color: #1F2126;
          margin: 12px 0 16px;
          line-height: 28px;
        }
        p:nth-of-type(2){
          font-size: 14px;
          line-height: 22px;
          color: #9498A6;
        }
        .uploaded_pic{
          max-height: 100%;
          max-width: 100%;
          // height: 100%;
          // width: 100%;
            border-radius: 24px;
            object-fit: contain;
          // img{
          //   max-height: 100%;
          //   max-width: 100%;
          //   height: 100%;
          //   width: 100%;
          //   border-radius: 24px;
          //   object-fit: contain;
          // }
        }
        .el-loading-mask{
          border-radius: 24px;
          background: #fff;
        }
      }
      .loading{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        background: #fff;
        border-radius: 24px;
        img{
          width: 48px;
          height: 48px;
          animation: rotate 1.5s linear infinite;
        }
        @keyframes rotate {
          to{
                transform: rotate(360deg);
            }
        }
        span:nth-of-type(1){
          margin: 40px 0 15px;
          font-family: Source Han Sans CN;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #1F2126;
        }
        span:nth-of-type(2){
          font-family: Source Han Sans CN;
          font-size: 14px;
          line-height: 22px;
          color: #ACB1BF;
        }
      }
      .failed{
        img{
          width: 179px;
          height: 109px;
        }
        p:nth-of-type(1){
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #1F2126;
          margin: 12px;
        }
        p:nth-of-type(2){
          font-size: 14px;
          line-height: 22px;
          color: #ACB1BF;
        }
      }
    }
    .showBG{
      background: none;
      box-shadow: none;
    }
    .btns{
      width: 100%;
      text-align: center;
      margin-top: 32px;
      .btn{
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        border-radius: 999px;
        width: 187px;
        height: 48px;
        gap: 5px;
        padding: 12px 0;
        background: linear-gradient(165deg, #2E61FC -36%, #6185FC 185%);
        box-shadow: 0px 1.5px 0px 0px #E6EAF0;
        img {
          width: 18px;
          height: 18px;
          margin-left: 6px;
          position: relative;
          top: 3px;
        }
        .cash{
          margin-left: 12px;
        }
        .cash2{
          margin-left: 12px;
          font-size: 14px;
          color: #BFCEFF;
          font-weight: normal;
        }
      }
      .btn2{
        margin-top: 39px;
        border-radius: 8px;
        width: 128px;
        height: 38px;
        padding: 8px 24px;
        gap: 8px;
        background: #FFFFFF;
        box-sizing: border-box;
        border: 1px solid #E6E9F0;
        box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
        color: #3D3D3D;
      }
    }
  }
  .container{
    width: 100%;
    margin-right: 24px;
    display: flex;
    justify-content: center;
    .con_right{
      margin-right: 24px;
      width: 50%;
      min-width: 135px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img{
        max-width: 100%;
        max-height: calc(100% - 121px);
        border-radius: 16px;
        object-fit: contain;
      }
      .btn{
        margin-top: 39px;
        border-radius: 8px;
        width: 128px;
        height: 38px;
        padding: 8px 24px;
        gap: 8px;
        background: #FFFFFF;
        box-sizing: border-box;
        border: 1px solid #E6E9F0;
        box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
        color: #3D3D3D;
      }
    }
    .con_left{
      min-width: 100px;
      width: 45%;
      height: calc(100% - 24px);
      background: #fff;
      padding: 32px 0 32px 32px;
      box-sizing: border-box;
      margin-bottom: 24px;
      border-radius: 16px;
      box-shadow: 0px 15px 35px -8px rgba(0, 0, 0, 0.06);
      .item_list{
        height: 100%;
        overflow-y: scroll;
        .left_item{
          margin: 0 24px 24px 0;
          .item_title{
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: #1F2126;
            margin-bottom: 12px;
            display: flex;
            align-items:center ;
            img{
              width: 16px;
              margin-left: 8px;
              cursor: pointer;
            }
          }
          .item_content{
            width: 100%;
            border-radius: 8px;
            background: #F2F4F7;
            padding: 10px 16px;
            box-sizing: border-box;
            font-size: 14px;
            line-height: 22px;
            color: #3D3D3D;
            word-wrap: break-word;
          }
        }
      }
    }
  }
  .draw_history{
    width: 192px;
    height: calc(100% - 24px);
    background: #FFFFFF;
    border-radius: 16px 0 0 16px;
    box-shadow: 0px 15px 35px -8px rgba(59, 119, 174, 0.08);
    .title{
      width: 100%;
      height: 46px;
      line-height: 46px;
      padding: 12px 16px;
      font-family: Source Han Sans CN;
      font-size: 14px;
      color: #3D3D3D;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      img{
        cursor: pointer;
        width: 16px;
        height: 16px;
      }
    }
    .pic_list{
      padding: 0 16px;
      height: 100%;
      height: calc(100% - 70px);
      overflow-y: scroll;
      overflow-x: hidden;
      .draw_item{
        width: 160px;
        height: 160px;
        margin-bottom: 16px;
        border-radius: 8px;
        position: relative;
        .generating{
          width: 100%;
          height: 100%;
          border-radius: 8px;
          background: linear-gradient(135deg, #E7EFFF 0%, #F3EFFD 98%);
          text-align: center;
          font-size: 14px;
          .str{
            display: block;
          }
          .str:nth-of-type(1){
            padding-top: 32px;
          }
          .str:nth-of-type(2){
            font-size: 20px;
            margin: 6px 0 12px 0;
          }
          .progress{
            margin: 0 20px;
            .el-progress-bar__inner{
              background: linear-gradient(149deg, #2E61FC 10%, #6185FC 102%);
            }
            .el-progress-bar__outer{
              background: #D6E4FF !important;
            }
          }
        }
        .flex{
          display: flex;
          align-items: center;
          &.c{
            flex-direction: column;
          }
          &.jc{
            justify-content: center;

          }
        }
        .failing{
          width: 100%;
          height: 100%;
          border-radius: 8px;
          border: 1px solid #E6E9F0;
          padding: 32px 0 40px;
          box-sizing: border-box;
          span:nth-of-type(1){
            font-size: 14px;
            color: #3D3D3D;
            margin: 16px;
          }
          span:nth-of-type(2){
            font-size: 12px;
            color: #ACB1BF;
            cursor: pointer;
          }
          img{
            width: 24px;
            height: 24px;
          }
        }
        .image_list{
          width: 160px;
          height: 160px;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          border-radius: 8px;
          background: #F2F4F7;
          .pic{
            img{
              width: 160px;
              height: 160px;
              border-radius: 8px;
              max-height: 100%;
              max-width: 100%;
              object-fit: contain;
            }
          }
        }
        .check{
          position: absolute;
          width: 160px;
          height: 160px;
          top: 0;
          right: 0px;
          z-index: 2;
          border-radius: 8px;
          box-sizing: border-box;
          img{
            position: absolute;
            top: 4px;
            right: 4px;
            width: 24px;
            height: 24px;
          }
        }
        .border{
          border: 2px solid #1467FF;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
        }
      }
      .list_loading{
        text-align: center;
        font-size: 14px;
        color: #ACB1BF;
        margin-bottom: 10px;
      }
    }
    .btn_delete{
      margin: 10px 0;
      padding: 0 16px;
      .el-button{
        width: 160px;
        height: 32px;
        border-radius: 6px;
        background: #CACDD9;
        color: #fff;
        box-shadow: 0px 1.5px 0px 0px rgba(0, 0, 0, 0.05);
        border: none;
        padding: 5px 16px;
      }
    }
    .bg_color{
      .el-button{
        background: #FF4D4F;
      }
    }
  }
}

</style>
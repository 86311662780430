<template>
  <div class="invite">
    <div
      v-if="$store.state.userInfo.userType != 'VIP0'"
      style="height: 100%;"
    >
      <div class="invite_top">
        <div class="invite_card">
          <p>我的邀请码</p>
          <div class="invite_code">
            <span>{{ user.invitationCode }}</span>
          </div>
          <p v-copy="user.invitationCode">
            一键复制
          </p>
        </div>
        <div class="invite_card">
          <div class="first">
            <span>返利余额</span>
            <span @click="bonusVisible = true"><img
              src="@/static/user/ques.png"
              alt=""
            >奖励说明</span>
          </div>
          <div class="second">
            <span>￥<span class="num">{{ user.rebateAmount }}</span></span>
            <el-tooltip
              v-if="applyDetail?.status == 'WAIT' || applyDetail?.status == 'PROCESS'"
              class="item"
              effect="dark"
              content="正在提现中，预计 24 小时内到账，非工作日顺延"
              placement="top"
            >
              <el-button
                round
                class="btn bg"
                :disabled="true"
                @click="handleWithdrawal"
              >
                {{ applyDetail?.status == 'WAIT' ? '等待处理' : applyDetail?.status == 'PROCESS' ? '处理中' : '立即提现' }}
              </el-button>
            </el-tooltip>
            <el-button
              v-else
              round
              class="btn"
              @click="handleWithdrawal"
            >
              立即提现
            </el-button>
            <div
              v-if="applyDetail?.status == 'WAIT'"
              class="cancel"
            >
              <span @click="handleShowCancel">撤销提现</span>
            </div>
          </div>
        </div>
      </div>
      <div class="title">
        <span class="text-right">已邀请用户</span>
        <span class="text-left" />
      </div>
      <div class="invite_list">
        <div class="item head">
          <span class="label1">序号</span>
          <span class="label2">用户名</span>
          <span class="label3">开通类型</span>
          <span class="label4">开通时间</span>
          <span>返利金额</span>
        </div>
        <div
          v-scrollActive
          class="item_list"
        >
          <div
            v-for="item,index in inviteList"
            :key="index"
            class="item"
            :class="index %2 == 0 ? '' : 'bg'"
          >
            <span class="label1">{{ item.orderNum }}</span>
            <span class="label2">{{ item.referralNickname }}</span>
            <span class="label3">{{ item.openTypeDesc }}</span>
            <span class="label4">{{ item.referralOpeningTime }}</span>
            <span class="label5">{{ item.rebateAmount }}</span>
          </div>
        </div>
        <el-pagination
          :current-page.sync="pageNum"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
        />
      </div>
      <el-dialog 
        :title="title"
        custom-class="withdrawal"
        :visible.sync="dialogVisible"
        width="480px"
        :before-close="handleClose"
      >
        <div>
          <div class="top">
            <p>提现说明</p>
            <p>· 仅支持全部提现到支付宝账户，请确认您的提现账户及姓名是否正确</p>
            <p>· 确定提现后，我们将在24小时内提现到您输入的账户中，若非工作日则顺延</p>
          </div>
          <p class="label">
            支付宝账号
          </p>
          <el-input
            v-model="formData.accountNo"
            class="input"
            placeholder="请输入您的支付宝账号"
          />
          <p class="label">
            账号姓名（仅用作验证）
          </p>
          <el-input
            v-model="formData.accountName"
            class="input"
            placeholder="请输入您的支付宝账号姓名"
          />
          <p class="label">
            提现金额
          </p>
          <p class="mony">
            ￥<span>{{ user.rebateAmount }}</span>
          </p>
          <div class="btns">
            <el-button
              class="btn"
              round
              @click="handleClose"
            >
              取消
            </el-button>
            <el-button
              class="btn btn2"
              round
              @click="handleSubmit"
            >
              确认提现
            </el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        custom-class="cancel_dialog"
        :visible.sync="showTip"
        width="418px"
        title="确认撤销提现"
        :before-close="handleCloseCancel"
        :show-close="false"
      >
        <span class="text">撤销后将取消提现操作，您可重新发起提现</span>
        <div class="btns">
          <el-button
            class="btn"
            @click="handleCloseCancel"
          >
            取消
          </el-button>
          <el-button
            class="btn btn2"
            type="primary"
            @click="handleCancel"
          >
            确认
          </el-button>
        </div>
      </el-dialog>
      <el-dialog
        width="480px"
        title="奖励说明"
        custom-class="bonus"
        :visible.sync="bonusVisible"
        :show-close="false"
      >
        <div class="bonus_card">
          <p class="label">
            · 若您是普通用户
          </p>
          <p class="content">
            现暂不支持返利及提现功能
          </p>
          <p class="label">
            · 若您是 Plus 会员
          </p>
          <p class="content">
            您可以获得所邀请用户实际付费金额*10%的返利
          </p>
          <p class="label">
            · 若您是 Max 会员
          </p>
          <p class="content">
            您可以获得邀请用户实际付费金额*20%的返利
          </p>
        </div>
        <div class="bonus_foot">
          <el-button
            class="btn"
            round
            @click="bonusVisible = false"
          >
            知道了
          </el-button>
        </div>
      </el-dialog>
    </div>
    <div
      v-else
      class="noinvite"
    >
      <img
        src="@/static/user/noinvite.png"
        alt=""
      >
      <span>您当前无法使用邀请奖励功能，开通会员后即可获得邀请返利~</span>
    </div>
  </div>
</template>

<script>
import {inviteList, withdrawalApply, withdrawalApplyDetail, withdrawalApplyCancel, referralDetail} from '@/api/index'

export default {
  data() {
    return {
      inviteList: [],
      pageNum: 1,
      pageSize: 10,
      dialogVisible: false,
      title: '发起提现',
      user: {
        invitationCode: '',
        rebateAmount:'',
      },
      formData: {
        accountNo: '',
        accountName: '',
      },
      showTip: false,
      applyDetail: {

      },
      total: 0,
      bonusVisible: false,
    }
  },
  mounted () {
    if(this.$store.state.userInfo.userType != 'VIP0'){
      this.getInviteList()
      this.getData()
    }
  },
  methods: {
    getData() {
      referralDetail().then(res => {
      this.user = res.data
    })
    withdrawalApplyDetail().then(res => {
      this.applyDetail = res.data
    })
    },
    handleShowCancel() {
      this.showTip = true
    },
    handleCloseCancel() {
      this.showTip = false
    },
    handleCancel() {
      withdrawalApplyCancel(this.applyDetail.withDrawApplyUid).then(res => {
        this.$message.success({message: '撤销成功', duration: 2000})
        this.showTip = false
        this.getData()
      })
    },
    handleSubmit() {
      if(this.formData.accountNo == '') return this.$message.warning({message: '支付宝账户不能为空', duration: 2000})
      if(this.formData.accountName == '') return this.$message.warning({message: '支付宝账户名不能为空', duration: 2000})
      const params = {
        ...this.formData,
        withdrawAmount: this.user.rebateAmount,
        remark: '',
      }
      withdrawalApply(params).then(res => {
        this.$message.success({message: '申请成功', duration: 2000})
        this.formData = {
          accountNo: '',
          accountName: '',
        }
        this.dialogVisible = false
        this.getData()
      })
    },
    getInviteList() {
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }
      inviteList(params).then(res => {
        let num = (this.pageNum - 1) * this.pageSize
        let data = res.data.records
        data.forEach(item => {
          num ++
          this.$set(item, 'orderNum', num)
        })
        this.inviteList = data
        this.total = res.data.total
      })
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getInviteList()
    },
    handleClose() {
      this.dialogVisible = false
      this.formData = {
        accountNo: '',
        accountName: '',
      }
    },
    handleWithdrawal() {
      // if(this.user.rebateAmount <= 0){
      //   this.$message.warning({message: `您的账户余额为${this.user.rebateAmount}元，暂不能提现`, duration: 2000})
      //   return
      // }
      this.dialogVisible = true
    },
  }
}
</script>

<style lang="less">
.invite{
  padding: 32px 92px;
  box-sizing: border-box;
  height: 100%;
  .invite_top{
    display: flex;
    justify-content: space-between;
    margin-bottom: 37px;
    .invite_card{
      width: 348px;
      height: 168px;
      border-radius: 16px;
      background: #F8F9FC;
      padding: 18px 24px ;
      box-sizing: border-box;
      .invite_code{
        width: 300px;
        height: 44px;
        border-radius: 8px;
        border: 1px dashed #ACB1BF;
        background: linear-gradient(90deg, #E2F2FF 0%, #FFF5F5 100%);
        gap: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        color: #1F2126;
      }
      p:nth-of-type(1){
        font-family: Source Han Sans CN;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: #1F2126;
        margin-bottom: 24px;
      }
      p:nth-of-type(2){
        font-family: Source Han Sans CN;
        font-size: 12px;
        line-height: 22px;
        color: #7A7F8C;
        margin-top: 16px;
        cursor: pointer;
      }
      .first{
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #1F2126;
        img{
          width: 16px;
          position: relative;
          top: 4px;
          margin-right: 2px;
        }
        span:nth-of-type(2){
          cursor: pointer;
          color: #646873;
        }
      }
      .second{
        margin-top: 54px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        .num{
          font-size: 38px;
          color: #1F2126;
          font-weight: 500;
        }
        .btn{
          width: 104px;
          height: 32px;
          background: #FA517B;
          box-shadow: 0px 1.5px 0px 0px #E6EAF0;
          border: none;
          color: #fff;
          padding: 5px 24px;
        }
        .cancel{
          position: absolute;
          font-size: 12px;
          color: #373940;
          bottom: 40px;
          right: 28px;
          cursor: pointer;
        }
        .bg{
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #FA517B;
        }
      }
    }
  }
  .title {
    width: 720px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    .text-right {
      position: relative;
      font-family: Source Han Sans CN;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      padding-left: 10px;
      /* Grey/10 */
      color: #1f2126;
      &::before {
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -7px;
        content: "";
        width: 4px;
        height: 14px;
        border-radius: 999px;
        opacity: 1;

        /* Grey/10 */
        background: #1f2126;
      }
    }
  }
  .invite_list{
    height: calc(100% - 280px);
    .item_list{
      height: calc(100% - 45px);
      width: 728px;
      overflow-y: scroll;
    }
    .item{
      width: 720px;
      height: 46px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 0 32px 0 24px;
      box-sizing: border-box;
      .label1{
        width: 90px;
      }
      .label2{
        width: 134px;
      }
      .label3{
        width: 197px;
      }
      .label4{
        width: 187px;
      }
      .label5{
        color: #9498A6;
        text-align: center;
        display: block;
        width: 56px;
      }
    }
    .head{
      background: #F2F4F7;
      margin-top: 29px;
      font-weight: 500;
      color: #1F2126;
    }
  }
  .withdrawal{
    width: 480px;
    height: 582px;
    border-radius: 24px;
    padding: 24px 32px;
    box-sizing: border-box;
    .top{
      width: 416px;
      height: 116px;
      border-radius: 8px;
      background: #F0F5FF;
      padding: 12px 16px;
      box-sizing: border-box;
      margin-top: 20px;
      p{
        font-family: Source Han Sans CN;
        font-size: 12px;
        line-height: 20px;
        color: #646873;
      }
      p:nth-of-type(1){
        color: #1F2126;
        margin-bottom: 8px;
      }
    }
    .label{
      font-size: 14px;
      color: #1F2126;
      line-height: 22px;
      font-weight: 500;
      margin: 16px 0;
    }
    .el-input__inner{
      height: 38px;
      border-radius: 6px;
      border: none;
      background: #F2F4F7;
      background: #F2F4F7;
    }
    .mony{
      font-size: 16px;
      span{
        font-size: 34px;
        margin-left: 4px;
      }
    }
    .btns{
      text-align: center;
      margin-top: 40px;
    }
    .btn{
      width: 92px;
      height: 32px;
      background: #FFFFFF;
      box-shadow: 0px 1.5px 0px 0px #F5F6F7;
      border: 1px solid #DADDE5;
      padding: 5px 32px;
      color: #373940;
    }
    .btn2{
      box-shadow: 0px 1.5px 0px 0px #E6EAF0;
      background: linear-gradient(169deg, #2E61FC -57%, #6185FC 223%);  
      gap: 8px;
      padding: 5px;
      color: #FFFFFF;
      border: none;
    }
  }
  .bonus{
    background: linear-gradient(134deg, #F9F6F0 0%, #F2F6FC 99%);
    padding: 24px;
    .bonus_card{
      margin-top: 24px;
      width: 432px;
      height: 320px;
      border-radius: 8px;
      background: #fff;
      padding: 40px 24px;
      box-sizing: border-box;
      .label{
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #1F2126;
        margin-bottom: 12px;
      }
      .content{
        font-size: 14px;
        line-height: 22px;
        color: #4E5159;
        margin-bottom: 33px;
      }
    }
    .bonus_foot{
      text-align: center;
      margin-top: 24px;
      .btn{
        width: 92px;
        height: 32px;
        box-shadow: 0px 1.5px 0px 0px #E6EAF0;
        background: linear-gradient(169deg, #2E61FC -57%, #6185FC 223%);
        padding: 5px 0;
        color: #fff;
      }
    }
  }
  .cancel_dialog{
    width: 418px;
    border-radius: 16px !important;
    padding: 24px;
    background: #FFFFFF;
    .el-dialog__body{
      text-align: center;
      padding: 24px 0 0 0 !important;
    }
    .btns{
      margin-top: 24px;
      .btn{
        width: 92px;
        height: 32px;
        border-radius: 6px;
        background: #FFFFFF;
        border: 1px solid #DADDE5;
        box-shadow: 0px 1.5px 0px 0px #F5F6F7;
        color: #373940;
        padding: 5px 32px;
        box-sizing: border-box;
      }
      .btn2{
        border: none;
        background: linear-gradient(169deg, #2E61FC -57%, #6185FC 223%);
        box-shadow: 0px 1.5px 0px 0px #E6EAF0;
        color: #fff;
      }
    }
  }
    .el-dialog__header {
      text-align: center;
    }
    .el-dialog__body{
      padding: 0 !important;
    }
  .noinvite{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img{
      width: 120px;
    }
    span{
      margin-top: 20px;font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: #767F9E;
    }
  }
  .el-pagination{
    text-align: center;
  }
}

</style>
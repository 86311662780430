import { fetchEventSource } from '@microsoft/fetch-event-source';
import { Message } from 'element-ui'
import store from '../store';
import axios from 'axios'
//  import store from '../store';
let controller = new AbortController()
let signal = controller.signal
// let content = ''
// let roomUid=''
const mixinDescribeFetch = {
  data () {
    return {
      describeFetchData: {
        progress: '',
        imageUrl: ''
      },
      describeCode: ''
    }
  },
  methods: {
    getDescribeData (url, data) {
      axios.post(
        url,
        data,
        {
          timeout: 60000 * 10,
          headers: {
            "content-type": "application/json",
            accept: "text/event-stream"
          },
          onDownloadProgress: progressEvent => {
            const response = progressEvent.event.target.response;
            // console.log('response', response)
            const lines = response.split('data:').filter(line => !!line)
            const lastLine = lines[lines.length - 1]
            console.log('lastLine', JSON.parse(lastLine))
            this.describeFetchData = JSON.parse(lastLine).data
            this.describeCode = this.describeFetchData?.code || null
            if (this.describeCode != null && this.describeCode != 200) {
              // this.$message.error({ message: this.describeFetchData?.message, duration: 2000 })
              this.$bus.emit('wordDrawError', this.describeFetchData?.message)
            }
          }
        }
      ).then(response => {
        this.$store.dispatch("CheckLogin")
        this.getDrawHotChargerule()
        this.describeCode = response.data.code || null
        if (this.describeCode != null && this.describeCode != 200) {
          // this.$message.error({ message: response.data.message, duration: 2000 })
          this.$bus.emit('wordDrawError', response.data.message)
        }
      }).catch(err => {
        this.describeCode = err.response.status || null
        if (this.code != null && this.describeCode != 200) {
          // this.$message.error({ message: err.response.data.message, duration: 2000 })
        }
        this.$bus.emit('wordDrawError', err.response.data.message)
      })
    }
  },
  //   created() {
  //     console.log(this.msg3)
  //   }
}
export default mixinDescribeFetch
<template>
  <div class="ai">
    <div class="title">
      <span class="top">文稿润色&纠错</span> 
    </div>
    <div class="boxContentAi">
      <div class="leftContent">
        <div class="leftbox">
          <div
            v-scrollActive
            class="itemBox"
          >
            <div class="item item1">
              <div class="itemTitle">
                <span><img src="@/static/index/tip1.png"> 文稿内容</span>
                <el-popover
                  placement="top-start"
                  popper-class="tip"
                  width="200"
                  trigger="hover"
                  content=" 此项为必填，可直接输入或粘贴文稿内容，注意单次字数不能超过8000汉字"
                >
                  <img
                    slot="reference"
                    src="@/static/index/tip.png"
                  >
                </el-popover>
              </div>
              <el-input
                v-model="draftContent"
                type="textarea"
                :autosize="{ minRows: 6, maxRows: 6 }"
                resize="none"
                placeholder="请输入内容"
              />
              <p
                v-if="enptyError"
                class="textEnpty"
              >
                请填写内容
              </p>
            </div>
           
            <div class="item item5">
              <div class="itemTitle">
                <span><img src="@/static/index/tip1.png"> 文稿润色
                </span>
                <el-popover
                  placement="top-start"
                  width="200"
                  popper-class="tip"
                  trigger="hover"
                  content=" 此项为必填，请选择文案润色和文案纠错"
                >
                  <img
                    slot="reference"
                    src="@/static/index/tip.png"
                  >
                </el-popover>
              </div>
              <el-radio
                v-model="content.draftType"
                label="文案润色"
              >
                文稿润色
              </el-radio> 
              <el-radio
                v-model="content.draftType"
                label="文稿纠错"
              >
                文稿纠错
              </el-radio>
            </div>
            <div
              v-show="content.draftType=='文案润色'"
              class="item item2"
            >
              <div class="itemTitle">
                <span><img src="@/static/index/tip1.png"> 润色风格
                  <span class="type">选填</span></span>
                <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  content="此项为选填，选择润色风格或自定义；系统默认为“自动”，此时模型将按照主题输出最佳润色风格"
                >
                  <img
                    slot="reference"
                    src="@/static/index/tip.png"
                  >
                </el-popover>
              </div>

              <labelCon
                :label-values="labelValues"
                :text.sync="content.polishStyle"
                group-no="AI_CHAT_C3_POLISH_STYLE"
              />
            </div>
          </div>
        </div>
        <div class="leftBotton">
          <div class="leftBottonContent">
            <div class="gptSelect">
              <el-select
                v-model="$store.state.version"
                placeholder="请选择"
                @change="changeValue"
              >
                <el-option
                  v-for="item in versions"
                  :key="item.code"
                  :label="item.desc"
                  :value="item.code"
                />
              </el-select>
            </div>
            <div
              v-if="$store.state.userInfo.userType == 'VIP0'"
              class="cash"
            >
              <span>消耗：</span>{{ $store.state.chargerule.chargeNum }}
              <img src="@/static/index/money.png">
            </div>
            <div
              v-else
              class="cash"
            >
              <span>剩余：<span style="color: #3D87FF;">{{ $store.state.chargerule?.surplusNum }}</span> 次</span>
            </div>
            <el-button
              class="buttonhover"
              type="primary"
              round
              :loading="disabled"
              style="width: 148px; margin: 0 12px"
              @click="sendQuesion"
            >
              生成
            </el-button>
            <el-popconfirm
              title="确定重置所有内容吗"
              @confirm="confirm"
            >
              <span
                slot="reference"
                class="reset"
              >重置</span>
              <!-- <el-button slot="reference">删除</el-button> -->
            </el-popconfirm>
          </div>
        </div>
      </div>
      <div class="rightContent">
        <div class="box">
          <div class="boxTitle">
            生成结果
          </div>
          <div
            v-show="fetchShow"
            ref="aiBox"
            v-scrollActive
            class="boxRes"
          >
            <aiMarkdown1 :data="fetchDataArray" />

            <append
              v-show="!appendShow && index == 1"
              @append="append"
            />
            <div
              v-show="appendShow && index == 1"
              class="loadtext"
            >
              <p>正在追问</p>
              <loading />
            </div>
          </div>

          <div
            v-show="!fetchShow"
            class="boxtEmpty"
          >
            <div>
              <img src="@/static/index/enpty.png">
              <p>暂无结果</p>
              <p>快尝试生成一段文案吧</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <noTimes
      ref="noTimes"
      :no-times-visible.sync="noTimesVisible"
    />
  </div>
</template>

<script>
import loading from "@/components/loading.vue";
import noTimes from "@/components/noTimes";

import labelCon from "./common/label";
import { dict } from "@/api/index";
 
import mixinFetch from "@/mixin/fench";
 import aiMarkdown1 from '@/pages/ai/common/aiMarkdown1'
 import append from '@/pages/ai/common/append'
export default {
  components: {
   aiMarkdown1,
    append,
    loading,
    labelCon,
    noTimes,
  },
    mixins: [mixinFetch],
  data() {
    return {
      noTimesVisible: false,
      disabled:false,
      radio:'文案润色',
      reply:'',
    value:'',
 chargeNum: "",
      enptyError: false, //文案为空显示的内容
      isInput: false,
      inputText: "",
      label: 0,
      labelValues: [
       
      ],
      labelValues2: ["自动", "严肃", "活泼", "口语"],
      options: [
  
      ],
      data:[],
      content: {
        draftContent: "",
        apiKeyModel: this.$store.state.version,
        accessSubType: "AI_CHAR_C3",
      draftType:'',
      polishStyle:'',
       roomUid:'',
      },
      draftContent:''
      
    };
  },
        computed:{
        versions(){
          return this.$store.state.versions
        }
       
      },
  watch: {
       "$store.state.searchIndex"(value) {
      if (!value) {
        this.value = "";
        this.disabled=false;
      }
      if(value){
        this.disabled=true;
      }
      
    },
    draftContent(value) {
      if (value ) {
        this.enptyError = false;
        
      }
    },
    "$store.state.version"() {
       this.$store.dispatch("getChargerule");
    },
  },
  mounted() {
    this.$store.dispatch("getVersions").then(res => {
      // let value = 'gpt-3.5-16K'
      this.value=this.$store.state.versions[0].code
      this.$store.commit('SET_VERSION', this.value)
    })
    this.getDict();
      // this.getChargerule();
  },
  methods: {
    sendQuesion() {
      if(!this.$store.state.isLogin){
        this.$store.commit('SET_LOGINDISLOG', true)
        return
      }
      this.content.draftContent = this.draftContent;
      if(this.$store.state.chargerule.userType == 'VIP0' && this.$store.state.chargerule.recharge){
        this.$refs.noTimes.type = 'gold'
        this.noTimesVisible = true
        return
      }
      if(this.$store.state.chargerule.userType != 'VIP0' && this.$store.state.chargerule.recharge && this.$store.state.chargerule.defaultUseBenefitRule && !this.$store.state.chargerule.baseBenefitRuleVO.recharge){
        this.$refs.noTimes.type = 'times'
        this.$refs.noTimes.num = this.$store.state.chargerule.baseBenefitRuleVO.chargeNum
        this.noTimesVisible = true
        return
      }
      if(this.$store.state.chargerule.userType != 'VIP0' && this.$store.state.chargerule.recharge && this.$store.state.chargerule.baseBenefitRuleVO.recharge){
        this.$refs.noTimes.type = 'nothing'
        this.noTimesVisible = true
        return
      }
      this.send();
    },
     append(value) {
      console.log(value);
      this.content.draftContent = value;
      if(this.$store.state.chargerule.userType == 'VIP0' && this.$store.state.chargerule.recharge){
        this.$refs.noTimes.type = 'gold'
        this.noTimesVisible = true
        return
      }
      if(this.$store.state.chargerule.userType != 'VIP0' && this.$store.state.chargerule.recharge && this.$store.state.chargerule.defaultUseBenefitRule && !this.$store.state.chargerule.baseBenefitRuleVO.recharge){
        this.$refs.noTimes.type = 'times'
        this.$refs.noTimes.num = this.$store.state.chargerule.baseBenefitRuleVO.chargeNum
        this.noTimesVisible = true
        return
      }
      if(this.$store.state.chargerule.userType != 'VIP0' && this.$store.state.chargerule.recharge && this.$store.state.chargerule.baseBenefitRuleVO.recharge){
        this.$refs.noTimes.type = 'nothing'
        this.noTimesVisible = true
        return
      }
      this.send();
    },
        confirm(){
      this.content={   
           draftContent: "",
        apiKeyModel: "gpt-4",
        accessSubType: "AI_CHAR_C3",
      draftType:'',
      polishStyle:'',
       roomUid:'',
      }
      this.draftContent=''
      
      },
     
      changeValue(e){
          this.$store.commit('SET_VERSION',e)
        },
    send() {
     
       if (!this.content.draftContent) {
        this.enptyError = true;
        return;
      } 
       this.fetchShow=true
      this.show = true;
 this.content.apiKeyModel=this.$store.state.version
      // this.fetchData.question = this.content.draftContent;
      // this.data.push(this.fetchData);
      // this.getFetchData("ai/chat/send/stream/c3", this.content);
  this.getBaseData("ai/chat/send/stream/c3", this.content)
  this.$store.dispatch("CheckLogin")
  this.$store.dispatch("getChargerule");
  },
    //   getChargerule() {
    //   const data = {
    //     accessType: "AI_CHAR",
    //     apiKey: this.$store.state.version,
    //   };
    //   chargerule(data).then((res) => {
    //     this.chargeNum = res.data.chargeNum;
    //   });
    // },
    Reply(){
      this.content.textSubject=this.reply
      if(this.$store.state.chargerule.userType == 'VIP0' && this.$store.state.chargerule.recharge){
        this.$refs.noTimes.type = 'gold'
        this.noTimesVisible = true
        return
      }
      if(this.$store.state.chargerule.userType != 'VIP0' && this.$store.state.chargerule.recharge && this.$store.state.chargerule.defaultUseBenefitRule && !this.$store.state.chargerule.baseBenefitRuleVO.recharge){
        this.$refs.noTimes.type = 'times'
        this.$refs.noTimes.num = this.$store.state.chargerule.baseBenefitRuleVO.chargeNum
        this.noTimesVisible = true
        return
      }
      if(this.$store.state.chargerule.userType != 'VIP0' && this.$store.state.chargerule.recharge && this.$store.state.chargerule.baseBenefitRuleVO.recharge){
        this.$refs.noTimes.type = 'nothing'
        this.noTimesVisible = true
        return
      }
      this.send()
    },
    addReply(value){
      this.content.textSubject=value
      if(this.$store.state.chargerule.userType == 'VIP0' && this.$store.state.chargerule.recharge){
        this.$refs.noTimes.type = 'gold'
        this.noTimesVisible = true
        return
      }
      if(this.$store.state.chargerule.userType != 'VIP0' && this.$store.state.chargerule.recharge && this.$store.state.chargerule.defaultUseBenefitRule && !this.$store.state.chargerule.baseBenefitRuleVO.recharge){
        this.$refs.noTimes.type = 'times'
        this.$refs.noTimes.num = this.$store.state.chargerule.baseBenefitRuleVO.chargeNum
        this.noTimesVisible = true
        return
      }
      if(this.$store.state.chargerule.userType != 'VIP0' && this.$store.state.chargerule.recharge && this.$store.state.chargerule.baseBenefitRuleVO.recharge){
        this.$refs.noTimes.type = 'nothing'
        this.noTimesVisible = true
        return
      }
      this.send()
    },
    changeInput() {
      this.isInput = true;
      this.label = -1;
    },
    changeLabel(index) {
      this.label = index;
    },
    getDict() {
      dict({ groupNo: "AI_CHAT_C3_POLISH_STYLE" }).then((res) => {
        console.log(res);
        const data = {
          dictUid: "1",
          groupNo: "1",
          id: "1",
          sort: null,
          text: "自动",
          value: "自动",
        };
        this.labelValues = res.data;
        this.labelValues.unshift(data);
      });
       
    },
    
  },
};
</script>

<style lang='less'>
.ai {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 1354px;
  min-width: 480px;
  height: 100%;
  
  .title {
    margin-top: 25px;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 16px;
    &::before {
      content: "";

      position: absolute;
      left: 0;

      top: 8px;
      width: 8px;
      height: 20px;
      border-radius: 2px;
      opacity: 1;

      /* Main/06 */
      background: #1467ff;
    }
    .top {
      font-family: Source Han Sans CN;
      font-size: 28px;
      font-weight: 500;
      line-height: 36px;
      letter-spacing: 0em;

      /* Grey/10 */
      color: #1f2126;
      margin-right: 25px;
    }
    .case {
      cursor: pointer;
      font-family: Source Han Sans CN;
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      letter-spacing: 0em;

      /* Main/06 */
      color: #1467ff;
    }
  }
  .boxContentAi {
    margin-top: 32px;
    width: 100%;
  height: calc(100% - 90px);
    
    display: flex;
    .leftContent {
      overflow: hidden;
      width: 404px;
      height: 100%;
      border-radius: 24px;
      padding: 0px 0px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 20px 35px 0px rgba(94, 120, 181, 0.1);
      position: relative;
      padding-bottom: 86px;
      .leftbox {
        width: 100%;
        height: 100%;
        border-radius: 24px;
        opacity: 1;
        padding: 32px 0px;
         

        /* Grey/0 */
        background: #ffffff;
        box-sizing: border-box;

        .itemBox {
          width: 100%;
          height: 100%;
  padding:0 24px;
          box-sizing: border-box;
 overflow-y: scroll;
          .itemTitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;
            span {
              font-family: Source Han Sans CN;
              font-size: 14px;
              font-weight: 500;
              line-height: 22px;
              letter-spacing: 0em;
              display: flex;
              align-items: center;
              /* Grey/10 */
              color: #1f2126;
              img {
                width: 16px;
                height: 16px;
                margin-right: 8px;
              }
              .type {
                /* Caption/regular */
                font-family: Source Han Sans CN;
                font-size: 12px;
                font-weight: normal;
                line-height: 20px;
                letter-spacing: 0em;
                cursor: pointer;
                /* Grey/4 */
                color: #acb1bf;
                margin-left: 8px;
              }
            }
            img {
              width: 12px;
              height: 12px;
            }
          }
          .item {
             margin-bottom: 20px;
            .textEnpty {
              font-family: Source Han Sans CN;
              font-size: 12px;
              font-weight: normal;
              line-height: 20px;
              letter-spacing: 0em;

              /* Red/5 */
              color: #ff4d4f;
            }
            .el-input__inner {
              border-radius: 6px;
            }
          }
        }
      }
      .leftBotton {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        width: 404px;
        height: 86px;
        border-radius: 0px 0px 24px 24px;
        opacity: 1;
        box-shadow: 0px -12px 20px 0px rgba(0, 0, 0, 0.05);
        /* 自动布局 */
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        padding: 24px;
        background: #fff;

        .leftBottonContent {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .gptSelect {
            width: 75px;
            .el-input__inner {
              border-color: #fff;
             font-size: 12px;
               padding-left: 0px;
              padding-right: 24px;
            }
          }
          .cash {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              font-family: Source Han Sans CN;
              font-size: 12px;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: 0em;
              white-space: nowrap;
              /* Grey/5 */
              color: #9498a6;
            }
            img {
              width: 16px;
              height: 16px;
              margin-left: 6px;
            }
          }
          .reset {
            font-family: Source Han Sans CN;
            font-size: 14px;
            font-weight: normal;
            line-height: 22px;
            letter-spacing: 0em;
            white-space: nowrap;
             cursor: pointer;
            /* Main/06 */
            color: #1467ff;
          }
        }
      }
    }
    .rightContent {
      /* 自动布局 */
      margin-left: 24px;
      display: flex;
      flex-direction: column;
      padding: 24px 0px;

      background: #ffffff;
      border-radius: 24px;
      box-shadow: 0px 20px 35px 0px rgba(94, 120, 181, 0.1);
      flex: 1;
      .box {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        .boxTitle {
          font-family: Source Han Sans CN;
          font-size: 20px;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: 0em;
padding:0 32px;
          /* Grey/10 */
          color: #1f2126;
        }
        .boxRes {
          padding: 24px 48px;
          overflow-y: scroll;
        
          .loadtext {
            margin-top: 24px;
            text-align: center;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            p {
              font-family: Source Han Sans CN;
              font-size: 14px;
              font-weight: normal;
              line-height: 22px;
              letter-spacing: 0em;
              margin-bottom: 10px;

              /* Grey/8 */
              color: #4e5159;
            }
          }
        }
        .boxtEmpty {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          div {
            img {
              width: 168px;
              margin-bottom: 32px;
            }
            P {
              margin-bottom: 12px;
              font-family: Source Han Sans CN;
              font-size: 14px;
              font-weight: normal;
              line-height: 22px;
              letter-spacing: 0em;
              text-align: center;

              /* Grey/4 */
              color: #acb1bf;
            }
          }
        }
      }
    }
  }
}
.el-textarea__inner {
  border-radius: 6px;
  opacity: 1;

  /* 自动布局 */
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 12px;
  align-self: stretch;

  /* Grey/0 */
  background: #ffffff;

  box-sizing: border-box;
  /* Grey/2 */
  border: 1px solid #dadde5;
}
.el-popover__reference-wrapper {
  img {
    width: 12px !important;
    height: 12px !important;
    cursor: pointer;
    margin-right: 0px !important;
  }
}
</style>
<template>
  <div class="policies" v-scrollActive> 
        成都咚佳旭科技有限公司-五点下班（以下简称“本公司”）非常重视您的个人隐私。本隐私协议旨在向您说明我们如何收集、使用、处理和保护与您相关的个人数据。请您在使用本网站之前仔细阅读本隐私协议，并只有在完全理解并接受本协议的所有条款之后使用本网站。
     <br />  1. 数据收集和使用
     <br />   1.1 个人信息的收集：为了提供更好的服务和用户体验，本公司可能需要收集您的个人信息。个人信息是指能够识别您身份的信息，包括但不限于您的姓名、联系方式、地址、电子邮件地址、公司名称等。我们只会在您自愿提供、授权或许可的情况下收集您的个人信息。
     <br />    1.2 个人信息的使用：本公司仅会在合法、公正、透明和安全的基础上使用您的个人信息，并仅用于以下目的： 为您提供服务并满足您的需求； 处理您的订单和交易； 向您发送更新、公告和相关信息； 改善我们的服务和网站功能； 分析数据和统计信息，以改进用户体验； 支持客户服务和技术支持； 履行法律义务。
     <br />     1.3 数据保留：我们将在满足收集目的所必需的时间范围内保留您的个人数据，除非法律要求或允许我们保留更长时间。
      <br />     2. 数据共享和披露 
     <br />      2.1 数据共享：当合理必需时，本公司可能会与第三方服务提供商共享您的个人数据，用于以下目的： 支持我们的业务运营和提供服务； 履行我们的合同义务； 处理付款和退款事务； 分析数据和提供统计信息； 防止欺诈和确保网站安全。 我们要求这些第三方服务提供商遵守与您个人数据保密和保护相关的法律法规。
      <br />     2.2 法律披露：在特定情况下，本公司可能根据法律法规的规定披露您的个人数据，包括但不限于回应法律要求、维护合法权益、保护公共利益等。
      <br />      3. 数据安全：本公司会采取合理的安全措施来保护您的个人数据，防止未经授权的访问、使用、修改、披露或破坏。
      <br />       4. Cookie 和其他技术：为了提供更好的用户体验和网站功能，我们可能会使用Cookie和其他类似技术来收集和存储您的个人数据。
      <br />      5. 第三方链接：本网站可能包含指向其他第三方网站的链接。请注意，我们无法控制这些第三方网站的隐私政策和行为，因此本隐私协议仅适用于本公司的网站。当您访问这些链接时，请仔细阅读并了解相应网站的隐私政策。 
       <br />     6. 权利保护：根据适用法律的要求，您享有一定的数据保护权利。您可以随时行使您的权利，包括但不限于访问、更正、删除、限制处理和数据移植。如需行使您的权利或有任何相关问题，请联系我们的客户服务团队。
      <br />       7. 隐私政策的变更：本公司有权随时更新、修改或更改本隐私协议的条款。更新后的隐私协议将在本网站上发布，并自发布之日起生效。我们建议您在使用本网站之前定期检查隐私协议的变更。
      <br />        8. 联系我们：如果您对本隐私协议或您的个人数据有任何问题或意见，或希望行使您的权利，请通过以下方式联系我们： 成都咚佳旭科技有限公司 地址：成都市武侯区领事馆路7号1栋1单元7层705号 电话：18381023988 电子邮件：wudianxiaban_aigc@163.com 感谢您阅读并理解我们的隐私协议。</div>
  
</template>

<script>
export default {

}
</script>

<style lang="less">
.policies{
    padding:0px 56px;
font-family: Source Han Sans CN;
font-size: 14px;
font-weight: normal;
line-height: 32px;
letter-spacing: 0em;
height:calc(100% - 120px);
 overflow-y: scroll;
/* Grey/10 */
color: #1F2126;
padding-bottom: 56px;

}
</style>
<template>
  <div class="policies">

  欢迎使用“五点下班”AI网站。在注册并使用本网站前，请务必仔细阅读并理解以下条款和条件。通过注册并使用本网站，即表示您同意遵守本协议所述的所有约定和条件。
    <br /> 1. 协议要求 
     <br />1.1 使用本网站即表示您同意接受本协议中所述的所有条款和条件。 
    <br /> 1.2 本协议适用于所有注册用户，在使用本网站提供的服务之前，请确保您已年满法定年龄并具有法律授权能力。 
   <br />  1.3 如果不同意本协议的任何条款，请不要注册或使用本网站。
    <br />  2. 注册与账户 
    <br />  2.1 在注册“五点下班”会员网站时，您需要提供真实、准确、完整的个人信息，并保证其隐私和安全。
     <br />  2.2 您负有保管账户和密码的责任，并对您的账户和密码的所有活动负完全责任。
     <br />   2.3 您同意不将您的账户借给第三方使用。 
     <br />   3. 会员账户 3.1 注册成功后，您将获得一个会员账户，该账户仅供您个人使用。
     <br />    3.2 您申请的会员账户仅限于您本人使用，不得转让、出售或授权给他人使用。
     <br />     3.3 您同意对您的会员账户和密码的所有活动负完全责任。
     <br />      4. 服务使用 
      <br />     4.1 本网站为会员提供一系列相关服务，包括但不限于信息浏览、社交互动、在线学习等。
      <br />      4.2 您同意在使用本网站服务时遵守所有适用的法律法规，不发布违法、淫秽、诽谤、反动等违反社会道德的内容。 4.3 您同意不干扰或破坏本网站的正常运行，不采取任何可能妨碍本网站提供服务的行为。
     <br />        5. 知识产权 
       <br />      5.1 本网站上的所有文字、图片、标志、商标、软件、音频、视频、图表以及其它任何形式的信息和内容的知识产权均归“五点下班”所有。
       <br />       5.2 未经“五点下班”书面许可，严禁以任何形式使用、复制、修改、传播、展示或制作衍生作品。
       <br />        6. 有偿服务 
      <br />         6.1 本网站可能提供付费服务，具体的服务内容和费用将在相关页面描述清晰明了。
      <br />          6.2 在使用付费服务前，请仔细阅读并同意相关的付费服务条款。 
      <br />          7. 账号注销 7.1 如需注销您的会员账户，请联系网站客服，并提供必要的验证信息。 
       <br />         7.2 注销账户后，您的个人信息将被删除，无法恢复，请谨慎操作。
       <br />          8. 个人信息
      <br />            8.1 本网站将按照相关法律法规的要求，妥善保护您的个人信息。
       <br />            8.2 您同意“五点下班”根据隐私政策收集和处理您的个人信息。 
       <br />            9. 免责声明 9.1 本网站所提供的所有服务均按现状提供，不作任何明示或暗示的保证。
       <br />             9.2 您在本网站上获取的任何内容和信息均由您自行承担风险和责任。
       <br />              9.3 “五点下班”不对您使用本网站所产生的任何直接或间接损失负责。
       <br />               10. 中止和协议变更 
       <br />               10.1 “五点下班”有权基于实际情况，在任何时候中止、终止或限制您使用本网站的全部或部分功能。
       <br />                10.2 “五点下班”有权随时修改本协议的任何条款，修改后的协议将在本网站上发布，您应定期查阅并了解最新协议内容。 请您在注册前详细阅读以上条款与条件，并确保理解和同意所有内容。如有任何疑问，请随时联系我们的客服团队。感谢您的合作和支持！ 
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
<template>
  <div class="fusion" />
</template>

<script>
export default {
  name: 'Fusion',
  data() {
    return{
      
    }
  }
}
</script>

<style lang="less">
.fusion{
  
}
</style>
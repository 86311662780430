<template>
  <div class="userInfo">
    <div class="title">
      <div class="title-left">
        <el-upload
          class="avatar-uploader"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :disabled="true"
        >
          <!-- <el-avatar v-if="imageUrl" :src="circleUrl"></el-avatar> -->

          <div class="defaultImg">
            <img src="@/static/index/default.png"> 
            <!-- <span>修改</span> -->
          </div>
        </el-upload>
        <div class="text">
          <span v-show="!show">{{ $store.state.userInfo.nickname }}</span> 
          <div
            v-show="show"
            class="editDefault"
          >
            <el-input
              v-model="$store.state.userInfo.nickname"
              class="editInput"
              placeholder=""
            />
            <el-button
              class="edit"
              round
              size="mini"
              :loading="loading"
              type="primary"
              @click="changesumit"
            >
              确认
            </el-button>
            <el-button
              class="edit"
              round
              size="mini"
              @click="cancel"
            >
              取消
            </el-button>
          </div>
        </div>
        <el-button
          v-show="!show"
          round
          size="mini"
          @click="changeDefault"
        >
          修改
        </el-button>
      </div>
      <div class="title-right">
        <div class="text">
          <img
            src="@/static/index/money.png"
          >
          {{ $store.state.userInfo.sysCurrencyNum }}
        </div>
      </div>
    </div>
    <div
      v-if="usageData.membershipType == 'VIP0'"
      class="box"
    >
      <div class="box-item">
        <div class="itemTitle">
          <span class="text-right">会员信息</span>
        </div>
        <div class="itemBox">
          <div class="itemBoxTitle">
            <span class="large">Hi, 普通用户</span>
            <span
              class="mini"
            >开通会员尊享以下服务 
            </span>
          </div>
          <div class="itemContent">
            <div class="tipContent">
              <div class="tip1">
                <span><img src="@/static/user/icon-1.png"></span>
                <p>AI文字</p>
              </div>
              <div class="tip1">
                <span><img src="@/static/user/icon-2.png"></span>
                <p>AI绘画</p>
              </div>
              <div class="tip1">
                <span><img src="@/static/user/icon-3.png"></span>
                <p>AI技能</p>
              </div>
              <div class="tip1">
                <span><img src="@/static/user/icon-4.png"></span>
                <p>工具箱</p>
              </div>
              <div class="tip1">
                <span> <i class="el-icon-more" /></span>
                <p>更新中</p>
              </div>
            </div>
            <el-button
              class="ljkt"
              round
              type=""
              @click="go"
            >
              立即开通
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="box"
    >
      <div class="box-item">
        <div class="itemTitle">
          <span class="text-right">会员信息</span>
        </div>
        <div
          class="itemBox"
          :class="usageData.membershipType != 'VIP0' ? 'vipbg' : ''"
        >
          <div class="itemBoxTitle">
            <span class="large">Hi, {{ usageData.membershipType == 'VIP8' ? '永久会员' : usageData.membershipType == 'VIP7' ? '年度会员' : '月度会员' }}</span>
            <img
              :src="require('@/static/user/' + (usageData.membershipType == 'VIP8' ? 'max.png' : 'plus.png'))"
              class="icon"
            >
          </div>
          <div
            class="ex_date"
            :style="{color: usageData.membershipType == 'VIP8' ? '#9E7360' : '#7A7F8C'}"
          >
            <span>会员有效期：{{ usageData.membershipType == 'VIP8' ? '永久' : usageData.expirationTime }}</span>
          </div>
          <div class="itemContent m_t">
            <div
              class="tipContent"
              :style="{color: usageData.membershipType == 'VIP8' ? '#9E7360' : '#7A7F8C'}"
            >
              <span class="text">已绘画<span
                class="num"
                :style="{color: usageData.membershipType == 'VIP8' ? '#703E28' : '#4E5159'}"
              >{{ usageData.drawUsageNum }}</span>张</span>
              <span class="text">已对话<span
                class="num"
                :style="{color: usageData.membershipType == 'VIP8' ? '#703E28' : '#4E5159'}"
              >{{ usageData.charUsageNum }}</span>次</span>
            </div>
            <el-button
              v-if="usageData.membershipType != 'VIP8'"
              class="ljkt max_btn"
              round
              type=""
              @click="goRenew"
            >
              续费会员
            </el-button>
          </div>
        </div>
      </div>
      <div class="box-item residue">
        <div class="itemTitle pb">
          <span class="text-right">会员权益剩余次数</span>
          <span
            class="record"
            @click="goHistory"
          >查看使用记录</span>
        </div>
        <div class="record_title">
          <span>功能</span>
          <span>剩余次数</span>
        </div>
        <div
          v-scrollActive
          style="height: calc(100% - 90px);overflow-y: scroll;"
        >
          <div
            v-for="item,index in recordData"
            :key="index"
            class="record_title times"
          >
            <span>{{ item.benefitFunTypeDesc }}</span>
            <span>{{ item.remainingNumDesc }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- :product-time-rule="productTimeRule" -->
    <Recharge
      ref="recharge"
      :dialog-visible.sync="dialogVisible"
      :product-uid="productUid"
      :way="way"
      :type-name="typeName"
      :charge-data="chargeData"
    />
    <rightsContrast ref="rights" />
  </div>
</template>

<script>
// import success from '@/pages/user/common/success'
import Recharge from "@/pages/user/common/Recharge";
import rightsContrast from "@/pages/user/common/rightsContrast";
import { getProductType,updateName, remaining, usage } from "@/api/index";
export default {
  components: {
    Recharge,
    rightsContrast
    // success
  },
  data() {
    return {
      imageUrl: "",
      dialogVisible: false,
      productTimeRule: "TIMELESS",
      productUid: "",
      circleUrl: "",
      show:false,
      loading:false,
      value:'用户名',
      tableData: [],
      usageData: {},
      recordData: [],
      way: '',
      typeName: '',
      timeRule: '',
      chargeData:{
      },
      visible: false,
      activeMax: 0,
      contrastList: []
    };
  },
  mounted () {
    usage().then(res => {
      this.usageData = res.data
      // this.typeName = this.usageData.membershipTypeName
      // this.productUid = this.usageData.productUid
      // this.productTimeRule = this.usageData.productTimeRule
      // this.chargeData.practicalPrice = this.usageData.productPrice
    })
    remaining().then(res => {
      this.recordData = res.data
    })
  },
  methods: {
    handleCheckRights() {
      this.$refs.rights.visible = true
    },
    goUpgrade() {
      this.way = '升级会员'
      this.dialogVisible = true;
      getProductType({ productType: this.usageData.upgradeProductType }).then((res) => {
        this.productUid = res.data.productUid;
        this.chargeData = res.data
        this.typeName = this.chargeData.membershipTypeName
        this.$refs.recharge.money = this.chargeData.timeRuleVOS[0].practicalPrice
        this.$refs.recharge.productTimeRule = this.chargeData.timeRuleVOS[0].value
        this.$refs.recharge.activePro = 0
      });
    },
    goRenew() {
      this.way = '续费会员'
      this.dialogVisible = true;
      getProductType({ productType: this.usageData.renewalProductType }).then((res) => {
        this.productUid = res.data.productUid;
        this.chargeData = res.data
        this.typeName = this.chargeData.membershipTypeName
        this.$refs.recharge.money = this.chargeData.timeRuleVOS[0].practicalPrice
        this.$refs.recharge.productTimeRule = this.chargeData.timeRuleVOS[0].value
        this.$refs.recharge.productUid = this.chargeData.productUid
        console.log('this.$refs.recharge.productUid', this.$refs.recharge.productUid, this.chargeData.productUid)
        this.$refs.recharge.activePro = 0
      });
    },
    goHistory() {
      this.$router.push('/user/history')
    },
    changeDefault(){
      this.show=true
    },
    cancel(){
      this.show=false
    },
    handleAvatarSuccess() {
      console.log();
    },
    changesumit(){
      this.loading=true
      this.value=this.$store.state.userInfo.nickname
       updateName({nickName:this.value}).then(res=>{
        if(res.data){
          this.$store.dispatch("GetUserInfo");
            this.show=false
            this.loading=false
          }
       })
    },
    showdialog() {
      this.way = '充值金豆'
      this.dialogVisible = true;
      getProductType({ productType: "RECHARGE_BEAN_QUANTITY" }).then((res) => {
        this.productUid = res.data.productUid;
        this.$refs.recharge.productTimeRule = res.data.timeRuleVOS[0].value
        this.$refs.recharge.money = 30
      });
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    go() {
      this.$router.push("/member");
    },
  },
};
</script>

<style lang="less">
.userInfo {
  padding: 32px 145px;
  height: 100%;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .text {
        margin: 0 16px;
      }
      .editDefault{
        display: flex;
        align-items: center;
        .edit{
        //  width: 56px;
height: 30px;
margin-left: 12px;
        }
        .editInput{
          .el-input__inner{
                font-family: Source Han Sans CN;
font-size: 14px;
font-weight: normal;

letter-spacing: 0em;

/* Grey/10 */
color: #1F2126;
          }
      
        }
      }
      .defaultImg{
      
        width: 64px;
        height: 64px;
        border-radius: 50% 50% ;
        overflow: hidden;
        position: relative;
        box-sizing: border-box;
        img{
          width: 64px;
        }
        span{
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 0;
          height: 0;
          background-color:rgba(0,0,0,0.5);
          font-family: Source Han Sans CN;
font-size: 14px;
font-weight: normal;
line-height: normal;
text-align: center;
letter-spacing: 0em;
color: #fff;

        }
        &:hover{
        span{
            width: 100%;
          height: 100%;
        }
        }
      }
    
    }
    .title-right {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .text {
        margin-right: 24px;
        display: flex;
        align-items: center;
        img {
          width: 16px;
          margin-right: 8px;
        }
      }
      .charge {
        box-sizing: border-box;
        border: 1px solid #d8b09d;
        font-family: Source Han Sans CN;
        font-size: 14px;
        font-weight: normal;

        letter-spacing: 0em;
        width: 70px;
        height: 32px;
        color: #a47965;
        &:hover {
          background-color: #fff;
        }
      }
    }
  }
  .box {
    height: calc(100% - 155px);
    .box-item {
      margin-top: 32px;
      .itemTitle {
        display: flex;
        justify-content: space-between;
        .text-right {
          position: relative;
          font-family: Source Han Sans CN;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          padding-left: 10px;
          /* Grey/10 */
          color: #1f2126;
          &::before {
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -7px;
            content: "";
            width: 4px;
            height: 14px;
            border-radius: 999px;
            opacity: 1;

            /* Grey/10 */
            background: #1f2126;
          }
        }
        .text-left {
          a {
            font-family: Source Han Sans CN;
            font-size: 14px;
            font-weight: normal;
            line-height: 22px;
            letter-spacing: 0em;
            text-decoration: none;
            /* Grey/8 */
            color: #4e5159;
          }
        }
        .record{
          color: #4E5159;
          font-size: 14px;
          cursor: pointer;
        }
        .check{
          color: #EE9E49;
          cursor: pointer;
          font-size: 14px;
        }
      }
      .pb{
        padding-bottom: 22px;
      }
      .itemBox {
        padding: 20px 24px;
        margin-top: 12px;
        opacity: 1;

        background: url(../../static/user/bg-card.png) no-repeat;
        background-size: 100% 100%;

        box-sizing: border-box;
        /* Grey/2 */

        .itemBoxTitle {
          .large {
            font-family: Source Han Sans CN;
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0em;

            /* Grey/8 */
            color: #4e5159;
          }
          .icon{
            width: 24px;
            height: 24px;
            position: relative;
            top: 5px;
            margin-left: 4px;
          }
          .mini {
            margin-left: 16px;
            color: #7a7f8c;

            /* 开通会员尊享以下服务， */
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 12;

            /* 查看详情 */
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 12px;
            a {
              text-decoration: none;
              color: #7a7f8c;
            }
          }
        }
        .ex_date{
          font-family: Source Han Sans CN;
          font-size: 12px;
          line-height: 20px;
          margin: 8px 0;
          position: relative;
          // display: flex;
          // justify-content: space-between;
          // align-items: center;
          .plus_btn{
            position: absolute;
            right: 0;
            top: -10px;
            background: transparent;
            color: #4E5159;
            border: 1px solid #646873;
            height: 38px;
          }
        }
        .itemContent {
          margin-top: 30px;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .tipContent {
            .tip1 {
              display: inline-block;
              margin-right: 32px;
              span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 34px;
                color: #fff;
                height: 34px;
                opacity: 1;
                border-radius: 50% 50%;
                background: linear-gradient(315deg, #f0d9b8 0%, #caa56f 100%);
                text-align: center;
                line-height: 34px;
                img {
                  width: 16px;
                  height: 16px;
                }
              }
              p {
                font-family: Source Han Sans CN;
                font-size: 12px;
                font-weight: normal;
                line-height: 20px;
                letter-spacing: 0em;

                /* Grey/8 */
                color: #4e5159;
              }
            }
            .text{
              font-size: 14px;
              margin-right: 40px;
              .num{
                font-size: 20px;
                font-weight: 500;
                margin: 0 4px 0 8px;
                color: #4E5159;
              }
            }
          }
          .ljkt {
            background: linear-gradient(290deg, #bb6f40 0%, #e8ae78 100%);
            border: none;
            color: #fff;
            font-size: 14px;
            font-weight: 500;
          }
        }
        .m_t{
          margin-top: 22px;
        }
        .plus_btn2{
          background: linear-gradient(290deg, #3A3C44 0%, #656875 99%) !important;
          color: #fff;
        }
        .max_btn{
          background: linear-gradient(290deg, #8A3E20 0%, #9E7360 100%) !important;
          color: #fff;
        }
      }
      .vipbg{
        background: url(../../static/user/vipbg.png) no-repeat;
        background-size: 100% 100%;
      }
      .itemBox2 {
        .el-table--enable-row-transition .el-table__body td.el-table__cell {
          border-bottom: 1px solid #dadde5;
        }
        .qyTable {
          height: 56px;
          th {
            background-color: #f2f4f7;
            color: #1f2126;
            font-size: 16px;
            border-bottom: 1px solid #fff;
            font-weight: 500;
            &:first-child {
              border-radius: 8px 0px 0px 8px;
            }
            &:nth-child(2) {
              border-radius: 0px 8px 8px 0px;
            }
          }
        }
        .el-table--enable-row-hover
          .el-table__body
          tr:hover
          > td.el-table__cell {
          background-color: #fff;
        }
        .el-table th.el-table__cell > .cell {
          padding: 0 24px;
        }
        .el-table .cell {
          padding: 0 24px;
        }
      }
      .record_title{
        // width: 614px;
        height: 46px;
        font-size: 14px;
        color: #3D3D3D;
        padding: 0 24px;
        box-sizing: border-box;
        font-weight: 500;
        border-radius: 8px;
        background: #F2F4F7;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .times{
        background: transparent;
        border-bottom: 1px solid #DADDE5;
        border-radius: 0;
        span:nth-of-type(2){
          color: #9498A6;
        }
      }
    }
    .residue{
      height: calc(100% - 235px);
    }
  }
}
</style>
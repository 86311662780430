<template>
  <div class="user">
    <div class="contentBox">
      <div class="left">
        <div class="leftContent">
          <ul>
            <li>
              <router-link
                to="/user/userinfo"
                active-class="active"
              >
                <SvgIcon
                  name="userinfo"
                  :color="$route.path === '/user/userinfo' ? '#fff' : ''"
                />个人信息
              </router-link>
            </li>
            <li>
              <router-link
                to="/user/invite"
                active-class="active"
              >
                <SvgIcon
                  name="invite"
                  :color="$route.path === '/user/invite' ? '#fff' : ''"
                />
                邀请奖励
              </router-link>
            </li>
            <li>
              <router-link
                to="/user/order"
                active-class="active"
              >
                <SvgIcon
                  name="order"
                  :color="$route.path === '/user/order' ? '#fff' : ''"
                />我的订单
              </router-link>
            </li>
            <li>
              <router-link
                to="/user/history"
                active-class="active"
              >
                <SvgIcon
                  name="history"
                  :color="$route.path === '/user/history' ? '#fff' : ''"
                />使用记录
              </router-link>
            </li>
            <li>
              <router-link
                to="/user/public"
                active-class="active"
              >
                <SvgIcon
                  name="public"
                  :color="$route.path === '/user/public' ? '#fff' : ''"
                />公开作品
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="right">
        <router-view />
      </div>
    </div>
    <company />
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";
  import company from  "@/components/company.vue"
export default {
  components: {
    SvgIcon,
    company
  },
  data() {
    return {};
  },
};
</script>

<style lang="less">
.user {
  width: 100%;
  box-sizing: border-box;
  padding: 76px 16px 16px 16px;
  height: 100%;
  overflow: hidden;
  background: #f8f9fc;
  .contentBox {
     width: 1152px;
  margin: 0 auto;

    display: flex;
    justify-content: space-between;
    height: calc(100% - 23px);
  

    .left {
      width: 224px;
      height: 100%;
      margin-right: 16px;
      .leftContent {
        border-radius: 16px;
        opacity: 1;
        padding: 16px;
        box-sizing: border-box;
        /* Grey/1 */
        background: #f2f4f7;
        height: 100%;
        ul {
          li {
            list-style: none;
            a {
              
              text-decoration: none;
              padding: 12px 16px;
              width: 100%;
              border-radius: 16px;
              box-sizing: border-box;
              font-family: Source Han Sans CN;
              font-size: 14px;
              font-weight: 500;
              line-height: 22px;
              letter-spacing: 0em;
              display: flex;
              align-items: center;
              /* Grey/8 */
              color: #4e5159;
              span {
                margin-right: 12px;
              }
              &.active {
                color: #fff;

                /* Grey/10 */
                background: #1f2126;
              }
            }
          }
        }
      }
    }
    .right{
        width: 904px;
height: 100%;
border-radius: 16px;
opacity: 1;
overflow: hidden;
background: #FFFFFF;
    }
  }
}

.right {
  flex: 1;
  width: 100%;
  height: 100%;
  margin-right: 16px;
}
</style>
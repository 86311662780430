<template>
  <span class="spansvg">
    <svg
      v-if="name == 'userinfo'"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <clipPath id="master_svg0_147_05188/147_04511">
          <rect
            x="0"
            y="0"
            width="16"
            height="16"
            rx="0"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#master_svg0_147_05188/147_04511)">
        <g>
          <path
            d="M8.00942,7.0266C6.34743,7.0266,4.99529,5.67483,4.99529,4.0133C4.99529,2.35177,6.34743,1,8.00942,1C9.67143,1,11.02355,2.35177,11.02355,4.0133C11.02355,5.67483,9.67143,7.0266,8.00942,7.0266ZM8.00942,2.20532C7.01221,2.20532,6.20094,3.01637,6.20094,4.0133C6.20094,5.01023,7.01221,5.82128,8.00942,5.82128C9.00662,5.82128,9.8179,5.01023,9.8179,4.0133C9.8179,3.01637,9.00662,2.20532,8.00942,2.20532ZM6.93377,15C5.1141000000000005,15,3.4499500000000003,14.9284,2.577168,14.0592C2.194185,13.6778,2,13.1839,2,12.5914C2,11.9957,2.193036,11.4084,2.5737579999999998,10.84581C2.912433,10.34536,3.3820699999999997,9.8902,3.96964,9.49297C5.13198,8.70721,6.60442,8.256540000000001,8.00942,8.256540000000001C9.41381,8.256540000000001,10.8832,8.707460000000001,12.0409,9.4937C12.6258,9.89097,13.0932,10.34634,13.4301,10.84718C13.8083,11.4093,14,11.9962,14,12.5914C14,13.1856,13.8056,13.6804,13.4223,14.0622C12.467,15.0137,10.56227,15.0063,8.545480000000001,14.9986C8.188089999999999,14.9973,7.8307,14.9972,7.47332,14.9986C7.29238,14.9993,7.11232,15,6.93377,15ZM8.00942,13.7919C8.18965,13.7919,8.370149999999999,13.7926,8.5501,13.7933C9.48297,13.7969,10.36392,13.8003,11.10849,13.7105C11.83222,13.6232,12.3244,13.4543,12.5715,13.2083C12.6671,13.113,12.7943,12.9453,12.7943,12.5914C12.7943,11.874,12.2728,11.1084,11.36333,10.49073C10.40062,9.83686,9.178149999999999,9.46186,8.00942,9.46186C5.62997,9.46186,3.2056500000000003,11.0412,3.2056500000000003,12.5914C3.2056500000000003,12.9431,3.33258,13.1102,3.42808,13.2053C4.031750000000001,13.8065,5.857749999999999,13.7994,7.46872,13.7933C7.64874,13.7926,7.82927,13.7919,8.00942,13.7919Z"
            :fill="color"
            fill-opacity="1"
          />
        </g>
      </g>
    </svg>
    <svg
      v-if="name == 'invite'"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <clipPath id="master_svg0_147_05207/147_04513">
          <rect
            x="0"
            y="0"
            width="16"
            height="16"
            rx="0"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#master_svg0_147_05207/147_04513)">
        <g>
          <path
            d="M14.226353125,3.336793125C12.564853125,1.284791125,9.554383125,0.968291125,7.501883125,2.6302931249999997L5.927383125,3.905293125L6.713883125,4.876793125L8.288383125,3.601793125C9.803993125,2.374476125,12.027553125,2.608183125,13.254853125,4.123793125000001C14.482153125,5.639403125,14.248453125,7.862973125,12.732853125,9.090293124999999L11.045883125,10.456293125L11.832353125,11.427793125L13.519353125,10.061793125C15.571953125,8.400113125,15.888453125,5.388993125,14.226353125,3.336793125ZM8.067883125,12.868253125C6.552353125,14.096753125,4.327783125,13.863553125,3.100043125,12.347353125C1.872303125,10.831243125,2.1066531250000002,8.606793125,3.623383125,7.379793125L5.357383125,5.975793125L4.570883125,5.004293125L2.836883125,6.408293125C0.784883125,8.069793125,0.468383125,11.080793125,2.129883125,13.132753125C3.791383125,15.184753125,6.802383125,15.501253125,8.854383125,13.839753125L10.496883125,12.509753125L9.710383125,11.538293125L8.067883125,12.868253125ZM10.564883125,5.520293125L11.365353125,6.480293125L5.997383125,10.953793125L5.197383125,9.993793125L10.564883125,5.520293125Z"
            :fill="color"
            fill-opacity="1"
          />
        </g>
      </g>
    </svg>

    <svg
      v-if="name == 'order'"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
     
      version="1.1"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <clipPath id="master_svg0_147_05227/147_04519">
          <rect
            x="0"
            y="0"
            width="16"
            height="16"
            rx="0"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#master_svg0_147_05227/147_04519)">
        <g>
          <path
            d="M11.6521059375,7.76170953125C11.6279059375,7.41901353125,11.359055937499999,7.16064453125,11.026605937500001,7.16064453125L4.8965369375,7.16064453125C4.5667769375,7.16064453125,4.2880859375,7.44219753125,4.2880859375,7.77541153125C4.2880859375,8.10856153125,4.5667769375,8.39005453125,4.8965369375,8.39005453125L11.0440159375,8.39005453125C11.373885937499999,8.39005453125,11.6525859375,8.10856153125,11.6525859375,7.77541153125L11.6521059375,7.76170953125Z"
            :fill="color"
            fill-opacity="1"
          />
        </g>
        <g>
          <path
            d="M6.5508764375,2.98923109375L9.4069084375,2.98923109375C9.8405784375,2.98923109375,10.2069884375,2.5707410937499997,10.2069884375,2.07540109375C10.2069884375,1.5715350937500001,9.8402284375,1.16162109375,9.3894484375,1.16162109375L6.5508764375,1.16162109375C6.1000934375,1.16162109375,5.7333984375,1.57151909375,5.7333984375,2.07540109375C5.7333984375,2.5792810937499997,6.1001104375,2.98923109375,6.5508764375,2.98923109375Z"
            :fill="color"
            fill-opacity="1"
          />
        </g>
        <g>
          <path
            d="M11.0440059375,4.87353515625L4.8965369375,4.87353515625C4.5667769375,4.87353515625,4.2880859375,5.15502415625,4.2880859375,5.48830215625C4.2880859375,5.82151615625,4.5667769375,6.10300515625,4.8965369375,6.10300515625L11.0440159375,6.10300515625C11.373885937499999,6.10300515625,11.6525859375,5.82151615625,11.6525859375,5.48830215625C11.6525659375,5.15502415625,11.3738759375,4.87353515625,11.0440059375,4.87353515625Z"
            :fill="color"
            fill-opacity="1"
          />
        </g>
        <g>
          <path
            d="M11.026605937500001,9.64111328125L4.8965369375,9.64111328125C4.5667769375,9.64111328125,4.2880859375,9.92266628125,4.2880859375,10.25588028125C4.2880859375,10.58903028125,4.5667769375,10.87052328125,4.8965369375,10.87052328125L11.0440159375,10.87052328125C11.373885937499999,10.87052328125,11.6525859375,10.58903028125,11.6525859375,10.25588028125L11.6521059375,10.24217828125C11.6279059375,9.89949828125,11.3590359375,9.64111328125,11.026605937500001,9.64111328125Z"
            :fill="color"
            fill-opacity="1"
          />
        </g>
        <g>
          <path
            d="M13.827546875,2.106676109375C13.500746875,1.753075109375,13.031746875,1.566162109375,12.471346875,1.566162109375L11.652846875,1.566162109375C11.326706875,1.566162109375,10.974716875,1.7810401093750001,10.974716875,2.128069109375C10.974716875,2.456103109375,11.310616875,2.778042109375,11.652846875,2.778042109375L12.471346875,2.778042109375C12.852846875,2.778042109375,13.099346875,3.026992109375,13.099346875,3.412352109375L13.099346875,12.929662109375C13.099346875,13.315062109375,12.852746875,13.563962109375,12.471346875,13.563962109375L3.659506875,13.563962109375C3.264496875,13.563962109375,2.822666875,13.292762109375,2.822666875,12.929662109375L2.822666875,3.412362109375C2.822666875,3.0270121093749998,3.0691268750000003,2.778062109375,3.450586875,2.778062109375L4.478056875,2.778062109375C4.7795168750000006,2.778062109375,4.982046875,2.516829109375,4.982046875,2.128085109375C4.982046875,1.776228109375,4.793636875,1.566178083375,4.478056875,1.566178083375L3.450586875,1.566178083375C2.306215875,1.566178083375,1.623046875,2.262938109375,1.623046875,3.429952109375L1.623046875,12.841662109375C1.623046875,14.016662109375,2.374569875,14.775862109375,3.5376568749999997,14.775862109375L12.384246875,14.775862109375C13.618946875,14.775862109375,14.298946875,14.088962109375,14.298946875,12.841662109375L14.298946875,3.429942109375C14.298946875,2.897882109375,14.135946875,2.440305109375,13.827546875,2.106676109375Z"
            :fill="color"
            fill-opacity="1"
          />
        </g>
      </g>
    </svg>
    <svg
      v-if="name == 'history'"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <clipPath id="master_svg0_147_05231/147_04519">
          <rect
            x="0"
            y="0"
            width="16"
            height="16"
            rx="0"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#master_svg0_147_05231/147_04519)">
        <g>
          <path
            d="M13.6442,3.92664C12.036,1.673457,9.75199,0.708629,7.03207,1.0761498C3.26941,1.5845470000000001,0.819272,4.81154,1.0104421,8.35891C1.214912,12.153,4.23308,14.9821,7.97127,14.9999C9.1079,15.0053,10.15727,14.7643,11.1241,14.2656C11.3123,14.1686,11.5069,14.068,11.6643,13.9299C11.9328,13.6942,12.0204,13.3927,11.8065,13.0744C11.6051,12.7748,11.3261,12.661,10.97907,12.8306C10.78802,12.924,10.60698,13.039,10.41284,13.1247C8.30321,14.0559,6.28847,13.8643,4.4843,12.4355C2.6732,11.0014,2.00008,9.04904,2.48405,6.80097C2.9770000000000003,4.5110399999999995,4.4475999999999996,3.02964,6.72036,2.49893C8.99803,1.967056,10.96075,2.67068,12.407,4.5036000000000005C13.8787,6.36885,14.0087,8.44633,13.0123,10.59721C12.9341,10.766,12.8195,10.92313,12.771,11.0993C12.6853,11.4106,12.7663,11.6955,13.0635,11.8548C13.348,12.0074,13.644,11.9801,13.8604,11.724C14.0127,11.5437,14.1399,11.3334,14.2398,11.1185C15.4066,8.609,15.2472,6.17244,13.6442,3.92664ZM8.645859999999999,5.65897C8.64348,5.04536,8.654119999999999,4.43108,8.62777,3.81845C8.61172,3.44597,8.37327,3.24247,8.002320000000001,3.22346C7.66219,3.206,7.41066,3.42222,7.34617,3.79016C7.31884,3.94617,7.32845,4.10937,7.32825,4.269299999999999C7.32689,5.39001,7.32271,6.51078,7.32876,7.63147C7.33342,8.49377,7.50602,8.67418,8.34296,8.68759C9.00796,8.69828,9.67334,8.69396,10.33845,8.68707C10.52251,8.68515,10.71499,8.67841,10.88821,8.62429C11.2012,8.52648,11.3649,8.2944,11.3429,7.95675C11.3233,7.65567,11.157,7.44875,10.87008,7.38571C10.63966,7.33506,10.39632,7.33345,10.15835,7.32948C9.68605,7.32162,9.21355,7.32716,8.646270000000001,7.32716C8.646270000000001,6.7116,8.64788,6.18529,8.645859999999999,5.65897Z"
            :fill="color"
            fill-opacity="1"
          />
        </g>
      </g>
    </svg>
    <svg
      v-if="name == 'public'"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <clipPath id="master_svg0_1388_29710">
          <rect
            x="0"
            y="0"
            width="16"
            height="16"
            rx="0"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#master_svg0_1388_29710)">
        <g>
          <path
            d="M1.236894,6.25546C2.22506,2.47934,6.0319,0.23077499999999995,9.72285,1.242279C13.2423,2.2075,15.7355,5.67524,14.8033,9.23553C14.5194,10.31976,13.8268,11.2441,12.8771,11.8053C11.9316,12.366,10.79888,12.5185,9.73879,12.2277L8.54568,11.901C8.32704,11.843,8.10318,11.9749,8.0479,12.1942C8.02185,12.2895,8.032350000000001,12.391,8.077069999999999,12.4785L8.07979,12.4851L8.085239999999999,12.4964L8.08874,12.503C8.178180000000001,12.6935,8.353570000000001,13.1509,8.205400000000001,13.7179C8.09226,14.1583,7.80943,14.5361,7.41868,14.7687C7.03229,14.9979,6.56924,15.06,6.13612,14.9405C2.44556,13.9279,0.24833700000000003,10.03275,1.236894,6.25546ZM9.39074,2.51045C6.38462,1.687169,3.28167,3.51962,2.47628,6.59613C1.6712829999999999,9.67225,3.46212,12.8475,6.46824,13.6716C6.57246,13.7008,6.68368,13.6864,6.77701,13.6312C6.87035,13.5759,6.9384,13.4849,6.96562,13.378C6.99078,13.2742,6.97852,13.1649,6.93101,13.0692C6.74452,12.6926,6.70073,12.2611,6.80774,11.8547C7.04962,10.93226,7.97596,10.3847,8.877410000000001,10.63203L10.0713,10.9587C10.80216,11.1592,11.5831,11.0542,12.2351,10.66781C12.8941,10.27555,13.3715,9.63892,13.5635,8.896419999999999C14.2705,6.19557,12.3926,3.33451,9.39074,2.51084L9.39074,2.51045Z"
            :fill="color"
            fill-opacity="1"
          />
        </g>
        <g>
          <path
            d="M10.52863,8.04347C10.56935,7.86863,10.66826,7.71908,10.803899999999999,7.62866C10.93999,7.53804,11.10073,7.5136,11.25189,7.56052L11.562429999999999,7.65694C11.76607,7.71993,11.92534,7.9042,11.97988,8.139890000000001C12.03443,8.37557,11.97625,8.62712,11.82716,8.79981C11.67807,8.97251,11.46025,9.03978,11.25662,8.976790000000001L10.94608,8.88037C10.795580000000001,8.83417,10.666699999999999,8.719719999999999,10.587530000000001,8.56198C10.50931,8.4056,10.48806,8.218589999999999,10.52863,8.04347ZM9.34827,4.62815C9.388639999999999,4.45288,9.487549999999999,4.30375,9.62318,4.21291C9.75928,4.12229,9.920010000000001,4.09784,10.07118,4.14477L10.38172,4.24119C10.694410000000001,4.3344000000000005,10.882159999999999,4.71079,10.79917,5.0780899999999995C10.71481,5.44234,10.39081,5.65874,10.0759,5.56104L9.76572,5.46462C9.61523,5.418419999999999,9.48635,5.30397,9.40718,5.14623C9.328949999999999,4.98985,9.30771,4.80284,9.34827,4.62772L9.34827,4.62815ZM6.38139,3.506274C6.42212,3.331437,6.52103,3.182312,6.6566600000000005,3.0914649C6.7927599999999995,3.000847259,6.9535,2.9764014,7.10466,3.0233299L7.4152000000000005,3.119747C7.72775,3.213152,7.9153,3.589482,7.83229,3.956651C7.74793,4.3209,7.42429,4.5373,7.109389999999999,4.4396L6.79884,4.34404C6.64816,4.29783,6.51912,4.18321,6.43994,4.02522C6.36196,3.868715,6.34097,3.68171,6.38176,3.506703L6.38139,3.506274ZM4.0203247,6.2389600000000005C4.060688,6.06412,4.159596,5.914569999999999,4.295231,5.8241499999999995C4.43128,5.73339,4.592018,5.7087900000000005,4.743227,5.75559L5.05377,5.85243C5.2574000000000005,5.915430000000001,5.4166799999999995,6.09969,5.47122,6.33538C5.52576,6.571070000000001,5.46758,6.82261,5.31849,6.9953C5.1693999999999996,7.168,4.951952,7.23528,4.747954,7.17228L4.437775,7.07629C4.287149,7.0302,4.158121,6.91573,4.0788695,6.757899999999999C4.000663748,6.60135,3.979546,6.41416,4.0203247,6.2389600000000005Z"
            :fill="color"
            fill-opacity="1"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    width: { type: String, default: "" },
    height: { type: String, default: "" },
    prefix: { type: String, default: "el-icon-" },
    name: { type: String, default: "" },
    color: { type: String, default: "#4E5159" },
  },
};
</script>

<style lang='less'>
.spansvg{
  display: flex;
  align-items: center;
}
</style>